import React, { useContext } from 'react';

import TimeboxerButton from '../timeboxer/TimeboxerButton';
import ReportBuilderButton from '../report-builder-2/ReportBuilderButton';
import { AuthContext } from '../../../context/AuthContext';

import { AccountContext } from '../../../context/AccountContext';

function BottomRightCornerMenu() {
  const { user } = useContext(AuthContext);

  const { currentManagerAccountId, currentManagerAccount } =
    useContext(AccountContext);
  return (
    <div
      style={{
        // background: 'red',
        width: 'fit-content',
        height: '60px',
        position: 'absolute',
        right: '0px',
        bottom: '0px',
        zIndex: '16000'
      }}
    >
      <ReportBuilderButton />

      {currentManagerAccount?.isTimeboxingOn && <TimeboxerButton />}
    </div>
  );
}

export default BottomRightCornerMenu;
