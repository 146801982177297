import {
  documentTypeToShortNameDict,
  dayOfWeekAsString
} from '../../components/tableV4/report-builder-2/report-builder-content/dictionary';

import {
  // generateWeeks,
  getWeekNumber,
  getDayAsString,
  generateMonths
} from '../../components/tableV4/roitable_functions/date_functions';

import formatDateYYYYMMDD from '../../components/tableV4/roitable_functions/formatDateYYYYMMDD';

import {
  nonSumEvalFields,
  sumEvalFields,
  plannedEvalFields
} from '../../components/tableV4/fields';

import { prepDataObject } from '../../components/tableV4/report-builder-2/report-builder-content/widgets/prepDataObject';

import { v4 as uuidv4 } from 'uuid';

export const comboChart = ({
  reportPage,
  widget,
  listOfFilteredDataSourceRows,
  aggregatedDateRange,
  startDateForLastDaysData
}) => {
  // const key = widget.metrics?.[0] || 'cost';
  const dataSourceData = listOfFilteredDataSourceRows.filter(
    (row) =>
      [
        'facebook_ads_campaign',
        'google_ads_campaign',
        'cm360_campaign',
        'dv360_insertion_order',
        'snapchat_ads_campaign',
        'tiktok_ads_campaign',
        'pinterest_ads_campaign',
        'readpeak_campaign',
        'strossle_campaign',
        'amazon_dsp_order',
        'taboola_ads_campaign',
        'microsoft_advertising_campaign',
        'microsoft_invest_insertion_order',
        'apple_search_ads_campaign',
        'adform_order',
        'linkedin_ads_campaign_group',
        'manualDataEntry'
      ].includes(row.type) &&
      (row.endDate >= formatDateYYYYMMDD(startDateForLastDaysData) ||
        !row.endDate)
  );

  let widgetGroupByKey = '';
  const groupedByDimensions = Object.groupBy(
    widget?.metrics || [],
    (metric) => metric?.dimensions?.[0] || 'none'
  );
  const dimensions = Object.keys(groupedByDimensions);
  // const dimensions = ['documentType'];

  const allSeries = [];
  let intervalRange_ = [];
  var safeAggregatedDateRange = [];
  let averageDivideCount = 1;
  let groupedSafeAggregatedDataRange = [];

  // console.log('debug9567>row155>intervalRange_: ', intervalRange_);
  // console.log('debug9567>row155>widget.interval: ', widget.interval);
  switch (widget.interval) {
    case 'month':
      var formattedDates = aggregatedDateRange.map((date) =>
        formatDateYYYYMMDD(date)
      );
      intervalRange_ = Object.groupBy(formattedDates, (date) =>
        date.slice(0, 7)
      );
      intervalRange_ = Object.keys(intervalRange_);

      if (new Date(formattedDates[0]).getDate() !== 1) {
        intervalRange_.shift();
      }
      // console.log('debug9567>row164>intervalRange_: ', intervalRange_);
      break;
    case 'week':
      var formattedDates = aggregatedDateRange.map((date) =>
        formatDateYYYYMMDD(date)
      );
      intervalRange_ = Object.groupBy(formattedDates, (date) => {
        let currentWeek = getWeekNumber(new Date(date));
        return `${currentWeek[0]}-${currentWeek[1]}`;
      });

      // intervalRange_ = Object.keys(intervalRange_);

      let currentWeek = getWeekNumber(new Date());
      currentWeek = `${currentWeek[0]}-${currentWeek[1]}`;
      // console.log('currentWeek: ', currentWeek);

      let newIntervalRange = [];

      for (let intervalKey of Object.keys(intervalRange_)) {
        if (
          intervalRange_[intervalKey]?.length === 7 ||
          intervalKey === currentWeek
        ) {
          newIntervalRange.push(intervalKey);
        }
      }

      intervalRange_ = newIntervalRange;
      // console.log('newIntervalRange: ', newIntervalRange);

      // if (new Date(formattedDates[0]).getDate() !== 1) {
      //   intervalRange_.shift();
      // }
      // console.log('debug9567>row164>intervalRange_: ', intervalRange_);
      break;
    case 'dayOfMonth':
      var formattedDates = aggregatedDateRange.map((date) =>
        formatDateYYYYMMDD(date)
      );

      var safeStartIndex = aggregatedDateRange.findIndex(
        (date) => new Date(date).getDate() === 1
      );

      var reverseAggregatedDateRage = [...aggregatedDateRange].reverse();
      // console.log('reverseAggregatedDateRage: ', reverseAggregatedDateRage);

      var safeStopIndex =
        reverseAggregatedDateRage?.findIndex(
          (date) => new Date(date)?.getDate() === 1
        ) + 1;

      safeAggregatedDateRange = (aggregatedDateRange || [])?.slice(
        safeStartIndex,
        -safeStopIndex
      );

      groupedSafeAggregatedDataRange = Object.groupBy(
        safeAggregatedDateRange || [],
        (date) => new Date(date)?.getDate() || null
      );
      // console.log(
      //   'groupedSafeAggregatedDataRange: ',
      //   groupedSafeAggregatedDataRange
      // );

      // FIX CURRENT MONTH OF IF TOTAL SPAN IS LESS THAN A MONTH

      // console.log('safeAggregatedDateRange: ', safeAggregatedDateRange);

      intervalRange_ = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31
      ];

      break;

    case 'dayOfWeek':
      var formattedDates = aggregatedDateRange.map((date) =>
        formatDateYYYYMMDD(date)
      );

      var safeStartIndex = aggregatedDateRange.findIndex(
        (date) => new Date(date).getDay() === 1
      );

      var reverseAggregatedDateRage = [...aggregatedDateRange].reverse();
      // console.log('reverseAggregatedDateRage: ', reverseAggregatedDateRage);

      var safeStopIndex =
        reverseAggregatedDateRage.findIndex(
          (date) => new Date(date).getDay() === 1
        ) + 1;

      safeAggregatedDateRange = aggregatedDateRange.slice(
        safeStartIndex,
        -safeStopIndex
      );

      groupedSafeAggregatedDataRange = Object.groupBy(
        safeAggregatedDateRange,
        (date) => new Date(date).getDay()
      );

      // FIX CURRENT MONTH OF IF TOTAL SPAN IS LESS THAN A MONTH

      // console.log('safeAggregatedDateRange: ', safeAggregatedDateRange);
      intervalRange_ = [1, 2, 3, 4, 5, 6, 0];

      break;

    default:
      intervalRange_ = aggregatedDateRange.map((date) =>
        formatDateYYYYMMDD(date)
      );
  }
  // console.log('intervalRange_: ', intervalRange_);
  // console.log('dimensions: ', dimensions);

  for (let dimension of dimensions) {
    switch (dimension) {
      case 'documentType':
        widgetGroupByKey = 'documentType';
        break;
      case 'name':
        widgetGroupByKey = 'name';
        break;
    }

    let tempGroupBy = {};
    // console.log('widget.dimensions: ', widget.dimensions);

    // console.log('widget.dimensions.length: ', widget.dimensions.length);
    if (dimension !== 'none') {
      tempGroupBy = Object.groupBy(
        dataSourceData,
        (row) => row[widgetGroupByKey]
      );
    } else {
      tempGroupBy = { default: dataSourceData };
      // console.log('widgetGroupByKey: ', widgetGroupByKey);
    }

    // console.log('PieChartWidget>testGroupBy: ', testGroupBy);
    // console.log('tempGroupBy: ', tempGroupBy);

    const dimensionKeys = Object.keys(tempGroupBy);

    // console.log('dimensionKeys: ', dimensionKeys);
    // console.log('tempGroupBy: ', tempGroupBy);
    // console.log('widget.metrics111: ', widget.metrics);

    // console.log('debug9567>row167>intervalRange_: ', intervalRange_);

    // setIntervalRange(intervalRange_);
    // aggregatedDateRange;

    for (let dimensionKey of dimensionKeys) {
      // data source
      // console.log('debug2233>row103>dimensionKey: ', dimensionKey);
      let newSeriesData = [];

      let allPropagationData = [];
      for (let row of tempGroupBy[dimensionKey]) {
        allPropagationData = [
          ...allPropagationData,
          ...(row?.total_list_of_propagation_data || [])
        ];
      }

      allPropagationData = allPropagationData.filter(
        (obj) => new Date(obj.date_start) >= new Date(startDateForLastDaysData)
      );

      for (let row of allPropagationData) {
        let formattedDate = formatDateYYYYMMDD(row.date_start);
        row.date = formattedDate;
        row.month = formattedDate.slice(0, 7);
        let currentWeek = getWeekNumber(new Date(formattedDate));
        row.week = `${currentWeek[0]}-${currentWeek[1]}`;

        row.dayOfMonth = new Date(formattedDate).getDate();
        row.dayOfWeek = new Date(formattedDate).getDay();
      }
      const dimensionsDate = Object.groupBy(
        allPropagationData,
        (obj) => obj[widget.interval]
      );

      // switch (widget.interval) {
      //   case 'month':
      //     intervalRange = Object.keys(dimensionsDate);
      //   default:
      //     intervalRange = aggregatedDateRange;
      // }
      // console.log('intervalRange_: ', intervalRange_);
      for (let intervalUnit of intervalRange_) {
        let sumObject = {};

        for (let dataObj of dimensionsDate?.[intervalUnit] || []) {
          for (let metric of [...sumEvalFields, ...plannedEvalFields]) {
            // console.log('dataObj[metric]: ', dataObj[metric]);
            sumObject[metric] =
              (sumObject[metric] || 0) + (dataObj[metric] || 0);
          }
        }

        let newValue = null;

        sumObject = prepDataObject(sumObject);
        // sumObject.date = date;
        // sumObject.month = new Date(date).getMonth() + 1;
        sumObject.intervalUnit = intervalUnit;

        // calc mean

        // console.log('widget.function: ', widget.function);

        // console.log(
        //   'sumObject[widget.metrics[0]: ',
        //   sumObject[widget.metrics[0]]
        // );

        switch (true) {
          case ['dayOfMonth', 'dayOfWeek'].includes(widget.interval):
            for (let metric of [...sumEvalFields, ...plannedEvalFields]) {
              sumObject[metric] =
                sumObject[metric] &&
                groupedSafeAggregatedDataRange[intervalUnit]?.length
                  ? sumObject[metric] /
                    groupedSafeAggregatedDataRange[intervalUnit]?.length
                  : null;
            }
            // console.log('debug9245>newValue: ', newValue);
            // console.log(
            //   'debug9245>groupedSafeAggregatedDataRange: ',
            //   groupedSafeAggregatedDataRange
            // );

            // console.log(
            //   'debug9245>groupedSafeAggregatedDataRange[intervalUnit]: ',
            //   groupedSafeAggregatedDataRange[intervalUnit]
            // );
            // console.log(
            //   'debug9245>groupedSafeAggregatedDataRange[intervalUnit].length: ',
            //   groupedSafeAggregatedDataRange[intervalUnit].length
            // );
            // console.log('debug9245>intervalUnit: ', intervalUnit);
            // console.log('debug9245>sumObject[metric]: ', sumObject['cost']);
            break;
          case 'sum':
            // console.log('debug9245>newValue: ', newValue);
            // console.log(
            //   'debug9245>groupedSafeAggregatedDataRange: ',
            //   groupedSafeAggregatedDataRange
            // );
            // console.log(
            //   'debug9245>groupedSafeAggregatedDataRange[intervalUnit].length: ',
            //   groupedSafeAggregatedDataRange[intervalUnit].length
            // );
            // newValue = sumObject[widget.metrics[0]];
            // console.log('intervalUnit: ', intervalUnit);
            // console.log('sumObject[metric]: ', sumObject['cost']);
            // console.log(
            //   'debug9245>groupedSafeAggregatedDataRange[intervalUnit]?.length: ',
            //   groupedSafeAggregatedDataRange[intervalUnit]?.length
            // );
            break;
        }

        newSeriesData.push(sumObject);
      }
      // console.log('newSeriesData: ', newSeriesData);
      let metrics_ = [];
      if (dimension === 'none') {
        metrics_ = widget.metrics.filter((obj) => !obj?.dimensions?.length);
      } else {
        metrics_ = widget?.metrics?.filter((obj) =>
          obj?.dimensions?.includes(dimension)
        );
      }

      for (let metricObj of metrics_) {
        let label = '';

        switch (true) {
          case dimension === 'none':
            label = metricObj.metric;
            break;

          case dimension === 'name':
            label = dimensionKey;
            break;

          case dimension === 'documentType':
            label =
              documentTypeToShortNameDict[dimensionKey] +
              ' ' +
              metricObj.metric;
            break;

          default:
            label = metricObj.metric;
        }
        // console.log('dimension: ', dimension);
        // if (
        //   (metricObj?.dimensions?.length > 0 && dimension === 'none') ||
        //   (!metricObj?.dimensions?.includes(dimension) && dimension !== 'none')
        // ) {
        //   continue;
        // }
        const metric = metricObj.metric;

        allSeries.push({
          dimension: dimensionKey,
          chartType: metricObj.chartType,
          dataLineWidth: metricObj.dataLineWidth, // null,
          dataLineStyle: metricObj.dataLineStyle, // undefined, //'solid',
          isDataLineSmooth: metricObj.isDataLineSmooth, // false,
          grouping: metricObj.grouping,
          groupingChartType: metricObj.groupingChartType,
          metric: metricObj.metric,
          stack:
            metricObj.axis +
            '_' +
            metricObj.metric +
            (metricObj?.grouping?.includes('stack') ? '' : ''),
          id: uuidv4(),
          yAxisId: metricObj.axis,
          label,
          data: newSeriesData, // (newSeriesData || []).map((obj) => obj[metric]), //Math.round(obj?.[key])),
          showMark: false
        });
      }
    }

    // console.log('newSeriesData: ', newSeriesData);
    // console.log('dimensionsDate: ', dimensionsDate);
    // console.log('newSeriesData11: ', newSeriesData);

    // switch (true) {
    //   case widget.interval === 'month':
    //     const intervalGroupBy = Object.groupBy(
    //       newSeriesData,
    //       (obj) => obj.month
    //     );
    //     console.log('intervalGroupBy: ', intervalGroupBy);
    //     break;
    // }

    // for (let row of tempGroupBy[dimensionKey]) {
    //   const dimensionsDate = Object.groupBy(
    //     row?.total_list_of_propagation_data || [],
    //     (obj) => formatDateYYYYMMDD(obj.date_start)
    //   );

    //   for (let date of aggregatedDateRange) {
    //     console.log('date111: ', date);
    //     let sumObject = {};
    //     console.log('dimensionsDate[date]: ', dimensionsDate[date]);
    //     for (let dataObj of dimensionsDate[date]) {
    //       for (let metric of widget.metrics) {
    //         console.log('dataObj[metric]: ', dataObj[metric]);
    //         sumObject[metric] += dataObj[metric] || 0;
    //       }
    //     }
    //     newSeriesData.push(sumObject);
    //   }
    //   console.log('dimensionsDate: ', dimensionsDate);
    //   console.log(' newSeriesData: ', newSeriesData);
    //   allSeries.push({ data: newSeriesData });
    // }
  }

  // console.log('allSeries: ', allSeries);

  // const seriesWithData = allSeries.filter((serie) =>
  //   serie.data.some((val) => Boolean(val))
  // );
  // console.log('seriesWithData: ', seriesWithData);
  // setSeries(allSeries);
  //   let dimensionsValue = 0;

  //   for (let row of testGroupBy[dimensionKey]) {
  //     dimensionsValue += row[key];
  //   }
  //   let seriesData = {
  //     // label: documentTypeToNameDict[dimensionKey],
  //     value: dimensionsValue
  //   };

  //   switch (widgetGroupByKey) {
  //     case 'documentType':
  //       seriesData.label = documentTypeToNameDict[dimensionKey];
  //       break;

  //     case 'name':
  //       seriesData.label =
  //         dimensionKey.slice(0, 20) + (dimensionKey.length > 20 ? '...' : '');
  //       break;
  //   }
  //   newSeriesData.push(seriesData);
  // }

  // let totalValue = 0;
  // for (let row of newSeriesData) {
  //   totalValue += row?.value || null;
  // }

  // for (let row of newSeriesData) {
  //   row.share = Math.round((row?.value / totalValue) * 100);
  //   row.value = Math.round(row?.value);
  // }
  // console.log('totalValue: ', totalValue);
  // setSeriesData(newSeriesData);

  let _interval = [];
  switch (widget.interval) {
    case 'month':
      _interval = intervalRange_;
      break;
    case 'week':
      _interval = intervalRange_;
      break;

    case 'dayOfMonth':
      _interval = intervalRange_.map((interv) => String(interv));
      break;
    case 'dayOfWeek':
      _interval = intervalRange_.map((interv) =>
        dayOfWeekAsString(interv)?.slice(0, 3)
      );
      break;
    case 'date':
      _interval = intervalRange_;
      break;
    default:
    // let dates_ = widget.dates?.map((date) => new Date(date));

    // console.log('row313>intervalRange_: ', intervalRange_);
    // _interval = intervalRange_;
  }

  // console.log('_interval: ', _interval);

  // console.log('debug9933>allSeries: ', allSeries);

  let title = '';
  for (let metricObj of widget.metrics) {
    // console.log('metricObj: ', metricObj);
    const metric = metricObj.metric;
    title = title + metric?.[0].toUpperCase() + metric.slice(1) + ', ';
  }
  title = title.slice(0, title?.length - 2);

  let copyOfWidget = {
    ...widget,

    title
  };

  if (!reportPage.isDataFrozen && !widget.isDataFrozen) {
    copyOfWidget = {
      ...copyOfWidget,
      mainData: [...allSeries],
      mainDates: [..._interval],
      mainInterval: copyOfWidget.interval
      // title
    };
  }

  // let comparisonData = [];
  // if (!reportPage.isComparisonDataFrozen && !widget.isComparisonDataFrozen) {
  //   comparisonData = widget.compar;
  // }

  copyOfWidget = {
    ...copyOfWidget,
    comparisonData:
      !reportPage.isComparisonDataFrozen && !widget.isComparisonDataFrozen
        ? allSeries
        : widget.comparisonData,
    // comparisonData: allSeries.map((obj) => ({
    //   ...obj,
    //   stack: obj.stack + '_comp',
    //   label: obj.label + ' (comp.)'
    // })),
    comparisonDates:
      !reportPage.isComparisonDataFrozen && !widget.isComparisonDataFrozen
        ? [..._interval]
        : widget.comparisonDates,
    comparisonInterval:
      !reportPage.isComparisonDataFrozen && !widget.isComparisonDataFrozen
        ? copyOfWidget.interval
        : widget.interval
    // title
  };

  if (
    (!reportPage.isDataFrozen &&
      !reportPage.isComparisonDataFrozen &&
      !widget.isDataFrozen &&
      !widget.isComparisonDataFrozen) ||
    !['week', 'month'].includes(copyOfWidget.mainInterval) ||
    (copyOfWidget.comparisonInterval &&
      !['week', 'month'].includes(copyOfWidget.comparisonInterval))
    // (reportPage.isDataFrozen &&
    //   widget.isDataFrozen &&
    //   copyOfWidget.mainInterval !== copyOfWidget.comparisonInterval) ||
    // (!reportPage.isDataFrozen && !widget.isDataFrozen)
  ) {
    let copyOfWidgetToReturn = {
      ...copyOfWidget,
      data: [
        ...allSeries.map((obj) => ({
          ...obj,
          data: obj.data.map((dataObj) => dataObj[obj.metric])
        }))
      ],
      dates: [..._interval]
    };
    // console.log('debug24>copyOfWidgetToReturn: ', copyOfWidgetToReturn);
    return copyOfWidgetToReturn;
  }

  // copyOfWidget = {
  //   ...copyOfWidget,
  //   data: [
  //     ...(copyOfWidget?.comparisonData || []),
  //     ...(copyOfWidget?.mainData || [])
  //   ],
  //   dates: [
  //     ...(copyOfWidget?.comparisonDates || []),
  //     ...(copyOfWidget?.mainDates || [])
  //   ],
  //   title
  // };
  const weekNumbers = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53
  ];
  const monthNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const newDates = [];
  for (let date of [
    ...(copyOfWidget.mainDates || []),
    ...(widget.isComparisonDataFrozen || reportPage.isComparisonDataFrozen
      ? copyOfWidget?.comparisonDates || []
      : [])
  ]) {
    switch (true) {
      case widget.interval === 'week':
        var formattedD = +date.slice(5);
        if (!newDates.includes(formattedD)) {
          newDates.push(+formattedD);
        }
        break;

      case widget.interval === 'month':
        var formattedD = +date.slice(5);
        if (!newDates.includes(formattedD)) {
          newDates.push(+formattedD);
        }
        break;

      // case widget.interval === 'date':
      //   switch (true) {
      //     case copyOfWidget.mainInterval.length <= 7:
      //       newDates.push(new Date(date).getDate());
      //       break;
      //     // case Math.abs(copyOfWidget.mainInterval[0]):
      //     //   break;
      //   }
    }
  }

  let newDates2 = copyOfWidget?.mainDates?.map((date) =>
    Number(date?.slice(5))
  );

  let uniqueDates = [];
  for (let date of newDates2) {
    if (!uniqueDates.includes(date)) {
      uniqueDates.push(date);
    }
  }

  // console.log('uniqueDates: ', uniqueDates);
  // // newDates2 = [...new Set(newDates2)].sort();
  // const newDates3 = newDates2.map((num) => Number(num)).sort();
  // console.log('newDates3: ', newDates3);
  newDates2 = uniqueDates;

  // newDates.sort();
  // // console.log('debug24>newDates: ', newDates);
  // let newDates2 = [];

  // switch (true) {
  //   case widget.interval === 'week':
  //     newDates2 = weekNumbers.slice(
  //       +newDates[0] - 1,
  //       newDates[newDates.length - 1]
  //     );
  //     break;
  //   case widget.interval === 'month':
  //     newDates2 = monthNumbers.slice(
  //       +newDates[0] - 1,
  //       +newDates[newDates.length - 1]
  //     );
  //     break;
  // }

  // console.log('debug24>newDates2: ', newDates2);

  // let data = [];

  // if(widget.isComparisonDataFrozen){
  //   data = [...data, ...(copyOfWidget?.comparisonData.map((obj) => ({
  //     ...obj,
  //     stack: obj.stack + '_comp',
  //     label: obj.label + ' (comp.)'
  //   })) || []) ];
  // }

  copyOfWidget = {
    ...copyOfWidget,
    data: [
      ...((reportPage.isComparisonDataFrozen ||
        widget.isComparisonDataFrozen) &&
      copyOfWidget?.comparisonData
        ? copyOfWidget?.comparisonData.map((obj) => ({
            ...obj,
            stack: obj.stack + '_comp',
            label: obj.label + ' (comp.)'
          }))
        : []),
      ...(copyOfWidget?.mainData || [])
    ].map((obj) => ({
      ...obj,
      data: newDates2.map(
        (date) =>
          obj.data.filter(
            (dataObj) => +dataObj.intervalUnit.slice(5) === +date
          )?.[0]?.[obj.metric] || 0
      ) //obj.data.map((dataObj) => dataObj[obj.metric])
    })),
    dates: newDates2 // [..._interval]
  };
  // console.log('debug24>row680>comboChart>copyOfWidget: ', copyOfWidget);

  // updateWidget(copyOfWidget, widgetIndex);
  return copyOfWidget;
};
