import { pieChart } from './pieChart';
import { comboChart } from './comboChart';
import { lineChart } from './lineChart';
import { numberChart } from './numberChart';
import { tableChart } from './tableChart';
import { mockupWidget } from './mockupWidget';

import { datePickerWidget } from './datePickerWidget';

import { calcDataFilters } from './calcDataFilters';

export const calcWidget = ({
  isReportBuilderOpen,
  reportPage,
  widget,
  listOfData,
  listOfFilteredDataSourceRows: listOfFilteredDataSourceRows_,
  aggregatedDateRange,
  startDateForLastDaysData,
  selectedDateStart,
  selectedDateStop,
  isDashboardUserMode
}) => {
  if (
    !isReportBuilderOpen
    // ||
    // reportPage?.isDataFrozen
    // ||
    // widget?.isDataFrozen
  ) {
    return widget;
  }

  let listOfFilteredDataSourceRows = listOfFilteredDataSourceRows_;

  // ################### filter ##############################

  let filters = [];
  // if (reportPage?.dataFilters?.length > 0) {
  //   filters = reportPage?.dataFilters;
  // }

  // if (widget?.dataFilters) {
  //   filters = widget?.dataFilters;
  // }

  if (widget?.dataFilters) {
    filters = widget?.dataFilters || [];
    let {
      arrayOfData,

      listOfFilteredDataSourceRows: listOfFilteredDataSourceRows2,

      openRows
    } = calcDataFilters({ listOfData, filters });
    // console.log('arrayOfDataSourceData: ', arrayOfDataSourceData);

    listOfFilteredDataSourceRows = listOfFilteredDataSourceRows2;
  }

  // #########################################################

  let copyOfWidget = null;
  switch (widget.type) {
    case 'PIE_CHART_WIDGET':
      copyOfWidget = pieChart({
        reportPage,
        widget,
        listOfFilteredDataSourceRows,
        aggregatedDateRange,
        startDateForLastDaysData
      });
      break;
    case 'LINE_CHART_WIDGET':
      copyOfWidget = lineChart({
        reportPage,
        widget,
        listOfFilteredDataSourceRows,
        aggregatedDateRange,
        startDateForLastDaysData
      });
      break;
    case 'COMBO_CHART_WIDGET':
      copyOfWidget = comboChart({
        reportPage,
        widget,
        listOfFilteredDataSourceRows,
        aggregatedDateRange,
        startDateForLastDaysData
      });
      break;
    case 'NUMBER_WIDGET':
      copyOfWidget = numberChart({
        reportPage,
        widget,
        listOfFilteredDataSourceRows,
        aggregatedDateRange,
        startDateForLastDaysData
      });
      break;
    case 'TABLE_CHART_WIDGET':
      copyOfWidget = tableChart({
        reportPage,
        widget,
        listOfFilteredDataSourceRows,
        aggregatedDateRange,
        startDateForLastDaysData
      });
      break;
    case 'MOCKUP_WIDGET':
      copyOfWidget = mockupWidget({ reportPage, widget });
      break;
    case 'DATE_PICKER_WIDGET':
      copyOfWidget = datePickerWidget({
        reportPage,
        widget,
        selectedDateStart,
        selectedDateStop
      });
      break;
    default:
      return widget;
  }

  return copyOfWidget;
};
