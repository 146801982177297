import React, { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Comment from '../tableV4/item-components/body/comment/Comment';
import HistoryEvent from '../tableV4/item-components/body/comment/HistoryEvent';
import Poke from '../tableV4/item-components/body/comment/Poke';

// import Comments from './../tableV4/item-components/body/comment/Comments';
// import CardMenu from './CardMenu';

import './news-feed.css';

import {
  useQuery,
  useLazyQuery,
  useMutation,
  useSubscription
} from '@apollo/client';

import {
  newsFeedItemQuery,
  commentAddedSubscription
} from '../../graphql/queries';

import { AccountContext } from '../../context/AccountContext';
import { AuthContext } from '../../context/AuthContext';

import { styled, useTheme } from '@mui/material/styles';

import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';

import CommentIcon from '@mui/icons-material/Comment';

import Spinner from '../layout/SpinnerSmall';

import { FilterContext } from '../../context/FilterContext';

import ReactionBar from './ReactionBar';

import NewsFeedItemWrapper from './NewsFeedItemWrapper';

import HistorySwitch from './HistorySwitch';

function NewsFeedItem() {
  const [searchParams, setSearchParams] = useSearchParams();
  const manager_account_id = searchParams.get('manager_account_id');
  // const accountId = searchParams.get('accid');
  const newsFeedItemId = searchParams.get('nfiid');
  const newsFeedItemType = searchParams.get('nfit');

  const [accountId, setAccountId] = useState('');

  const navigate = useNavigate();
  const { currentManagerAccountId, currentAccountIds, setCurrentAccountIds } =
    useContext(AccountContext);

  const { filters, setFilters, filterBySelection, setFilterBySelection } =
    useContext(FilterContext);

  // const [includeHistory, setIncludeHistory] = useState(false);

  const theme = useTheme();

  const variables = {
    input: {
      managerAccountId: currentManagerAccountId,
      // accountId: currentAccountIds[0],
      newsFeedItemId: newsFeedItemId, // '636513c25091d1325ce37437',
      newsFeedItemType: newsFeedItemType // 'comment'
    }
  };

  const [
    getData,
    { loading, error, data, refetch, client, startPolling, stopPolling }
  ] = useLazyQuery(newsFeedItemQuery, {
    variables,
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      console.log('RoiTable>Query completed.');
    },
    onError: (error) => {
      console.log('error: ', error);
    }
  });

  const [rerender, setRerender] = useState(0);

  useSubscription(commentAddedSubscription, {
    variables: {
      input: { screen: 'NEWSFEEDITEM', accountIds: [accountId] }
    },
    skip: !Boolean(accountId),
    onSubscriptionData: async ({ client, subscriptionData }) => {
      let scrollToBot = false;

      if (
        messagesEndRef.current.scrollTop >
        messagesEndRef?.current?.scrollHeight - 1000
      ) {
        scrollToBot = true;
      }

      let newComment;

      // const copyOfComments = [...data.comments]

      let action = subscriptionData?.data?.commentAdded?.action;

      const commentObj = { ...subscriptionData?.data?.commentAdded?.comment };

      delete commentObj.path;

      if (
        action === 'add_child_comment'
          ? commentObj.parentDocumentId === data.newsFeedItem._id
          : commentObj._id === data.newsFeedItem._id
      ) {
        switch (action) {
          case 'add_comment':

          case 'add_history_event':
          case 'edit_comment':
            newComment = commentObj;

            break;

          case 'add_child_comment':
            console.log('add_child_comment: ');
            const copyOfObject = { ...data.newsFeedItem };
            copyOfObject.childComments.push(commentObj);

            newComment = copyOfObject;

            break;

          case 'reaction':
            newComment = {
              ...data.newsFeedItem,
              reactions: commentObj.reactions
            };

            break;
        }

        newComment.path = data?.newsFeedItem?.path;

        data.newsFeedItem = newComment;

        setRerender(rerender + 1);
        if (scrollToBot) {
          scrollToBottom();
        }
      }

      // data.newsFeedItem = { ...commentObj };

      // setRerender(rerender + 1);
      // if (scrollToBot) {
      //   scrollToBottom();
      // }
    }
  });

  useEffect(() => {
    if (
      variables?.input?.managerAccountId
      // variables?.input?.accountId
      // variables?.accountsInput?.accountId?.length > 0
    ) {
      console.log('running get data 65555');
      const { data, loading } = getData();
      // console.log('running get data>data: ', data);
    }
  }, []);

  useEffect(() => {
    scrollToBottom();

    if (!accountId && data?.newsFeedItem?.accountId) {
      setAccountId(data?.newsFeedItem?.accountId);
    }
  }, [data]);

  console.log('NewsFeedItem>data: ', data);
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    if (messagesEndRef?.current?.scrollHeight)
      messagesEndRef.current.scrollTop = messagesEndRef?.current?.scrollHeight;
  };

  // const onClickPathHandler = (obj, accountId) => {
  //   // setFilterBySelection([obj]);
  //   // if (!filters.includes('filter_by_selection')) {
  //   //   setFilters([...filters, 'filter_by_selection']);
  //   // }
  //   setFilters([
  //     ...filters,
  //     {
  //       type: 'filter_by_selection',
  //       category: 'array',
  //       field: '_id',
  //       operator: 'EQUALS',
  //       conjunction: 'OR',
  //       // array: [String],
  //       // string: { type: String },
  //       values: [obj],
  //       isCaseSensitive: true,
  //       isIncludingAccounts: true,
  //       isIncludingFolders: true
  //     }
  //   ]);
  //   setCurrentAccountIds([accountId]);
  //   navigate(`/table?manager_account_id=${currentManagerAccountId}`);
  // };

  const commentObj = data?.newsFeedItem;

  const generateCard = (commentObj) => {
    switch (commentObj.type) {
      case 'poke':
        return (
          <NewsFeedItemWrapper
            key={commentObj._id}
            item={{ ...commentObj, _id: commentObj.documentId }}
            commentObj={commentObj}
          >
            <Poke
              key={commentObj._id}
              commentObj={commentObj}
              item={commentObj}
              currentManagerAccountId={currentManagerAccountId}
            />
          </NewsFeedItemWrapper>
        );
        break;

      case 'comment':
        return (
          <NewsFeedItemWrapper
            key={commentObj._id}
            item={{ ...commentObj, _id: commentObj.documentId }}
            commentObj={commentObj}
          >
            <Comment
              key={commentObj._id}
              commentObj={commentObj}
              item={{ ...commentObj, _id: commentObj.documentId }}
              currentManagerAccountId={currentManagerAccountId}
            />
          </NewsFeedItemWrapper>
        );
        break;

      case 'historyEvent':
        return (
          <NewsFeedItemWrapper
            key={commentObj._id}
            item={{ ...commentObj, _id: commentObj.documentId }}
            commentObj={commentObj}
          >
            <HistoryEvent
              key={commentObj._id}
              commentObj={commentObj}
              item={{ ...commentObj, _id: commentObj.documentId }}
              currentManagerAccountId={currentManagerAccountId}
            />
          </NewsFeedItemWrapper>
        );
        break;
      default:
        return <div></div>;
    }
  };

  if (loading) {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Spinner />
      </div>
    );
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100vh - 64px)'
      }}
    >
      {/* <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'right',
          width: '887px'
        }}
      >
        <HistorySwitch
          includeHistory={includeHistory}
          setIncludeHistory={setIncludeHistory}
        />
      </div> */}
      <div
        className="newsfeed-item-wrapper"
        ref={messagesEndRef}
        style={{
          // width: '60%',
          // minWidth: '800px',
          maxWidth: '1100px',
          //   background: 'rgba(255,255,255,0.9)',
          //   marginTop: '50px',
          maxHeight: '85vh',
          overflowY: 'scroll',
          borderRadius: '5px',
          //   padding: '40px',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '15px',
          paddingTop: '10px'
        }}
      >
        {commentObj && generateCard(commentObj)}
      </div>
    </div>
  );
}

export default NewsFeedItem;
