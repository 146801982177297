import React, { useContext, useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CardIcon from './CardIcon';

import SimpleTooltip from '../../../../item-components/body/ganttItem/SimpleTooltip';

import { FilterContext } from '../../../../../../context/FilterContext';

import { DialogContext } from '../../../../../../context/DialogContext';

import CardAvatar from './CardAvatar';

import WarningTooltip from '../../WarningTooltip';

import InfoModal from '../../../info/InfoModal';
import SubTasks from './SubTasks';

import CommentIcon from '@mui/icons-material/Comment';

import Comments from './Comments';

import PersonIcon from '@mui/icons-material/Person';

import { useInView } from 'react-intersection-observer';

import CheckboxItem from '../../../../item-components/body/CheckboxItem';

import rgba2hex from '../../../../roitable_functions/rgba2hex';

import { useTheme } from '@emotion/react';

const differenceInDays = (start, stop) => {
  // var date1 = new Date(start);
  // var date2 = new Date(stop);
  // var Difference_In_Time = date2.getTime() - date1.getTime();
  // var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date(start);
  const secondDate = new Date(stop);

  const diffDays = Math.ceil((secondDate - firstDate) / oneDay);

  return diffDays;
};
// const numberOfDaysLeft = (startDate, endDate) => {
//   const numDaysLeft = differenceInDays(startDate, endDate) + 1;
//   return numDaysLeft > 0 ? numDaysLeft : 0;
// };

function Card({ item, rawArrayOfData, stylesStoredInRedux, hideSelected }) {
  const theme = useTheme();
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });
  const { filters, setFilters, filterBySelection, setFilterBySelection } =
    useContext(FilterContext);

  const { infoModalDocumentOpen, setInfoModalDocumentOpen, setItemId } =
    useContext(DialogContext);

  // ####################### drag and drop ##########################

  const dragStart = (e) => {
    const target = e.target;

    e.dataTransfer.setData(
      'droppedItem',
      JSON.stringify({
        id: target.id, //item._id,
        // id: item._id,
        status: item.status,
        accountId: item.accountId,
        type: item.type,
        level: item.level,
        documentType: item.documentType
        // level_1_container_id: item.level_1_container_id,
        // level_2_container_id: item.level_2_container_id,
        // level_3_container_id: item.level_3_container_id,
        // level_4_container_id: item.level_4_container_id,
        // level_5_container_id: item.level_5_container_id,
        // level_6_container_id: item.level_6_container_id,
        // level_7_container_id: item.level_7_container_id,
        // level_8_container_id: item.level_8_container_id,
        // level_9_container_id: item.level_9_container_id,
        // level_10_container_id: item.level_10_container_id
      })
    );

    setTimeout(() => {
      target.style.display = 'none';
    }, 0);
  };

  const dragEnd = (e, value) => {
    console.log('dragEnd');
    const target = e.target;

    setTimeout(() => {
      target.style.display = 'block';
    }, 0);
  };

  // ########## end of drag and drop #############################

  const onClickHandler = (obj) => {
    // console.log('Card>obj: ', obj);
    // console.log('filters: ', filters);
    // setFilterBySelection([obj._id]);
    // if (!filters.includes('filter_by_selection')) {
    //   setFilters([...filters, 'filter_by_selection']);
    // }
    setFilters([
      ...filters,
      {
        type: 'filter_by_selection',
        category: 'array',
        field: '_id',
        operator: 'EQUALS',
        conjunction: 'OR',
        // array: [String],
        // string: { type: String },
        values: [obj._id],
        isCaseSensitive: true,
        isIncludingAccounts: true,
        isIncludingFolders: true
      }
    ]);
  };

  const [showStartDateTips, setShowStartDateTips] = useState(false);
  const [startDateTipsString, setStartDateTipsString] = useState(false);
  const [startDateTipsColor, setStartDateTipsColor] =
    useState('rgb(255 165 0)');

  const [showEndDateTips, setShowEndDateTips] = useState(false);
  const [endDateTipsString, setEndDateTipsString] = useState(false);
  const [endDateTipsColor, setEndDateTipsColor] = useState('rgb(255 165 0)');

  useEffect(() => {
    // let showDateTips = false;
    // let dateTipsString = '';

    const today = new Date();
    const todayAsLocaleDateString = today.toLocaleDateString();

    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const tomorrowAsLocaleDateString = tomorrow.toLocaleDateString();

    const yesterdayAsLocaleDateString = yesterday.toLocaleDateString();

    const startDateAsLocaleDateString =
      item?.startDate && new Date(item.startDate).toLocaleDateString();

    const endDateAsLocaleDateString =
      item?.endDate && new Date(item.endDate).toLocaleDateString();

    // console.log('kanban>Card>today: ', today);
    // console.log('kanban>Card>startDate: ', startDate);

    const daysTillStart = differenceInDays(today, item.startDate);

    const daysTillEnd = differenceInDays(today, item.endDate);
    // console.log('daysTillStart: ', daysTillStart);

    if (['DRAFT', 'PLANNED', 'READY', 'ACTIVE'].includes(item.status)) {
      if (
        item.endDate &&
        endDateAsLocaleDateString === todayAsLocaleDateString
      ) {
        setShowEndDateTips(true);
        setEndDateTipsString('Ending today.');
      } else if (
        item.endDate &&
        endDateAsLocaleDateString === tomorrowAsLocaleDateString
      ) {
        setShowEndDateTips(true);
        setEndDateTipsString('Ending tomorrow.');
      } else if (item.endDate && daysTillEnd >= 2 && daysTillEnd <= 4) {
        setShowEndDateTips(true);
        setEndDateTipsString(`Ending in ${daysTillEnd} days.`);
      } else if (
        item.endDate &&
        endDateAsLocaleDateString === yesterdayAsLocaleDateString
      ) {
        setShowEndDateTips(true);
        setEndDateTipsString(`Should have ended yesterday.`);
        setEndDateTipsColor('red');
      } else if (item.endDate && daysTillEnd < 1) {
        setShowEndDateTips(true);
        setEndDateTipsString(`Should have ended ${daysTillEnd * -1} days ago.`);
        setEndDateTipsColor('red');
      } else {
        if (item.endDate && showEndDateTips && endDateTipsString) {
          setShowEndDateTips(false);
          setEndDateTipsString('');
        }
      }
    }

    if (['DRAFT', 'PLANNED', 'READY'].includes(item.status)) {
      if (startDateAsLocaleDateString === todayAsLocaleDateString) {
        setShowStartDateTips(true);
        setStartDateTipsString('Starting today.');
      } else if (
        item.startDate &&
        startDateAsLocaleDateString &&
        startDateAsLocaleDateString === tomorrowAsLocaleDateString
      ) {
        setShowStartDateTips(true);
        setStartDateTipsString('Starting tomorrow.');
      } else if (item.startDate && daysTillStart >= 2 && daysTillStart <= 4) {
        setShowStartDateTips(true);
        setStartDateTipsString(`Starting in ${daysTillStart} days.`);
      } else if (
        item.startDate &&
        startDateAsLocaleDateString === yesterdayAsLocaleDateString
      ) {
        setShowStartDateTips(true);
        setStartDateTipsString(`Should have started yesterday.`);
        setStartDateTipsColor('red');
      } else if (item.startDate && daysTillStart < 1) {
        setShowStartDateTips(true);
        setStartDateTipsString(
          `Should have started ${daysTillStart * -1} days ago.`
        );
        setStartDateTipsColor('red');
      } else {
        if (item.startDate && showStartDateTips && startDateTipsString) {
          setShowStartDateTips(false);
          setStartDateTipsString('');
        }
      }
    }
    // switch (item.status) {
    //   case 'ACTIVE':
    //   case 'PAUSED':
    //     if (endDateAsLocaleDateString === todayAsLocaleDateString) {
    //       setShowDateTips(true);
    //       setDateTipsString('Ending today');
    //     } else if (endDateAsLocaleDateString === tomorrowAsLocaleDateString) {
    //       setShowDateTips(true);
    //       setDateTipsString('Ending tomorrow');
    //     } else if (daysTillEnd >= 2 && daysTillEnd <= 4) {
    //       setShowDateTips(true);
    //       setDateTipsString(`Ending in ${daysTillEnd} days`);
    //     } else if (endDateAsLocaleDateString === yesterdayAsLocaleDateString) {
    //       setShowDateTips(true);
    //       setDateTipsString(`Should have ended yesterday`);
    //       setDateTipsColor('red');
    //     } else if (daysTillEnd < 1) {
    //       setShowDateTips(true);
    //       setDateTipsString(`Should have ended ${daysTillEnd * -1} days ago`);
    //       setDateTipsColor('red');
    //     } else {
    //       if (showDateTips && dateTipsString) {
    //         setShowDateTips(false);
    //         setDateTipsString('');
    //       }
    //     }

    //     break;

    //   case 'COMPLETED':
    //   case 'CANCELED':
    //     break;
    //   default:
    //     if (startDateAsLocaleDateString === todayAsLocaleDateString) {
    //       setShowDateTips(true);
    //       setDateTipsString('Starting today');
    //     } else if (
    //       startDateAsLocaleDateString &&
    //       startDateAsLocaleDateString === tomorrowAsLocaleDateString
    //     ) {
    //       setShowDateTips(true);
    //       setDateTipsString('Starting tomorrow');
    //     } else if (daysTillStart >= 2 && daysTillStart <= 4) {
    //       setShowDateTips(true);
    //       setDateTipsString(`Starting in ${daysTillStart} days`);
    //     } else if (
    //       startDateAsLocaleDateString === yesterdayAsLocaleDateString
    //     ) {
    //       setShowDateTips(true);
    //       setDateTipsString(`Should have started yesterday`);
    //       setDateTipsColor('red');
    //     } else if (daysTillStart < 1) {
    //       setShowDateTips(true);
    //       setDateTipsString(
    //         `Should have started ${daysTillStart * -1} days ago`
    //       );
    //       setDateTipsColor('red');
    //     } else {
    //       if (showDateTips && dateTipsString) {
    //         setShowDateTips(false);
    //         setDateTipsString('');
    //       }
    //     }
    // }
  }, [item.startDate, item.endDate, item.status]);

  // const [kanbanCardOpen, setKanbanCardOpen] = useState(false);

  // ################### colors #####################

  const accountColorAsRGBA = item.accountColor
    ? item.accountColor
    : theme?.palette?.mode === 'dark'
    ? 'rgba(255,255,255,0.3)'
    : 'rgba(200,200,255,1)';

  const accountColorAsHex = '#' + rgba2hex(accountColorAsRGBA);

  const transparentAccountColorAsHex =
    accountColorAsHex.slice(0, 7) +
    (theme?.palette?.mode === 'dark' ? '05' : '15');
  // const transparentAccountColorAsHexNexusDocumentIcon =
  //   accountColorAsHex.slice(0, 7) + '10';
  const transparentAccountColorAsHexNexusDocumentIconBackground =
    theme?.palette?.mode === 'dark'
      ? 'rgba(255,255,255,0.3)'
      : accountColorAsHex.slice(0, 7) + '20';

  // ###########################################################

  return (
    <div
      ref={ref}
      id={item._id}
      onDragStart={dragStart}
      onDragEnd={dragEnd}
      draggable
      className="kanbanCard cursorGrab"
      style={{
        minHeight: '80px',
        // display: 'grid',

        // padding: '10px',
        // width: '100%',
        // backgroundColor:
        //   stylesStoredInRedux.styleName === 'light'
        //     ? 'rgba(255,255,255,.9)'
        //     : 'rgba(255,255,255,0.12)',
        backgroundColor:
          theme?.palette?.mode !== 'dark' && 'rgba(255,255,255,0.5)',
        // backgroundImage:
        //   inView &&
        //   (theme?.palette?.mode === 'dark'
        //     ? 'linear-gradient(to top, rgb(70, 70, 81), rgb(85, 85, 100))'
        //     : `linear-gradient(to top left, ${transparentAccountColorAsHex}, ${'rgba(255, 255, 255, 0.7)'})`),
        border: item.highestWarningLevel === 5 && '2px solid red',
        borderRadius: '5px',
        boxShadow:
          '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        backdropFilter: inView && 'blur(50px)',
        margin: '0px 0px 10px 10px'
      }}
    >
      {inView && (
        <div
          style={{
            borderRadius: '5px',
            // margin: '0px 0px 10px 10px',
            padding: '10px',
            backgroundImage:
              inView &&
              (theme?.palette?.mode === 'dark'
                ? 'linear-gradient(to top, rgb(70, 70, 81), rgb(85, 85, 100))'
                : `linear-gradient(to top left, ${transparentAccountColorAsHex}, ${'rgba(255, 255, 255, 0.7)'})`)
          }}
        >
          {!hideSelected.includes('accountName') && (
            <div
              style={{
                // position: 'absolute',
                // left: '0px',
                // top: '-2px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                marginBottom: '12px'
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  left: '0px',
                  top: '-2px',
                  fontSize: '10px',
                  backgroundColor: item.accountColor,
                  color: 'white',
                  padding: '1px 7px',
                  margin: '2px 0px 10px 0px',
                  borderRadius: '5px 0px',
                  width: 'fit-content',
                  maxWidth: '200px',
                  textOverflow: 'ellipsis'
                  // fontWeight: 'bold'
                }}
              >
                <span>{item.accountName}</span>
              </div>
              <div
                style={{
                  position: 'absolute',
                  right: '0px',
                  top: '-2px',
                  margin: '2px 0px'
                }}
              >
                <CheckboxItem item={item} maxHeight="26px" />
              </div>
            </div>
          )}
          {/* {!hideSelected.includes('accountName') && (
            <div
              style={{
                fontSize: '11px',
                // backgroundColor: item.accountColor,
                color: 'white',
                padding: '3px 7px',
                margin: '2px 0px 10px 0px',
                borderRadius: '5px',
                width: 'fit-content',
                maxWidth: '200px',
                textOverflow: 'ellipsis'
                // fontWeight: 'bold'
              }}
            >
              <CheckboxItem item={item} />
            </div>
          )} */}
          <div
            style={{
              wordWrap: 'break-word',
              lineHeight: 'normal',
              padding: '0px 0px 5px 0px'
            }}
          >
            {item?.warnings.length > 0 && (
              <div style={{ display: 'inline-block', paddingRight: '3px' }}>
                <WarningTooltip
                  warnings={item.warnings}
                  subWarnings={item.subWarnings}
                  stylesStoredInRedux={stylesStoredInRedux}
                />
              </div>
            )}

            <div style={{ display: 'inline-block' }}>
              <SimpleTooltip item={item} renderChildren={true}>
                <CardIcon itemType={item.type} />
              </SimpleTooltip>
            </div>

            {item.personal && (
              <div style={{ display: 'inline-block', marginRight: '5px' }}>
                <PersonIcon
                  style={{
                    position: 'relative',
                    top: '3px',
                    height: '15px',
                    width: '14px'
                  }}
                />
              </div>
            )}
            <span
              onClick={() => {
                setItemId(item._id);
                setInfoModalDocumentOpen(true);
              }}
              style={{ fontSize: '12px', fontWeight: 'bold' }}
            >
              {item.name}
            </span>
          </div>
          {!hideSelected.includes('path') && (
            <div
              style={{
                fontSize: '10px',
                padding: '5px 0px',
                wordWrap: 'break-word'
              }}
            >
              {/* <span>{item.path}</span> */}
              <span>
                <span> / </span>
                {(item?.interactivePath || []).map((obj) => {
                  return (
                    <>
                      <span
                        className="hover-grey"
                        style={{
                          cursor: 'pointer'
                          // textDecoration: 'underline'
                        }}
                        onClick={() => onClickHandler(obj)}
                      >
                        {obj.name}
                      </span>
                      <span> / </span>
                    </>
                  );
                })}
              </span>
            </div>
          )}
          {item.showSubTasksOnCard && (
            <SubTasks
              rawArrayOfData={rawArrayOfData}
              item={item}
              stylesStoredInRedux={stylesStoredInRedux}
            />
          )}
          {item?.comments > 0 && (
            <Comments item={item} />
            // <div>
            //   <CommentIcon style={{ fontSize: '9px' }} />{' '}
            //   <span style={{ fontSize: '12px' }}>{item.comments}</span>
            // </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div
              style={{
                fontSize: '10px',
                padding: '5px 0px 0px 0px',
                alignSelf: 'end'
              }}
            >
              <div>
                {item.startDate} - {item.endDate}
              </div>
              {showStartDateTips && (
                <div style={{ color: startDateTipsColor }}>
                  {startDateTipsString}
                </div>
              )}
              {showEndDateTips && (
                <div style={{ color: endDateTipsColor }}>
                  {endDateTipsString}
                </div>
              )}
            </div>
            <div style={{ alignSelf: 'end' }}>
              <CardAvatar item={item} />
            </div>
          </div>
        </div>
      )}

      {/* {infoModalDocumentIdOpen === item._id && (
        <InfoModal
          item={item}
          open={infoModalDocumentIdOpen === item._id}
          setOpen={setInfoModalDocumentIdOpen}
          rawArrayOfData={rawArrayOfData}
        />
      )} */}
    </div>
  );
}

// export default Card;

Card.propTypes = {
  // getPosts: PropTypes.func.isRequired,
  // post: PropTypes.object.isRequired
  // currentManagerAccount: PropTypes.object.isRequired
  // style: PropTypes.object.isRequired,
  // user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  // currentManagerAccount: state.managerAccount.currentManagerAccount,
  // currentAccountIds: state.account.currentAccountIds,
  stylesStoredInRedux: state.style
  // user: state.auth.user
});

export default connect(mapStateToProps, {})(React.memo(Card));
