import React, { useContext, useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SpeedIcon from '@mui/icons-material/Speed';

import { ReportBuilderContext } from '../../../../../context/ReportBuilderContext';

import Slider from '@mui/material/Slider';

import Tooltip from '@mui/material/Tooltip';

import { TextField } from '@mui/material';

import FormatSizeIcon from '@mui/icons-material/FormatSize';
import { widgetStyleKeys } from './../defaultWidgetObject';

export default function BasicPopover({
  valueKey,
  handleClose: handleMenuClose
}) {
  const { widgets, setWidgets, selectedWidgetIds, presetColors } =
    useContext(ReportBuilderContext);
  // console.log('daysToGraph: ', daysToGraph);

  const [value, setValue] = React.useState(false);

  // useEffect(() => {
  //   const defaultFontSize =
  //     widgets.filter((wid) => selectedWidgetIds.includes(wid.id))?.[0]?.[
  //       valueKey
  //     ] || 14;
  //   setValue(defaultFontSize);
  // }, [anchorEl, selectedWidgetIds]);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const copyOfWidgets = [...widgets];
    for (let copyOfWidget of copyOfWidgets) {
      if (selectedWidgetIds.includes(copyOfWidget.id)) {
        switch (true) {
          case valueKey.includes('all_'):
            const keyPart = valueKey.split('all_')[1];

            const keysToUpdate = widgetStyleKeys.filter((str) =>
              str.includes(keyPart)
            );

            for (let k of keysToUpdate) {
              copyOfWidget[k] = !value;
            }
            break;

          default:
            copyOfWidget[valueKey] = !value;
        }
      }
    }

    setValue(!value);
    setWidgets(copyOfWidgets);
  };

  let name = '';
  switch (valueKey) {
    case 'all_FontBold':
      name = 'All Fonts Bold';
      break;
    case 'all_FontItalic':
      name = 'All Fonts Italic';
      break;
    case 'isTitleFontBold':
      name = 'Title Font Bild';
      break;

    case 'isTextFontBold':
      name = 'Text Font Bold';
      break;

    case 'isValueFontBold':
      name = 'Value Font Bold';
      break;

    case 'isDataLabelFontBold':
      name = 'Data Label Font';
      break;

    case 'isLegendFontBold':
      name = 'Legend Font Bold';
      break;
    case 'isLeftAxisLabelFontBold':
      name = 'Left Axis Font Bold';
      break;
    case 'isBottomAxisLabelFontBold':
      name = 'Bottom Axis Label Font Bold';
      break;
    case 'isRightAxisLabelFontBold':
      name = 'Right Axis Font Bold';
      break;

    case 'isBottomAxisTitleFontBold':
      name = 'Bottom Axis Title Bold';
      break;

    case 'isTitleFontItalic':
      name = 'Title Font Italic';
      break;

    case 'isTextFontItalic':
      name = 'Text Font Italic';
      break;

    case 'isValueFontItalic':
      name = 'Value Font Italic';
      break;

    case 'isDataLabelFontItalic':
      name = 'Chart Font Italic';
      break;

    case 'isLegendFontItalic':
      name = 'Legend Font Italic';
      break;
    case 'isLeftAxisLabelFontItalic':
      name = 'Left Axis Font Italic';
      break;
    case 'isBottomAxisLabelFontItalic':
      name = 'Bottom Axis Label Font Italic';
      break;
    case 'isRightAxisLabelFontItalic':
      name = 'Right Axis Font Italic';
      break;

    case 'isBottomAxisTitleFontItalic':
      name = 'Bottom Axis Title Font Italic';
      break;
  }

  return (
    <>
      <Button
        // aria-describedby={id}
        style={{
          width: '100%',

          textAlign: 'start',
          textTransform: 'none',
          width: '100%',
          fontSize: '12px',
          padding: '10px 10px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start'
        }}
        onClick={handleClick}
      >
        <span>{name}</span>
      </Button>
    </>
  );
}
