import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Chip from '@mui/material/Chip';
import StorageIcon from '@mui/icons-material/Storage';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReceiptIcon from '@mui/icons-material/Receipt';
import FaceIcon from '@mui/icons-material/Face';

import MovingIcon from '@mui/icons-material/Moving';

import Checkbox from '@mui/material/Checkbox';

import { documentTypeToNameDict } from './../report-builder-2/report-builder-content/dictionary';
import { DataContext } from '../../../context/DataContext';

import { AccountContext } from '../../../context/AccountContext';

import { Divider } from '@mui/material';

import TextField from '@mui/material/TextField';

export default function BasicMenu({
  // setFilterBySelection,
  // checkedBoxes,
  // setCheckedBoxes,
  setFilters,
  filters,
  // enqueueSnackbar,
  // arrayOfDataSources,
  // filterByDataSource,
  // setFilterByDataSource,
  filter,
  filterIndex
}) {
  const { nexusDocumentStatuses, nexusDocumentInvoiceStatuses } =
    useContext(AccountContext);
  const [filterMenuLoading, setFilterMenuLoading] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const { listOfData } = useContext(DataContext);

  const [options, setOptions] = useState([]);

  const [search, setSearch] = useState('');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    let newFilters = [...filters];
    newFilters.splice(filterIndex, 1);
    setFilters(newFilters);
    // console.info('You clicked the delete icon.');
    // // setFilterBySelection([]);

    // const filteredFilter = filters.filter((filter_) => {
    //   return filter_ !== filter;
    // });

    // setFilters(filteredFilter);
    // setFilterByDataSource([]);
    // setCheckedBoxes([]);
  };

  const handleMenuItemClick = (e, option) => {
    let copyOfFilter = { ...filter };

    switch (true) {
      case option === 'isIncludingAccounts':
        copyOfFilter.isIncludingAccounts = !copyOfFilter.isIncludingAccounts;
        break;
      case option === 'isIncludingFolders':
        copyOfFilter.isIncludingFolders = !copyOfFilter.isIncludingFolders;
        break;

      case option === 'all':
        if (filter.values.length === options.length) {
          copyOfFilter.values = [];
        } else {
          copyOfFilter.values = [...options];
        }
        break;

      default:
        if (copyOfFilter.values.includes(option)) {
          copyOfFilter.values = copyOfFilter.values.filter(
            (val) => val !== option
          );
        } else {
          copyOfFilter.values.push(option);
        }
    }

    // if (option === 'all') {
    //   if (filter.values.length === options.length) {
    //     copyOfFilter.values = [];
    //   } else {
    //     copyOfFilter.values = [...options];
    //   }
    // } else {
    //   if (copyOfFilter.values.includes(option)) {
    //     copyOfFilter.values = copyOfFilter.values.filter(
    //       (val) => val !== option
    //     );
    //   } else {
    //     copyOfFilter.values.push(option);
    //   }
    // }
    let copyOfFiltrers = [...filters];
    copyOfFiltrers.splice(filterIndex, 1, copyOfFilter);

    setFilters(copyOfFiltrers);
  };

  let labelText = 'Data Source Filter';

  let Icon = StorageIcon;

  switch (filter.field) {
    case 'documentType':
      Icon = StorageIcon;
      break;
    case '_id':
      labelText = 'Filter By Selection';
      Icon = CheckCircleIcon;
      break;

    case 'status':
      labelText = 'Filter By Status';
      Icon = ReceiptIcon;
      break;
    case 'invoiceStatus':
      labelText = 'Filter By Invoice Status';
      Icon = ReceiptIcon;
      break;

    case 'members':
      labelText = 'Filter By Members';
      Icon = FaceIcon;
      break;

    case 'pace':
      labelText = 'Filter By Pace';
      Icon = MovingIcon;
      break;

    default:
      labelText = filter.field;
  }

  let width = 'auto';

  switch (filter.field) {
    case 'members':
      width = '300px';
      break;
  }

  return (
    <div>
      {/* <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Dashboard
      </Button> */}
      <Chip
        icon={<Icon />}
        // ref={anchorRef}
        label={<div>{labelText}</div>}
        onClick={handleClick}
        onDelete={handleDelete}
      />

      <Menu
        style={{ width: width }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem
          style={{ height: '36px', padding: '0 20px 0 0' }}
          data-id={'isIncludingAccounts'}
          data-name={'isIncludingAccounts'}
          value={filter.isIncludingAccounts}
          key={'isIncludingAccounts'}
          // selected={option.name === 'All Accounts'}
          onClick={(e) => handleMenuItemClick(e, 'isIncludingAccounts')}
        >
          <Checkbox
            style={{
              transform: 'scale(0.7)'
            }}
            checked={filter.isIncludingAccounts}
            // defaultChecked
            color="default"
            inputProps={{
              'aria-label': 'checkbox with default color'
            }}
          />
          <span style={{ fontSize: '14px' }}>Include Accounts</span>
        </MenuItem>
        <MenuItem
          style={{ height: '36px', padding: '0 20px 0 0' }}
          data-id={'isIncludingFolders'}
          data-name={'isIncludingFolders'}
          value={filter.isIncludingFolders}
          key={'isIncludingFolders'}
          // selected={option.name === 'All Accounts'}
          onClick={(e) => handleMenuItemClick(e, 'isIncludingFolders')}
        >
          <Checkbox
            style={{
              transform: 'scale(0.7)'
            }}
            checked={filter.isIncludingFolders}
            // defaultChecked
            color="default"
            inputProps={{
              'aria-label': 'checkbox with default color'
            }}
          />
          <span style={{ fontSize: '14px' }}>Include Folders</span>
        </MenuItem>
      </Menu>
    </div>
  );
}
