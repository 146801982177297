import React, { useState, useEffect, useContext, useRef } from 'react';
import Tooltip from '@mui/material/Tooltip';

import WidgetDescriptionMenu from './WidgetDescriptionMenu';

import { useTheme } from '@mui/material';

import { AccountContext } from '../../../../../context/AccountContext';
import { ReportBuilderContext } from '../../../../../context/ReportBuilderContext';

import FileUploadIcon from '@mui/icons-material/FileUpload';

import { SnackbarProvider, useSnackbar } from 'notistack';

function MockupWidget({
  widget,
  // displayMode,
  updateWidget,
  widgetIndex,
  // setMouseDownWidgetIndex,
  // // editMode,
  // dropOnWidget,
  dragOver,
  // dragStart,
  // dragEnd,
  // isDraggingWidget,
  // setIsDraggingWidget,
  // onMouseDownHandler,
  onMouseUpHandler,
  // widgetType,
  dropOnWidget
}) {
  const inputRef = useRef(null);
  // console.log('mockupWidge>widget: ', widget);
  const screenImageRef = useRef();

  const mockupRef = useRef();

  const widgetContentRef = useRef();
  // console.log('mockupWidget>widget: ', widget);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { currentManagerAccount } = useContext(AccountContext);
  const theme = useTheme();

  const {
    // widgets,
    // setWidgets,
    widgetIdThatTriggeredContextMenu,
    inputImageTriggerCounter,
    setInputImageTriggerCounter
    // updateWidget,
    // isAxisMenuOpen,
    // toggleIsAxisSettingsOpen
  } = useContext(ReportBuilderContext);

  // const logoUrl =
  //   currentManagerAccount?.logo ||
  //   'https://nexustable-dev.herokuapp.com/static/media/nexuslogo.7d069a28e8fddb231627.png';

  // console.log('logoUrl: ', logoUrl);

  // const [imageUrl, setImageUrl] = useState();

  const [dragActive, setDragActive] = React.useState(false);

  // useEffect(() => {
  //   console.log(`widgetId: ${widget.id}, imageUrl: ', ${imageUrl}`);
  // }, [imageUrl]);

  const [imageNaturalHeight, setImgNaturalHeight] = useState(
    widget.imageNaturalHeight
  );
  const [imageNaturalWidth, setImgNaturalWidth] = useState(
    widget.imageNaturalWidth
  );

  const parseFile = async (e, file) => {
    let copyOfWidget = { ...widget };

    var isFileTransfer = false;
    if (
      isFileTransfer &&
      !['image/png', 'image/jpg', 'image/jpeg'].includes(file.type)
    ) {
      enqueueSnackbar('FILE TYPE NOT SUPPORTED. PLEASE CONTACT SUPPORT', {
        variant: 'error'
      });
      return;
    }
    if (file) {
      e.stopPropagation();

      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = async function () {
        // console.log('img.naturalHeight: ', img.naturalHeight);
        setImgNaturalHeight(this.naturalHeight);
        setImgNaturalWidth(this.naturalWidth);

        copyOfWidget = {
          ...copyOfWidget,
          imageNaturalHeight: img.naturalHeight,
          imageNaturalWidth: img.naturalWidth,
          imageNaturalAspectRatio: img.naturalWidth / img.naturalHeight
        };
        delete copyOfWidget.imageAssetId;

        var canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        canvas.height = this.naturalHeight;
        canvas.width = this.naturalWidth;
        context.drawImage(this, 0, 0);
        var dataUri = canvas.toDataURL('image/png');

        // console.log('dataURL: ', dataUri);

        copyOfWidget.imageDataUri = dataUri;
        updateWidget(copyOfWidget, widgetIndex);
      };

      setDragActive(false);
    }
  };

  const onDropHandler = async (e) => {
    // console.log('widget12312312: ', widget);

    e.preventDefault();
    var isFileTransfer = false;

    if (e.dataTransfer.types) {
      for (var i = 0; i < e.dataTransfer.types.length; i++) {
        if (e.dataTransfer.types[i] == 'Files') {
          isFileTransfer = true;
          break;
        }
      }
    }
    if (!isFileTransfer) {
      return;
    }

    const file = e?.dataTransfer?.files[0];
    console.log('file: ', file);
    parseFile(e, file);
  };

  // useEffect(() => {
  //   if (widget.naturalHeight) {
  //     setImgNaturalHeight(widget.naturalHeight);
  //   }
  //   if (widget.naturalWidth) {
  //     setImgNaturalWidth(widget.naturalWidth);
  //   }
  // }, []);

  // console.log('mockupWidget: ', widget);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();

    var isFileTransfer = false;
    if (e.dataTransfer.types) {
      for (var i = 0; i < e.dataTransfer.types.length; i++) {
        if (e.dataTransfer.types[i] == 'Files') {
          isFileTransfer = true;
          break;
        }
      }
    }

    if (!isFileTransfer) {
      return;
    }
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // console.log('mockupRef?.current?.height: ', mockupRef?.current?.height);
  // console.log('mockupRef: ', mockupRef);
  // console.log(
  //   'widgetContentRef?.current?.height: ',
  //   widgetContentRef?.current?.clientHeight
  // );

  // console.log('widgetContentRef: ', widgetContentRef);
  // console.log('widget: ', widget);

  // console.log(
  //   'test9456>mockupRef?.current?.width / mockupRef?.current?.height: ',
  //   mockupRef?.current?.width / mockupRef?.current?.height
  // );

  // console.log(
  //   "test9456>(widget?.width / widget?.height): ",
  //   widget?.width / widget?.height
  // );
  // console.log('widget.screenImageDataUri: ', widget.screenImageDataUri);

  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      // handleFiles(e.target.files);

      parseFile(e, file);
    }
  };

  const onButtonClick = (e) => {
    e?.stopPropagation();
    e?.preventDefault();
    inputRef.current.click();
  };

  useEffect(() => {
    if (
      Boolean(inputImageTriggerCounter) &
      (String(widget?.id) === String(widgetIdThatTriggeredContextMenu))
    ) {
      onButtonClick();
      setInputImageTriggerCounter(0);
    }
  }, [inputImageTriggerCounter]);

  let screenStyle = {};

  switch (widget.mockupType) {
    case 'PHONE':
      screenStyle = {
        width: widget?.width * 0.945 + 'px',
        height: widget?.height * 0.858 + 'px',
        marginBottom: '2px',
        alignItems: 'center'
      };
      break;

    case 'LAPTOP':
      screenStyle = {
        width: widget?.width * 0.8207 + 'px',
        height: widget?.height * 0.9 + 'px',
        marginBottom: '2px',
        alignItems: 'start',
        top: widget.height * 0.0201,
        left: widget.width * 0.001
      };
      break;
  }

  return (
    <div
      ref={widgetContentRef}
      key={widget.id}
      onDrop={(e) => {
        // console.log('widget12312312: ', widget);
        e.preventDefault();
        // e.stopPropagation();
        // dropOnWidget(e, widget, widgetIndex);
      }}
      onMouseUp={onMouseUpHandler}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {dragActive ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={(e) => onDropHandler(e)}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            onClick={(e) => {
              onButtonClick(e);
            }}
            style={{
              // background: 'red',
              height: '70%',
              width: '70%',
              minWidth: '100px',
              minHeight: '50px',
              border: '1px dashed lightgrey',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '5px'
            }}
          >
            DROP IMAGE HERE <FileUploadIcon />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {widget?.imageDataUri && (
            <div
              style={{
                height: '100%',
                width: '100%',
                position: 'absolute',

                overflow: 'hidden',

                display: 'flex',
                justifyContent: 'center',

                alignItems: screenStyle.alignItems,
                top: screenStyle.top,
                left: screenStyle.left
              }}
            >
              <div
                id={'screen_' + widget.id}
                style={{
                  background:
                    'linear-gradient(335deg,rgb(245,245,245), rgb(255,255,255))', // 'white',
                  // aspectRatio: (345 / 711) * 0.95,
                  // width: widget?.originalWidth * 0.95,
                  // height: widget?.originalHeight * 0.95,
                  width: screenStyle.width, //widget?.width * 0.945 + 'px',
                  height: screenStyle.height, //widget?.height * 0.858 + 'px',
                  // marginBottom: screenStyle.marginBottom, //'2px',
                  // position: 'absolute',
                  // borderRadius: '50px',

                  overflow: 'hidden',
                  // background: 'red',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: screenStyle.alignItems // 'center'
                  // zIndex: 100
                }}
              >
                <div
                  onDragEnter={handleDrag}
                  onDrop={(e) => onDropHandler(e)}
                  draggable={false}
                  style={{
                    // height: '100%',
                    // height:'auto',
                    aspectRatio: widget?.imageNaturalAspectRatio, //`${widget.imageNaturalWidth} / ${widget.imageNaturalHeight}`,

                    width: '100%',
                    // minWidth: widget?.width * 0.995,
                    // margin: '0.1%',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundAttachment: 'fixed',
                    // border-radius: 50%;
                    // border: solid 1px #e6e6e6;
                    // margin: '0 auto',
                    // overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    // img: {
                    //   height: '100%',
                    //   width: '100%'
                    // }
                    // height: '100%',
                    backgroundImage: `url(${widget.imageDataUri})`
                  }}
                ></div>

                {/* <img
                  onDragEnter={handleDrag}
                  onDrop={(e) => onDropHandler(e)}
                  draggable={false}
                  src={widget.imageUrl}
                  alt="Logo"
                  style={{
                    width: '100%',
                    // borderRadius: '5px',
                    display: 'block',
                    // height: '97%',
                    // width:'97%',
                    objectFit: 'cover',
                    overflow: 'hidden'
                    // opacity: 0.5
                    // alignSelf: 'baseline',
                    // margin: '0px 3px',
                    // marginTop: '12px'
                  }}
                /> */}
              </div>
            </div>
          )}

          <img
            ref={mockupRef}
            onDragEnter={handleDrag}
            onDrop={(e) => onDropHandler(e)}
            draggable={false}
            src={widget.mockupImageDataUri}
            alt="Logo"
            style={{
              top: '0px',
              left: '0px',
              position: 'absolute',
              borderRadius: '5px',
              display: 'block',
              height: '100%',
              objectFit: 'cover',
              overflow: 'hidden'
              // zIndex: 0
              // opacity: 0.5
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      )}
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        multiple={true}
        onChange={(e) => {
          console.log('test>e: ', e);
          e.stopPropagation();
          e.preventDefault();
          handleChange(e);
        }}
        onClick={(e) => e.stopPropagation()}
      />
    </div>
  );
}

export default MockupWidget;
