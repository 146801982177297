import React, { useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

import { ReportBuilderContext } from '../../../../../context/ReportBuilderContext';

import Icon from '../Icon';

export default function SliderSizes({ widget, styleKey, min, max, step }) {
  const {
    reportPage,
    reportPages,
    setReportPages,
    // selectedReportPageIds: selectedReportPageIds_,
    presetColors,
    selectedPageId: reportPageId
  } = useContext(ReportBuilderContext);

  const [value, setValue] = React.useState(reportPage?.[styleKey]);

  let selectedReportPageIds_ = [];

  let selectedReportPageIds =
    selectedReportPageIds_.length &&
    selectedReportPageIds_.includes(reportPageId)
      ? selectedReportPageIds_
      : [reportPageId];

  const handleChange = (event, newValue_) => {
    let newValue = newValue_;

    switch (true) {
      // case ['legendFontSize', 'outerBorderRadius', 'textFontSize'].includes(
      //   styleKey
      // ) || styleKey?.includes('FontSize'):
      // case styleKey.includes('FontSize'):
      // // case styleKey.includes('Opacity'):
      // case styleKey.includes('Radius'):
      // case styleKey.includes('Width'):
      //   newValue = newValue + 'px';
      //   break;
      default:
        newValue = newValue;
    }

    const copyOfReportPages = [...reportPages];
    for (let copyOfReportPage of copyOfReportPages) {
      if (selectedReportPageIds.includes(copyOfReportPage.id)) {
        copyOfReportPage[styleKey] = newValue;
      }
    }

    setReportPages(copyOfReportPages);
  };

  // let value = widget?.[styleKey];

  useEffect(() => {
    setValue(
      reportPages.filter((page) =>
        selectedReportPageIds?.includes(page.id)
      )?.[0]?.[styleKey]
    );
  }, [reportPage?.[styleKey]]);

  // console.log('debug34>value: ', value);

  // switch (true) {
  //   // case ['legendFontSize', 'outerBorderRadius', 'textFontSize'].includes(
  //   //   styleKey
  //   // ) || styleKey.includes('FontSize'):

  //   // case styleKey.includes('FontSize'):
  //   // // case styleKey.includes('Opacity'):
  //   // case styleKey.includes('Radius'):
  //   // case styleKey.includes('Width'):
  //   //   value = String(reportPage?.[styleKey])?.split('px')?.[0];
  //   //   break;
  //   default:
  // }

  return (
    <Box
      sx={{
        width: 280,
        // padding: '0px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '4px 16px'
      }}
    >
      <Icon
        valueKey={styleKey}
        style={{ fontSize: '18px', marginRight: '18px' }}
      />
      <Slider
        style={{
          // width: '185px',
          // maxWidth: '185px',
          // minWidth: '185px',
          // padding: '0px',
          margin: '0px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
        size="small"
        // defaultValue={widget[styleKey]}
        // aria-label="Small"
        // valueLabelDisplay="auto"

        step={step}
        marks
        min={min}
        max={max}
        getAriaLabel={() => 'Temperature range'}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        valueLabelFormat={(val) => {
          switch (true) {
            case styleKey.includes('Opacity'):
            case styleKey.includes('Brightness'):
            case styleKey.includes('Contrast'):
            case styleKey.includes('Saturate'):
            case styleKey.includes('Sepia'):
            case styleKey.includes('Invert'):
            case styleKey.includes('Grayscale'):
              return Math.round(val * 100) + '%';
            case styleKey.includes('Rotate'):
              return val + 'deg';
            default:
              return val;
          }
        }}
        // getAriaValueText={valuetext}
      />
    </Box>
  );
}
