import { documentTypeToShortNameDict } from './../../components/tableV4/report-builder-2/report-builder-content/dictionary';

import formatDateYYYYMMDD from '../../components/tableV4/roitable_functions/formatDateYYYYMMDD';

import {
  nonSumEvalFields,
  sumEvalFields,
  plannedEvalFields
} from '../../components/tableV4/fields';

import { prepDataObject } from '../../components/tableV4/report-builder-2/report-builder-content/widgets/prepDataObject';

function compareValues(key, order = 'asc') {
  // console.log('order: ', order);
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) && !b.hasOwnProperty(key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    // let varA = a[key];

    // switch(true){
    //   case typeof a[key] === 'string':
    //   varA =a[key].toUpperCase();
    //   break;
    //   case typeof a[key] === 'number':
    //     break;

    // }

    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    switch (true) {
      case (varA === undefined ||
        varA === null ||
        // isNaN(varA) ||
        varA === '') &&
        (varB === undefined ||
          varB === null ||
          //  isNaN(varB) ||
          varB === ''):
        break;

      case varA === undefined ||
        varA === null ||
        // isNaN(varA) ||
        varA === '':
        comparison = order === 'desc' ? -1 : 1;
        break;

      case varB === undefined ||
        varB === null ||
        // isNaN(varB) ||
        varB === '':
        comparison = order === 'desc' ? 1 : -1;
        break;

      default:
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }
    }

    return order === 'desc' ? comparison * -1 : comparison;
  };
}

export const tableChart = ({
  reportPage,
  widget,
  listOfFilteredDataSourceRows,
  aggregatedDateRange,
  startDateForLastDaysData
}) => {
  if (reportPage.isDataFrozen || widget.isDataFrozen) {
    return widget;
  }
  let widgetGroupByKey = '';

  switch (widget.dimensions[0]) {
    case 'documentType':
      widgetGroupByKey = 'documentType';
      break;
    case 'name':
      widgetGroupByKey = 'name';
      break;
  }
  const testGroupBy = Object.groupBy(
    listOfFilteredDataSourceRows,
    (row) => row[widgetGroupByKey]
  );

  // console.log('PieChartWidget>testGroupBy: ', testGroupBy);

  const dimensionKeys = Object.keys(testGroupBy);

  const newSeriesData = [];
  for (let dimensionKey of dimensionKeys) {
    let dimensionsValueObject = {};

    for (let row of testGroupBy[dimensionKey]) {
      for (let metric of [...sumEvalFields, ...plannedEvalFields]) {
        if (nonSumEvalFields.includes(metric)) {
          continue;
        }

        dimensionsValueObject[metric] =
          (dimensionsValueObject[metric] || null) + (row[metric] || null);
      }
    }
    let seriesData = {
      // label: documentTypeToNameDict[dimensionKey],
      ...dimensionsValueObject
    };

    switch (widgetGroupByKey) {
      case 'documentType':
        seriesData.label = documentTypeToShortNameDict?.[dimensionKey];
        break;

      case 'name':
        seriesData.label = dimensionKey;
        break;
    }

    let addSeries = false;
    for (let metric of [...sumEvalFields, ...plannedEvalFields] || []) {
      if (seriesData[metric]) {
        addSeries = true;
      }
      // if (!nonSumEvalFields.includes(metric)) {
      //   continue;
      // }
      // switch (true) {
      //   case ['cpc', 'cpv', 'cpa', 'roas'].includes(metric):
      //     seriesData[metric] = Math.round(seriesData[metric]);
      //     break;
      //   // case ['ctr'].includes(metric):
      //   //   break;
      //   default:
      //     seriesData[metric] = Math.round(seriesData[metric]);
      // }
    }
    if (addSeries) {
      // seriesData.cpm =
      //   seriesData.impressions &&
      //   (seriesData.cost || 0) / (seriesData.impressions / 1000);

      // seriesData.cpc =
      //   seriesData.clicks && (seriesData.cost || 0) / seriesData.clicks;

      seriesData = prepDataObject(seriesData);

      newSeriesData.push(seriesData);
    }
  }
  // console.log('newSeriesData: ', newSeriesData);
  // let totalValueObject = 0;
  // for (let row of newSeriesData) {
  //   for (let metric of widget.metrics) {
  //     totalValueObject[metric] += row?.[metric] || null;
  //   }
  // }

  // for (let row of newSeriesData) {
  //   // row.share = Math.round((row.value / totalValue) * 100);
  //   row.value = Math.round(row.value);
  // }
  // console.log('totalValueObject: ', totalValueObject);
  const fontSize = 14;
  const columnWidths = {};
  for (let dataObj of newSeriesData) {
    for (let key of Object.keys(dataObj)) {
      // console.log(
      //   'String(columnWidths[key]).length * fontSize: ',
      //   String(columnWidths[key]).length * fontSize
      // );

      // key.length * fontSize < String(dataObj[key]).length * fontSize
      //   ? String(dataObj[key]).length * fontSize
      //   : String(columnWidths[key]).length * fontSize || key.length * fontSize;
      switch (true) {
        case key.length * fontSize < String(dataObj[key]).length * fontSize:
          columnWidths[key] = String(dataObj[key]).length * fontSize;

        case columnWidths[key] * fontSize >
          String(dataObj[key]).length * fontSize:
          break;
        default:
          columnWidths[key] = String(columnWidths[key]).length * fontSize;
      }
    }
  }
  // console.log('columnWidths: ', columnWidths);
  const sortedNewSeriesOfData = newSeriesData.sort(
    compareValues(widget.sortByColumn, widget.sortByOrder)
  );

  const summaryObj = { label: 'Summary' };
  // console.log(' sortedNewSeriesOfData: ', sortedNewSeriesOfData);

  for (let dataObj of sortedNewSeriesOfData) {
    for (let metric of [...sumEvalFields, ...plannedEvalFields]) {
      summaryObj[metric] =
        (summaryObj[metric] || null) + (dataObj[metric] || null);
    }
  }

  sortedNewSeriesOfData.push(prepDataObject(summaryObj));

  let copyOfWidget = { ...widget };
  copyOfWidget = {
    ...copyOfWidget,
    data: sortedNewSeriesOfData,
    columnWidths
  };

  // updateWidget(copyOfWidget, widgetIndex);

  // console.log('copyOfWidget: ', copyOfWidget);

  // console.log('PieChartWidget>widget: ', widget);
  // setSeriesData([...newSeriesData]);
  // setArrayOfDataInCronologicalOrder([...newSeriesData]);
  return copyOfWidget;
};
