import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Chip from '@mui/material/Chip';
import StorageIcon from '@mui/icons-material/Storage';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReceiptIcon from '@mui/icons-material/Receipt';
import FaceIcon from '@mui/icons-material/Face';

import Checkbox from '@mui/material/Checkbox';

import { documentTypeToNameDict } from './../report-builder-2/report-builder-content/dictionary';
import { DataContext } from '../../../context/DataContext';

import { AccountContext } from '../../../context/AccountContext';

import { Divider } from '@mui/material';

import TextField from '@mui/material/TextField';

export default function BasicMenu({
  // setFilterBySelection,
  // checkedBoxes,
  // setCheckedBoxes,
  setFilters,
  filters,
  // enqueueSnackbar,
  // arrayOfDataSources,
  // filterByDataSource,
  // setFilterByDataSource,
  filter,
  filterIndex
}) {
  const { nexusDocumentStatuses, nexusDocumentInvoiceStatuses } =
    useContext(AccountContext);
  const [filterMenuLoading, setFilterMenuLoading] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const { listOfData } = useContext(DataContext);

  const [options, setOptions] = useState([]);

  const [search, setSearch] = useState('');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    let newFilters = [...filters];
    newFilters.splice(filterIndex, 1);
    setFilters(newFilters);
    // console.info('You clicked the delete icon.');
    // // setFilterBySelection([]);

    // const filteredFilter = filters.filter((filter_) => {
    //   return filter_ !== filter;
    // });

    // setFilters(filteredFilter);
    // setFilterByDataSource([]);
    // setCheckedBoxes([]);
  };

  const handleMenuItemClick = (e, option) => {
    let copyOfFilter = { ...filter };

    switch (true) {
      case option === 'isIncludingAccounts':
        copyOfFilter.isIncludingAccounts = !copyOfFilter.isIncludingAccounts;
        break;
      case option === 'isIncludingFolders':
        copyOfFilter.isIncludingFolders = !copyOfFilter.isIncludingFolders;
        break;

      case option === 'all':
        if (filter.values.length === options.length) {
          copyOfFilter.values = [];
        } else {
          copyOfFilter.values = [...options];
        }
        break;

      default:
        if (copyOfFilter.values.includes(option)) {
          copyOfFilter.values = copyOfFilter.values.filter(
            (val) => val !== option
          );
        } else {
          copyOfFilter.values.push(option);
        }
    }

    // if (option === 'all') {
    //   if (filter.values.length === options.length) {
    //     copyOfFilter.values = [];
    //   } else {
    //     copyOfFilter.values = [...options];
    //   }
    // } else {
    //   if (copyOfFilter.values.includes(option)) {
    //     copyOfFilter.values = copyOfFilter.values.filter(
    //       (val) => val !== option
    //     );
    //   } else {
    //     copyOfFilter.values.push(option);
    //   }
    // }
    let copyOfFiltrers = [...filters];
    copyOfFiltrers.splice(filterIndex, 1, copyOfFilter);

    setFilters(copyOfFiltrers);
  };

  const [dictionary, setDictionary] = useState({});
  useEffect(() => {
    let dictionary_ = {};

    switch (true) {
      case filter.field === 'documentType':
        dictionary_ = documentTypeToNameDict;
        break;

      case filter.field === 'status':
        var newDict = {};
        for (let obj of nexusDocumentStatuses) {
          newDict[obj.string] = obj.name;
        }
        dictionary_ = newDict;
        break;

      case filter.field === 'invoiceStatus':
        var newDict = {};
        for (let obj of nexusDocumentInvoiceStatuses) {
          newDict[obj.string] = obj.name;
        }
        dictionary_ = newDict;
        break;

      case filter.field === 'members':
        var newDict = {};
        for (let row of listOfData) {
          for (let member of row.members) {
            newDict[member._id] = member.email;
          }
        }
        dictionary_ = newDict;
        break;
    }

    let options_ = listOfData.map((row) => row[filter.field]);

    switch (true) {
      case filter.field === 'members':
        options_ = []; //options_.map((obj) => obj._id);
        break;
    }

    let newOptions = [];

    console.log('options_: ', options_);
    let optionKeys = [...options_, ...Object.keys(dictionary_)];
    optionKeys = [...new Set(optionKeys)];
    for (let option of optionKeys) {
      if (
        [
          'account',
          'folder',
          'task',
          'subTask',
          'globalTasks',
          'totalActionsDocument'
        ].includes(option)
      ) {
        continue;
      }
      if (!newOptions.includes(option)) {
        newOptions.push(option);
      }
    }

    // if (['documentType'].includes(filter.field)) {
    //   newOptions.unshift('all');
    // }

    setDictionary(dictionary_);

    if (search) {
      switch (filter.field) {
        // case 'members':
        //   newOptions = newOptions.filter((val) =>
        //     dictionary_[val].toLowerCase().includes(search.toLowerCase())||val.toLowerCase().includes(search.toLowerCase())
        //   );
        //   break;
        default:
          newOptions = newOptions.filter(
            (val) =>
              // val.toLowerCase().includes(search.toLowerCase())
              dictionary_[val]
                ?.toLowerCase()
                ?.includes(search?.toLowerCase()) ||
              val?.toLowerCase()?.includes(search?.toLowerCase())
          );
      }
    }

    newOptions = newOptions.filter((option) => Boolean(option));
    setOptions(newOptions.sort());
  }, [listOfData, filter?.field, search]);

  let labelText = 'Data Source Filter';

  let Icon = StorageIcon;

  switch (filter.field) {
    case 'documentType':
      Icon = StorageIcon;
      break;
    case '_id':
      labelText = 'Filter By Selection';
      Icon = CheckCircleIcon;
      break;

    case 'status':
      labelText = 'Filter By Status';
      Icon = ReceiptIcon;
      break;
    case 'invoiceStatus':
      labelText = 'Filter By Invoice Status';
      Icon = ReceiptIcon;
      break;

    case 'members':
      labelText = 'Filter By Members';
      Icon = FaceIcon;
      break;

    default:
      labelText = filter.field;
  }

  let width = 'auto';

  switch (filter.field) {
    case 'members':
      width = '300px';
      break;
  }

  return (
    <div>
      {/* <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Dashboard
      </Button> */}
      <Chip
        icon={<Icon />}
        // ref={anchorRef}
        label={<div>{labelText}</div>}
        onClick={handleClick}
        onDelete={handleDelete}
      />
      <Menu
        style={{ width: width }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <div style={{ padding: '0px 15px 15px 15px', display: 'flex' }}>
          <TextField
            style={{ width: '100%' }}
            onKeyDown={(e) => {
              // e.preventDefault();
              e.stopPropagation();
            }}
            variant="standard"
            size="small"
            id="outlined-controlled"
            label="Search"
            value={search}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
          />
        </div>
        <MenuItem
          style={{ height: '36px', padding: '0 20px 0 0' }}
          data-id={'isIncludingAccounts'}
          data-name={'isIncludingAccounts'}
          value={filter.isIncludingAccounts}
          key={'isIncludingAccounts'}
          // selected={option.name === 'All Accounts'}
          onClick={(e) => handleMenuItemClick(e, 'isIncludingAccounts')}
        >
          <Checkbox
            style={{
              transform: 'scale(0.7)'
            }}
            checked={filter.isIncludingAccounts}
            // defaultChecked
            color="default"
            inputProps={{
              'aria-label': 'checkbox with default color'
            }}
          />
          <span style={{ fontSize: '14px' }}>Include Accounts</span>
        </MenuItem>
        <MenuItem
          style={{ height: '36px', padding: '0 20px 0 0' }}
          data-id={'isIncludingFolders'}
          data-name={'isIncludingFolders'}
          value={filter.isIncludingFolders}
          key={'isIncludingFolders'}
          // selected={option.name === 'All Accounts'}
          onClick={(e) => handleMenuItemClick(e, 'isIncludingFolders')}
        >
          <Checkbox
            style={{
              transform: 'scale(0.7)'
            }}
            checked={filter.isIncludingFolders}
            // defaultChecked
            color="default"
            inputProps={{
              'aria-label': 'checkbox with default color'
            }}
          />
          <span style={{ fontSize: '14px' }}>Include Folders</span>
        </MenuItem>
        <Divider />
        <MenuItem
          style={{ height: '36px', padding: '0 20px 0 0' }}
          data-id={'all'}
          data-name={'all'}
          value={'all'}
          key={'all'}
          // selected={option.name === 'All Accounts'}
          onClick={(e) => handleMenuItemClick(e, 'all')}
        >
          <Checkbox
            style={{
              transform: 'scale(0.7)'
            }}
            checked={filter.values.length === options.length}
            // defaultChecked
            color="default"
            inputProps={{
              'aria-label': 'checkbox with default color'
            }}
          />
          <span style={{ fontSize: '14px' }}>All</span>
        </MenuItem>
        <Divider />
        {[
          ...options.filter((option) => filter.values.includes(option)),
          ...options.filter((option) => !filter.values.includes(option))
        ].map((option) => {
          let checked = filter.values.includes(option);
          return (
            <MenuItem
              style={{
                height: '36px',
                padding: '0 20px 0 0',
                background: checked && 'rgba(180,180,180,0.3)'
              }}
              data-id={option}
              data-name={option}
              value={option}
              key={option}
              // selected={option.name === 'All Accounts'}
              onClick={(e) => handleMenuItemClick(e, option)}
            >
              <Checkbox
                style={{
                  transform: 'scale(0.7)'
                }}
                checked={checked}
                // defaultChecked
                color="default"
                inputProps={{
                  'aria-label': 'checkbox with default color'
                }}
              />
              <span
                style={{
                  fontSize: '14px',
                  width: width,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {dictionary[option] || option}
              </span>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
