import React from 'react';
// import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// const useStyles = makeStyles((theme) => ({
//   fab: {
//     margin: theme.spacing(2)
//   },
//   absolute: {
//     position: 'absolute',
//     bottom: theme.spacing(2),
//     right: theme.spacing(3)
//   }
// }));

export default function SimpleTooltips({
  children,
  item,
  renderChildren,
  showDot,
  hideTooltip
}) {
  // const classes = useStyles();

  let fiberManualRecordIconColor = '#FFFFFF';

  switch (item.status) {
    case 'ACTIVE':
      // case 'DONE':
      fiberManualRecordIconColor = 'green';
      break;
    case 'DONE':
    case 'COMPLETED':
      fiberManualRecordIconColor = 'rgba(0,128,0,0.4)';
      break;
    case 'REMOVED':
      fiberManualRecordIconColor = 'red';
      break;

    case 'DRAFT':
      fiberManualRecordIconColor = '#bcbcbc';
      break;
    case 'PAUSED':
      fiberManualRecordIconColor = 'rgb(62, 142, 208)';
      break;
    default:
      fiberManualRecordIconColor = 'orange';
      break;
  }

  const dictionary = {
    type: 'Type',
    name: 'Name',
    status: 'Status',
    startDate: 'Start Date',
    endDate: 'End Date',
    budget: 'Budget',
    cost: 'Cost',
    pace: 'Pace',
    calcPaceYesterday: 'Calculated Pace Yesterday',
    impressions: 'Impressions',
    viewableImpressions: 'Viewable Impressions',
    measurableImpressions: 'Measurable Impressions',
    eligibleImpressions: 'Eligible Impressions',
    cpm: 'CPM',
    vCpm: 'vCPM',
    videoCompletions: 'Video Completions',
    clicks: 'Clicks',
    cpcv: 'CPCV',
    cpc: 'CPC',
    ctr: 'CTR',
    viewableRate: 'Viewable Rate',
    vcr: 'Video Completion Rate',
    searchImpressionShare: 'Search Impression Share',
    action01Count: item?.action01?.name
      ? item?.action01?.name + ' actions'
      : 'Action 01 actions',
    action02Count: item?.action02?.name
      ? item?.action02?.name + ' actions'
      : 'Action 02 actions',
    action03Count: item?.action03?.name
      ? item?.action03?.name + ' actions'
      : 'Action 03 actions',
    action01Value: item?.action01?.name
      ? item?.action01?.name + ' value'
      : 'Action 01 value',
    action02Value: item?.action02?.name
      ? item?.action02?.name + ' value'
      : 'Action 02 value',
    action03Value: item?.action03?.name
      ? item?.action03?.name + ' value'
      : 'Action 03 value',

    action01Roas: item?.action01?.name
      ? item?.action01?.name + ' roas'
      : 'Action 01 roas',
    action02Roas: item?.action02?.name
      ? item?.action02?.name + ' roas'
      : 'Action 02 roas',
    action03Roas: item?.action03?.name
      ? item?.action03?.name + ' roas'
      : 'Action 03 roas',

    action01Crr: item?.action01?.name
      ? item?.action01?.name + ' crr'
      : 'Action 01 crr',
    action02Crr: item?.action02?.name
      ? item?.action02?.name + ' crr'
      : 'Action 02 crr',
    action03Crr: item?.action03?.name
      ? item?.action03?.name + ' crr'
      : 'Action 03 crr',

    action01Cpa: item?.action01?.name
      ? item?.action01?.name + ' cpa'
      : 'Action 01 cpa',
    action02Cpa: item?.action02?.name
      ? item?.action02?.name + ' cpa'
      : 'Action 02 cpa',
    action03Cpa: item?.action03?.name
      ? item?.action03?.name + ' cpa'
      : 'Action 03 cpa'
  };

  const displayKeys = [];

  for (let k of [
    'type',
    'status',
    'name',
    'startDate',
    'endDate',
    'budget',
    'cost',
    'pace',
    'calcPaceYesterday',
    'impressions',
    // 'viewableImpressions',
    // 'measurableImpressions',
    // 'eligibleImpressions',
    'cpm',
    'vCpm',

    'videoCompletions',
    'clicks',
    'cpcv',
    'cpc',
    'ctr',
    // 'viewableRate',
    // 'vcr',
    // 'searchImpressionShare',
    'action01Count',
    'action01Cpa',
    'action01Value',
    'action01Roas',
    'action01Crr',
    'action02Count',
    'action02Cpa',
    'action02Value',
    'action02Roas',
    'action02Crr',
    'action03Count',
    'action03Cpa',
    'action03Value',
    'action03Roas',
    'action03Crr'
  ]) {
    if (item[k]) {
      displayKeys.push(k);
    }
  }

  const formatValue = (key) => {
    switch (key) {
      case 'status':
        return (
          item['status'][0].toUpperCase() +
          item['status'].substring(1).toLowerCase()
        );
      case 'ctr':
        return (
          item?.ctr &&
          (item?.ctr * 100)?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }) + '%'
        );

      case 'viewableRate':
        return (
          item?.viewableRate &&
          (item?.viewableRate * 100)?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }) + '%'
        );

      case 'vcr':
        return (
          item?.vcr &&
          (item?.vcr * 100)?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }) + '%'
        );

      case 'searchImpressionShare':
        return (
          item?.searchImpressionShare &&
          (item?.searchImpressionShare * 100)?.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }) + '%'
        );

      case 'pace':
        return (
          item?.pace &&
          item?.pace?.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }) + '%'
        );

      case 'calcPaceYesterday':
        return (
          item?.calcPaceYesterday &&
          item?.calcPaceYesterday?.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }) + '%'
        );

      case 'action01Roas':
      case 'action02Roas':
      case 'action03Roas':
      case 'action01Crr':
      case 'action02Crr':
      case 'action03Crr':
      case 'cpcv':
      case 'cpc':
        return item?.[key]?.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });

      default:
        return item?.[key]?.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        });
    }
  };

  const infoRows = displayKeys.map((k, index) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'end',
          padding: '7px',
          // marginTop: '10px',
          // marginBottom: '10px',
          background: index % 2 === 0 && 'rgba(255,255,255,0.1)',
          borderRadius: '5px'
        }}
      >
        <span style={{ marginRight: '10px', fontSize: '12px' }}>
          {dictionary[k]}:
        </span>
        <span style={{ fontSize: '12px', overflow: k === 'name' && 'clip' }}>
          {formatValue(k)}
        </span>
      </div>
    );
  });

  if (renderChildren) {
    return (
      <Tooltip
        title={
          <div
            style={{ width: '180px', paddingLeft: '5px', paddingRight: '5px' }}
          >
            {infoRows}
          </div>
        }
      >
        <span>{children}</span>
      </Tooltip>
    );
  }

  return (
    <Tooltip
      followCursor
      arrow
      placement="right"
      PopperProps={{
        style: {
          visibility: hideTooltip ? 'hidden' : 'visible'
        }
      }}
      title={
        <div
          style={{
            width: '240px',
            // paddingLeft: '5px',
            // paddingRight: '5px',
            paddingTop: '5px',
            paddingBottom: '5px',
            backdropFilter: 'blur(10px)'
          }}
        >
          {infoRows}
        </div>
      }
    >
      {showDot ? (
        <div
          style={{
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          {/* <FiberManualRecordIcon
            style={{
              // border: '1px solid white',
              borderRadius: '15px',
              fontSize: '10px',
              verticalAlign: 'middle',
              color: fiberManualRecordIconColor,
              padding: '0px',
              marginRight: '5px'
            }}
          /> */}
          <span
            style={{
              height: '10px',
              width: '10px',
              minWidth: '10px',
              backgroundColor: fiberManualRecordIconColor,
              borderRadius: '50%',
              display: 'inline-block',
              border: '1px solid white',
              marginRight: '5px'
            }}
          >
            {' '}
          </span>
          <span
            style={{
              userSelect: 'none',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {children}
          </span>
          {/* <IconButton aria-label="delete">
          <DeleteIcon />
        </IconButton> */}
        </div>
      ) : (
        children
      )}
    </Tooltip>
  );
}
