import React, { useContext } from 'react';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Divider } from '@mui/material';

import { DialogContext } from '../../../../context/DialogContext';
import { InterfaceContext } from '../../../../context/InterfaceContext';

export default function BasicTooltip({ item }) {
  const { editSetFiltersDialogOpen, setEditSetFiltersDialogOpen } =
    useContext(DialogContext);

  const { setEditSetFilterMenuItem } = useContext(InterfaceContext);
  const keyStyle = { color: 'rgba(200,200,200,1)' };

  const title = (
    <div style={{ padding: '0px 0px 4px 0px' }}>
      <div style={{ padding: '5px 5px 0px 5px' }}>Filters:</div>

      {item?.setFilters?.length ? (
        item.setFilters.map((setFilter) => (
          <div
            style={{
              background: 'rgba(150,150,150,0.3',
              borderRadius: '5px',
              padding: '5px 10px',
              marginTop: '7px'
            }}
          >
            <div>
              <span style={keyStyle}>Field:</span>{' '}
              <span style={{ fontWeight: 'bold' }}>{setFilter.field}</span>
            </div>
            <div>
              <span style={keyStyle}>Operator:</span> {setFilter.operator}
            </div>
            <div>
              <span style={keyStyle}>Conjunction:</span> {setFilter.conjunction}
            </div>
            {setFilter.type === 'ARRAY' && (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div>
                  <span style={keyStyle}>List:</span>
                </div>
                <ul style={{ fontWeight: 'bold', marginLeft: '5px' }}>
                  {setFilter?.values?.map((str) => (
                    <li>{str}</li>
                  ))}
                </ul>
              </div>
            )}
            {/* {setFilter.type === 'STRING' && (
              <div style={{ fontWeight: 'bold' }}>
                <span style={keyStyle}>String:</span> {setFilter.string}
              </div>
            )}
            {setFilter.type === 'STRING' && (
              <div>
                <span style={keyStyle}>Case Sensitive:</span>{' '}
                {setFilter.isCaseSensitive ? 'true' : 'false'}
              </div>
            )} */}
          </div>
        ))
      ) : (
        <div>No filter</div>
      )}
    </div>
  );
  return (
    <Tooltip title={title}>
      {/* <IconButton> */}
      <FilterAltIcon
        onClick={() => {
          setEditSetFilterMenuItem(item);
          setEditSetFiltersDialogOpen(true);
        }}
        style={{ marginLeft: '5px', fontSize: '12px', cursor: 'pointer' }}
      />
      {/* </IconButton> */}
    </Tooltip>
  );
}
