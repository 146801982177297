import { documentTypeToShortNameDict } from './../../components/tableV4/report-builder-2/report-builder-content/dictionary';

import formatDateYYYYMMDD from '../../components/tableV4/roitable_functions/formatDateYYYYMMDD';

import {
  nonSumEvalFields,
  sumEvalFields,
  plannedEvalFields
} from '../../components/tableV4/fields';

import { prepDataObject } from '../../components/tableV4/report-builder-2/report-builder-content/widgets/prepDataObject';

export const numberChart = ({
  reportPage,
  widget,
  listOfFilteredDataSourceRows,
  aggregatedDateRange,
  startDateForLastDaysData
}) => {
  let sumObject = {};
  for (let row of listOfFilteredDataSourceRows) {
    for (let key of [...sumEvalFields, ...plannedEvalFields]) {
      sumObject[key] = (sumObject[key] || 0) + (row[key] || 0);
    }
  }

  let newValue = null;

  sumObject = prepDataObject(sumObject);

  newValue = sumObject[widget.metrics[0]?.metric];

  // console.log('sumObject: ', sumObject);
  // switch (true) {
  //   case widget.metrics[0] === 'cpm':
  //     newValue = sumObject['impressions']
  //       ? sumObject['cost'] / (sumObject['impressions'] / 1000)
  //       : '-';
  //     break;

  //   case widget.metrics[0] === 'vCpm':
  //     newValue = sumObject['viewableImpressions']
  //       ? sumObject['cost'] / (sumObject['viewableImpressions'] / 1000)
  //       : '-';
  //     break;

  //   case widget.metrics[0] === 'cpcv':
  //     newValue = sumObject['videoCompletions']
  //       ? sumObject['cost'] / sumObject['videoCompletions']
  //       : '-';
  //     break;

  //   case widget.metrics[0] === 'cpc':
  //     newValue = sumObject['clicks']
  //       ? sumObject['cost'] / sumObject['clicks']
  //       : '-';
  //     break;

  //   case widget.metrics[0] === 'ctr':
  //     newValue = sumObject['impressions']
  //       ? (sumObject['clicks'] / sumObject['impressions']) * 100
  //       : '-';
  //     break;

  //   case widget.metrics[0] === 'action01Roas':
  //     newValue = sumObject['cost']
  //       ? sumObject['action01Value'] / sumObject['cost']
  //       : '-';
  //     break;

  //   case widget.metrics[0] === 'action01Cpa':
  //     newValue = sumObject['action01Count']
  //       ? sumObject['cost'] / sumObject['action01Count']
  //       : '-';
  //     break;

  //   case widget.metrics[0] === 'action01Vpa':
  //     newValue = sumObject['action01Count']
  //       ? sumObject['cost'] / sumObject['action01Count']
  //       : '-';
  //     break;

  //   case widget.metrics[0] === 'action02Roas':
  //     newValue = sumObject['cost']
  //       ? sumObject['action02Value'] / sumObject['cost']
  //       : '-';
  //     break;

  //   case widget.metrics[0] === 'action02Cpa':
  //     newValue = sumObject['action02Count']
  //       ? sumObject['cost'] / sumObject['action02Count']
  //       : '-';
  //     break;

  //   case widget.metrics[0] === 'action02Vpa':
  //     newValue = sumObject['action02Count']
  //       ? sumObject['cost'] / sumObject['action02Count']
  //       : '-';
  //     break;

  //   case widget.metrics[0] === 'action03Roas':
  //     newValue = sumObject['cost']
  //       ? sumObject['action03Value'] / sumObject['cost']
  //       : '-';
  //     break;

  //   case widget.metrics[0] === 'action03Cpa':
  //     newValue = sumObject['action03Count']
  //       ? sumObject['cost'] / sumObject['action03Count']
  //       : '-';
  //     break;

  //   case widget.metrics[0] === 'action03Vpa':
  //     newValue = sumObject['action03Count']
  //       ? sumObject['cost'] / sumObject['action03Count']
  //       : '-';
  //     break;

  //   case [...sumEvalFields, ...plannedEvalFields].includes(widget.metrics[0]):
  //     newValue = sumObject[widget.metrics[0]];
  //     break;

  //   default:
  //     newValue = 'error';
  // }

  const formattedValue = Math.round((newValue + Number.EPSILON) * 100) / 100;
  // (
  //   Math.round((newValue + Number.EPSILON) * 100) / 100
  // ).toLocaleString();

  // setValue(formattedValue);
  let copyOfWidget = { ...widget };

  if (!reportPage.isDataFrozen && !widget.isDataFrozen) {
    copyOfWidget = {
      ...copyOfWidget,
      mainValue: newValue === undefined ? '-' : formattedValue
      // comparisonValue: newValue === undefined ? '-' : formattedValue
    };
  }

  if (!reportPage.isComparisonDataFrozen && !widget.isComparisonDataFrozen) {
    copyOfWidget = {
      ...copyOfWidget,
      // value: newValue === undefined ? '-' : formattedValue,
      comparisonValue: newValue === undefined ? '-' : formattedValue
    };
  }
  copyOfWidget = {
    ...copyOfWidget,
    value: copyOfWidget.mainValue
    // comparisonValue: newValue === undefined ? '-' : formattedValue
  };
  return copyOfWidget;
};
