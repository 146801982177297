const dateWidget = async (widget, pres, slide, chartFillColors) => {
  // slide.addShape(pres.shapes.ROUNDED_RECTANGLE, {
  //   fill: {
  //     color: 'FFFFFF',
  //     transparency: 5
  //   },
  //   border: { pt: '1', color: 'f1f1f1', transparency: 10 },
  //   w: widget.width / 72 / 2,
  //   h: widget.height / 72 / 2,
  //   x: +widget?.left / 72 / 2,
  //   y: widget.top / 72 / 2,

  //   rectRadius: 0.05,
  //   shadow: {
  //     type: 'outer',
  //     blur: 6,
  //     offset: 2,
  //     angle: 45,
  //     color: '000000',
  //     opacity: 0.2
  //   }
  // });

  slide.addText(widget.value, {
    w: widget.width / 72 / 2,
    h: widget.height / 72 / 2,
    x: +widget?.left / 72 / 2,
    y: widget.top / 72 / 2,

    margin: 3,
    // fontFace: 'Roboto', // 'Arial',
    // fontSize: 8,
    // color: '000000',
    fontFace: widget?.textFontFamily || 'Roboto', // 'Roboto', // 'Arial',
    // fontSize: (+widget?.bodyTextFontSize / 96) * 72 || 7,
    fontSize: +widget?.textFontSize / 2 || 7,
    color: widget?.textFontColor || '#000000',
    bold: widget?.isTextFontBold || false,
    italic: widget?.isTextFontItalic || false,
    // underline: true,
    isTextBox: true,
    align: widget.horizontalTextAlign, //'left'
    valign: widget.verticalTextAlign
    // fontFace: widget?.fontFamily || 'Roboto', // 'Roboto', // 'Arial',
    // // fontSize: (+widget?.bodyTextFontSize / 96) * 72 || 7,
    // fontSize: +widget?.fontSize?.split('px')[0] / 2 || 7,
    // color: widget?.fontColor || '#000000',
    // bold: widget?.isFontBold || false,
    // italic: widget?.isFontItalic || false,
    // // bold: true,
    // // underline: true,
    // isTextBox: true,
    // align: 'center'
    // fill: { color: 'F1F1F1' }
    // highlight: '#bcbcbc'
  });

  let titleWidth = Math.round(widget.title.length * 9 + 10);
  // if (titleWidth < 120) {
  //   titleWidth = 120;
  // }

  // slide.addShape(pres.shapes.ROUNDED_RECTANGLE, {
  //   fill: {
  //     color: 'ababab',
  //     transparency: 90
  //   },
  //   border: { pt: '1', color: 'f1f1f1', transparency: 10 },
  //   w: (titleWidth + 20) / 72 / 2,
  //   h: 25 / 72 / 2,
  //   x:
  //     +widget?.left / 72 / 2 +
  //     widget?.width / 72 / 2 -
  //     (titleWidth + 20) / 72 / 2,
  //   y: widget.top / 72 / 2 + 0 / 72 / 2,

  //   rectRadius: 0.01
  //   // shadow: {
  //   //   type: 'outer',
  //   //   blur: 6,
  //   //   offset: 2,
  //   //   angle: 45,
  //   //   color: 'bcbcbc',
  //   //   opacity: 0.2
  //   // }
  // });

  // slide.addText(widget.title, {
  //   w: titleWidth / 72 / 2,
  //   h: 20 / 72 / 2,
  //   x:
  //     +widget?.left / 72 / 2 +
  //     widget?.width / 72 / 2 -
  //     (titleWidth + 10) / 72 / 2,
  //   y: widget.top / 72 / 2 + 2 / 72 / 2,

  //   margin: 0,
  //   fontFace: 'Roboto', // 'Arial',
  //   fontSize: 6,
  //   color: '000000',
  //   bold: true,
  //   // underline: true,
  //   isTextBox: true,
  //   align: 'left'
  //   // fill: { color: 'F1F1F1' }
  //   // highlight: '#bcbcbc'
  // });
};

export default dateWidget;
