import React, { useState, useEffect, useContext } from 'react';
import { withStyles, makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { dataSourceErrorsQuery } from './../../graphql/queries';

import { useLazyQuery } from '@apollo/client';

// const HtmlTooltip = withStyles((theme) => ({
//   tooltip: {
//     backgroundColor: '#f5f5f9',
//     color: 'rgba(0, 0, 0, 0.87)',
//     maxWidth: 600,
//     maxHeight: '50vw',
//     // overflow: 'scroll',
//     overflow: 'hidden',
//     fontSize: theme.typography.pxToRem(12),
//     border: '1px solid #dadde9',
//     padding: '5px 5px 0px 5px'
//   }
// }))(Tooltip);

import { AccountContext } from '../../context/AccountContext';

export default function CustomizedTooltips(
  {
    //   stylesStoredInRedux
    // managerAccount,
    // currentAccountIds
  }
) {
  const { currentManagerAccount, currentAccountIds } =
    useContext(AccountContext);

  const [warnings, setWarnings] = useState([]);

  const [skip, setSkip] = useState(true);

  const dataSourceDict = {
    GoogleAdsDataSource: 'Google Ads',
    FacebookAdsDataSource: 'Facebook Ads',
    GoogleAnalytics4DataSource: 'Google Analytics 4',
    CM360DataSource: 'Campaign Manager 360',
    DV360DataSource: 'Display & Video 360',
    SnapchatAdsDataSource: 'Snapchat Ads',
    TikTokAdsDataSource: 'TikTok Ads',
    PinterestAdsDataSource: 'Pinterest Ads',
    ReadpeakDataSource: 'Readpeak',
    StrossleDataSource: 'Strossle',
    AmazonDspDataSource: 'Amazon DSP',
    TaboolaAdsDataSource: 'Taboola Ads',
    MicrosoftAdvertisingDataSource: 'Microsoft Advertising',
    MicrosoftInvestDataSource: 'Microsoft Invest',
    LinkedInAdsDataSource: 'LinkedIn Ads',
    AdformDataSource: 'Adform'
  };

  const currentManagerAccountId = currentManagerAccount?._id;

  const variables = {
    dataSourceErrorsInput: {
      managerAccountId: currentManagerAccountId,
      accountIds: currentAccountIds
    }
  };

  // const { data, refetch } = useQuery(dataSourceErrorsQuery, {
  //   variables,
  //   fetchPolicy: 'no-cache',
  //   skip: !currentManagerAccountId && skip
  // });

  const [loadWarnings, { called, loading, data }] = useLazyQuery(
    dataSourceErrorsQuery,
    {
      variables,
      fetchPolicy: 'no-cache'
      // skip: !currentManagerAccountId && skip
    }
  );

  useEffect(() => {
    if (currentManagerAccountId && currentAccountIds) {
      loadWarnings();
    }
  }, [currentManagerAccountId, currentAccountIds]);

  useEffect(() => {
    if (data?.dataSourceErrors && called) {
      // setWarnings(
      //   data?.dataSourceErrors?.filter((obj) => obj.status !== 'DEACTIVATED')
      // );
      setWarnings(data?.dataSourceErrors);
    }
  }, [data]);

  //   const warnings = data.dataSourceErrors;
  //   const warnings = [];

  const stylesStoredInRedux = {
    styleName: 'light'
  };
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      // backgroundColor: '#f5f5f9',
      backgroundImage:
        stylesStoredInRedux.styleName === 'light'
          ? 'linear-gradient(to top, rgb(255, 255, 255), rgb(255, 255, 255))'
          : 'linear-gradient(to top, rgb(66, 66, 66), rgb(85, 85, 85))',
      // color: 'rgba(0, 0, 0, 0.87)',
      color: stylesStoredInRedux.styleName === 'light' ? 'black' : 'white',
      maxWidth: 600,
      maxHeight: '50vw',
      // overflow: 'scroll',
      overflow: 'hidden',
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      borderColor:
        stylesStoredInRedux.styleName === 'light'
          ? '1px solid #bcbcbc'
          : 'rgba(255, 255, 255, 0.12)',
      padding: '5px 5px 0px 5px'
    }
  }))(Tooltip);

  const toolTips = warnings.map((warning, index) => {
    return (
      <div
        key={index}
        style={{
          padding: '10px',
          marginBottom: '5px',
          border: '1px solid #bcbcbc',
          borderColor:
            stylesStoredInRedux.styleName === 'light'
              ? '1px solid #bcbcbc'
              : 'rgba(255, 255, 255, 0.12)',
          borderRadius: '5px',
          fontSize: '12px'
        }}
      >
        {/* <b>{warning.headline}</b>
        <br /> */}
        {'Data Source: ' + warning.name}
        <br />
        {'Data Source Account Id: '} {warning.dataSourceAccountId}
        <br />
        {'Type: '} {dataSourceDict[warning.dataSourceRef]}
        <br />
        {'Status: '} {warning.status}
        <br />
      </div>
    );
  });

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(!open);
  };

  const handleTooltipOpen = () => {
    setOpen(!open);
  };

  return (
    <div>
      {warnings.length > 0 ? (
        <HtmlTooltip
          // disableFocusListener
          // disableHoverListener
          // disableTouchListener
          // onBlur={handleTooltipClose}
          // onClose={handleTooltipClose}
          // open={open}
          placement="left"
          title={
            <div
              style={{
                height: '400px',
                overflowY: 'scroll',
                background: 'white'
              }}
            >
              <div style={{ height: '600px', width: '400px' }}>{toolTips}</div>
            </div>
          }
        >
          <div
            style={{
              fontSize: '10px',
              color: warnings.length > 0 ? '#D0021B' : 'rgba(255,255,255,0.1)'
              // visibility: warnings.length > 0 ? 'visible' : 'hidden'
            }}
            //   onClick={handleTooltipOpen}
          >
            {warnings.length > 0 && (
              <span
                className={warnings.length > 0 ? 'blink' : ''}
                style={{
                  marginRight: '10px',
                  fontWeight: 'bold',
                  fontSize: '14px'
                }}
              >
                DATA CONNECTION ERROR!
              </span>
            )}
            <i
              className={
                warnings.length > 0 ? 'fas fa-bell blink' : 'fas fa-bell'
              }
              style={{
                //   color: stylesStoredInRedux.MiniDrawer.AppBar.color,
                color: warnings.length > 0 ? 'red' : 'grey',
                // color: 'red',
                // padding: '1.4rem',
                verticalAlign: 'centers',
                fontSize: '1.1rem'
              }}
            />
            {/* ({warnings.length}) */}
          </div>
        </HtmlTooltip>
      ) : (
        <i
          className="fas fa-bell"
          style={{
            //   color: stylesStoredInRedux.MiniDrawer.AppBar.color,
            color: warnings.length > 0 ? 'red' : 'grey',
            // padding: '1.4rem',
            verticalAlign: 'centers',
            fontSize: '1.1rem'
          }}
        />
      )}
    </div>
  );
}
