import React, { useContext, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Comment from './../tableV4/item-components/body/comment/Comment';
import HistoryEvent from './../tableV4/item-components/body/comment/HistoryEvent';
import Poke from './../tableV4/item-components/body/comment/Poke';

import Comments from './../tableV4/item-components/body/comment/Comments';
import CardMenu from './CardMenu';

import './news-feed.css';

import {
  useQuery,
  useLazyQuery,
  useMutation,
  useSubscription
} from '@apollo/client';

import {
  newsFeedQuery,
  commentAddedSubscription
} from './../../graphql/queries';

import { AccountContext } from '../../context/AccountContext';
import { AuthContext } from '../../context/AuthContext';

import { styled, useTheme } from '@mui/material/styles';

import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';

import CommentIcon from '@mui/icons-material/Comment';

import Spinner from './../layout/SpinnerSmall';

import { FilterContext } from '../../context/FilterContext';

import ReactionBar from './ReactionBar';

import NewsFeedItemWrapper from './NewsFeedItemWrapper';

import HistorySwitch from './HistorySwitch';

// import Comment from './../tableV4/item-components/body/comment/Comment';

function NewsFeed() {
  const navigate = useNavigate();
  const { currentManagerAccountId, currentAccountIds, setCurrentAccountIds } =
    useContext(AccountContext);

  const { filters, setFilters, filterBySelection, setFilterBySelection } =
    useContext(FilterContext);

  const [includeHistory, setIncludeHistory] = useState(false);

  const theme = useTheme();

  const variables = {
    input: {
      managerAccountId: currentManagerAccountId,
      accountIds: currentAccountIds,
      includeHistory
    }
  };
  //   const { data } = useQuery(newsFeedQuery, {
  //     variables,
  //     fetchPolicy: 'no-cache',
  //     skip: false
  //   });

  const [
    getData,
    { loading, error, data, refetch, client, startPolling, stopPolling }
  ] = useLazyQuery(newsFeedQuery, {
    variables,

    fetchPolicy: 'no-cache',

    onCompleted: () => {
      console.log('RoiTable>Query completed.');
    },
    onError: (error) => {
      console.log('error: ', error);
    }
  });

  const [rerender, setRerender] = useState(0);

  useSubscription(commentAddedSubscription, {
    variables: {
      input: { screen: 'NEWSFEED', accountIds: currentAccountIds }
    },
    // skip: subscriptionSkip,
    onSubscriptionData: async ({ client, subscriptionData }) => {
      let scrollToBot = false;

      if (
        messagesEndRef.current.scrollTop >
        messagesEndRef?.current?.scrollHeight - 1000
      ) {
        scrollToBot = true;
      }

      let newComments;
      const copyOfData = [...data.newsFeedItems];
      // const copyOfComments = [...data.comments]

      const commentObj = subscriptionData.data.commentAdded.comment;

      // const rowInRawArrayOfData = rawArrayOfData.filter(
      //   (rawRow) => rawRow._id === commentObj.documentId
      // );

      // if (rowInRawArrayOfData && rowInRawArrayOfData[0]) {
      //   commentObj.path = rowInRawArrayOfData[0].path;
      //   commentObj.interactivePath = rowInRawArrayOfData[0].interactivePath;
      // }

      switch (subscriptionData.data.commentAdded.action) {
        case 'add_comment':

        case 'add_history_event':
          newComments = [
            ...data.newsFeedItems,
            commentObj
            // subscriptionData.data.commentAdded.comment
          ];
          break;

        case 'add_child_comment':
          const parentCommentIndex = data.newsFeedItems.findIndex((comment) => {
            return (
              // comment._id === subscriptionData.data.commentAdded.comment._id
              comment._id === commentObj.parentDocumentId
            );
          });

          const copyOfObject = { ...data.newsFeedItems[parentCommentIndex] };
          copyOfObject.childComments.push(commentObj);

          copyOfData.splice(
            parentCommentIndex,
            1,
            copyOfObject
            // subscriptionData.data.commentAdded.comment
          );

          newComments = copyOfData;

          break;

        case 'edit_comment':
          // case 'reaction':
          const commentIndex = data.newsFeedItems.findIndex((comment) => {
            return (
              // comment._id === subscriptionData.data.commentAdded.comment._id
              comment._id === commentObj._id
            );
          });

          copyOfData.splice(
            commentIndex,
            1,
            commentObj
            // subscriptionData.data.commentAdded.comment
          );

          newComments = copyOfData;

          break;

        case 'reaction':
          const reactionIndex = data.newsFeedItems.findIndex((comment) => {
            return (
              // comment._id === subscriptionData.data.commentAdded.comment._id
              comment._id === commentObj._id
            );
          });

          copyOfData.splice(
            reactionIndex,
            1,
            {
              ...data.newsFeedItems[reactionIndex],
              reactions: commentObj.reactions
            }
            // subscriptionData.data.commentAdded.comment
          );

          newComments = copyOfData;

          break;
      }

      data.newsFeedItems = newComments;

      setRerender(rerender + 1);
      if (scrollToBot) {
        scrollToBottom();
      }
    }
  });

  useEffect(() => {
    if (
      variables?.input?.managerAccountId &&
      variables?.input?.accountIds?.length > 0
      // variables?.accountsInput?.accountId?.length > 0
    ) {
      const { data, loading } = getData();
      // console.log('running get data>data: ', data);
    }
  }, []);
  useEffect(() => {
    scrollToBottom();
  }, [data]);

  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    // messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    if (messagesEndRef?.current?.scrollHeight)
      messagesEndRef.current.scrollTop = messagesEndRef?.current?.scrollHeight;
  };

  // const onClickPathHandler = (obj, accountId) => {
  //   // setFilterBySelection([obj]);
  //   // if (!filters.includes('filter_by_selection')) {
  //   //   setFilters([...filters, 'filter_by_selection']);
  //   // }
  //   setFilters([
  //     // ...filters,
  //     {
  //       type: 'filter_by_selection',
  //       category: 'array',
  //       field: '_id',
  //       operator: 'EQUALS',
  //       conjunction: 'OR',
  //       // array: [String],
  //       // string: { type: String },
  //       values: [obj],
  //       isCaseSensitive: true,
  //       isIncludingAccounts: true,
  //       isIncludingFolders: true
  //     }
  //   ]);
  //   setCurrentAccountIds([accountId]);
  //   navigate(`/table?manager_account_id=${currentManagerAccountId}`);
  // };

  const comments = data?.newsFeedItems?.map((commentObj) => {
    // const rowInRawArrayOfData = rawArrayOfData.filter(
    //   (rawRow) => rawRow._id === commentObj.documentId
    // );

    // if (rowInRawArrayOfData && rowInRawArrayOfData[0]) {
    //   commentObj.path = rowInRawArrayOfData[0].path;
    //   commentObj.interactivePath = rowInRawArrayOfData[0].interactivePath;
    // }

    switch (commentObj.type) {
      case 'poke':
        return (
          <NewsFeedItemWrapper
            key={commentObj._id}
            item={{ ...commentObj, _id: commentObj.documentId }}
            commentObj={commentObj}
          >
            <Poke
              key={commentObj._id}
              commentObj={commentObj}
              item={commentObj}
              currentManagerAccountId={currentManagerAccountId}
            />
          </NewsFeedItemWrapper>
          // <div style={{ display: 'flex', flexDirection: 'row' }}>
          //   <div
          //     style={{
          //       display: 'flex',
          //       flexDirection: 'column',
          //       justifyContent: 'center',
          //       marginRight: '20px'
          //     }}
          //   >
          //     {/* <CommentIcon
          //       style={{
          //         color:
          //           theme.palette.mode === 'dark'
          //             ? 'rgba(255,255,255,0.3)'
          //             : 'grey'
          //       }}
          //     /> */}
          //     <i
          //       style={{
          //         fontSize: '20px',
          //         color:
          //           theme.palette.mode === 'dark'
          //             ? 'rgba(255,255,255,0.3)'
          //             : '#36529c87'
          //       }}
          //       className="fas fa-hand-point-right"
          //     ></i>
          //   </div>
          //   <div
          //     style={{
          //       //   display: 'flex',
          //       //   flexDirection: 'row',
          //       //   justifyContent: 'center',
          //       //   alignItems: 'center',
          //       background:
          //         theme.palette.mode === 'dark'
          //           ? 'rgba(255,255,255,0.1)'
          //           : 'rgba(255,255,255,0.9)',
          //       padding: '5px 20px 25px 20px',
          //       borderRadius: '5px',
          //       width: '100%',
          //       margin: '3px',
          //       boxShadow:
          //         '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
          //     }}
          //   >
          //     <div
          //       style={{
          //         //   fontSize: '11px',
          //         //   backgroundColor: commentObj.accountColor || '#a72683',
          //         //   color: 'white',
          //         //   padding: '3px 7px',
          //         //   // margin: '2px 0px 10px 0px',
          //         //   borderRadius: '5px',
          //         //   width: 'fit-content',
          //         //   maxWidth: '200px',
          //         //   textOverflow: 'ellipsis',
          //         display: 'flex',
          //         flexDirection: 'row',

          //         position: 'relative',
          //         top: '-15px'
          //         // marginLeft: '60px'
          //         // fontWeight: 'bold'
          //       }}
          //     >
          //       <div
          //         style={{
          //           fontSize: '11px',
          //           backgroundColor: commentObj.accountColor || '#a72683',
          //           color: 'white',
          //           padding: '3px 7px',
          //           // margin: '2px 0px 10px 0px',
          //           borderRadius: '5px',
          //           width: 'fit-content',
          //           maxWidth: '200px',
          //           textOverflow: 'ellipsis'
          //         }}
          //       >
          //         <span>{commentObj.accountName}</span>
          //       </div>
          //       {/* {commentObj.labels.map((obj) => (
          //         <div
          //           style={{
          //             fontSize: '11px',
          //             backgroundColor: obj.color || '#a72683',
          //             color: 'white',
          //             padding: '3px 7px',
          //             // margin: '2px 0px 10px 0px',
          //             borderRadius: '5px',
          //             width: 'fit-content',
          //             maxWidth: '200px',
          //             textOverflow: 'ellipsis'
          //           }}
          //         >
          //           <span>{obj.name}</span>
          //         </div>
          //       ))} */}
          //     </div>
          //     {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
          //         <div
          //           style={{
          //             display: 'flex',
          //             flexDirection: 'column',
          //             justifyContent: 'center',
          //             marginRight: '20px'
          //           }}
          //         >
          //           <CommentIcon />
          //         </div> */}
          //     <Poke
          //       key={commentObj._id}
          //       commentObj={commentObj}
          //       item={commentObj}
          //       currentManagerAccountId={currentManagerAccountId}
          //     />
          //     {/* </div> */}
          //   </div>
          // </div>
        );
        break;

      case 'comment':
        return (
          <NewsFeedItemWrapper
            key={commentObj._id}
            item={{ ...commentObj, _id: commentObj.documentId }}
            commentObj={commentObj}
          >
            <Comment
              key={commentObj._id}
              commentObj={commentObj}
              item={{ ...commentObj, _id: commentObj.documentId }}
              currentManagerAccountId={currentManagerAccountId}
              // onClickPathHandler={onClickPathHandler}
            />
          </NewsFeedItemWrapper>
          // <div style={{ display: 'flex', flexDirection: 'row' }}>
          //   <div
          //     style={{
          //       display: 'flex',
          //       flexDirection: 'column',
          //       justifyContent: 'center',
          //       marginRight: '20px'
          //     }}
          //   >
          //     <CommentIcon
          //       style={{
          //         color:
          //           theme.palette.mode === 'dark'
          //             ? 'rgba(255,255,255,0.3)'
          //             : '#36529c87'
          //       }}
          //     />
          //   </div>
          //   <div
          //     style={{
          //       //   display: 'flex',
          //       //   flexDirection: 'row',
          //       //   justifyContent: 'center',
          //       //   alignItems: 'center',
          //       background:
          //         theme.palette.mode === 'dark'
          //           ? 'rgba(255,255,255,0.1)'
          //           : 'rgba(255,255,255,0.9)',
          //       padding: '5px 20px 25px 20px',
          //       borderRadius: '5px',
          //       width: '100%',
          //       margin: '3px',
          //       boxShadow:
          //         '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
          //     }}
          //   >
          //     <div
          //       style={{
          //         display: 'flex',
          //         flexDirection: 'row',
          //         justifyContent: 'space-between'
          //       }}
          //     >
          //       <div
          //         style={{
          //           //   fontSize: '11px',
          //           //   backgroundColor: commentObj.accountColor || '#a72683',
          //           //   color: 'white',
          //           //   padding: '3px 7px',
          //           //   // margin: '2px 0px 10px 0px',
          //           //   borderRadius: '5px',
          //           //   width: 'fit-content',
          //           //   maxWidth: '200px',
          //           //   textOverflow: 'ellipsis',
          //           display: 'flex',
          //           flexDirection: 'row',
          //           columnGap: '10px',

          //           position: 'relative',
          //           top: '-15px'
          //           // marginLeft: '60px'
          //           // fontWeight: 'bold'
          //         }}
          //       >
          //         <div
          //           style={{
          //             fontSize: '11px',
          //             backgroundColor: commentObj.accountColor || '#a72683',
          //             color: 'white',
          //             padding: '3px 7px',
          //             // margin: '2px 0px 10px 0px',
          //             borderRadius: '5px',
          //             width: 'fit-content',
          //             maxWidth: '200px',
          //             height: 'fit-content',
          //             textOverflow: 'ellipsis'
          //           }}
          //         >
          //           <span>{commentObj.accountName}</span>
          //         </div>
          //         {commentObj?.labels?.map((obj) => (
          //           <div
          //             style={{
          //               fontSize: '11px',
          //               backgroundColor: obj.color || '#a72683',
          //               color: 'white',
          //               padding: '3px 7px',
          //               // margin: '2px 0px 10px 0px',
          //               borderRadius: '5px',
          //               width: 'fit-content',
          //               maxWidth: '200px',
          //               height: 'fit-content',
          //               textOverflow: 'ellipsis'
          //             }}
          //           >
          //             <span>{obj.name}</span>
          //           </div>
          //         ))}
          //       </div>
          //       <div
          //         style={{
          //           height: '22.5px',
          //           position: 'relative',
          //           right: '-10px'
          //         }}
          //       >
          //         <CardMenu
          //           commentObj={commentObj}
          //           item={{ ...commentObj, _id: commentObj.documentId }}
          //         />
          //       </div>
          //     </div>

          //     <Comment
          //       key={commentObj._id}
          //       commentObj={commentObj}
          //       item={{ ...commentObj, _id: commentObj.documentId }}
          //       currentManagerAccountId={currentManagerAccountId}
          //       onClickPathHandler={onClickPathHandler}
          //     />
          //     {/* </div> */}
          //     {Boolean(commentObj?.reactions?.length) && (
          //       <div
          //         style={{
          //           padding: '5px 50px',
          //           // background: 'red',
          //           // height: '50px',
          //           width: '100%',
          //           display: 'flex',
          //           flexDirection: 'column'
          //           // alignItems: 'end'
          //         }}
          //       >
          //         <ReactionBar reactions={commentObj.reactions} />
          //       </div>
          //     )}
          //   </div>
          // </div>
        );
        break;

      case 'historyEvent':
        return (
          <NewsFeedItemWrapper
            key={commentObj._id}
            item={{ ...commentObj, _id: commentObj.documentId }}
            commentObj={commentObj}
          >
            <HistoryEvent
              key={commentObj._id}
              commentObj={commentObj}
              item={{ ...commentObj, _id: commentObj.documentId }}
              currentManagerAccountId={currentManagerAccountId}
              // onClickPathHandler={onClickPathHandler}
            />
          </NewsFeedItemWrapper>
          // <div style={{ display: 'flex', flexDirection: 'row' }}>
          //   <div
          //     style={{
          //       display: 'flex',
          //       flexDirection: 'column',
          //       justifyContent: 'center',
          //       marginRight: '20px'
          //     }}
          //   >
          //     <ChangeHistoryIcon
          //       style={{
          //         color:
          //           theme.palette.mode === 'dark'
          //             ? 'rgba(255,255,255,0.3)'
          //             : '#36529c87'
          //       }}
          //     />
          //   </div>
          //   <div
          //     style={{
          //       //   display: 'flex',
          //       //   flexDirection: 'row',
          //       //   justifyContent: 'center',
          //       //   alignItems: 'center',
          //       background:
          //         theme.palette.mode === 'dark'
          //           ? 'rgba(255,255,255,0.1)'
          //           : 'rgba(255,255,255,0.9)',
          //       padding: '5px 20px 25px 20px',
          //       borderRadius: '5px',
          //       width: '100%',
          //       margin: '3px',
          //       boxShadow:
          //         '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
          //     }}
          //   >
          //     <div
          //       style={{
          //         //   fontSize: '11px',
          //         //   backgroundColor: commentObj.accountColor || '#a72683',
          //         //   color: 'white',
          //         //   padding: '3px 7px',
          //         //   // margin: '2px 0px 10px 0px',
          //         //   borderRadius: '5px',
          //         //   width: 'fit-content',
          //         //   maxWidth: '200px',
          //         //   textOverflow: 'ellipsis',
          //         display: 'flex',
          //         flexDirection: 'row',
          //         columnGap: '10px',

          //         position: 'relative',
          //         top: '-15px'
          //         // marginLeft: '60px'
          //         // fontWeight: 'bold'
          //       }}
          //     >
          //       <div
          //         style={{
          //           fontSize: '11px',
          //           backgroundColor: commentObj.accountColor || '#a72683',
          //           color: 'white',
          //           padding: '3px 7px',
          //           // margin: '2px 0px 10px 0px',
          //           borderRadius: '5px',
          //           width: 'fit-content',
          //           maxWidth: '200px',
          //           textOverflow: 'ellipsis'
          //         }}
          //       >
          //         <span>{commentObj.accountName}</span>
          //       </div>
          //       {commentObj?.labels?.map((obj) => (
          //         <div
          //           style={{
          //             fontSize: '11px',
          //             backgroundColor: obj.color || '#a72683',
          //             color: 'white',
          //             padding: '3px 7px',
          //             // margin: '2px 0px 10px 0px',
          //             borderRadius: '5px',
          //             width: 'fit-content',
          //             maxWidth: '200px',
          //             textOverflow: 'ellipsis'
          //           }}
          //         >
          //           <span>{obj.name}</span>
          //         </div>
          //       ))}
          //     </div>
          //     {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
          //         <div
          //           style={{
          //             display: 'flex',
          //             flexDirection: 'column',
          //             justifyContent: 'center',
          //             marginRight: '20px'
          //           }}
          //         >
          //           <CommentIcon />
          //         </div> */}
          //     <HistoryEvent
          //       key={commentObj._id}
          //       commentObj={commentObj}
          //       item={commentObj}
          //       currentManagerAccountId={currentManagerAccountId}
          //     />
          //     {/* </div> */}
          //   </div>
          // </div>
        );
        break;

      //   case 'historyEvent':
      //     return (
      //       <div
      //         style={{
      //           //   display: 'flex',
      //           //   flexDirection: 'row',
      //           //   justifyContent: 'center',
      //           //   alignItems: 'center',
      //           background:
      //             theme.palette.mode === 'dark'
      //               ? 'rgba(255,255,255,0.1)'
      //               : 'rgba(255,255,255,0.9)',
      //           padding: '5px 20px 20px 20px',
      //           borderRadius: '5px'
      //         }}
      //       >
      //         <div
      //           style={{
      //             fontSize: '11px',
      //             backgroundColor:
      //               commentObj.accountColor || '#a72683',
      //             color: 'white',
      //             padding: '3px 7px',
      //             // margin: '2px 0px 10px 0px',
      //             borderRadius: '5px',
      //             width: 'fit-content',
      //             maxWidth: '200px',
      //             textOverflow: 'ellipsis',
      //             position: 'relative',
      //             top: '-15px'
      //             // marginLeft: '60px'
      //             // fontWeight: 'bold'
      //           }}
      //         >
      //           <span>{commentObj.accountName}</span>
      //         </div>
      //         <HistoryEvent
      //           key={commentObj._id}
      //           commentObj={commentObj}
      //           item={commentObj}
      //           currentManagerAccountId={currentManagerAccountId}
      //         />
      //       </div>
      // );
    }
  });

  if (loading) {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Spinner />
      </div>
    );
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'calc(100vh - 64px)'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'right',
          width: '887px'
        }}
      >
        <HistorySwitch
          includeHistory={includeHistory}
          setIncludeHistory={setIncludeHistory}
        />
      </div>
      <div
        ref={messagesEndRef}
        style={{
          width: '60%',
          minWidth: '800px',
          maxWidth: '1100px',
          //   background: 'rgba(255,255,255,0.9)',
          //   marginTop: '50px',
          maxHeight: '85vh',
          overflowY: 'scroll',
          borderRadius: '5px',
          //   padding: '40px',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '15px',
          paddingTop: '10px'
        }}
      >
        {comments}
        {/* <Comments
          comments={comments}
          item={{}}
          currentManagerAccountId={currentManagerAccountId}
        /> */}
      </div>
    </div>
  );
}

export default NewsFeed;
