import React, { useEffect, useState, useContext } from 'react';

import { AccountContext } from './AccountContext';

import { InterfaceContext } from './InterfaceContext';

export const DialogContext = React.createContext();

export const DialogProvider = (props) => {
  const { isDashboardUserMode, isReportBuilderOpen, setIsReportBuilderOpen } =
    useContext(InterfaceContext);
  const { currentManagerAccount } = useContext(AccountContext);
  const [viewDialogOpen, toggleViewDialogOpen] = useState(false);
  const [viewDialogView, setViewDialogView] = useState('kanban');

  const [infoModalDocumentOpen, setInfoModalDocumentOpen] = useState(false);
  const [columnDialogOpen, setColumnDialogOpen] = useState(false);
  const [graphDialogOpen, setGraphDialogOpen] = useState(false);
  const [reportBuilderDialogOpen, setReportBuilderDialogOpen] = useState(false);
  const [reportColorPaletteDialogOpen, setReporColorPaletteDialogOpen] =
    useState(false);

  const [reportBuilderAssetDialogOpen, setReportBuilderAssetDialogOpen] =
    useState(false);

  const [uploadReportAssetsDialogOpen, setUploadReportAssetsDialogOpen] =
    useState(false);

  const [reportBuilder2DialogOpen, setReportBuilder2DialogOpen] =
    useState(false);
  const [newsFeedDialogOpen, setNewsFeedDialogOpen] = useState(false);

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [copyDialogOpen, setCopyDialogOpen] = useState(false);
  const [editSetFiltersDialogOpen, setEditSetFiltersDialogOpen] =
    useState(false);

  const [itemKey, setItemKey] = useState('');

  const [itemId, setItemId] = useState({});

  const [fieldDialogOpen, setFieldDialogOpen] = useState(false);

  const [emailPokeDialogOpen, setEmailPokeDialogOpen] = useState(false);

  const [uploadCustomExcelDialogOpen, setUploadCustomExcelDialogOpen] =
    useState(false);

  const [taxonomyDialogOpen, setTaxonomyDialogOpen] = useState(false);

  const [openDataSourceModal, setOpenDataSourceModal] = useState(false);

  const [isTimeboxViewDialogOpen, setIsTimeboxViewDialogOpen] = useState(false);

  const [timeboxViewDialogType, setTimeboxViewDialogType] = useState('');

  // const [isTimeboxerOpen, setIsTimeboxerOpen] = useState(false);
  // // const [isTimeboxListOpen, setIsTimeboxListOpen] = useState(false);

  const [isTimeboxerOpen, setIsTimeboxerOpen] = useState(
    JSON.parse(localStorage.getItem('isTimeboxerOpen')) || false
  );
  const updateIsTimeboxerOpen = (value) => {
    setIsTimeboxerOpen(value);
    localStorage.setItem('isTimeboxerOpen', JSON.stringify(value));
  };

  const [isTimeboxListOpen, setIsTimeboxListOpen] = useState(
    JSON.parse(localStorage.getItem('isTimeboxListOpen')) || false
  );
  const updateIsTimeboxListOpen = (value) => {
    setIsTimeboxListOpen(value);
    localStorage.setItem('isTimeboxListOpen', JSON.stringify(value));
  };

  const [timeboxListScale, setTimeboxListScale] = useState(
    JSON.parse(localStorage.getItem('timeboxListScale')) || 1
  );
  const updateTimeboxListScale = (value) => {
    setTimeboxListScale(value);
    localStorage.setItem('timeboxListScale', JSON.stringify(value));
  };

  const setViewDialogOpen = (bool, view) => {
    if (view) {
      setViewDialogView(view);
    }
    toggleViewDialogOpen(bool);
  };

  useEffect(() => {
    if (isReportBuilderOpen && isTimeboxerOpen) {
      setIsReportBuilderOpen(false);
    }
  }, [isTimeboxerOpen]);

  useEffect(() => {
    if (isReportBuilderOpen && isTimeboxerOpen) {
      setIsTimeboxerOpen(false);
    }
  }, [isReportBuilderOpen]);
  return (
    <DialogContext.Provider
      value={{
        viewDialogView,
        setViewDialogView,
        columnDialogOpen,
        setColumnDialogOpen,
        infoModalDocumentOpen,
        setInfoModalDocumentOpen,
        graphDialogOpen,
        setGraphDialogOpen,
        reportBuilderDialogOpen,
        setReportBuilderDialogOpen,
        reportColorPaletteDialogOpen,
        setReporColorPaletteDialogOpen,
        reportBuilderAssetDialogOpen,
        setReportBuilderAssetDialogOpen,
        uploadReportAssetsDialogOpen,
        setUploadReportAssetsDialogOpen,
        reportBuilder2DialogOpen,
        setReportBuilder2DialogOpen,
        newsFeedDialogOpen,
        setNewsFeedDialogOpen,
        editDialogOpen,
        setEditDialogOpen,
        copyDialogOpen,
        setCopyDialogOpen,
        editSetFiltersDialogOpen,
        setEditSetFiltersDialogOpen,
        itemId,
        setItemId,
        itemKey,
        setItemKey,
        fieldDialogOpen,
        setFieldDialogOpen,
        emailPokeDialogOpen,
        setEmailPokeDialogOpen,
        uploadCustomExcelDialogOpen,
        setUploadCustomExcelDialogOpen,
        viewDialogOpen,
        setViewDialogOpen,
        taxonomyDialogOpen,
        setTaxonomyDialogOpen,
        openDataSourceModal,
        setOpenDataSourceModal,
        isTimeboxerOpen,
        setIsTimeboxerOpen: updateIsTimeboxerOpen,
        isTimeboxListOpen,
        setIsTimeboxListOpen: updateIsTimeboxListOpen,

        // isReportBuilderOpen: isDashboardUserMode || isReportBuilderOpen,
        // setIsReportBuilderOpen: updateIsReportBuilderOpen,
        // isReportBuilderPageListOpen,
        // setIsReportBuilderPageListOpen: updateIsReportBuilderPageListOpen,

        timeboxListScale,
        setTimeboxListScale: updateTimeboxListScale,
        // reportBuilderListScale,
        // setReportBuilderListScale: updateReportBuilderListScale,
        isTimeboxViewDialogOpen,
        setIsTimeboxViewDialogOpen,
        timeboxViewDialogType,
        setTimeboxViewDialogType
      }}
    >
      {props.children}
    </DialogContext.Provider>
  );
};
