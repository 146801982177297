import React from 'react';
// import { makeStyles, useTheme } from '@mui/styles';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

import { columnDictionary } from '../../column-menu/columns';

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 300,
//     maxWidth: 300
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap'
//   },
//   chip: {
//     margin: 2
//   },
//   noLabel: {
//     marginTop: theme.spacing(3)
//   }
// }));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const columns = [
  { id: 'cost', name: 'Cost' },
  { id: 'budget', name: 'Budget' },
  { id: 'impressions', name: 'Impressions' },
  { id: 'viewableImpressions', name: 'Viewable Impressions' },
  { id: 'measurableImpressions', name: 'Measurable Impressions' },
  { id: 'eligibleImpressions', name: 'Eligible Impressions' },
  { id: 'cpm', name: 'CPM' },
  { id: 'vCpm', name: 'vCPM' },
  { id: 'videoCompletions', name: 'Video Completions' },
  { id: 'clicks', name: 'Clicks' },
  { id: 'ctr', name: 'CTR' },
  { id: 'viewableRate', name: 'Viewable Rate' },
  { id: 'vcr', name: 'Video Completion Rate' },
  { id: 'searchImpressionShare', name: 'Search Impression Share' },
  { id: 'cpcv', name: 'CPCV' },
  { id: 'cpc', name: 'CPC' },
  { id: 'action01Count', name: 'Action 01 Count' },
  { id: 'action01Cvr', name: 'Action 01 Conversion Rate' },
  { id: 'action01Value', name: 'Action 01 Value' },
  { id: 'action01Cpa', name: 'Action 01 CPA' },
  { id: 'action01Roas', name: 'Action 01 ROAS' },
  { id: 'action01Crr', name: 'Action 01 CRR' },
  {
    id: 'action01Vpa',
    name: 'Action 01 Average Value Per Action'
  },

  { id: 'action02Count', name: 'Action 02 Count' },
  { id: 'action02Cvr', name: 'Action 02 Conversion Rate' },
  { id: 'action02Value', name: 'Action 02 Value' },
  { id: 'action02Cpa', name: 'Action 02 CPA' },
  { id: 'action02Roas', name: 'Action 02 ROAS' },
  { id: 'action02Crr', name: 'Action 02 CRR' },
  {
    id: 'action02Vpa',
    name: 'Action 02 Average Value Per Action'
  },

  { id: 'action03Count', name: 'Action 03 Count' },
  { id: 'action03Cvr', name: 'Action 03 Conversion Rate' },
  { id: 'action03Value', name: 'Action 03 Value' },
  { id: 'action03Cpa', name: 'Action 03 CPA' },
  { id: 'action03Roas', name: 'Action 03 ROAS' },
  { id: 'action03Crr', name: 'Action 03 CRR' },
  {
    id: 'action03Vpa',
    name: 'Action 03 Average Value Per Action'
  },

  { id: 'totalCost', name: 'Total Cost' },
  { id: 'totalImpressions', name: 'Total Impressions' },
  { id: 'totalCpm', name: 'Total CPM' },
  { id: 'totalClicks', name: 'Total Clicks' },
  { id: 'totalCtr', name: 'Total CTR' },
  { id: 'totalCpc', name: 'Total CPC' },

  { id: 'totalAction01Count', name: 'Total Action 01 Count' },
  {
    id: 'totalaction01Cvr',
    name: 'Total Action 01 Conversion Rate'
  },
  { id: 'totalAction01Value', name: 'Total Action 01 Value' },
  { id: 'totalAction01Cpa', name: 'Total Action 01 CPA' },
  { id: 'totalAction01Roas', name: 'Total Action 01 ROAS' },
  { id: 'totalAction01Crr', name: 'Total Action 01 CRR' },
  {
    id: 'totalAction01Vpa',
    name: 'Total Action 01 Average Value Per Action'
  },

  { id: 'totalAction02Count', name: 'Total Action 02 Count' },
  {
    id: 'totalaction02Cvr',
    name: 'Total Action 02 Conversion Rate'
  },
  { id: 'totalAction02Value', name: 'Total Action 02 Value' },
  { id: 'totalAction02Cpa', name: 'Total Action 02 CPA' },
  { id: 'totalAction02Roas', name: 'Total Action 02 ROAS' },
  { id: 'totalAction02Crr', name: 'Total Action 02 CRR' },
  {
    id: 'totalAction02Vpa',
    name: 'Total Action 02 Average Value Per Action'
  },

  { id: 'totalAction03Count', name: 'Total Action 03 Count' },
  {
    id: 'totalaction03Cvr',
    name: 'Total Action 03 Conversion Rate'
  },
  { id: 'totalAction03Value', name: 'Total Action 03 Value' },
  { id: 'totalAction03Cpa', name: 'Total Action 03 CPA' },
  { id: 'totalAction03Roas', name: 'Total Action 03 ROAS' },
  { id: 'totalAction03Crr', name: 'Total Action 03 CRR' },
  {
    id: 'totalAction03Vpa',
    name: 'Total Action 03 Average Value Per Action'
  }
];

const names = [
  'cost',
  'impressions',
  'viewableImpressions',
  'measurableImpressions',
  'eligibleImpressions',
  'cpm',
  'vCpm',
  'videoCompletions',
  'clicks',
  'ctr',
  'viewableRate',
  'vcr',
  'searchImpressionShare',
  'cpcv',
  'cpc',
  'action01Count',
  'action01Cvr',
  'action01Value',
  'action01Cpa',
  'action01Roas',
  'action01Crr',
  'action01Vpa',

  'action02Count',
  'action02Cvr',
  'action02Value',
  'action02Cpa',
  'action02Roas',
  'action02Crr',
  'action02Vpa',

  'action03Count',
  'action03Cvr',
  'action03Value',
  'action03Cpa',
  'action03Roas',
  'action03Crr',
  'action03Vpa'
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
  };
}

export default function MultipleSelect({
  yAxisMetrics,
  setYAxisMetrics,
  tag,
  item
}) {
  // const classes = useStyles();

  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    setYAxisMetrics(event.target.value);
  };

  const modifiedColumnDictionary = { ...columnDictionary };

  if (item.type === 'summary') {
    for (let columnKey of Object.keys(columnDictionary)) {
      if (columnKey.includes('total')) {
        modifiedColumnDictionary[columnKey] =
          columnDictionary[columnKey] + ' (All Selected Accounts)';
      }
    }

    // // columns.totalcost = 'Total Cost (All Selected Accounts)';
    // columnDictionary.totalCost = 'Total Cost (All Selected Accounts)';
    // columnDictionary.totalClicks = 'Total Clicks (All Selected Accounts)';
    // columnDictionary.totalImpressions =
    //   'Total Impressions (All Selected Accounts)';
    //   columnDictionary.totalImpressions =
    //   'Total Impressions (All Selected Accounts)';
  } else {
    for (let columnKey of Object.keys(columnDictionary)) {
      if (columnKey.includes('total')) {
        modifiedColumnDictionary[columnKey] =
          columnDictionary[columnKey] + ' (Account)';
      }
    }
    // columnDictionary.totalCost = 'Total Cost (Account)';
    // columnDictionary.totalClicks = 'Total Clicks (Account)';
    // columnDictionary.totalImpressions = 'Total Impressions (Account)';
  }

  return (
    <div>
      <FormControl
        style={{
          //  margin: theme.spacing(1),
          minWidth: 300,
          maxWidth: 300
        }}
        // className={classes.formControl}
      >
        <InputLabel id="demo-mutiple-checkbox-label">{tag}</InputLabel>
        <Select
          size="small"
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          disableCloseOnSelect
          value={yAxisMetrics}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) => {
            return selected
              .map((selectedColumn) => modifiedColumnDictionary[selectedColumn])
              .join(', ');
          }}
          MenuProps={MenuProps}
        >
          {columns.map((column) => (
            <MenuItem key={column.name} value={column.id}>
              <Checkbox checked={yAxisMetrics.indexOf(column.id) > -1} />
              <ListItemText primary={modifiedColumnDictionary[column.id]} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
