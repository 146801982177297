import React, { useState, useEffect } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags({
  filter,
  updateSFilter,
  documentType
}) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(_options.map((item) => ({ title: item, value: item })));
  }, [documentType, filter]);

  const handleChange = (event, newValue) => {
    // if(filter.values.includes(new))
    // console.log('newValue: ', newValue);

    // if(newValue.)

    let listOfValues = newValue.map((obj) => obj.value);

    let option = event?.target.textContent;
    // console.log('debug98>option: ', option);
    // console.log('debug98>listOfValues1: ', listOfValues);

    if (filter?.values?.includes(option)) {
      listOfValues = listOfValues.filter((val) => val !== option);
    }

    function onlyUnique(value, index, array) {
      return array.indexOf(value) === index;
    }

    listOfValues = listOfValues.filter(onlyUnique);
    // console.log('debug98>listOfValues2: ', listOfValues);

    updateSFilter({ values: listOfValues });
    // updateSFilter
  };

  // console.log('ArrayFilter>filter: ', filter);

  let _options = [];
  switch (true) {
    case documentType === 'googleAds' &&
      filter.field === 'campaignAdvertisingChannelType':
      _options = [
        'DISCOVERY',
        'DISPLAY',
        'HOTEL',
        'LOCAL',
        'LOCAL_SERVICES',
        'MULTI_CHANNEL',
        'PERFORMANCE_MAX',
        'SEARCH',
        'SHOPPING',
        'SMART',
        'TRAVEL',
        'UNKNOWN',
        'UNSPECIFIED',
        'VIDEO'
      ];
      break;

    case documentType === 'googleAds' &&
      filter.field === 'campaignAdvertisingChannelSubType':
      _options = [
        'APP_CAMPAIGN',
        'APP_CAMPAIGN_FOR_ENGAGEMENT',
        'APP_CAMPAIGN_FOR_PRE_REGISTRATION',
        'DISPLAY_EXPRESS',
        'DISPLAY_GMAIL_AD',
        'DISPLAY_MOBILE_APP',
        'DISPLAY_SMART_CAMPAIGN',
        'LOCAL_CAMPAIGN',
        'SEARCH_EXPRESS',
        'SEARCH_MOBILE_APP',
        'SHOPPING_COMPARISON_LISTING_ADS',
        'SHOPPING_SMART_ADS',
        'SMART_CAMPAIGN',
        'TRAVEL_ACTIVITIES',
        'UNKNOWN',
        'UNSPECIFIED',
        'VIDEO_ACTION',
        'VIDEO_NON_SKIPPABLE',
        'VIDEO_OUTSTREAM',
        'VIDEO_REACH_TARGET_FREQUENCY',
        'VIDEO_SEQUENCE'
      ];
      break;

    case documentType === 'microsoftAdvertising' &&
      filter.field === 'campaignType':
      _options = [
        'Search',
        'Shopping',
        'DynamicSearchAds',
        'Audience',
        'Hotel',
        'PerformanceMax'
      ];
      break;

    case documentType === 'microsoftAdvertising' && filter.field === 'subType':
      _options = [
        'AudienceVideoAds',
        'ShoppingSmartAds',
        'ShoppingSponsoredProductAd'
      ];
      break;
  }

  let name = filter.field;

  switch (filter?.field) {
    case 'campaignAdvertisingChannelType':
      name = 'Campaign Advertising Channel Type';
      break;
    case 'campaignAdvertisingChannelSubType':
      name = 'Campaign Advertising Channel SubType';
      break;
  }

  // const options = _options.map((item) => ({ title: item, value: item }));
  return (
    <Autocomplete
      value={filter?.values?.map((item) => ({ title: item, value: item }))}
      onChange={(event, newValue) => {
        console.log('event: ', event);
        handleChange(event, newValue);
      }}
      size="small"
      multiple
      id="checkboxes-tags-demo"
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option) => option.title}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={filter?.values?.includes(option.value)}
          />
          {option.title}
        </li>
      )}
      style={{ width: 380, margin: '10px 0px' }}
      renderInput={(params) => (
        <TextField {...params} label={'Select ' + name} placeholder={name} />
      )}
    />
  );
}
