import React, { useEffect, useContext } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ReportBuilderContextMenuContent from './ReportBuilderContextMenuContent';

import { ReportBuilderContext } from '../../../../context/ReportBuilderContext';

import { InterfaceContext } from '../../../../context/InterfaceContext';

export default function BasicPopover({
  // contextMenuAnchorPosition: contextMenuAnchorPosition,
  // setContextMenuAnchorPosition: setContextMenuAnchorPosition,
  mouseDownWidgetIds,
  widgetIdThatTriggeredContextMenu,
  setWidgetIdThatTriggeredContextMenu,
  widgets
}) {
  const { isDashboardUserMode } = useContext(InterfaceContext);
  //   const [anchorEl, setAnchorEl] = React.useState(null);

  //   const handleClick = (event) => {
  //     setAnchorEl(event.currentTarget);
  //   };

  const {
    contextMenuAnchorPosition,
    setContextMenuAnchorPosition,
    setInputImageTriggerCounter
  } = useContext(ReportBuilderContext);

  const handleClose = () => {
    setContextMenuAnchorPosition(null);

    return () => setInputImageTriggerCounter(0);
  };

  const open = Boolean(contextMenuAnchorPosition);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (!open) {
      setWidgetIdThatTriggeredContextMenu(null);
    }
  }, [open]);

  return (
    <div>
      <Popover
        id={id}
        open={isDashboardUserMode ? false : open}
        // anchorEl={anchorEl}
        anchorPosition={contextMenuAnchorPosition}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        style={{ maxHeight: '500px' }}
      >
        {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
        <ReportBuilderContextMenuContent handleClose={handleClose} />
      </Popover>
    </div>
  );
}
