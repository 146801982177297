import React, { useState, useEffect, useContext, useRef } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '@mui/material/Button';

import BalanceIcon from '@mui/icons-material/Balance';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import ViewKanbanOutlinedIcon from '@mui/icons-material/ViewKanbanOutlined';
import TableRowsIcon from '@mui/icons-material/TableRows';

import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import ColorPicker from './ColorPicker';

import SaveColorPalette from './SaveColorPalette';

import SaveColorPalettePopper from './SaveColorPalettePopper';
import { DialogContext } from '../../../../context/DialogContext';
import { ReportBuilderContext } from '../../../../context/ReportBuilderContext';

import { useTheme } from '@emotion/react';

// import './kanban.css';

function ViewDialogContent({ isExpanded }) {
  const ref = useRef();
  const theme = useTheme();
  const {
    reportBuilderColorPalette,
    setReportBuilderColorPalette,
    widgets,
    setWidgets,
    selectedWidgetIds: selectedWidgetIds_,
    widgetIdThatTriggeredContextMenu
  } = useContext(ReportBuilderContext);
  const {
    reportColorPaletteDialogOpen: open,
    setReporColorPaletteDialogOpen: setOpen
  } = useContext(DialogContext);
  const [shouldScrollRight, setShouldScrollRight] = useState(false);

  const [colors, setColors] = useState([]);

  let selectedWidgetIds =
    selectedWidgetIds_.length &&
    (selectedWidgetIds_.includes(widgetIdThatTriggeredContextMenu) ||
      !widgetIdThatTriggeredContextMenu)
      ? selectedWidgetIds_
      : [widgetIdThatTriggeredContextMenu];

  useEffect(() => {
    const widgetChartFillColors = widgets?.filter(
      (widget) => widget.id === widgetIdThatTriggeredContextMenu
    )?.[0]?.chartFillColors;

    if (widgetChartFillColors?.length) {
      setColors([...widgetChartFillColors]);
    } else {
      setColors([...reportBuilderColorPalette]);
    }
  }, [reportBuilderColorPalette, open]);
  const setColor = (color, index) => {
    const copyOfColors = [...colors];
    copyOfColors[index] = color;

    setColors(copyOfColors);
  };
  const removeColor = (index) => {
    const copyOfColors = [...colors];
    copyOfColors.splice(index, 1);

    setShouldScrollRight(true);
    setColors(copyOfColors);
  };

  const scrollToRight = () => {
    // messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    // console.log('ref: ', ref);
    if (ref?.current?.scrollWidth)
      ref.current.scrollLeft = ref?.current?.scrollWidth;
  };
  // console.log('shouldScrollRight: ', shouldScrollRight);
  useEffect(() => {
    // console.log('shouldScrollRight: ', shouldScrollRight);
    if (shouldScrollRight) {
      scrollToRight();
      setShouldScrollRight(false);
    }
  }, [shouldScrollRight]);

  const onClickApplyHandler = () => {
    if (selectedWidgetIds?.length) {
      const copyOfWidgets = [...widgets];
      for (let copyOfWidget of copyOfWidgets) {
        if (selectedWidgetIds.includes(copyOfWidget.id)) {
          copyOfWidget.chartFillColors = [...(colors || [])];
        }
      }

      setWidgets(copyOfWidgets);
    } else {
      // setReportBuilderColorPalette([...colors] || []);
    }
    // setReportBuilderColorPalette([...(colors || [])]);

    setColors([]);
    setOpen(false);
  };

  return (
    <div
      style={{
        width: '100%',

        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      {/* <div
        style={{
          width: '100%',
          height: '50px',
          marginLeft: '30px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
          // backgroundColor:
          //   stylesStoredInRedux.styleName === 'light'
          //     ? 'red'
          //     : 'rgba(255,255,255,0)'
        }}
      ></div> */}
      <div
        style={{
          // display: 'flex',
          // flexDirection: 'row',
          // padding: '10px 20px',
          // overflowX: 'scroll',
          // overflowX: 'scroll',
          // height: isExpanded ? 'calc(100vh - 116px)' : 'calc(90vh - 116px)',
          // maxHeight: isExpanded ? 'calc(100vh - 116px)' : 'calc(90vh - 116px)',
          // width: isExpanded ? 'calc(100vw - 116px)' : 'calc(90vw - 116px)',
          // maxWidth: isExpanded ? 'calc(100vw - 116px)' : 'calc(90vw - 116px)',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          padding: '10px'
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            zIndex: 25000
          }}
        >
          <div
            ref={ref}
            style={{
              overflowX: 'scroll',
              overflowY: 'hidden',
              maxWidth: '600px',
              width: '100%'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                background:
                  theme.palette.mode === 'dark'
                    ? 'rgba(255,255,255,0.1)'
                    : 'white',
                border: '1px solid rgba(255,255,255,0.2)',
                borderRadius: '5px',
                height: '50px',
                width: 'fit-content'
                // width: '100%'
              }}
            >
              {colors.map((color, index) => (
                <ColorPicker
                  colors={colors}
                  color={color}
                  setColor={setColor}
                  removeColor={removeColor}
                  index={index}
                />
              ))}
              <Button
                variant="outlined"
                style={{
                  height: '40px',
                  maxHeight: '40px',
                  minHeight: '40px',
                  width: '40px',
                  maxWidth: '40px',
                  minWidth: '40px',
                  margin: '5px'
                  // border: '1px solid grey'
                }}
                onClick={() => {
                  setColors([...colors, '#a72683']);
                  setShouldScrollRight(true);
                }}
              >
                +
              </Button>
            </div>
          </div>
        </div>
        {/* <SaveColorPalette colors={colors} /> */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            width: '100%',
            columnGap: '10px',
            minHeight: '35px',
            height: '35px'
          }}
        >
          <SaveColorPalettePopper colors={colors} />
          <Button size="small" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            style={{ background: 'green', color: 'white' }}
            onClick={() => {
              onClickApplyHandler();
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
}

// export default Kanban;

ViewDialogContent.propTypes = {
  // getPosts: PropTypes.func.isRequired,
  // post: PropTypes.object.isRequired
  currentManagerAccount: PropTypes.object.isRequired
  // style: PropTypes.object.isRequired,
  // user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  currentManagerAccount: state.managerAccount.currentManagerAccount,
  // currentAccountIds: state.account.currentAccountIds,
  stylesStoredInRedux: state.style
  // user: state.auth.user
});

export default connect(mapStateToProps, {})(ViewDialogContent);
