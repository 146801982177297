import React from 'react';
import { Button } from '@mui/material';
import { useTheme } from '@emotion/react';

import { useInView } from 'react-intersection-observer';

function ReportPage({ reportPage, selectedReportPage, setSelectedReportPage }) {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    // threshold: 0
    triggerOnce: true,
    rootMargin: '200px 0px'
  });
  const theme = useTheme();

  const aspectRatio = reportPage?.width / reportPage?.height;
  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-block',
        // margin: '5px 0px',
        height: 'fit-content',
        aspectRatio: aspectRatio,
        width: '100%',
        maxWidth: '600px',
        padding: '5px',
        margin: '0px auto'
        // height: '100%'
      }}
      ref={ref}
    >
      {inView ? (
        <Button
          // ref={ref}
          style={{
            borderRadius: '5px',
            padding: '0px',
            // margin: '10px 10px',
            // margin: '5px 0px',
            // height: '300px',
            width: '100%',
            // maxWidth: '600px',
            maxWidth: '100%',
            aspectRatio: aspectRatio,
            boxShadow:
              theme?.palette?.mode === 'dark'
                ? '0px 3px 1px -2px rgb(128 128 164 / 20%), 0px 2px 2px 0px rgb(128 128 164 / 14%), 0px 1px 5px 0px rgb(128 128 164 / 12%)' //'0px 3px 1px -2px rgb(75 75 150 / 20%), 0px 2px 2px 0px rgb(75 75 150 / 14%), 0px 1px 5px 0px rgb(75 75 150 / 12%)' //'0px 3px 1px -2px rgb(255 255 255 / 20%), 0px 2px 2px 0px rgb(255 255 255 / 14%), 0px 1px 5px 0px rgb(255 255 255 / 12%)'
                : '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
            // gridRowStart: 2,
            // gridRowEnd: 2,
            // height: '40px',
            // justifyContent: 'flex-start'
            // background:
            //   (selectedReport.type === report.type ||
            //     selectedReport._id === report._id) &&
            //   stylesStoredInReduxManagerAccountSettings.selectedListButton
            border:
              selectedReportPage?._id === reportPage?._id
                ? '1.5px solid red'
                : '1.5px solid rgba(255,255,255,0)'
          }}
          onClick={() => setSelectedReportPage(reportPage)}
        >
          <div
            style={{
              // margin: '5px',
              borderRadius: '5px',
              // height: '100%',
              width: '100%',
              aspectRatio: aspectRatio,
              // width: '400px',
              backgroundSize: 'cover',
              // width: '170px',

              backgroundImage: `url(${reportPage.previewImageUrl})`,
              backgroundRepeat: 'no-repeat',
              border:
                reportPage?._id === selectedReportPage?._id
                  ? theme?.palette?.mode === 'dark'
                    ? '1px solid rgba(255,255,255,1)'
                    : 'red'
                  : '1px solid rgba(255,255,255,0)'
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '1px',
                right: '1px',
                padding: '1px 7px',
                background: '#cdb9d4', //'#a7268344',
                borderRadius: '0px 5px 0px 5px',
                fontSize: '10px',
                fontWeight: 'bold'
              }}
            >
              {reportPage?.name}
            </div>
          </div>

          {/* <img src={`${reportPage.thumbnailImageDataUri}`} /> */}
          {/* {reportPage?.name} */}
        </Button>
      ) : (
        <div style={{ height: '200px', width: '355px' }}></div>
      )}
    </div>
  );
}

export default ReportPage;
