import { DataContext } from '../DataContext';

export const calcDataFilters = ({ filters, listOfData: rawArrayOfData }) => {
  if (!rawArrayOfData?.length) {
    return {
      arrayOfData: [],

      listOfFilteredDataSourceRows: [],

      openRows: []
    };
  }
  let arrayOfData = [];

  let arrayOfDataSourceData = [];

  let openRows = [];

  if (
    filters.length > 0

    //   (filters.includes('filter_by_selection') &&
    //     !displayArrayOfData.length) || // ??????
    //   (filters.includes('filter_by_selection') && viewDialogOpen)) &&
    // rawArrayOfData.length
  ) {
    const copyOfRawArrayOfData = [...rawArrayOfData];

    let filteredArraytoLoad3 = [];

    for (let filter of filters) {
      let filteredRawArrayOfData = [];
      switch (true) {
        case filter.category === 'array':
          filteredRawArrayOfData = rawArrayOfData?.filter((row) => {
            switch (filter.field) {
              case 'members':
                let overlappingArray = row[filter.field]
                  .map((obj) => obj._id)
                  .filter((value) => filter.values.includes(value));
                if (overlappingArray.length === 0) {
                  return false;
                }
                if (
                  !filter.isIncludingAccounts &&
                  row.documentType === 'account'
                ) {
                  return false;
                }
                if (
                  !filter.isIncludingFolders &&
                  row.documentType === 'folder'
                ) {
                  return false;
                }
                break;

              default:
                if (!filter.values.includes(row[filter.field])) {
                  return false;
                }
                if (
                  !filter.isIncludingAccounts &&
                  row.documentType === 'account'
                ) {
                  return false;
                }
                if (
                  !filter.isIncludingFolders &&
                  row.documentType === 'folder'
                ) {
                  return false;
                }
            }
            return true;
          });

          break;

        case filter.category === 'special':
          filteredArraytoLoad3 = rawArrayOfData?.filter((row) => {
            switch (true) {
              case filter.field === 'pace' &&
                (row.isSetChild ||
                  row.pace === null ||
                  !(Boolean(row.pace) || typeof row.pace === 'number') ||
                  !Boolean(row.budgetUntillNow)):
                return false;

              case !filter.isIncludingAccounts &&
                row.documentType === 'account':
              case !filter.isIncludingFolders && row.documentType === 'folder':
                return false;
            }
            return true;
          });

          break;
      }
      let childOfs = [];
      for (let row of filteredRawArrayOfData) {
        childOfs = [...childOfs, ...(row?.childOf || [])];
      }

      filteredArraytoLoad3 = [
        ...filteredArraytoLoad3,
        ...rawArrayOfData.filter((row) => childOfs.includes(row._id))
      ];
    }

    const newOpenRows = [];
    for (let i = 0; i < filteredArraytoLoad3?.length; i++) {
      const item = filteredArraytoLoad3[i];
      // console.log('debug93>item: ', item);
      let newItem = {
        _id: filteredArraytoLoad3[i][
          'level_' + (item.level - 1) + '_container_id'
        ], //item._id,
        // accountId: item.accountId,
        childOf: []
        // level: item.level
      };
      for (let y = 0; y < item.level; y++) {
        // newItem[`level_${i}_container_id`] = item[`level_${y}_container_id`];
        if (item[`level_${y}_container_id`]) {
          newItem?.childOf?.push(item[`level_${y}_container_id`]);
        }
      }

      // ########### alternative faster way but with wrong order ################

      // // newItem._id = newItem.childOf[newItem.childOf.length - 1];
      if (!newOpenRows.map((row) => row._id).includes(newItem._id)) {
        newOpenRows.push(newItem);
      }

      // ########### end of alternative faster way but with wrong order ################

      // let levels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].slice(
      //   0,
      //   filteredArraytoLoad3[i].level
      // );
      // for (let level of levels) {
      //   if (filteredArraytoLoad3[i].level > level) {
      //     const newRow = {
      //       childOf: newItem.childOf.slice(0, level), //.slice(0, i)
      //       _id: filteredArraytoLoad3[i]['level_' + level + '_container_id']
      //     };

      //     if (
      //       !newOpenRows
      //         .map((row) => String(row._id))
      //         .includes(String(newRow._id))
      //     ) {
      //       newOpenRows.push(newRow);
      //     }
      //   }
      // }

      // if (filteredArraytoLoad3[i].level > 1) {
      //   newOpenRows.push({
      //     childOf: newItem.childOf.slice(0, 1),
      //     _id: filteredArraytoLoad3[i]['level_' + 1 + '_container_id'],
      //     level_1_container_id:
      //       filteredArraytoLoad3[i]['level_' + 1 + '_container_id']
      //   });
      // }
    }

    const newOpenRows2 = [...openRows, ...newOpenRows];
    console.log('newOpenRows2: ', newOpenRows2);

    const uniquefilteredNewOpenRows = newOpenRows2.filter(
      (v, i, a) => a.findIndex((t) => t._id === v._id) === i
    );
    // setPreviousOpenRows([...openRows]);
    openRows = uniquefilteredNewOpenRows;
  }

  // const sortedRawArrayOfData = rawArrayOfData;

  const sortedRawArrayOfData = [];

  const level_1 = rawArrayOfData.filter((obj) => {
    return obj.level === 1;
  });
  for (let a = 0; a < level_1.length; a++) {
    sortedRawArrayOfData.push(level_1[a]);
    const level_2 = rawArrayOfData.filter((obj) => {
      return obj.level === 2 && obj.level_1_container_id === level_1[a]._id;
    });

    for (let b = 0; b < level_2.length; b++) {
      sortedRawArrayOfData.push(level_2[b]);
      const level_3 = rawArrayOfData.filter((obj) => {
        return obj.level === 3 && obj.level_2_container_id === level_2[b]._id;
      });

      // ###### push level 3 and filter level 4 #############
      for (let c = 0; c < level_3.length; c++) {
        sortedRawArrayOfData.push(level_3[c]);
        const level_4 = rawArrayOfData.filter((obj) => {
          return obj.level === 4 && obj.level_3_container_id === level_3[c]._id;
        });

        // ###### push level 4 and filter level 5 #############
        for (let d = 0; d < level_4.length; d++) {
          sortedRawArrayOfData.push(level_4[d]);
          const level_5 = rawArrayOfData.filter((obj) => {
            return (
              obj.level === 5 && obj.level_4_container_id === level_4[d]._id
            );
          });

          // ###### push level 5 and filter level 6 #############
          for (let e = 0; e < level_5.length; e++) {
            sortedRawArrayOfData.push(level_5[e]);
            const level_6 = rawArrayOfData.filter((obj) => {
              return (
                obj.level === 6 && obj.level_5_container_id === level_5[e]._id
              );
            });

            // ###### push level 6 and filter level 7 #############
            for (let f = 0; f < level_6.length; f++) {
              sortedRawArrayOfData.push(level_6[f]);
              const level_7 = rawArrayOfData.filter((obj) => {
                return (
                  obj.level === 7 && obj.level_6_container_id === level_6[f]._id
                );
              });

              // ###### push level 7 and filter level 8 #############
              for (let g = 0; g < level_7.length; g++) {
                sortedRawArrayOfData.push(level_7[g]);
                const level_8 = rawArrayOfData.filter((obj) => {
                  return (
                    obj.level === 8 &&
                    obj.level_7_container_id === level_7[g]._id
                  );
                });

                // ###### push level 8 and filter level 9 #############
                for (let h = 0; h < level_8.length; h++) {
                  sortedRawArrayOfData.push(level_8[h]);
                  const level_9 = rawArrayOfData.filter((obj) => {
                    return (
                      obj.level === 9 &&
                      obj.level_8_container_id === level_8[h]._id
                    );
                  });

                  // ###### push level 9 and filter level 10 #############
                  for (let i = 0; i < level_9.length; i++) {
                    sortedRawArrayOfData.push(level_9[i]);
                    const level_10 = rawArrayOfData.filter((obj) => {
                      return (
                        obj.level === 10 &&
                        obj.level_9_container_id === level_9[i]._id
                      );
                    });

                    // ###### push level 10 and filter level 11 #############
                    for (let j = 0; j < level_10.length; j++) {
                      sortedRawArrayOfData.push(level_10[j]);
                      const level_11 = rawArrayOfData.filter((obj) => {
                        return (
                          obj.level === 11 &&
                          obj.level_10_container_id === level_10[j]._id
                        );
                      });

                      for (let k = 0; k < level_11.length; k++) {
                        sortedRawArrayOfData.push(level_11[k]);
                        const level_12 = rawArrayOfData.filter((obj) => {
                          return (
                            obj.level === 12 &&
                            obj.level_11_container_id === level_11[k]._id
                          );
                        });

                        for (let l = 0; l < level_12.length; l++) {
                          sortedRawArrayOfData.push(level_12[l]);
                          const level_13 = rawArrayOfData.filter((obj) => {
                            return (
                              obj.level === 13 &&
                              obj.level_12_container_id === level_12[l]._id
                            );
                          });

                          // add next level.
                        }
                        // add next level.
                      }
                    }

                    // add next level.
                  }

                  // add next level.
                }
              }
            }
          }
        }
      }

      //   //################
    }
  }

  // const sortedRawArrayOfData = rawArrayOfData.sort(compareValues('name'));
  const initial_array = sortedRawArrayOfData.filter((obj) => {
    return obj.visible === true;
  });

  let arrayToLoad = [];

  // const [1,2,3,4,5,6,7,8,9,10,11,12]
  const sortedArrayOfDataIds = sortedRawArrayOfData.map((obj) => obj._id);

  arrayToLoad = [...arrayToLoad, ...initial_array];
  for (let i = 0; i < sortedRawArrayOfData.length; i++) {
    if (
      sortedRawArrayOfData &&
      sortedRawArrayOfData[i] &&
      openRows.findIndex(
        (openRow) => openRow['_id'] === sortedRawArrayOfData[i]._id
      ) !== -1
    ) {
      const filteredItems = sortedRawArrayOfData.filter((obj) => {
        return (
          obj[`level_${sortedRawArrayOfData[i].level}_container_id`] ===
            sortedRawArrayOfData[i][
              `level_${sortedRawArrayOfData[i].level}_container_id`
            ] && obj['level'] === sortedRawArrayOfData[i].level + 1
        );
        // }
      });

      const indexOf = arrayToLoad.findIndex(
        (item) => item._id == sortedRawArrayOfData[i]._id
      );

      arrayToLoad.splice(indexOf + 1, 0, ...filteredItems);
    }
  }

  let newArrayToLoad = [];

  for (let _id of sortedArrayOfDataIds) {
    let i = 0;
    for (let row of arrayToLoad) {
      if (row._id === _id) {
        newArrayToLoad.push(row);
        arrayToLoad.splice(i, 1);
        // console.log('arrayToLoad:', arrayToLoad);
      }

      i++;
    }
  }
  arrayToLoad = newArrayToLoad;

  // ############## filter by team #####################
  let filteredArrayOfDataFinal = [];

  let filteredArrayOfData = [...rawArrayOfData];

  let arrayOfArraysOfFilteredIds = [];
  let arrayOfArraysOfFilteredArrays = [];

  for (let filter of filters) {
    switch (true) {
      case filter.category === 'array':
        arrayOfArraysOfFilteredArrays.push([
          ...filteredArrayOfData.filter((row) => {
            // filter.values.includes(row[filter.field])

            switch (filter.field) {
              case 'members':
                let overlappingArray = row[filter.field]
                  .map((obj) => obj._id)
                  .filter((value) => filter.values.includes(value));
                if (overlappingArray.length === 0) {
                  return false;
                }
                if (
                  !filter.isIncludingAccounts &&
                  row.documentType === 'account'
                ) {
                  return false;
                }
                if (
                  !filter.isIncludingFolders &&
                  row.documentType === 'folder'
                ) {
                  return false;
                }
                break;

              default:
                if (!filter.values.includes(row[filter.field])) {
                  return false;
                }
                if (
                  !filter.isIncludingAccounts &&
                  row.documentType === 'account'
                ) {
                  return false;
                }
                if (
                  !filter.isIncludingFolders &&
                  row.documentType === 'folder'
                ) {
                  return false;
                }
            }
            return true;
          })
        ]);

        break;

      case filter.category === 'special':
        arrayOfArraysOfFilteredArrays.push([
          ...filteredArrayOfData.filter((row) => {
            // filter.values.includes(row[filter.field])

            switch (true) {
              case filter.field === 'pace' &&
                (row.isSetChild ||
                  row.pace === null ||
                  !(Boolean(row.pace) || typeof row.pace === 'number') ||
                  !Boolean(row.budgetUntillNow)):
                return false;

              case !filter.isIncludingAccounts &&
                row.documentType === 'account':
              case !filter.isIncludingFolders && row.documentType === 'folder':
                return false;
            }
            return true;
          })
        ]);
    }
  }

  arrayOfArraysOfFilteredIds = [];
  for (let filteredArray of arrayOfArraysOfFilteredArrays) {
    let newArr = [];
    for (let row of filteredArray) {
      // console.log('row1: ', row);
      newArr = [
        ...newArr,
        ...filteredArrayOfData
          .filter(
            (obj) => obj['level_' + row.level + '_container_id'] === row._id
          )
          .map((obj) => obj._id)
      ];
    }
    arrayOfArraysOfFilteredIds.push(newArr);
  }

  let intersectionArray = arrayOfArraysOfFilteredIds?.pop() || [];
  for (let arr of arrayOfArraysOfFilteredIds) {
    intersectionArray = intersectionArray.filter((value) =>
      arr.includes(value)
    );
  }

  filteredArrayOfDataFinal = filteredArrayOfData.filter((obj) =>
    intersectionArray?.includes(obj._id)
  );

  // console.log('debug75>filters: ', filters);
  // setOpenRows(rawArrayOfData);

  // ############## filter by selection ################
  if (filters.length > 0) {
    let filteredArrayToLoad = [];
    for (let i = 0; i < arrayToLoad.length; i++) {
      for (let x = 0; x < filteredArrayOfDataFinal.length; x++) {
        if (
          arrayToLoad[i][
            'level_' + filteredArrayOfDataFinal[x].level + '_container_id'
          ] === filteredArrayOfDataFinal[x]._id
        ) {
          filteredArrayToLoad.push(arrayToLoad[i]);
        } else if (
          !['folder', 'to_do_list'].includes(
            filteredArrayOfDataFinal[x].type
          ) &&
          filteredArrayOfDataFinal[x]._id === arrayToLoad[i]._id
        ) {
          filteredArrayToLoad.push(arrayToLoad[i]);
        }
      }
    }

    const uniquefilteredArrayToLoad = filteredArrayToLoad.filter(
      (v, i, a) => a.findIndex((t) => t._id === v._id) === i
    );

    arrayToLoad = uniquefilteredArrayToLoad;
  }

  // ###########################################
  let filterTypes = filters.map((filter) => filter.type);
  if (
    filterTypes?.length &&
    filterTypes?.includes('filter_by_pace') &&
    filterTypes?.includes('filter_by_data_source')
  ) {
    arrayToLoad = arrayToLoad.filter(
      (row) =>
        row &&
        !row.isSetChild &&
        row.pace !== null &&
        (Boolean(row.pace) || typeof row.pace === 'number') &&
        Boolean(row.budgetUntillNow)
    );
  }

  arrayOfData = arrayToLoad;
  // setArrayOfDataInCronologicalOrder([...arrayToLoad]);
  // setArrayOfData([...arrayToLoad]);

  //   switch (true) {
  //     case !Boolean(sortedColumn?.column):
  //       setArrayOfData([...(arrayToLoad || [])]);
  //       break;

  //     case sortedColumn?.column === 'dateRange':
  //       setArrayOfData([
  //         ...(arrayToLoad?.sort(
  //           compareValues('startDate', sortedColumn?.order)
  //         ) || [])
  //       ]);
  //       break;

  //     default:
  //       setArrayOfData([
  //         ...(arrayToLoad?.sort(
  //           compareValues(sortedColumn?.column, sortedColumn?.order)
  //         ) || [])
  //       ]);
  //   }

  // remove items that arent shown from checkedBoxes list.

  // const [arrayOfDataSourceData, setArrayOfDataSourceData] = useState([]);

  // ###### populate array of data to get child sources #######

  if (filters.length === 0) {
    arrayOfDataSourceData = rawArrayOfData.filter(
      (obj) => obj.type === 'account'
    );
  } else {
    let dataSourceDataList = [];

    for (let dataObj of arrayOfData) {
      for (let rawDataObj of rawArrayOfData) {
        if (
          rawDataObj['level_' + dataObj.level + '_container_id'] === dataObj._id
        ) {
          dataSourceDataList.push(rawDataObj);
        }
      }
    }

    const uniquedataSourceDataList = dataSourceDataList.filter(
      (v, i, a) => a.findIndex((t) => t._id === v._id) === i
    );

    let copyOfDataSourceDataList = [];
    for (let row of uniquedataSourceDataList) {
      const copyOfRow = { ...row, fieldsToNull: [] };
      copyOfRow.total_list_of_propagation_data_row = (
        copyOfRow.total_list_of_propagation_data_row || []
      ).map((obj) => ({
        ...obj
      }));
      copyOfDataSourceDataList.push(copyOfRow);
    }
    const rowLevels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
    for (let rowLevel of rowLevels) {
      // const newLevelDataSourceDataList = [];
      for (let row of copyOfDataSourceDataList) {
        if (row.level === rowLevel) {
          let i = 0;
          copyOfDataSourceDataList = copyOfDataSourceDataList.filter(
            (dataRow) =>
              dataRow._id === row._id ||
              row._id !== dataRow['level_' + rowLevel + '_container_id']
          );
        }
      }
    }

    arrayOfDataSourceData = copyOfDataSourceDataList;
  }

  let listOfIdsToFilter = [];
  for (let row of arrayOfDataSourceData || []) {
    if (!listOfIdsToFilter.includes(row._id)) {
      listOfIdsToFilter.push(row._id);
    }
    for (let id of row?.allChildDocumentIds || []) {
      if (!listOfIdsToFilter.includes(id)) {
        listOfIdsToFilter.push(id);
      }
    }
  }

  let dataSourceRows = rawArrayOfData.filter((row) =>
    [
      'facebook_ads_campaign',
      'google_ads_campaign',
      'cm360_campaign',
      'dv360_insertion_order',
      'snapchat_ads_campaign',
      'tiktok_ads_campaign',
      'pinterest_ads_campaign',
      'readpeak_campaign',
      'strossle_campaign',
      'amazon_dsp_order',
      'taboola_ads_campaign',
      'microsoft_advertising_campaign',
      'microsoft_invest_insertion_order',
      'apple_search_ads_campaign',
      'adform_order',
      'linkedin_ads_campaign_group',
      'manualDataEntry'
    ].includes(row.type)
  );

  dataSourceRows = dataSourceRows.filter((row) =>
    listOfIdsToFilter.includes(row._id)
  );

  return {
    arrayOfData,

    listOfFilteredDataSourceRows: dataSourceRows,

    openRows
  };

  // ###########################################################
};
