const widgetCard = async (widget, pres, slide, chartFillColors) => {
  const pptx = pres;

  const borderWidth = +widget.outerBorderWidth || 0;

  // if (widget.isShowingCard) {
  slide.addShape(
    +widget?.outerBorderRadius === 0
      ? pres.shapes.RECTANGLE
      : pres.shapes.ROUNDED_RECTANGLE,
    {
      fill: {
        color: widget.cardFillColor || 'FFFFFF',
        transparency: (widget.cardFillOpacity - 1) * -100 //5
      },
      line: +widget?.outerBorderWidth !== 0 && {
        width: +widget?.outerBorderWidth / 2,
        color: widget.outerBorderColor.split('#')[1], //'f1f1f1',
        transparency: (widget.outerBorderOpacity - 1) * -100 //5
      },
      // w: widget.width / 72 / 2,
      // h: widget.height / 72 / 2,
      // x: +widget?.left / 72 / 2,
      // y: widget.top / 72 / 2,
      w: (widget.width - borderWidth) / 72 / 2,
      h: (widget.height - borderWidth) / 72 / 2,
      x: (+widget?.left + borderWidth / 2) / 72 / 2,
      y: (widget.top + borderWidth / 2) / 72 / 2,

      // rectRadius: 0.05,
      rectRadius:
        +widget.outerBorderRadius / 72 / 2 +
        (+widget.outerBorderRadius === 0 ? 0.01 : 0),

      shadow: widget.isCardShadowOn && {
        type: 'outer',
        blur: 6,
        offset: 2,
        angle: 45,
        color: '000000',
        opacity: 0.2
      }
    }
  );
  // }
};

export default widgetCard;
