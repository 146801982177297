import React, { useEffect, useState, useContext } from 'react';
import Button from '@mui/material/Button';
import { ClickAwayListener } from '@mui/base';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
// import { makeStyles } from '@mui/styles';

import {
  addDocumentMutation,
  updateDocumentMutation
} from '../../../../../graphql/queries';

import { useMutation } from '@apollo/client';

import { AccountContext } from '../../../../../context/AccountContext';
import { InterfaceContext } from '../../../../../context/InterfaceContext';
import { ColumnContext } from '../../../../../context/ColumnContext';
import { AuthContext } from '../../../../../context/AuthContext';
import { DialogContext } from '../../../../../context/DialogContext';

import { FilterContext } from '../../../../../context/FilterContext';

import { DataContext } from '../../../../../context/DataContext';

import { MutationContext } from '../../../../../context/MutationContext';

import { useSnackbar } from 'notistack';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import StorageIcon from '@mui/icons-material/Storage';
import KeyboardReturnOutlinedIcon from '@mui/icons-material/KeyboardReturnOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ListIcon from '@mui/icons-material/List';
import EditIcon from '@mui/icons-material/Edit';
import FilterListIcon from '@mui/icons-material/FilterList';
import ScheduleIcon from '@mui/icons-material/Schedule';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import { Divider } from '@mui/material';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex'
//   },
//   paper: {
//     marginRight: theme.spacing(2)
//   }
// }));

export default function MenuListComposition({
  children,
  // typeItemAnchorRef,
  // setTypeItemAnchorRef,
  // typeItemOpen: open,
  // setTypeItemOpen: setOpen,
  // open,
  // setOpen,
  typeItemToggledItem,
  setTypeItemToggledItem,
  currentManagerAccountId,
  typeItemTemplateMenuOpen,
  setTypeItemTemplateMenuOpen
}) {
  const levelLimit = 11;
  const { enqueueSnackbar } = useSnackbar();
  // const classes = useStyles();
  //   const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  // const [addDocument] = useMutation(addDocumentMutation);
  // const [updateDocument] = useMutation(updateDocumentMutation);

  const { addDocument, updateDocument } = useContext(MutationContext);

  const { user } = useContext(AuthContext);

  const { currentManagerAccount } = useContext(AccountContext);

  const {
    copiedDocumentIds,
    setCopiedDocumentIds,
    copiedDocumentsAccountId,
    setCopiedDocumentsAccountId,
    documentIdsToBeDuplicated,
    setDocumentIdsToBeDuplicated,
    accountIdForDocumentsToBeDuplicated,
    setAccountIdForDocumentsToBeDuplicated,
    setEditSetFilterMenuItem,
    typeItemOpen: open,
    setTypeItemOpen: setOpen,
    typeItemAnchorRef,
    setTypeItemAnchorRef
    // openDataSourceModal,
    // setOpenDataSourceModal
  } = useContext(InterfaceContext);

  const { columns } = useContext(ColumnContext);

  const { copyOrDuplicateAction, setCopyOrDuplicateAction } =
    useContext(MutationContext);

  const {
    checkedBoxes,
    setCheckedBoxes,
    filters,
    setFilters,
    setFilterBySelection
  } = useContext(FilterContext);

  const {
    setCopyDialogOpen,
    openDataSourceModal,
    setOpenDataSourceModal,
    setEmailPokeDialogOpen,
    setEditDialogOpen,
    setTimeboxViewDialogType,
    setIsTimeboxViewDialogOpen,
    setEditSetFiltersDialogOpen
  } = useContext(DialogContext);

  const { listOfData } = useContext(DataContext);

  //   const [typeItemAnchorRef, setTypeItemAnchorRef] = useState(null);

  //   useEffect(() => {
  //     setTypeItemAnchorRef(anchorRef);
  //   }, [anchorRef]);

  // console.log('TypeItemMenuPopper>typeItemAnchorRef: ', typeItemAnchorRef);
  // console.log('TypeItemMenuPopper>open: ', open);

  const handleToggle = () => {
    setOpen(false);
    setTypeItemAnchorRef(null);
    setTypeItemToggledItem(null);
    // console.log('toggle');
    // setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
    setTypeItemToggledItem(null);
    setTypeItemAnchorRef(null);
  };

  // useEffect(() => {
  //   if (!open) {
  //     setTypeItemToggledItem(null);
  //   }
  // }, [open]);

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  //   return children;

  let iconStyle = { fontSize: '13px', marginRight: '7px' };

  const handleClickMenuItem = async (e, value, documentType) => {
    if (value === 'edit_set_filters') {
      setEditSetFilterMenuItem({ ...typeItemToggledItem });
      setEditSetFiltersDialogOpen(true);
      return;
    }
    if (
      [
        'new_folder',
        'new_task',
        'new_sub_task',
        'new_manual_data_entry',
        'add_data_source',
        'new_template',
        'paste'
      ].includes(value) &&
      typeItemToggledItem.level > levelLimit
    ) {
      enqueueSnackbar('Max sub folder level reached.', {
        variant: 'default'
        // persist: true
      });
      return;
    }
    switch (value) {
      case 'filter':
        // setFilters([...filters, 'filter_by_selection']);
        // setFilterBySelection([typeItemToggledItem?._id]);

        setFilters([
          ...filters,
          {
            type: 'filter_by_selection',
            category: 'array',
            field: '_id',
            operator: 'EQUALS',
            conjunction: 'OR',
            // array: [String],
            // string: { type: String },
            values: [typeItemToggledItem?._id],
            isCaseSensitive: true,
            isIncludingAccounts: true,
            isIncludingFolders: true
          }
        ]);
        break;
      case 'filter_selected':
        // setFilters([...filters, 'filter_by_selection']);
        // setFilterBySelection(checkedBoxes.map((obj) => obj._id));

        setFilters([
          ...filters,
          {
            type: 'filter_by_selection',
            category: 'array',
            field: '_id',
            operator: 'EQUALS',
            conjunction: 'OR',
            // array: [String],
            // string: { type: String },
            values: checkedBoxes.map((obj) => obj._id),
            isCaseSensitive: true,
            isIncludingAccounts: true,
            isIncludingFolders: true
          }
        ]);
        break;

      case 'new_folder':
      case 'new_task':
      case 'new_sub_task':
      case 'new_manual_data_entry':
        // let addDocumentInput = {
        //   action: 'CREATE_DOCUMENT',
        //   managerAccountId: currentManagerAccountId,
        //   // documentType: 'container',
        //   documentType: type,
        //   newDocumentAccountAccessLevel: 1,
        //   checkedDocumentId: typeItemToggledItem._id
        //   // document: {}
        // };

        let name = 'new document';
        switch (documentType) {
          case 'folder':
            name = 'New Folder';
            break;
          case 'manualDataEntry':
            name = 'New Manual Input';
            break;
          case 'customForm':
            name = 'Custom Form';
            break;
          case 'task':
            name = 'New task';
            break;
          case 'subTask':
            name = 'New subtask';
            break;
          case 'taxonomy':
            name = 'New taxonomy';
            break;
        }

        // addDocumentInput = {
        //   ...addDocumentInput,
        //   level: typeItemToggledItem.level,
        //   accountId: typeItemToggledItem.accountId,
        //   document: {
        //     name,
        //     type
        //   }
        // };

        let addDocumentInput = {
          managerAccountId: currentManagerAccountId,
          accountId: typeItemToggledItem.accountId,
          checkedDocumentId: typeItemToggledItem._id,
          level: typeItemToggledItem.level,
          newDocuments: [{ documentType, name }]
        };

        addDocument({ variables: { input: { ...addDocumentInput } } });

        break;
      case 'add_data_source':
        setCheckedBoxes([typeItemToggledItem]);
        setOpenDataSourceModal(true);
        break;
      case 'new_template':
        setTypeItemTemplateMenuOpen(true);
        break;
      case 'copy':
      case 'copy_selected':
        const documentsToBeCopied_ =
          value === 'copy' ? [typeItemToggledItem] : checkedBoxes;

        for (let doc of documentsToBeCopied_) {
          if (
            ![
              'folder',
              'task',
              'subTask',
              'taxonomy',
              'manualDataEntry',
              'facebook_ads_campaign',
              'google_ads_campaign',
              'google_ads_campaign_set',
              'cm360_campaign',
              'dv360_insertion_order',
              'snapchat_ads_campaign',
              'tiktok_ads_campaign',
              'pinterest_ads_campaign',
              'readpeak_campaign',
              'strossle_campaign',
              'amazon_dsp_order',
              'taboola_ads_campaign',
              'microsoft_advertising_campaign',
              'microsoft_advertising_campaign_set',
              'microsoft_invest_insertion_order',
              'apple_search_ads_campaign',
              'apple_search_ads_campaign_set',
              'adform_order',
              'linkedin_ads_campaign_group',
              'googleAds'
            ].includes(doc.type) ||
            doc.isSetChild
          ) {
            enqueueSnackbar(
              `Copy ${doc.type} or a set child is not supported.`,
              {
                variant: 'default'
                // persist: true
              }
            );
            return;
          }
        }

        let docIds = documentsToBeCopied_.map((obj) => obj._id);

        let copiedDocumentsAccountId_ = documentsToBeCopied_[0].accountId;

        if (
          value === 'copy_selected' &&
          checkedBoxes.filter(
            (obj) => obj.accountId !== copiedDocumentsAccountId_
          ).length
        ) {
          enqueueSnackbar(
            `Only copy documents from the same account is supported.`,
            {
              variant: 'default'
              // persist: true
            }
          );
          return;
        }

        setCopiedDocumentsAccountId(copiedDocumentsAccountId_);

        setCopiedDocumentIds(docIds);
        enqueueSnackbar('Documents Copied', {
          variant: 'default'
          // persist: true
        });
        break;
      case 'paste':
        if (
          typeItemToggledItem.accountId !== copiedDocumentsAccountId &&
          !['system_admin'].includes(user.role)
        ) {
          enqueueSnackbar(
            'You cannot paste documents into a different account',
            {
              variant: 'default'
              // persist: true
            }
          );
          return;
        }
        if (copiedDocumentIds.includes(typeItemToggledItem._id)) {
          enqueueSnackbar('You cannot paste a document into itself', {
            variant: 'default'
            // persist: true
          });
          return;
        }
        if (!['account', 'folder'].includes(typeItemToggledItem.documentType)) {
          enqueueSnackbar(
            'You can only paste documents into same account or folders from the same account',
            {
              variant: 'default'
              // persist: true
            }
          );
          return;
        }

        const documentsToBeCopied = listOfData.filter((obj) =>
          copiedDocumentIds.includes(obj._id)
        );
        const documentsToBeCopiedTypes = documentsToBeCopied.map(
          (obj) => obj.type
        );
        console.log('documentsToBeCopiedTypes: ', documentsToBeCopiedTypes);
        if (
          typeItemToggledItem.documentType !== 'folder' &&
          documentsToBeCopiedTypes.filter((str) => str !== 'folder')?.length
        ) {
          enqueueSnackbar('You can only paste folders into an account.', {
            variant: 'default'
            // persist: true
          });
          return;
        }

        setCheckedBoxes([typeItemToggledItem]);
        setCopyOrDuplicateAction('COPY');
        setCopyDialogOpen(true);
        break;

      case 'duplicate':
      case 'duplicate_selected':
        const documentsToBeDuplicated_ =
          value === 'duplicate' ? [typeItemToggledItem] : checkedBoxes;

        for (let doc of documentsToBeDuplicated_) {
          if (
            ![
              'folder',
              'task',
              'subTask',
              'taxonomy',
              'manualDataEntry',
              'facebook_ads_campaign',
              'google_ads_campaign',
              'google_ads_campaign_set',
              'cm360_campaign',
              'dv360_insertion_order',
              'snapchat_ads_campaign',
              'tiktok_ads_campaign',
              'pinterest_ads_campaign',
              'readpeak_campaign',
              'strossle_campaign',
              'amazon_dsp_order',
              'taboola_ads_campaign',
              'microsoft_advertising_campaign',
              'microsoft_advertising_campaign_set',
              'microsoft_invest_insertion_order',
              'apple_search_ads_campaign',
              'apple_search_ads_campaign_set',
              'adform_order',
              'linkedin_ads_campaign_group',
              'googleAds'
            ].includes(doc.type) ||
            doc.isSetChild
          ) {
            enqueueSnackbar(
              `Duplication of ${doc.type} or a set child is not supported.`,
              {
                variant: 'default'
                // persist: true
              }
            );
            return;
          }
        }

        // let docIds = documentsToBeDuplicated_.map((obj) => obj._id);

        let duplicatedDocumentsAccountId_ =
          documentsToBeDuplicated_[0].accountId;

        if (
          value === 'duplicate_selected' &&
          checkedBoxes.filter(
            (obj) => obj.accountId !== duplicatedDocumentsAccountId_
          ).length
        ) {
          enqueueSnackbar(
            `Only duplication of documents from the same account is supported.`,
            {
              variant: 'default'
              // persist: true
            }
          );
          return;
        }

        // documentIdsToBeDuplicated,
        setDocumentIdsToBeDuplicated(
          documentsToBeDuplicated_.map((obj) => obj._id)
        );
        // accountIdForDocumentsToBeDuplicated,
        setAccountIdForDocumentsToBeDuplicated(duplicatedDocumentsAccountId_);

        setCopyOrDuplicateAction('DUPLICATE');
        setCopyDialogOpen(true);

        // setCopiedDocumentsAccountId(copiedDocumentsAccountId_);

        // setCopiedDocumentIds(docIds);
        // enqueueSnackbar('Documents Copied', {
        //   variant: 'default'
        //   // persist: true
        // });
        break;

      case 'bulk_edit_selected':
        setEditDialogOpen(true);
        break;

      case 'check_selected':
        for (let doc of checkedBoxes) {
          const timeDiff = new Date() - new Date(doc.checkedAt);

          if (timeDiff < 36000) {
            enqueueSnackbar('The button was recently clicked. Wait a bit.', {
              variant: 'error'
            });
            return;
          }

          if (
            ![
              'folder',
              'task',
              'subTask',
              'taxonomy',
              'manualDataEntry',
              'facebook_ads_campaign',
              'google_ads_campaign',
              'cm360_campaign',
              'dv360_insertion_order',
              'snapchat_ads_campaign',
              'tiktok_ads_campaign',
              'pinterest_ads_campaign',
              'readpeak_campaign',
              'strossle_campaign',
              'amazon_dsp_order',
              'taboola_ads_campaign',
              'microsoft_advertising_campaign',
              'microsoft_invest_insertion_order',
              'apple_search_ads_campaign',
              'adform_order',
              'linkedin_ads_campaign_group',
              'googleAds'
            ].includes(doc.type) ||
            doc.isSetChild
          ) {
            enqueueSnackbar('Doc type not supported.', {
              variant: 'error'
            });
            return;
          }
          if (doc.status !== 'ACTIVE') {
            enqueueSnackbar('All documents must have status ACTIVE.', {
              variant: 'error'
            });
            return;
          }
        }

        const documentInput = {
          managerAccountId: currentManagerAccountId,
          documents: checkedBoxes.map((doc) => ({
            accountId: doc.accountId,
            documentId: doc._id,
            documentType: doc.documentType,
            checkedAt: new Date()
          })),
          updateKeys: ['checkedAt']
        };

        try {
          const res = await updateDocument({
            variables: { input: { ...documentInput } }
          });

          if (res?.data?.updateDocument?.response !== 'Success') {
            throw new Error();
          }
        } catch (error) {
          enqueueSnackbar('ERROR! PLEASE RELOAD THE PAGE AND TRY AGAIN!', {
            variant: 'error'
          });
        }

        break;

      case 'poke':
        setCheckedBoxes([typeItemToggledItem]);
        setEmailPokeDialogOpen(true);
        break;
      case 'poke_selected':
        setEmailPokeDialogOpen(true);
        break;

      case 'show_timeboxes':
        setCheckedBoxes([typeItemToggledItem]);
        setTimeboxViewDialogType('NEXUSDOCUMENTS');
        setIsTimeboxViewDialogOpen(true);
        break;
      case 'show_timeboxes_for_selected':
        setTimeboxViewDialogType('NEXUSDOCUMENTS');
        setIsTimeboxViewDialogOpen(true);
        break;
    }
  };

  const [menuOptions, setMenuOptions] = useState([]);

  useEffect(() => {
    if (!open) {
      setMenuOptions([]);
      return;
    }
    // typeItemToggledItem

    // console.log('checkedBoxes: ', checkedBoxes);
    const checkedBoxesIds = checkedBoxes.map((obj) => obj._id);
    const isCheckboxedItemRightClicked = Boolean(typeItemToggledItem?._id)
      ? checkedBoxesIds?.includes(typeItemToggledItem._id)
      : false;
    let newMenuOptions = [];

    if (!isCheckboxedItemRightClicked) {
      newMenuOptions = [
        ...newMenuOptions,
        {
          type: 'option',
          name:
            'Filter' + (Boolean(checkedBoxes?.length) ? ' Right Clicked' : ''),
          value: 'filter',
          documentType: 'filter',
          icon: <FilterListIcon style={iconStyle} />
        }
      ];
    }
    if (checkedBoxes?.length) {
      newMenuOptions = [
        ...newMenuOptions,
        {
          type: 'option',
          name: `Filter Selected (${checkedBoxes?.length})`,
          value: 'filter_selected',
          documentType: 'filter_selected',
          icon: <FilterListIcon style={iconStyle} />
        }
      ];
    }

    if (['account', 'folder'].includes(typeItemToggledItem?.type)) {
      newMenuOptions = [
        ...newMenuOptions,
        { type: 'headline', name: '+ Add' },
        {
          type: 'option',
          name: 'New Folder',
          value: 'new_folder',
          documentType: 'folder',
          icon: <CreateNewFolderIcon style={iconStyle} />
        }
      ];
      if (typeItemToggledItem?.type === 'folder') {
        newMenuOptions = [
          ...newMenuOptions,
          {
            type: 'option',
            name: 'New Manual Data Entry',
            value: 'new_manual_data_entry',
            documentType: 'manualDataEntry',
            icon: <KeyboardReturnOutlinedIcon style={iconStyle} />
          },
          {
            type: 'option',
            name: 'Data Source',
            value: 'add_data_source',
            documentType: 'dataSource',
            icon: <StorageIcon style={iconStyle} />
          },
          {
            type: 'option',
            name: 'New Task',
            value: 'new_task',
            documentType: 'task',
            icon: <ListAltIcon style={iconStyle} />
          }
        ];
      }
    }

    if (typeItemToggledItem?.type === 'task') {
      newMenuOptions = [
        ...newMenuOptions,
        { type: 'headline', name: '+ Add' },
        {
          type: 'option',
          name: 'Sub-task',
          value: 'new_sub_task',
          documentType: 'subTask',
          icon: <ListIcon style={iconStyle} />
        },
        { type: 'divider' }
      ];
    }

    if (
      (['account'].includes(typeItemToggledItem?.type) &&
        copiedDocumentIds.length) ||
      ['folder'].includes(typeItemToggledItem?.type)
    ) {
      newMenuOptions = [
        ...newMenuOptions,
        { type: 'divider' }
        // { type: 'headline', name: 'Copy & paste' }
      ];
    }

    if (
      [
        'folder',
        'task',
        'subTask',
        'taxonomy',
        'manualDataEntry',
        'facebook_ads_campaign',
        'google_ads_campaign',
        'google_ads_campaign_set',
        'cm360_campaign',
        'dv360_insertion_order',
        'snapchat_ads_campaign',
        'tiktok_ads_campaign',
        'pinterest_ads_campaign',
        'readpeak_campaign',
        'strossle_campaign',
        'amazon_dsp_order',
        'taboola_ads_campaign',
        'microsoft_advertising_campaign',
        'microsoft_advertising_campaign_set',
        'microsoft_invest_insertion_order',
        'apple_search_ads_campaign',
        'apple_search_ads_campaign_set',
        'adform_order',
        'linkedin_ads_campaign_group',
        'googleAds'
      ].includes(typeItemToggledItem?.type) &&
      !typeItemToggledItem.isSetChild
    ) {
      if (!isCheckboxedItemRightClicked) {
        {
          newMenuOptions = [
            ...newMenuOptions,
            {
              type: 'option',
              name:
                'Copy' +
                (Boolean(checkedBoxes?.length) ? ' Right Clicked' : ''),
              value: 'copy',
              documentType: 'copy',
              icon: <ContentCopyIcon style={iconStyle} />
            }
          ];
        }
      }
    }
    if (checkedBoxes?.length) {
      newMenuOptions = [
        ...newMenuOptions,
        {
          type: 'option',
          name: `Copy Selected (${checkedBoxes?.length})`,
          value: 'copy_selected',
          documentType: 'copy_selected',
          icon: <ContentCopyIcon style={iconStyle} />
        }
      ];
    }
    if (
      [
        'account',
        'folder'
        // 'task',
        // 'subTask',
        // 'taxonomy',
        // 'manualDataEntry',
        // 'facebook_ads_campaign',
        // 'google_ads_campaign',
        // 'cm360_campaign',
        // 'dv360_insertion_order',
        // 'snapchat_ads_campaign',
        // 'tiktok_ads_campaign',
        // 'pinterest_ads_campaign',
        // 'readpeak_campaign',
        // 'strossle_campaign',
        // 'amazon_dsp_order',
        // 'taboola_ads_campaign',
        // 'microsoft_advertising_campaign',
        // 'microsoft_invest_insertion_order',
        // 'apple_search_ads_campaign',
        // 'adform_order',
        // 'linkedin_ads_campaign_group',
        // 'googleAds'
      ].includes(typeItemToggledItem?.type) &&
      copiedDocumentIds.length
    ) {
      newMenuOptions = [
        ...newMenuOptions,
        {
          type: 'option',
          name: 'Paste',
          value: 'paste',
          documentType: 'paste',
          icon: <ContentPasteIcon style={iconStyle} />
        }
      ];
    }

    if (
      [
        'folder',
        'task',
        'subTask',
        'taxonomy',
        'manualDataEntry',
        'facebook_ads_campaign',
        'google_ads_campaign',
        'google_ads_campaign_set',
        'cm360_campaign',
        'dv360_insertion_order',
        'snapchat_ads_campaign',
        'tiktok_ads_campaign',
        'pinterest_ads_campaign',
        'readpeak_campaign',
        'strossle_campaign',
        'amazon_dsp_order',
        'taboola_ads_campaign',
        'microsoft_advertising_campaign',
        'microsoft_advertising_campaign_set',
        'microsoft_invest_insertion_order',
        'apple_search_ads_campaign',
        'apple_search_ads_campaign_set',
        'adform_order',
        'linkedin_ads_campaign_group',
        'googleAds'
      ].includes(typeItemToggledItem?.type) &&
      !typeItemToggledItem.isSetChild
    ) {
      if (!isCheckboxedItemRightClicked) {
        newMenuOptions = [
          ...newMenuOptions,
          {
            type: 'option',
            name:
              'Duplicate' +
              (Boolean(checkedBoxes?.length) ? ' Right Clicked' : ''),
            value: 'duplicate',
            documentType: 'duplicate',
            icon: <FileCopyIcon style={iconStyle} />
          }
        ];
      }
      if (checkedBoxes?.length) {
        newMenuOptions = [
          ...newMenuOptions,
          {
            type: 'option',
            name: `Duplicate Selected (${checkedBoxes?.length})`,
            value: 'duplicate_selected',
            documentType: 'duplicate_selected',
            icon: <FileCopyIcon style={iconStyle} />
          }
        ];
      }
    }
    if (checkedBoxes?.length || typeItemToggledItem?.isSet) {
      newMenuOptions = [...newMenuOptions, { type: 'headline', name: 'Edit' }];
    }

    if (checkedBoxes?.length) {
      newMenuOptions = [
        ...newMenuOptions,
        // { type: 'headline', name: 'Edit' },
        {
          type: 'option',
          name: `Bulk Edit Selected (${checkedBoxes?.length})`,
          value: 'bulk_edit_selected',
          documentType: 'bulk_edit_selected',
          icon: <EditIcon style={iconStyle} />
        }
      ];
    }

    if (typeItemToggledItem.isSet) {
      newMenuOptions = [
        ...newMenuOptions,
        // { type: 'headline', name: 'Edit' },
        {
          type: 'option',
          name: `Edit Set filters`,
          value: 'edit_set_filters',
          documentType: 'edit_set_filters',
          icon: <FilterAltIcon style={iconStyle} />
        }
      ];
    }

    if (
      checkedBoxes?.length &&
      checkedBoxes.filter(
        (obj) =>
          ![
            // 'account',
            'folder',
            'task',
            'subTask',
            'taxonomy',
            'manualDataEntry',
            'facebook_ads_campaign',
            'google_ads_campaign',
            'cm360_campaign',
            'dv360_insertion_order',
            'snapchat_ads_campaign',
            'tiktok_ads_campaign',
            'pinterest_ads_campaign',
            'readpeak_campaign',
            'strossle_campaign',
            'amazon_dsp_order',
            'taboola_ads_campaign',
            'microsoft_advertising_campaign',
            'microsoft_invest_insertion_order',
            'apple_search_ads_campaign',
            'adform_order',
            'linkedin_ads_campaign_group',
            'googleAds'
          ].includes(obj.type)
      ).length === 0 &&
      columns.includes('checkedAt')
    ) {
      newMenuOptions = [
        ...newMenuOptions,
        // { type: 'headline', name: 'Edit' },
        {
          type: 'option',
          name: `Check Selected (${checkedBoxes?.length})`,
          value: 'check_selected',
          documentType: 'check_selected',
          icon: (
            <div
              style={{
                fontSize: '7px',
                marginRight: '7px',
                borderRadius: '3px',
                height: '13px',
                width: '13px',
                background: 'grey',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white'
              }}
            >
              <span>1</span>
            </div>
          )
        }
      ];
    }

    if (
      !['account', 'taxonomy'].includes(typeItemToggledItem?.type) &&
      newMenuOptions.length
    ) {
      newMenuOptions = [
        ...newMenuOptions,
        { type: 'headline', name: 'Communicate' }
        // { type: 'divider' }
      ];
    }

    if (!['account', 'taxonomy'].includes(typeItemToggledItem?.type)) {
      if (!isCheckboxedItemRightClicked) {
        newMenuOptions = [
          ...newMenuOptions,

          {
            type: 'option',
            name:
              'Poke' + (Boolean(checkedBoxes?.length) ? ' Right Clicked' : ''),
            value: 'poke',
            documentType: 'poke',
            icon: <i style={iconStyle} className="fas fa-hand-point-right"></i>
          }
        ];
      }

      if (checkedBoxes?.length) {
        newMenuOptions = [
          ...newMenuOptions,

          {
            type: 'option',
            name: `Poke Selected (${checkedBoxes?.length})`,
            value: 'poke_selected',
            documentType: 'poke_selected',
            icon: <i style={iconStyle} className="fas fa-hand-point-right"></i>
          }
        ];
      }
    }
    if (
      currentManagerAccount?.isTimeboxingOn ||
      ['system_admin', 'debug'].includes(user?.role) ||
      [
        '6167f2a56a44185b71fd0bcc',
        '61a917e9d86ba532fcee25a9',
        '63ea0160f4ab796775dbe0da',
        '640b425a1b1064af10bc2918',
        '640b3de89db4bd2b100bdf58'
      ].includes(user?._id)
    ) {
      if (!isCheckboxedItemRightClicked) {
        newMenuOptions = [
          ...newMenuOptions,
          { type: 'divider' },
          {
            type: 'option',
            name:
              `Show Timeboxes` +
              (Boolean(checkedBoxes?.length) ? ' Right Clicked' : ''),
            value: 'show_timeboxes',
            documentType: 'show_timeboxes',
            icon: <ScheduleIcon style={iconStyle} />
          }
        ];
      }
      if (checkedBoxes?.length) {
        newMenuOptions = [
          ...newMenuOptions,
          // { type: 'divider' },
          {
            type: 'option',
            name: `Show Timeboxes For Selected (${checkedBoxes?.length})`,
            value: 'show_timeboxes_for_selected',
            documentType: 'show_timeboxes_for_selected',
            icon: <ScheduleIcon style={iconStyle} />
          }
        ];
      }
    }

    setMenuOptions(newMenuOptions);
  }, [typeItemToggledItem, copiedDocumentIds, checkedBoxes, columns]);

  return (
    // <div
    //   style={{ display: (!open || !typeItemAnchorRef?.current) && 'none' }}
    //   // className={classes.root}
    // >
    <div
      ref={anchorRef}
      onClick={handleToggle}
      style={{ display: (!open || !typeItemAnchorRef?.current) && 'none' }}
    >
      <Popper
        placement="right"
        style={{ zIndex: '5000' }}
        open={open && typeItemAnchorRef?.current}
        anchorEl={typeItemAnchorRef?.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'left'
              // placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                {menuOptions.length ? (
                  <MenuList
                    style={{ minWidth: '150px' }}
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {menuOptions.map((obj, index) => {
                      if (obj.type === 'divider') {
                        // return <Divider />;
                        // return <></>;
                        return (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                              minHeight: '15px'
                            }}
                          >
                            <span
                              style={{
                                border: '0.5px solid rgba(150,150,150,0.3)',
                                width: '100%',
                                height: '0px'
                              }}
                            ></span>
                          </div>
                        );
                      }
                      if (obj.type === 'headline') {
                        return (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                              minHeight: '15px'
                            }}
                          >
                            <span
                              style={{
                                border: '0.5px solid rgba(150,150,150,0.3)',
                                width: '100%',
                                height: '0px'
                              }}
                            ></span>
                            <span
                              style={{
                                fontSize: '10px',
                                textAlign: 'center',
                                padding: '0px 5px',
                                whiteSpace: 'nowrap',
                                color: 'rgba(150,150,150,0.7)'
                              }}
                            >
                              {obj.name}
                            </span>
                            <span
                              style={{
                                border: '0.5px solid rgba(150,150,150,0.3)',
                                width: '100%',
                                height: '0px'
                              }}
                            ></span>
                          </div>
                        );
                      }
                      return (
                        <MenuItem
                          key={index}
                          style={{ fontSize: '13px' }}
                          onClick={(e) =>
                            handleClickMenuItem(e, obj.value, obj.documentType)
                          }
                        >
                          {obj.icon}
                          {obj.name}
                        </MenuItem>
                      );
                    })}
                    {/* <MenuItem
                      onClick={(e) =>
                        handleClickMenuItem(e, 'new_folder', 'folder')
                      }
                    >
                      New Folder
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => handleClickMenuItem(e, 'copy', 'copy')}
                    >
                      Copy
                    </MenuItem>
                    <MenuItem
                      onClick={(e) => handleClickMenuItem(e, 'paste', 'paste')}
                    >
                      Paste
                    </MenuItem> */}
                    {/* {typeItemToggledItem?.type === 'folder' && (
                      <MenuItem
                        onClick={(e) =>
                          handleClickMenuItem(e, 'new_template', 'template')
                        }
                      >
                        New Template
                      </MenuItem>
                    )} */}
                    {/* <MenuItem
                      onClick={(e) =>
                        handleClickMenuItem(e, 'add_data_source', 'dataSource')
                      }
                    >
                      Add Data Source
                    </MenuItem> */}
                    {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
                  </MenuList>
                ) : (
                  <div>{typeItemToggledItem && 'No options available'}</div>
                )}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
    // </div>
  );
}
