import React, { useEffect, useState, useContext } from 'react';

import { AccountContext } from '../../../../context/AccountContext';

import { ReportBuilderContext } from '../../../../context/ReportBuilderContext';

import { TextField } from '@mui/material';
import { set } from 'mongoose';

import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import PieChartIcon from '@mui/icons-material/PieChart';

import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import TimelineIcon from '@mui/icons-material/Timeline';
import BarChartIcon from '@mui/icons-material/BarChart';

import ShowChartIcon from '@mui/icons-material/ShowChart';
import DateRangeIcon from '@mui/icons-material/DateRange';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import TableChartIcon from '@mui/icons-material/TableChart';

import ArticleIcon from '@mui/icons-material/Article';

import ImageIcon from '@mui/icons-material/Image';
import SmartphoneIcon from '@mui/icons-material/Smartphone';

import LaptopIcon from '@mui/icons-material/Laptop';

import FiveKIcon from '@mui/icons-material/FiveK';

import ClearAllIcon from '@mui/icons-material/ClearAll';

import AcUnitIcon from '@mui/icons-material/AcUnit';

import SquareIcon from '@mui/icons-material/Square';

import { useTheme } from '@mui/material';
import { phoneMockupImage } from './assets/phoneMockupImage';

import { defaultWidgetObject } from './defaultWidgetObject';

import { fieldDictionary } from './dictionary';

import { v4 as uuidv4 } from 'uuid';

function TaxonomyWidgetList({ isDraggingWidget, setIsDraggingWidget, zoom }) {
  const { currentManagerAccount } = useContext(AccountContext);

  const {
    currentlyDraggingWidget,
    setCurrentlyDraggingWidget,
    reportBuilderColorPalette
  } = useContext(ReportBuilderContext);
  const theme = useTheme();
  const [isDraggingThisWidgetType, setIsDraggingThisWidgetType] =
    useState(false);
  const [search, setSearch] = React.useState('');

  // const [grabbing, toggleGrabbing] = useState(false);

  const widgets = [
    {
      category: 'tools',
      title: '',
      description: '',
      dimensions: [],
      metrics: [],
      name: 'Erase All',
      type: 'ERASE_ALL_TOOL'
    },
    // {
    //   // isResizable: false,
    //   category: 'tools',
    //   title: '',
    //   description: '',
    //   dimensions: [],
    //   metrics: [],
    //   name: 'Freeze Data',
    //   type: 'FREEZE_DATA_TOOL',
    //   // imageUrl:
    //   //   'https://nexustable.wpengine.com/wp-content/uploads/2024/10/white-wall-1-scaled.jpg',
    //   // // value:
    //   //   'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    //   style: {
    //     // height: 200,
    //     // width: 200
    //     // overflow: 'hidden',
    //     // padding: '10px'
    //     // opacity: 1,
    //     // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
    //   }
    //   // isShowingCard: true
    //   // defaultValue: ''
    // },
    {
      ...defaultWidgetObject,
      isResizable: true,
      category: 'widgets',
      title: '',
      description: '',
      dimensions: [],
      metrics: [],
      name: 'Image',
      type: 'IMAGE_WIDGET',
      imageUrl:
        'https://nexustable.wpengine.com/wp-content/uploads/2024/10/white-wall-1-scaled.jpg',
      // value:
      //   'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      height: 200,
      width: 200,
      // overflow: 'hidden',
      // padding: '10px'
      // opacity: 1,
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
      // style: {
      //   height: 200,
      //   width: 200,
      //   // overflow: 'hidden',
      //   // padding: '10px'
      //   opacity: 1,
      //   boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      // },
      isShowingCard: false,
      isCardShadowOn: false,
      isDropShadowOn: true,
      isShowingTitle: false,
      cardFillOpacity: 0
      // defaultValue: ''
    },
    {
      ...defaultWidgetObject,
      isAspectRatioLocked: true,
      originalAspectRatio: 345 / 711,
      originalHeight: 711,
      originalWidth: 345,
      isResizable: true,
      category: 'widgets',
      title: '',
      description: '',
      dimensions: [],
      metrics: [],
      name: 'Phone Mockup',
      type: 'MOCKUP_WIDGET',
      mockupType: 'PHONE',
      mockupImageDataUri: phoneMockupImage,
      // mockupUrl:
      //   // 'https://nexustable.wpengine.com/wp-content/uploads/2024/10/phone-mockup.png',
      //   'https://nexustable.wpengine.com/wp-content/uploads/2024/10/phone-mockup-safe.png',

      // imageUrl: undefined,
      // value:
      //   'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      height: 711,
      width: 345,
      // opacity: 1,
      // overflow: 'hidden',
      // padding: '10px'
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
      // style: {
      //   height: '711px',
      //   width: '345px',
      //   opacity: 1,
      //   // overflow: 'hidden',
      //   // padding: '10px'
      //   boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      // },
      isShowingCard: false,
      isCardShadowOn: false,
      isDropShadowOn: true,
      isShowingTitle: false

      // defaultValue: ''
    },
    {
      ...defaultWidgetObject,
      isAspectRatioLocked: true,
      originalAspectRatio: 1860 / 1124,
      originalHeight: 1124,
      originalWidth: 1860,
      isResizable: true,
      category: 'widgets',
      title: '',
      description: '',
      dimensions: [],
      metrics: [],
      name: 'Laptop Mockup',
      type: 'MOCKUP_WIDGET',
      mockupType: 'LAPTOP',
      // mockupImageDataUri: phoneMockupImage,
      // mockupUrl:
      //   // 'https://nexustable.wpengine.com/wp-content/uploads/2024/10/phone-mockup.png',
      //   'https://nexustable.wpengine.com/wp-content/uploads/2024/10/phone-mockup-safe.png',

      // imageUrl: undefined,
      // value:
      //   'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      height: 600,
      width: 1000,
      // opacity: 1,
      // overflow: 'hidden',
      // padding: '10px'
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
      // style: {
      //   height: '711px',
      //   width: '345px',
      //   opacity: 1,
      //   // overflow: 'hidden',
      //   // padding: '10px'
      //   boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      // },
      isShowingCard: false,
      isCardShadowOn: false,
      isDropShadowOn: true,
      isShowingTitle: false

      // defaultValue: ''
    },
    // {
    //   isResizable: true,
    //   category: 'widgets',
    //   title: '',
    //   description: '',
    //   dimensions: [],
    //   metrics: [],
    //   name: 'Manager Account Logo',
    //   type: 'MANAGER_ACCOUNT_LOGO_WIDGET',
    //   // value:
    //   //   'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    //   height: 80,
    //   width: 200,
    //   padding: '10px',
    //   opacity: 1,
    //   // style: {
    //   //   height: 80,
    //   //   width: 200,
    //   //   padding: 10,
    //   //   opacity: 1
    //   //   // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
    //   // },
    //   imageUrl:
    //     currentManagerAccount?.logo ||
    //     'https://nexustable-dev.herokuapp.com/static/media/nexuslogo.7d069a28e8fddb231627.png',
    //   isShowingCard: false
    //   // defaultValue: ''
    // },
    {
      ...defaultWidgetObject,
      isResizable: false,
      category: 'widgets',
      title: '',
      description: '',
      dimensions: [],
      metrics: [],
      name: 'Date Picker',
      type: 'DATE_PICKER_WIDGET',
      // value:
      //   'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      height: 60,
      width: 200,
      padding: '10px',
      // opacity: 1,
      // style: {
      //   height: '60px',
      //   width: '200px',
      //   padding: '10px',
      //   opacity: 1
      //   // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      // },
      isShowingCard: false,
      isCardShadowOn: true,
      isDropShadowOn: false,
      isShowingTitle: false

      // fontSize: '14px',
      // fontFamily: 'Roboto',
      // isFontItalic: false,
      // isFontBold: false,
      // fontColor: '#000000'

      // defaultValue: ''
    },
    {
      ...defaultWidgetObject,
      isResizable: true,
      category: 'widgets',
      title: '',
      description: '',
      dimensions: [],
      metrics: [],
      name: 'Text',
      type: 'TEXT_WIDGET',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      height: 100,
      width: 200,
      // opacity: 1,
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
      // style: {
      //   height: 100,
      //   width: 200,
      //   boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
      // },
      isShowingCard: true,
      isCardShadowOn: true,
      isDropShadowOn: false,
      isShowingTitle: false
    },
    {
      ...defaultWidgetObject,
      isResizable: true,
      category: 'widgets',
      title: 'Cost',
      description: '',
      dimensions: [],
      // function: 'sum',
      metrics: [
        {
          metric: 'cost'
          // axis: 'leftAxisId'
        }
      ],
      name: 'Number',
      type: 'NUMBER_WIDGET',
      value: '',
      variant: 'STANDARD',
      height: 150,
      width: 150,
      // opacity: 1,
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',

      isShowingCard: true,
      isCardShadowOn: true,
      isDropShadowOn: false,
      isShowingTitle: true,

      titleFontSize: 12

      // fontFamily: 'Roboto',
      // isFontItalic: false,
      // isFontBold: false,
      // fontColor: '#000000'
      // defaultValue: ''
    },
    // {
    //   isResizable: true,
    //   category: 'widgets',
    //   title: 'Cost',
    //   description: '',
    //   dimensions: [],
    //   function: 'sum',
    //   metrics: [{ metric: 'cost', axis: 'leftAxisId' }],
    //   interval: 'date',
    //   name: 'Line Chart',
    //   type: 'LINE_CHART_WIDGET',

    //   isShowingCard: true,
    //   data: []
    //   // defaultValue: ''
    // },
    {
      ...defaultWidgetObject,
      isResizable: true,
      category: 'widgets',
      title: 'Cost',
      description: '',
      dimensions: ['documentType'],
      // function: 'sum',
      metrics: [
        {
          metric: 'cost'
          // axis: 'leftAxisId',
          // grouping: 'standard',
          // chartType: 'bar'
        }
      ],
      interval: 'date',
      name: 'Table Chart',
      type: 'TABLE_CHART_WIDGET',

      height: 310,
      width: 630,
      // opacity: 1,
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',

      isShowingCard: true,
      isCardShadowOn: true,
      isDropShadowOn: false,
      isShowingTitle: false,
      isTextFontBold: false,

      data: []
      // defaultValue: ''
    },
    {
      ...defaultWidgetObject,
      isResizable: true,
      category: 'widgets',
      title: 'Cost',
      description: '',
      dimensions: [],
      // function: 'sum',
      metrics: [
        {
          // id: uuidv4(),
          metric: 'cost',
          axis: 'leftAxisId',
          grouping: 'standard',
          chartType: 'bar',
          // dataLineWidth: undefined,
          // dataLineStyle: undefined, //'solid',
          isDataLineSmooth: false,
          groupingChartType: 'bar_standard'
        }
      ],
      interval: 'date',
      name: 'Combo Chart',
      type: 'COMBO_CHART_WIDGET',

      height: 310,
      width: 630,
      // opacity: 1,
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',

      isShowingCard: true,
      isCardShadowOn: true,
      isDropShadowOn: false,
      isShowingTitle: false,
      isShowingBottomAxisTitle: true,
      chartFillColors: [...(reportBuilderColorPalette || [])],

      data: []
      // defaultValue: ''
    },
    {
      ...defaultWidgetObject,
      isResizable: true,
      category: 'widgets',
      title: 'Cost',
      description: '',
      dimensions: ['documentType'],
      // function: 'sum',
      metrics: [
        {
          // id: uuidv4(),
          metric: 'cost'
          // axis: 'leftAxisId'
        }
      ],
      name: 'Pie Chart',
      type: 'PIE_CHART_WIDGET',

      innerChartRadius: 0,
      // holeSize: 50,

      height: 310,
      width: 470,
      // opacity: 1,
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',

      isShowingCard: true,
      isCardShadowOn: true,
      isDropShadowOn: true,
      isShowingTitle: true,

      chartFillColors: [...(reportBuilderColorPalette || [])]
    },

    {
      ...defaultWidgetObject,
      isResizable: true,
      category: 'widgets',
      title: 'Cost',
      description: '',
      dimensions: ['documentType'],
      // function: 'sum',
      metrics: [
        {
          // id: uuidv4(),
          metric: 'cost'
          // axis: 'leftAxisId'
        }
      ],
      name: 'Donut Chart',
      type: 'PIE_CHART_WIDGET',

      height: 310,
      width: 470,
      // opacity: 1,
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',

      isShowingCard: true,
      isCardShadowOn: true,
      isDropShadowOn: true,
      isShowingTitle: true,
      innerChartRadius: 50,

      chartFillColors: [...(reportBuilderColorPalette || [])]
    },

    {
      category: 'dimensions',
      title: 'None',
      description: '',
      dimensions: [],
      metrics: [],
      name: 'None',
      type: 'DIMENSION'
    },
    {
      category: 'dimensions',
      title: 'Name',
      description: '',
      dimensions: ['name'],
      metrics: [],
      name: 'Name',
      type: 'DIMENSION'
    },
    {
      category: 'functions',
      title: 'Average',
      description: '',
      // function: 'average',
      metrics: [],
      name: 'AVERAGE',
      type: 'FUNCTION'
    },
    {
      category: 'functions',
      title: 'SUM',
      description: '',
      // function: 'sum',
      metrics: [],
      name: 'SUM',
      type: 'FUNCTION'
    },
    {
      category: 'intervals',
      title: 'Date',
      description: '',
      interval: 'date',
      metrics: [],
      name: 'Date',
      type: 'INTERVAL'
    },
    {
      category: 'intervals',
      title: 'Week',
      description: '',
      interval: 'week',
      metrics: [],
      name: 'Week',
      type: 'INTERVAL'
    },
    {
      category: 'intervals',
      title: 'Month',
      description: '',
      interval: 'month',
      metrics: [],
      name: 'Month',
      type: 'INTERVAL'
    },
    {
      category: 'intervals',
      title: 'Day of Month',
      description: '',
      interval: 'dayOfMonth',
      metrics: [],
      name: 'Day of Month',
      type: 'INTERVAL'
    },
    {
      category: 'intervals',
      title: 'Day of Week',
      description: '',
      interval: 'dayOfWeek',
      metrics: [],
      name: 'Day of Week',
      type: 'INTERVAL'
    },
    {
      category: 'dimensions',
      title: 'Data Source Type',
      description: '',
      dimensions: ['documentType'],
      metrics: [],
      name: 'Data Source Type',
      type: 'DIMENSION'
    },
    ...[
      'impressions',
      'cpm',
      'vCpm',
      'clicks',
      // 'ctr',
      'cpcv',
      'cpc',
      'viewableRate',
      'vcr',
      'searchImpressionShare',

      'cost',
      'budget',

      'viewableImpressions',
      'measurableImpressions',
      'eligibleImpressions',
      'videoCompletions',

      'action01Count',
      'action01Cpa',
      'action01Value',
      'action01Roas',
      'action01Crr',
      'action01Vpa',
      'action01Cvr',

      'action02Count',
      'action02Cpa',
      'action02Value',
      'action02Roas',
      'action02Crr',
      'action02Vpa',
      'action02Cvr',

      'action03Count',
      'action03Cpa',
      'action03Value',
      'action03Roas',
      'action03Crr',
      'action03Vpa',
      'action03Cvr'
    ]
      .sort()
      .map((column) => ({
        category: 'metrics',
        title:
          fieldDictionary[column] ||
          column?.[0]?.toUpperCase() + column?.slice(1),
        description: '',
        dimensions: [],
        metrics: [
          {
            metric: column
            // axis: 'leftAxisId',
            // grouping: 'standard',
            // chartType: 'line',
            // dataLineWidth: undefined,
            // dataLineStyle: undefined, //'solid',
            // isDataLineSmooth: false,
            // groupingChartType: 'line_standard'
          }
        ],
        name:
          fieldDictionary[column] ||
          column?.[0]?.toUpperCase() + column?.slice(1),
        type: 'METRIC'
      })),
    {
      category: 'metrics',
      title: 'Ctr (%)',
      description: '',
      dimensions: [],
      metrics: [
        {
          metric: 'ctr'
          // axis: 'leftAxisId',
          // grouping: 'standard',
          // chartType: 'line',
          // dataLineWidth: undefined,
          // dataLineStyle: undefined, //'solid',
          // isDataLineSmooth: false,
          // groupingChartType: 'line_standard'
        }
      ],
      name: 'Ctr (%)',
      type: 'METRIC'
    },

    {
      ...defaultWidgetObject,
      isResizable: true,
      category: 'widgets',
      title: '',
      description: '',
      dimensions: [],
      // function: 'sum',
      metrics: [],
      name: 'Rectangle Shape',
      type: 'RECTANGLE_SHAPE_WIDGET',
      value: '',
      variant: 'STANDARD',
      height: 150,
      width: 150,
      // opacity: 1,
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',

      shapeFillColor: '#BCBCBC',

      outerBorderWidth: 0,
      isShowingCard: false,
      isCardShadowOn: false,
      isDropShadowOn: true,
      isShowingTitle: false
      // defaultValue: ''
    }
  ];

  //   const dragStart = (e, value) => {
  //     const target = e.target;
  //     console.log('value: ', value);
  //     e.dataTransfer.setData('droppedItem', JSON.stringify(value));
  //     setTimeout(() => {
  //       target.style.display = 'none';
  //     }, 0);
  //   };

  const dragStart = (e, value, origin, originIndex) => {
    setIsDraggingThisWidgetType(value.type);
    setIsDraggingWidget(true);
    setCurrentlyDraggingWidget(value);
    const target = e.target;
    e.dataTransfer.setData(
      'droppedItem',
      JSON.stringify({
        value,
        origin,
        originIndex,
        eNativeEventOffsetX: e.nativeEvent.offsetX,
        eNativeEventOffsetY: e.nativeEvent.offsetY
      })
    );
    // console.log('{ value, origin, originIndex }: ', {
    //   value,
    //   origin,
    //   originIndex
    // });

    switch (true) {
      // case 'TEXT':

      // case 'LINE_CHART':

      case value.category === 'widgets':
        target.style.width = value.width + 'px';
        target.style.minWidth = value.width + 'px';
        target.style.height = value.height + 'px';
        target.style.minHeight = value.height + 'px';
        target.style.zoom = zoom;
        target.style.display = 'flex';
        target.style.justifyContent = 'center';
        target.style.alignItems = 'center';
        break;

      default:

      // target.style.transform = `scale(${transformScale})`;

      // target.style.transformOrigin = '0% 0%';
      // target.style.height = value.style.height;
      // target.style.height = '100%';

      // var elem = window.document.createElement('div');
      // elem.style.width = '100px';
      // elem.style.height = '100px';
      // elem.style.backgroundColor = 'green';
      // target.appendChild(elem);
    }

    setTimeout(() => {
      target.style.display = 'none';
      target.style.zoom = 1;
    }, 0);
  };

  const dragEnd = (e, value) => {
    setIsDraggingThisWidgetType(null);
    setIsDraggingWidget(false);
    setCurrentlyDraggingWidget(null);
    console.log('dragEnd');
    const target = e.target;

    setTimeout(() => {
      target.style.display = 'flex';
      target.style.width = 'auto';
      target.style.minWidth = 'auto';
      target.style.height = 'auto';
      target.style.minHeight = 'auto';
    }, 0);
    // toggleGrabbing(false);
  };

  const dragOver = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const [filteredWidgetList, setFilteredWidgetList] = useState([]);
  useEffect(() => {
    const newWidgetList = widgets.filter(
      (widget) =>
        widget.type.includes(search.toLocaleUpperCase()) ||
        (widget.type === 'METRIC' &&
          widget?.metrics?.filter((metricObj) =>
            metricObj?.metric?.includes(search)
          )?.length) ||
        (widget.type === 'DIMENSION' &&
          widget?.dimensions?.filter((dimensions) =>
            dimensions?.includes(search)
          )?.length)
    );
    setFilteredWidgetList(newWidgetList);
  }, [search]);

  const iconStyle = {
    color: theme.palette.mode === 'light' && 'grey',
    fontSize: '20px',
    marginRight: '7px'
  };

  const metricWidgetList = filteredWidgetList.filter(
    (listItem) => listItem.type === 'METRIC'
  );

  const intervalWidgetList = filteredWidgetList.filter(
    (listItem) => listItem.type === 'INTERVAL'
  );

  const toolWidgetList = filteredWidgetList.filter((listItem) =>
    listItem.type.includes('TOOL')
  );

  const widgetWidgetList = filteredWidgetList.filter((listItem) =>
    listItem.type.includes('WIDGET')
  );

  const dimensionWidgetList = filteredWidgetList.filter(
    (listItem) => listItem.type === 'DIMENSION'
  );

  return (
    <div draggable={false} style={{ userSelect: 'none' }}>
      <div
        draggable={false}
        style={{
          padding: '0 0 10px 10px',
          userSelect: 'none'
          // background: 'rgba(255,255,255,0.6)'
        }}
      >
        <TextField
          size="small"
          variant="standard"
          id="outlined-controlled"
          label="Search"
          value={search}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
        />
      </div>
      <div
        draggable={false}
        style={{
          userSelect: 'none',
          width: '100%',
          //   height: '100%',
          // background: 'rgba(255,255,255,0.6)',
          padding: '10px',
          rowGap: '5px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          //   maxHeight: 'calc(71vh - 64px)',
          overflowY: 'scroll',
          // height: '100%'
          height: 'calc(80vh - 78px)'
          // height: '80vh'
          //   maxHeight: 'calc(100vh - 64px)'
        }}
      >
        {Boolean(toolWidgetList.legth) && (
          <div
            style={{
              borderBottom: '1px solid rgba(150,150,150,0.3)',
              marginTop: '20px',
              marginBottom: '10px',
              width: '100%',
              fontWeight: 'bold',
              fontSize: '12px'
            }}
          >
            Tools
          </div>
        )}
        {toolWidgetList.map((widget, index) => {
          let icon = <></>;

          switch (widget.type) {
            case 'ERASE_ALL_TOOL':
              icon = <ClearAllIcon style={iconStyle} />;
              break;
            case 'FREEZE_DATA_TOOL':
              icon = <AcUnitIcon style={iconStyle} />;
              break;
          }
          return (
            <div
              key={index}
              onDragOver={(e) => dragOver(e, widget)}
              draggable={true}
              onDragStart={(e) =>
                dragStart(e, widget, 'taxonomy_widget_list', index)
              }
              onDragEnd={(e) => dragEnd(e, widget)}
              style={{
                // background: 'white',
                background:
                  theme.palette.mode === 'dark'
                    ? 'linear-gradient(to top, rgb(70, 70, 81), rgb(85, 85, 100))'
                    : 'rgba(255,255,255,1)',
                // margin: '10px',
                padding: '10px',
                borderRadius: '5px',
                transform: 'translate(0, 0)',
                // color: 'black',
                fontSize: '12px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow:
                  isDraggingThisWidgetType !== widget.type &&
                  'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
                cursor: isDraggingWidget ? 'grabbing' : 'grab'
              }}
              onMouseDown={() => setIsDraggingWidget(true)}
              onMouseUp={() => setIsDraggingWidget(false)}
            >
              {icon}
              <span style={{ marginRight: '10px', fontWeight: 'bold' }}>
                {widget.name}
              </span>
            </div>
          );
        })}
        {Boolean(widgetWidgetList.length) && (
          <div
            style={{
              borderBottom: '1px solid rgba(150,150,150,0.3)',
              marginTop: '20px',
              marginBottom: '10px',
              width: '100%',
              fontWeight: 'bold',
              fontSize: '12px'
            }}
          >
            Widgets
          </div>
        )}
        {widgetWidgetList.map((widget, index) => {
          let icon = <></>;

          switch (true) {
            case widget.type === 'IMAGE_WIDGET':
              icon = <ImageIcon style={iconStyle} />;
              break;

            case widget.type === 'MOCKUP_WIDGET' &&
              widget.mockupType === 'PHONE':
              icon = <SmartphoneIcon style={iconStyle} />;
              break;

            case widget.type === 'MOCKUP_WIDGET' &&
              widget.mockupType === 'LAPTOP':
              icon = <LaptopIcon style={iconStyle} />;
              break;

            case widget.type === 'MANAGER_ACCOUNT_LOGO_WIDGET':
              icon = <BrandingWatermarkIcon style={iconStyle} />;
              break;

            case widget.type === 'LINE_CHART_WIDGET':
              icon = <TimelineIcon style={iconStyle} />;
              break;
            case widget.type === 'COMBO_CHART_WIDGET':
              icon = (
                <>
                  <div>
                    <BarChartIcon style={iconStyle} />
                    {/* <TimelineIcon
                        style={{
                          ...iconStyle,
                          position: 'absolute',
                          left: '9px',
                          top: '11px',
                          color: '#a72683ee',
                          fontSize: '20px'
                        }}
                      /> */}
                  </div>
                </>
              );
              break;
            case widget.name === 'Donut Chart':
              icon = <DonutLargeIcon style={iconStyle} />;
              break;
            case widget.type === 'PIE_CHART_WIDGET':
              icon = <PieChartIcon style={iconStyle} />;
              break;

            case widget.type === 'TABLE_CHART_WIDGET':
              icon = <TableChartIcon style={iconStyle} />;
              break;
            case widget.type === 'DATE_PICKER_WIDGET':
              icon = <DateRangeIcon style={iconStyle} />;
              break;
            case widget.type === 'TEXT_WIDGET':
              icon = <ArticleIcon style={iconStyle} />;
              break;
            case widget.type === 'NUMBER_WIDGET':
              icon = <FiveKIcon style={iconStyle} />;
              break;
            case widget.type === 'RECTANGLE_SHAPE_WIDGET':
              icon = <SquareIcon style={iconStyle} />;
              break;
          }
          return (
            <div
              key={index}
              onDragOver={(e) => dragOver(e, widget)}
              draggable={true}
              onDragStart={(e) =>
                dragStart(e, widget, 'taxonomy_widget_list', index)
              }
              onDragEnd={(e) => dragEnd(e, widget)}
              style={{
                // background: 'white',
                background:
                  theme.palette.mode === 'dark'
                    ? 'linear-gradient(to top, rgb(70, 70, 81), rgb(85, 85, 100))'
                    : 'rgba(255,255,255,1)',
                // margin: '10px',
                padding: '10px',
                borderRadius: '5px',
                transform: 'translate(0, 0)',
                // color: 'black',
                fontSize: '12px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow:
                  isDraggingThisWidgetType !== widget.type &&
                  'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
                cursor: isDraggingWidget ? 'grabbing' : 'grab'
              }}
              onMouseDown={() => setIsDraggingWidget(true)}
              onMouseUp={() => setIsDraggingWidget(false)}
            >
              {icon}
              <span style={{ marginRight: '10px', fontWeight: 'bold' }}>
                {widget.name}
              </span>
            </div>
          );
        })}
        {Boolean(dimensionWidgetList.length) && (
          <div
            style={{
              borderBottom: '1px solid rgba(150,150,150,0.3)',
              marginTop: '20px',
              marginBottom: '10px',
              width: '100%',
              fontWeight: 'bold',
              fontSize: '12px'
            }}
          >
            Dimensions
          </div>
        )}
        {dimensionWidgetList.map((widget, index) => {
          let icon = <></>;

          switch (true) {
            case widget.type === 'LINE_CHART_WIDGET':
              icon = <TimelineIcon style={iconStyle} />;
              break;
            case widget.name === 'Donut Chart':
              icon = <DonutLargeIcon style={iconStyle} />;
              break;
            case widget.type === 'PIE_CHART_WIDGET':
              icon = <PieChartIcon style={iconStyle} />;
              break;

            case widget.type === 'TABLE_CHART_WIDGET':
              icon = <TableChartIcon style={iconStyle} />;
              break;
            case widget.type === 'DATE_PICKER_WIDGET':
              icon = <DateRangeIcon style={iconStyle} />;
              break;
            case widget.type === 'TEXT_WIDGET':
              icon = <TextFieldsIcon style={iconStyle} />;
              break;
            case widget.type === 'NUMBER_WIDGET':
              icon = <FiveKIcon style={iconStyle} />;
              break;
          }
          return (
            <div
              key={index}
              onDragOver={(e) => dragOver(e, widget)}
              draggable={true}
              onDragStart={(e) =>
                dragStart(e, widget, 'taxonomy_widget_list', index)
              }
              onDragEnd={(e) => dragEnd(e, widget)}
              style={{
                background:
                  theme.palette.mode === 'dark'
                    ? 'linear-gradient(to top, rgb(70, 70, 81), rgb(85, 85, 100))'
                    : 'rgba(255,255,255,1)',
                // margin: '10px',
                padding: '10px',
                borderRadius: '5px',
                transform: 'translate(0, 0)',
                // color: 'black',
                fontSize: '12px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow:
                  isDraggingThisWidgetType !== widget.type &&
                  'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
                cursor: isDraggingWidget ? 'grabbing' : 'grab'
              }}
              onMouseDown={() => setIsDraggingWidget(true)}
              onMouseUp={() => setIsDraggingWidget(false)}
            >
              {icon}
              <span style={{ marginRight: '10px', fontWeight: 'bold' }}>
                {widget.name}
              </span>
            </div>
          );
        })}
        {/* <div
          style={{
            borderBottom: '1px solid rgba(150,150,150,0.3)',
            marginTop: '20px',
            marginBottom: '10px',
            width: '100%',
            fontWeight: 'bold',
            fontSize: '12px'
          }}
        >
          Functions
        </div>
        {filteredWidgetList
          .filter((listItem) => listItem.type === 'FUNCTION')
          .map((widget, index) => {
            let icon = <></>;

            switch (widget.type) {
              case 'LINE_CHART_WIDGET':
                icon = <TimelineIcon style={iconStyle} />;
                break;
              case 'PIE_CHART_WIDGET':
                icon = <PieChartIcon style={iconStyle} />;
                break;
              case 'TABLE_CHART_WIDGET':
                icon = <TableChartIcon style={iconStyle} />;
                break;
              case 'DATE_PICKER_WIDGET':
                icon = <DateRangeIcon style={iconStyle} />;
                break;
              case 'TEXT_WIDGET':
                icon = <TextFieldsIcon style={iconStyle} />;
                break;
              case 'NUMBER_WIDGET':
                icon = <FiveKIcon style={iconStyle} />;
                break;
            }
            return (
              <div
                key={index}
                onDragOver={(e) => dragOver(e, widget)}
                draggable={true}
                onDragStart={(e) =>
                  dragStart(e, widget, 'taxonomy_widget_list', index)
                }
                onDragEnd={(e) => dragEnd(e, widget)}
                style={{
                  background:
                    theme.palette.mode === 'dark'
                      ? 'linear-gradient(to top, rgb(70, 70, 81), rgb(85, 85, 100))'
                      : 'rgba(255,255,255,1)',
                  // margin: '10px',
                  padding: '10px',
                  borderRadius: '5px',
                  transform: 'translate(0, 0)',
                  // color: 'black',
                  fontSize: '12px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow:
                    isDraggingThisWidgetType !== widget.type &&
                    'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
                  cursor: isDraggingWidget ? 'grabbing' : 'grab'
                }}
                onMouseDown={() => setIsDraggingWidget(true)}
                onMouseUp={() => setIsDraggingWidget(false)}
              >
                {icon}
                <span style={{ marginRight: '10px', fontWeight: 'bold' }}>
                  {widget.name}
                </span>
              </div>
            );
          })} */}
        {Boolean(intervalWidgetList.length) && (
          <div
            style={{
              borderBottom: '1px solid rgba(150,150,150,0.3)',
              marginTop: '20px',
              marginBottom: '10px',
              width: '100%',
              fontWeight: 'bold',
              fontSize: '12px'
            }}
          >
            Intervals
          </div>
        )}
        {intervalWidgetList.map((widget, index) => {
          let icon = <></>;

          switch (true) {
            case widget.type === 'LINE_CHART_WIDGET':
              icon = <TimelineIcon style={iconStyle} />;
              break;
            case widget.name === 'Donut Chart':
              icon = <DonutLargeIcon style={iconStyle} />;
              break;
            case widget.type === 'PIE_CHART_WIDGET':
              icon = <PieChartIcon style={iconStyle} />;
              break;

            case widget.type === 'TABLE_CHART_WIDGET':
              icon = <TableChartIcon style={iconStyle} />;
              break;
            case widget.type === 'DATE_PICKER_WIDGET':
              icon = <DateRangeIcon style={iconStyle} />;
              break;
            case widget.type === 'TEXT_WIDGET':
              icon = <TextFieldsIcon style={iconStyle} />;
              break;
            case widget.type === 'NUMBER_WIDGET':
              icon = <FiveKIcon style={iconStyle} />;
              break;
          }
          return (
            <div
              key={index}
              onDragOver={(e) => dragOver(e, widget)}
              draggable={true}
              onDragStart={(e) =>
                dragStart(e, widget, 'taxonomy_widget_list', index)
              }
              onDragEnd={(e) => dragEnd(e, widget)}
              style={{
                background:
                  theme.palette.mode === 'dark'
                    ? 'linear-gradient(to top, rgb(70, 70, 81), rgb(85, 85, 100))'
                    : 'rgba(255,255,255,1)',
                // margin: '10px',
                padding: '10px',
                borderRadius: '5px',
                transform: 'translate(0, 0)',
                // color: 'black',
                fontSize: '12px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow:
                  isDraggingThisWidgetType !== widget.type &&
                  'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
                cursor: isDraggingWidget ? 'grabbing' : 'grab'
              }}
              onMouseDown={() => setIsDraggingWidget(true)}
              onMouseUp={() => setIsDraggingWidget(false)}
            >
              {icon}
              <span style={{ marginRight: '10px', fontWeight: 'bold' }}>
                {widget.name}{' '}
                {['dayOfWeek', 'dayOfMonth'].includes(widget.interval) ? (
                  <span style={{ fontSize: '10px' }}>(avg)</span>
                ) : (
                  ''
                )}
              </span>
            </div>
          );
        })}
        {Boolean(metricWidgetList.length) && (
          <div
            style={{
              borderBottom: '1px solid rgba(150,150,150,0.3)',
              marginTop: '20px',
              marginBottom: '10px',
              width: '100%',
              fontWeight: 'bold',
              fontSize: '12px'
            }}
          >
            Metrics
          </div>
        )}
        {metricWidgetList.map((widget, index) => {
          let icon = <></>;

          switch (true) {
            case widget.type === 'LINE_CHART_WIDGET':
              icon = <TimelineIcon style={iconStyle} />;
              break;
            case widget.name === 'Donut Chart':
              icon = <DonutLargeIcon style={iconStyle} />;
              break;
            case widget.type === 'PIE_CHART_WIDGET':
              icon = <PieChartIcon style={iconStyle} />;
              break;

            case widget.type === 'TABLE_CHART_WIDGET':
              icon = <TableChartIcon style={iconStyle} />;
              break;

            case widget.type === 'DATE_PICKER_WIDGET':
              icon = <DateRangeIcon style={iconStyle} />;
              break;
            case widget.type === 'TEXT_WIDGET':
              icon = <TextFieldsIcon style={iconStyle} />;
              break;
            case widget.type === 'NUMBER_WIDGET':
              icon = <FiveKIcon style={iconStyle} />;
              break;
          }
          return (
            <div
              key={index}
              onDragOver={(e) => dragOver(e, widget)}
              draggable={true}
              onDragStart={(e) =>
                dragStart(e, widget, 'taxonomy_widget_list', index)
              }
              onDragEnd={(e) => dragEnd(e, widget)}
              style={{
                background:
                  theme.palette.mode === 'dark'
                    ? 'linear-gradient(to top, rgb(70, 70, 81), rgb(85, 85, 100))'
                    : 'rgba(255,255,255,1)',
                // margin: '10px',
                padding: '10px',
                borderRadius: '5px',
                transform: 'translate(0, 0)',
                // color: 'black',
                fontSize: '12px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow:
                  isDraggingThisWidgetType !== widget.type &&
                  'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
                cursor: isDraggingWidget ? 'grabbing' : 'grab'
              }}
              onMouseDown={() => setIsDraggingWidget(true)}
              onMouseUp={() => setIsDraggingWidget(false)}
            >
              {icon}
              <span style={{ marginRight: '10px', fontWeight: 'bold' }}>
                {widget.name}
              </span>{' '}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TaxonomyWidgetList;
