export const MONS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

const bottomAxisTitleDict = {
  day: 'Date',
  month: 'Month',
  dayOfWeek: 'Day of Week',
  dayOfMonth: 'Day of month'
};

const comboChartWidget = async (widget, pres, slide, chartFillColors) => {
  const pptx = pres;
  // console.log('widget: ', widget);

  const borderWidth = +widget?.outerBorderWidth || 0;

  // if (widget.isShowingCard) {
  //   slide.addShape(
  //     +widget?.outerBorderRadius === 0
  //       ? pres.shapes.RECTANGLE
  //       : pres.shapes.ROUNDED_RECTANGLE,
  //     {
  //       fill: {
  //         color: widget.cardFillColor || 'FFFFFF',
  //         transparency: (widget.cardFillOpacity - 1) * -100 //5
  //       },
  //       line: +widget?.outerBorderWidth !== 0 && {
  //         width: +widget?.outerBorderWidth / 2,
  //         color: widget.outerBorderColor.split('#')[1], //'f1f1f1',
  //         transparency: (widget.outerBorderOpacity - 1) * -100 //5
  //       },
  //       // w: widget.width / 72 / 2,
  //       // h: widget.height / 72 / 2,
  //       // x: +widget?.left / 72 / 2,
  //       // y: widget.top / 72 / 2,
  //       w: (widget.width - borderWidth) / 72 / 2,
  //       h: (widget.height - borderWidth) / 72 / 2,
  //       x: (+widget?.left + borderWidth / 2) / 72 / 2,
  //       y: (widget.top + borderWidth / 2) / 72 / 2,

  //       // rectRadius: 0.05,
  //       rectRadius:
  //         +widget.outerBorderRadius / 72 / 2 +
  //         (+widget.outerBorderRadius === 0 ? 0.01 : 0),

  //       shadow: widget.isCardShadowOn && {
  //         type: 'outer',
  //         blur: 6,
  //         offset: 2,
  //         angle: 45,
  //         color: '000000',
  //         opacity: 0.2
  //       }
  //     }
  //   );
  // }

  // let dataChartObj = {
  //   name: widget.title,
  //   labels: [], // widget.data.map((obj) => obj.label),
  //   values: [] // widget.data.map((obj) => obj.value)
  // };

  // let totalValue = 0;

  // for (let obj of widget.data) {
  //   totalValue = totalValue + (obj.value || null);
  // }

  // for (let obj of widget.data) {
  //   if (obj.value / totalValue >= 0.005) {
  //     dataChartObj.labels.push(obj.label);
  //     dataChartObj.values.push(obj.value);
  //   }
  // }
  // let dataChartPieStat = [dataChartObj];

  // console.log('dataChartPieStat: ', dataChartPieStat);
  // {
  //   name: 'Project Status',
  //   labels: ['Red', 'Yellow', 'Green', 'Complete', 'Cancelled', 'Unknown'],
  //   values: [25, 5, 5, 5, 5, 5]
  // }

  // ##################################################################################################

  let MAXVAL = 20000;
  // console.log('widget.data: ', widget.data);

  let highestValues = [0, 0];
  let isPlotAreaFilled = [];
  let numberOfLegends = 0;

  let sliceLabelNumber = 0;
  let arrDataTimelineLabels = [];
  switch (widget.interval) {
    case 'month':
      // sliceLabelNumber = 2;
      arrDataTimelineLabels = widget.dates.map(
        (date) => MONS[new Date(date).getMonth()]
      );

      break;

    case 'date':
      // sliceLabelNumber = 5;
      arrDataTimelineLabels = widget.dates.map((date) => date.slice(5));
      // console.log('arrDataTimelineLabels: ', arrDataTimelineLabels);
      break;

    default:
      arrDataTimelineLabels = widget.dates.map((date) => date);
  }
  // console.log('debug9465>widget.data: ', widget.data);

  const data = widget.data.map((obj) => {
    let stack = '';
    // let isPlotAreaFilled = false;
    switch (true) {
      case obj.chartType === 'bar' &&
        obj.grouping === 'stacked' &&
        obj.dimension:
        stack = obj.chartType + '_' + obj.grouping + '_' + obj.dimension;
        break;
      case obj.chartType === 'bar' &&
        obj.grouping === 'standard' &&
        obj.dimension !== 'default':
        stack = obj.chartType + '_' + obj.grouping;
        break;
      case obj.chartType === 'bar' &&
        obj.grouping === 'standard' &&
        obj.dimension === 'default':
        stack = obj.chartType + '_' + obj.grouping;
        break;

      case obj.chartType === 'line' &&
        obj.grouping === 'stacked' &&
        obj.dimension:
        stack = obj.chartType + '_' + obj.grouping + '_' + obj.dimension;
        // isPlotAreaFilled = true;
        break;
      case obj.chartType === 'line' &&
        obj.grouping === 'standard' &&
        obj.dimension !== 'default':
        stack = obj.chartType + '_' + obj.grouping;
        break;
      case obj.chartType === 'line' &&
        obj.grouping === 'standard' &&
        obj.dimension === 'default':
        stack = obj.chartType + '_' + obj.grouping;
        break;
    }

    return { ...obj, stack, isPlotAreaFilled };
  });

  const groupedByAxis = Object.groupBy(data, (obj) => obj.yAxisId);
  const groupedAxisKeys = Object.keys(groupedByAxis);

  // console.log('debug9465>groupedByAxis: ', groupedByAxis);
  // console.log('debug9465>groupedAxisKeys: ', groupedAxisKeys);
  // console.log('debug9465>arrDataTimelineLabels: ', arrDataTimelineLabels);

  let allColors = [...chartFillColors];
  const chartTypes = [];
  let axisIndex = 0;
  for (let groupedAxisKey of groupedAxisKeys) {
    const axisIndex =
      groupedAxisKeys.length > 1 && groupedAxisKey === 'rightAxisId' ? 1 : 0;
    // let highestValue = 0;
    // console.log(
    //   'debug9465>groupedByAxis[groupedAxisKey]: ',
    //   groupedByAxis[groupedAxisKey]
    // );
    const groupedStack = Object.groupBy(
      groupedByAxis[groupedAxisKey],
      (obj) => obj.stack
    );
    const groupedStackKeys = Object.keys(groupedStack);

    for (let groupedStackKey of groupedStackKeys) {
      let arrDataTimeline = [];
      numberOfLegends = numberOfLegends + 1;

      // const axisIndex =
      //   groupedAxisKeys.length > 1 &&
      //   groupedStack?.[groupedStackKey]?.[0]?.yAxisId === 'rightAxisId'
      //     ? 1
      //     : 0;
      // console.log('debug9465>groupedStackKey: ', groupedStackKey);
      // console.log(
      //   'debug9465>groupedStack[groupedStackKey]: ',
      //   groupedStack[groupedStackKey]
      // );

      // // console.log('debug9465>groupedStack: ', groupedStack);

      // // console.log('debug9465>groupedStackKeys: ', groupedStackKeys);
      // // for (let groupedStackKey of groupedStackKeys) {
      // //   for (let groupedDataStack of groupedStack[groupedStackKey]) {
      // // console.log('dataObj: ', dataObj);

      for (let groupedStackObj of groupedStack[groupedStackKey]) {
        const highestValueIndex = axisIndex;
        // groupedStackObj.yAxisId === 'leftAxisId' ? 0 : 1;

        // console.log('highestValueIndex: ', highestValueIndex);
        let totalValues = [0, 0];
        for (let value of groupedStackObj.data || []) {
          totalValues[highestValueIndex] =
            totalValues[highestValueIndex] + value;
        }

        if (highestValues[highestValueIndex] < totalValues[highestValueIndex]) {
          highestValues[highestValueIndex] = totalValues[highestValueIndex];
        }

        arrDataTimeline.push({
          name: groupedStackObj.label || widget.metrics[0],
          labels: arrDataTimelineLabels.map((str) => str), //arrDataTimelineLabels,
          values: groupedStackObj?.data.map((val) => val) //Math.round(val))
          // totalValue
        });
        // console.log('highestValues: ', highestValues);
      }

      // const allColors = [...chartFillColors];
      // const colors =
      //   axisIndex === 0 ? [...allColors] : [...allColors].reverse();
      // const colors = [...allColors];
      let colors = allColors.slice(0, arrDataTimeline.length);
      allColors = allColors.slice(arrDataTimeline.length);
      chartTypes.push({
        type:
          groupedStack?.[groupedStackKey]?.[0]?.chartType === 'bar'
            ? pptx.charts.BAR
            : pptx.charts.LINE,
        data: arrDataTimeline,
        options: {
          // chartColors: ['F38940', '000000'],
          chartColors: colors,
          // barGrouping: 'stacked',
          barGrouping: groupedStack[groupedStackKey][0].grouping, // 'stacked', // 'standard',
          secondaryValAxis: axisIndex,
          secondaryCatAxis: axisIndex,
          lineSmooth:
            groupedStack[groupedStackKey][0].isDataLineSmooth ||
            widget.isDataLineSmooth,
          lineSize: groupedStack[groupedStackKey][0].dataLineWidth
            ? groupedStack[groupedStackKey][0].dataLineWidth / 2
            : widget.dataLineWidth / 2
        }
      });
    }

    // console.log('debug9465>arrDataTimeline: ', arrDataTimeline);

    // highestValues[axisIndex] = highestValue;
    // axisIndex++;
    //   }
    // }
  }

  // console.log('groupedData: ', groupedData);

  // console.log('debug9465>chartTypes: ', chartTypes);

  // console.log('arrDataTimeline: ', arrDataTimeline);

  // for (let idx = 0; idx < 15; idx++) {
  //   let tmpObj = {
  //     name: `Series ${idx}`,
  //     labels: MONS,
  //     values: []
  //   };

  //   for (let idy = 0; idy < MONS.length; idy++) {
  //     tmpObj.values.push(Math.floor(Math.random() * MAXVAL) + 1);
  //   }

  //   // arrDataTimeline.push(tmpObj);
  // }
  let showLegend = false;

  switch (true) {
    case widget?.dimensions?.length > 0 &&
      widget?.dimensions[0] === 'documentType':
    case widget?.dimensions?.length > 0 && widget?.dimensions?.length < 3:
      showLegend = true;
      break;
  }

  // let valAxisMajorUnit = undefined;
  // let valAxisDisplayUnit = undefined;
  // let valAxisLabelFormatCode = undefined;

  const valAxisMajorUnit = (highestValue) => {
    let averageValuePerDay = highestValue / widget.dates.length;
    switch (true) {
      // case averageValuePerDay >= 10000:
      //   valAxisDisplayUnit = 'thousands';
      //   valAxisLabelFormatCode = '#,##0 k';
      //   break;

      case averageValuePerDay < 0.1:
        return 0.05;
        break;
      case averageValuePerDay < 1:
        return 0.1;
        break;

      default:
        return undefined;
    }
  };

  const valAxisDisplayUnit = (highestValue) => {
    let averageValuePerDay = highestValue / widget.dates.length;
    switch (true) {
      case averageValuePerDay >= 1000000:
        return 'millions';
        // valAxisLabelFormatCode = '#,##0 k';
        break;
      case averageValuePerDay >= 10000:
        return 'thousands';
        // valAxisLabelFormatCode = '#,##0 k';
        break;

      // case averageValuePerDay < 0.5:
      //   return (valAxisMajorUnit = 0.01);
      //   break;
      // case averageValuePerDay < 1:
      //   return (valAxisMajorUnit = 0.1);
      //   break;

      default:
        // return (valAxisLabelFormatCode = '#,##0');
        return undefined;
    }
  };

  const valAxisLabelFormatCode = (highestValue) => {
    let averageValuePerDay = highestValue / widget.dates.length;
    switch (true) {
      case averageValuePerDay >= 1000000:
        // return valAxisDisplayUnit = 'thousands';
        return '#,##0 "M"';
        break;
      case averageValuePerDay >= 10000:
        // return valAxisDisplayUnit = 'thousands';
        return '#,##0 k';
        break;

      // case averageValuePerDay < 0.5:
      //   return valAxisMajorUnit = 0.01;
      //   break;
      // case averageValuePerDay < 1:
      //   return valAxisMajorUnit = 0.1;
      //   break;

      default:
        // return '#,##0';
        return undefined;
    }
  };
  // let showKFormatting = averageValuePerDay >= 10000;
  // console.log(
  //   'highestValue / widget.dates.length: ',
  //   highestValue / widget.dates.length
  // );
  // FULL SLIDE:
  // let optsChartLine1 = {
  //   chartArea: {
  //     fill: {
  //       color: 'FFFFFF',
  //       transparency: 100
  //     },
  //     // border: { pt: '1', color: 'f1f1f1', transparency: 100 },
  //     roundedCorners: true

  //     // shadow: {
  //     //   type: 'outer',
  //     //   blur: 10,
  //     //   offset: 5,
  //     //   angle: 45,
  //     //   color: '000000',
  //     //   opacity: 1
  //     // }
  //   },

  //   plotArea: {
  //     fill: { color: '00FFFF', transparency: 100 },
  //     border: { pt: '1', color: 'f1f1f1' }
  //   },
  //   // x: 0.5,
  //   // y: 0.6,
  //   // w: '95%',
  //   // h: '85%',
  //   // barDir: 'col',
  //   // barGrouping: 'stacked',
  //   chartColors: chartFillColors.slice(0, arrDataTimeline.length), //COLORS_RYGU,['#FF0099'],
  //   // invertedColors: ['C0504D'],
  //   w: widget.width / 72 / 2 + 0 / 72 / 2,
  //   h: widget.height / 72 / 2,
  //   x: +widget?.left / 72 / 2,
  //   y: widget.top / 72 / 2,
  //   // plotArea: { fill: { color: 'F2F9FC' } },
  //   // valAxisMaxVal: MAXVAL,
  //   showLegend: showLegend,
  //   legendPos: 'r',
  //   catAxisLabelFontSize: 7,
  //   valAxisLabelFontSize: 7,
  //   valAxisMajorUnit: valAxisMajorUnit && valAxisMajorUnit,
  //   valAxisDisplayUnit: valAxisDisplayUnit && valAxisDisplayUnit, //showKFormatting && 'thousands',
  //   valAxisLabelFormatCode: valAxisLabelFormatCode, // showKFormatting ? '#,##0 k' : '#,##0',
  //   legendFontSize: 8,
  //   legendFontFace: 'Roboto',

  //   // layout: { x: 0.03, y: 0.35, w: 0.8, h: 0.65 },
  //   // layout: showLegend && { x: 0.01, y: 0, w: 0.54, h: 1 },
  //   // layout: showLegend && { x: 0.01, y: 0, w: '10%', h: 1 },
  //   catAxisLineSize: 1,
  //   valAxisLineSize: 1,
  //   // valAxisMajorTickMark: 'none',
  //   // valAxisMinorTickMark: 'none',
  //   // catAxisMajorTickMark: 'none',
  //   // catAxisMinorTickMark: 'none',
  //   radarStyle: 'filled',
  //   catAxisBaseTimeUnit: 'days',
  //   catAxisMajorTimeUnit: 'days',
  //   catAxisLabelRotate: '90',
  //   // catAxisLabelFrequency: 5,
  //   // catAxisLineStyle: 'solid',
  //   lineDataSymbol: 'none',
  //   valAxisLineShow: true,
  //   catGridLine: { style: 'none' },
  //   valGridLine: {
  //     // style: 'none',
  //     color: 'F5F5F5'
  //   },
  //   lineSize: 1.5
  //   // lineDash: 'dot',
  //   // lineCap: 'round'
  //   // lineDataSymbol: 'dot'
  // };
  // slide.addChart(pres.charts.BAR, arrDataTimeline, optsChartLine1);

  let borderRadiusLimit =
    widget.width > widget.height ? widget.height : widget.width;

  // console.log('debug46>borderRadiusLimit: ', borderRadiusLimit);

  let borderRadiusPercent = +widget.outerBorderRadius / borderRadiusLimit;

  // console.log('borderRadiusPercent: ', borderRadiusPercent);

  let outerBorderRadius =
    +widget.outerBorderRadius > borderRadiusLimit / 2
      ? borderRadiusLimit / 2
      : +widget.outerBorderRadius;
  // outerBorderRadius = +outerBorderRadius / 72 / 2 + 0 / 72 / 2;

  // console.log('debug46>outerBorderRadius: ', outerBorderRadius);

  let widthCorrection = (outerBorderRadius / widget.width) * widget.width * 0.3;
  let heightCorrection =
    (outerBorderRadius / widget.height) * widget.height * 0.3;

  if (borderRadiusPercent < 0.15) {
    widthCorrection = 0;
    heightCorrection = 0;
  }

  // console.log('debug46>widthCorrection: ', widthCorrection);

  // ####################################################################################################
  let opts = {
    // x: 0.6,
    // y: 0.6,
    // w: 6.0,
    // h: 3.0,
    // w: widget.width / 72 / 2 + 0 / 72 / 2,
    // h: widget.height / 72 / 2,
    // x: +widget?.left / 72 / 2,
    // y: widget.top / 72 / 2,

    w: (widget.width - widthCorrection - borderWidth * 2) / 72 / 2 + 0 / 72 / 2,
    h:
      (widget.height -
        (widget.isShowingBottomAxisTitle
          ? (widget?.bottomAxisTitleFontSize || 14) + 5
          : 0) -
        heightCorrection -
        borderWidth * 2) /
      72 /
      2,
    x: (+widget?.left + widthCorrection / 2 + borderWidth) / 72 / 2,
    y: (widget.top + heightCorrection / 2 + borderWidth) / 72 / 2,

    // w: (widget.width - borderWidth * 2) / 72 / 2,
    // h: (widget.height - borderWidth * 2) / 72 / 2,
    // x: (+widget?.left + borderWidth) / 72 / 2,
    // y: (widget.top + borderWidth) / 72 / 2,
    // showCatAxisTitle: widget.isShowingBottomAxisTitle,
    // catAxisTitle: bottomAxisTitleFontColor,
    // catAxisTitleColor: widget.bottomAxisTitleFontColor,
    // catAxisTitleFontSize: widget.bottomAxisTitleFontSize,
    barDir: 'col',
    catAxisLabelColor: '666666',
    catAxisLabelFontFace: 'Arial',
    // catAxisLabelFontSize: 12,
    catAxisOrientation: 'minMax',
    showLegend: widget.isShowingLegend, // true, //numberOfLegends > 2,
    // legendFontSize: 6,
    // legendFontFace: 'Roboto',
    legendPos: 't',
    showTitle: false,

    // catAxisLabelFontSize: +widget?.fontSize?.split('px')[0] / 2 || 7, // 7,
    // valAxisLabelFontSize: +widget?.fontSize?.split('px')[0] / 2 || 7, // 7,
    lineDataSymbol: widget.dates.length > 7 && 'none',

    // legendFontFace: widget?.fontFamily || 'Roboto', //'Arial', //'Courier New',
    // titleFontFace: widget?.fontFamily || 'Roboto',
    // catAxisLabelFontFace: widget?.fontFamily || 'Roboto',
    // catAxisTitleFontFace: widget?.fontFamily || 'Roboto',
    // valAxisLabelFontFace: widget?.fontFamily || 'Roboto',
    // valAxisTitleFontFace: widget?.fontFamily || 'Roboto',
    // dataLabelFontFace: widget?.fontFamily || 'Roboto',
    // serAxisLabelFontFace: widget?.fontFamily || 'Roboto',
    // serAxisTitleFontFace: widget?.fontFamily || 'Roboto',

    dataTableFontSize: +widget?.dataLabelFontSize / 2 || 7,
    legendFontSize: +widget?.legendFontSize / 2 || 7, //8,
    titleFontSize: +widget?.titleFontSize / 2 || 7,
    catAxisLabelFontSize: +widget?.bottomAxisLabelFontSize / 2 || 7,
    catAxisTitleFontSize: +widget?.bottomAxisLabelFontSize / 2 || 7,
    // valAxisLabelFontSize: +widget?.fontSize?.split('px')[0] / 2 || 7,
    // valAxisTitleFontSize: +widget?.fontSize?.split('px')[0] / 2 || 7,
    dataLabelFontSize: +widget?.dataLabelFontSize / 2 || 7,
    serAxisLabelFontSize: +widget?.fontSize?.split('px')[0] / 2 || 7, // FIX
    // serAxisTitleFontSize: +widget?.fontSize?.split('px')[0] / 2 || 7,

    legendFontFace: widget?.legendFontFamily || 'Roboto', //'Arial', //'Courier New',
    // titleFontFace: widget?.fontFamily || 'Roboto',
    catAxisLabelFontFace: widget?.bottomAxisLabelFontFamily || 'Roboto',
    catAxisTitleFontFace: widget?.bottomAxisLabelFontFamily || 'Roboto',
    // valAxisLabelFontFace: widget?.fontFamily || 'Roboto',
    // valAxisTitleFontFace: widget?.fontFamily || 'Roboto',
    dataLabelFontFace: widget?.dataLabelFontFamily || 'Roboto',
    // serAxisLabelFontFace: widget?.fontFamily || 'Roboto',
    // serAxisTitleFontFace: widget?.fontFamily || 'Roboto',

    // fontSize: +widget?.fontSize?.split('px')[0] / 2 || 7,
    // color: widget?.fontColor || '#000000',
    // bold: widget?.isFontBold || false,
    // italic: widget?.isFontItalic || false,

    catAxisLabelFontBold: widget?.isBottomAxisLabelFontBold,
    // valAxisLabelFontBold: widget?.isFontBold,
    dataLabelFontBold: widget?.isDataLabelFontBold,
    // serAxisLabelFontBold: widget?.isFontBold,
    catAxisLabelFontBold: widget?.isBottomAxisLabelFontBold,

    catAxisLabelColor: widget?.bottomAxisLabelFontColor,
    catAxisLineColor: widget?.bottomAxisLineColor,
    catAxisTitleColor: widget?.bottomAxisLabelFontColor,
    // valAxisLabelColor: widget?.fontColor,
    // valAxisLineColor: widget?.axisLineColor,
    // valAxisTitleColor: widget?.fontColor,
    dataLabelColor: widget?.dataLabelFontColor,
    lineDataSymbolLineColor: widget?.dataLabelFontColor,
    // serAxisLabelColor: widget?.fontColor,
    // serAxisTitleColor: widget?.fontColor,
    legendColor: widget?.legendFontColor,
    // valAxisMajorUnit: valAxisMajorUnit && valAxisMajorUnit,
    // valAxisDisplayUnit: valAxisDisplayUnit && valAxisDisplayUnit, //showKFormatting && 'thousands',
    // valAxisLabelFormatCode: valAxisLabelFormatCode, // showKFormatting ? '#,##0 k' : '#,##0',

    // valAxisMaxVal: 100,
    // valAxisMajorUnit: 10,
    lineSize: widget.dataLineWidth / 2, //1.5,
    lineSmooth: widget.isDataLineSmooth,

    catAxisLineSize: +widget?.bottomAxisLineWidth / 2,

    // valGridLine: {
    //   // style: 'none',
    //   color: 'F5F5F5'
    // },

    valAxes: [],

    catAxes: []
  };
  // console.log('debug9465>highestValues: ', highestValues);

  if (groupedAxisKeys.includes('leftAxisId')) {
    opts.valAxes.push({
      valGridLine: {
        // style: 'none',
        // transparency: 0.5, //(widget?.leftAxisValueGridLineOpacity - 1) * -100, //90,
        style: widget?.leftValueGridLineWidth === 0 ? 'none' : 'solid',
        color: widget?.leftValueGridLineColor?.split('#')?.[1], //'F5F5F5',
        size: widget?.leftValueGridLineWidth / 2
      },

      showValAxisTitle: false,
      valAxisTitle: 'Primary Value Axis',
      // valAxisMajorUnit: valAxisMajorUnit(highestValues[0]),
      valAxisDisplayUnit: valAxisDisplayUnit(highestValues[0]), //showKFormatting && 'thousands',
      valAxisLabelFormatCode: valAxisLabelFormatCode(highestValues[0]), // showKFormatting ? '#,##0 k' : '#,##0',
      valAxisMinVal: 0,
      valAxisLineColor: widget?.leftAxisLineColor,
      valAxisLabelFontFace: widget?.leftAxisLabelFontFamily || 'Roboto',
      valAxisTitleFontFace: widget?.leftAxisLabelFontFamily || 'Roboto',

      valAxisLabelColor: widget?.leftAxisLabelFontColor,
      // valAxisLineColor: widget?.leftAxisLineColor,
      valAxisTitleColor: widget?.leftAxisLabelFontColor,

      valAxisLabelFontSize: +widget?.leftAxisLabelFontSize / 2 || 7,
      // fontSize: +widget?.leftAxisLabelFontSize / 2 || 7,
      color: widget?.leftAxisLabelFontColor || '#000000',
      valAxisLabelFontBold: widget?.isLeftAxisLabelFontBold,
      valAxisLineSize: +widget?.leftAxisLineWidth / 2
    });

    opts.catAxes.push({
      catAxisTitle: 'Primary Category Axis'
    });
  }

  if (groupedAxisKeys.includes('rightAxisId')) {
    opts.valAxes.push({
      showValAxisTitle: false,
      valAxisTitle: 'Secondary Value Axis',
      valGridLine: {
        style:
          // !groupedAxisKeys?.includes('leftAxisId') &&
          widget?.rightValueGridLineWidth === 0 ? 'none' : 'solid',
        color: widget?.rightValueGridLineColor?.split('#')?.[1], //'F5F5F5',
        size: +widget?.rightValueGridLineWidth / 2
        // style: 'solid'
        // color: widget.leftValueGridLineColor, //'F5F5F5',
        // size: widget?.leftValueGridLineWidth
      },
      // valAxisMajorUnit: valAxisMajorUnit(highestValues[1]),
      valAxisDisplayUnit: valAxisDisplayUnit(highestValues[1]), //showKFormatting && 'thousands',
      valAxisLabelFormatCode: valAxisLabelFormatCode(highestValues[1]), // showKFormatting ? '#,##0 k' : '#,##0',
      valAxisMinVal: 0,
      valAxisLineColor: widget?.rightAxisLineColor,
      valAxisLabelFontFace: widget?.rightAxisLabelFontFamily || 'Roboto',
      valAxisTitleFontFace: widget?.rightAxisLabelFontFamily || 'Roboto',

      valAxisLabelColor: widget?.rightAxisLabelFontColor,
      // valAxisLineColor: widget?.rightAxisLineColor,
      valAxisTitleColor: widget?.rightAxisLabelFontColor,

      // fontSize: +widget?.rightAxisLabelFontSize / 2 || 7,
      valAxisLabelFontSize: +widget?.leftAxisLabelFontSize / 2 || 7,
      color: widget?.rightAxisLabelFontColor || '#000000',
      valAxisLabelFontBold: widget?.isRightAxisLabelFontBold,
      valAxisLineSize: +widget?.rightAxisLineWidth / 2
    });

    opts.catAxes.push({
      catAxisHidden: groupedAxisKeys.length > 1
    });
  }

  // console.log(
  //   'debug4582>valAxisMajorUnit(highestValues[1]) ',
  //   valAxisMajorUnit(highestValues[1])
  // );

  // const comboTypes = [];

  // const comboTypes = [
  //   {
  //     type: pptx.charts.BAR,
  //     data: CHART_DATA.EvSales_Vol.chartData,
  //     options: { chartColors: COLORS_SPECTRUM, barGrouping: 'stacked' }
  //   },
  //   {
  //     type: pptx.charts.LINE,
  //     data: CHART_DATA.EvSales_Pct.chartData,
  //     options: {
  //       chartColors: ['F38940'],
  //       secondaryValAxis: true,
  //       secondaryCatAxis: true
  //     }
  //   }
  // ];

  slide.addChart(chartTypes, opts);

  // ##################################################################################################

  // let titleWidth = Math.round(widget.title.length * 9 + 10);
  // if (titleWidth < 120) {
  //   titleWidth = 120;
  // }

  // interval

  if (widget.isShowingBottomAxisTitle) {
    slide.addText(bottomAxisTitleDict[widget?.interval] || widget?.interval, {
      w: widget.width / 72 / 2,
      h: ((widget?.bottomAxisTitleFontSize || 14) + 20) / 72 / 2,
      x: +widget?.left / 72 / 2,
      y:
        (widget.top +
          widget.height -
          (widget?.bottomAxisTitleFontSize || 14) -
          20) /
        72 /
        2,

      margin: 0,
      // fontFace: 'Roboto', // 'Arial',
      // fontSize: 8,
      // color: '000000',
      // bold: true,

      fontFace: widget?.bottomAxisTitleFontFamily || 'Roboto', // 'Arial',
      fontSize: +widget?.bottomAxisTitleFontSize / 2 || 7,
      color: widget?.bottomAxisTitleFontColor || '#000000',
      bold: widget?.isBottomAxisTitleFontBold || false, //widget?.isValueFontBold || false,
      italic: widget?.isBottomAxisTitleFontItalic || false,
      // underline: true,
      isTextBox: true,
      align: 'center'
      // fill: { color: 'F1F1F1' }
      // highlight: '#bcbcbc'
    });
  }

  // title

  if (widget.isShowingTitle) {
    // let titleWidth = Math.round(widget?.title?.length * 9 + 10);
    let titleWidth = Math.round(
      widget.title.length * (+widget?.titleFontSize - 4)
    );
    // if (titleWidth < 120) {
    //   titleWidth = 120;
    // }

    // slide.addShape(pres.shapes.ROUNDED_RECTANGLE, {
    //   fill: {
    //     // color: widget.titleFilLColor, //'ababab',
    //     // transparency: 90
    //     color: widget.titleFillColor, // 'ababab',
    //     transparency: (widget.titleFillOpacity - 1) * -100 //90
    //   },
    //   border: { pt: '1', color: 'f1f1f1', transparency: 10 },
    //   w: (titleWidth + 20) / 72 / 2,
    //   h: 25 / 72 / 2,
    //   x:
    //     +widget?.left / 72 / 2 +
    //     widget?.width / 72 / 2 -
    //     (titleWidth + 20) / 72 / 2,
    //   y: widget.top / 72 / 2 + 0 / 72 / 2,

    //   rectRadius: 0.01
    //   // rectRadius: widget.outerBorderRadius / 72 / 2,
    //   // shadow: {
    //   //   type: 'outer',
    //   //   blur: 6,
    //   //   offset: 2,
    //   //   angle: 45,
    //   //   color: 'bcbcbc',
    //   //   opacity: 0.2
    //   // }
    // });

    // title

    let borderRadiusLimit =
      widget.width > widget.height ? widget.height : widget.width;

    // console.log('debug46>borderRadiusLimit: ', borderRadiusLimit);

    let borderRadiusPercent = +widget.outerBorderRadius / borderRadiusLimit;
    let outerBorderRadius =
      +widget.outerBorderRadius > borderRadiusLimit / 2
        ? borderRadiusLimit / 2
        : +widget.outerBorderRadius;

    slide.addText(widget.title, {
      w: titleWidth / 72 / 2,
      // h: 20 / 72 / 2,
      h: (+widget?.titleFontSize + 5) / 72 / 2,
      x:
        +widget?.left / 72 / 2 +
        widget?.width / 72 / 2 -
        (titleWidth + 20 + outerBorderRadius) / 72 / 2,
      y: (widget.top + 13) / 72 / 2 + 2 / 72 / 2,

      margin: 0,
      fontFace: widget?.titleFontFamily || 'Roboto', // 'Arial',
      // fontSize: ((widget?.titleFontSize || 14) / 96) * 72,
      // fontSize: (widget?.fontSize?.split('px')?.[0] || 14) / 2,
      // color: widget.fontColor || '000000',
      // bold: true,

      fontSize: +widget?.titleFontSize / 2 || 7,
      color: widget?.titleFontColor || '#000000',
      bold: widget?.isTitleFontBold, //true, //widget?.isFontBold || false,
      italic: widget?.isTitleFontItalic || false,
      // underline: true,
      isTextBox: true,
      align: 'right'
      // fill: { color: 'F1F1F1' }
      // highlight: '#bcbcbc'
    });
  }
};

export default comboChartWidget;
