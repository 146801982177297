const pieChartWidget = async (widget, pres, slide, chartFillColors) => {
  // if (widget.isShowingCard) {
  //   slide.addShape(
  //     +widget?.outerBorderRadius === 0
  //       ? pres.shapes.RECTANGLE
  //       : pres.shapes.ROUNDED_RECTANGLE,
  //     {
  //       fill: {
  //         // color: 'FFFFFF',
  //         // transparency: (widget.opacity - 1) * -100 // 5
  //         color: widget.cardFillColor || 'FFFFFF',
  //         transparency: (widget.cardFillOpacity - 1) * -100 //5
  //       },
  //       line: +widget?.outerBorderWidth !== 0 && {
  //         width: +widget?.outerBorderWidth / 2,
  //         color: widget.outerBorderColor.split('#')[1], //'f1f1f1',
  //         transparency: (widget.outerBorderOpacity - 1) * -100 //5
  //       },
  //       // border: { pt: '1', color: 'f1f1f1', transparency: 10 },
  //       w: widget.width / 72 / 2,
  //       h: widget.height / 72 / 2,
  //       x: +widget?.left / 72 / 2,
  //       y: widget.top / 72 / 2,

  //       // rectRadius: 0.05,
  //       rectRadius:
  //         +widget.outerBorderRadius / 72 / 2 +
  //         (+widget.outerBorderRadius === 0 ? 0.01 : 0),
  //       shadow: widget.isCardShadowOn && {
  //         type: 'outer',
  //         blur: 6,
  //         offset: 2,
  //         angle: 45,
  //         color: '000000',
  //         opacity: 0.2
  //       }
  //     }
  //   );
  // }

  const borderWidth = +widget?.outerBorderWidth || 0;

  let dataChartObj = {
    name: widget.title,
    labels: [], // widget.data.map((obj) => obj.label),
    values: [] // widget.data.map((obj) => obj.value)
  };

  let totalValue = 0;

  let extraSpace = [
    ' ',
    ' ',
    ' ',
    ' ',
    ' ',
    ' ',
    ' ',
    ' ',
    ' ',
    ' ',
    ' ',
    ' ',
    ' ',
    ' ',
    ' ',
    ' ',
    ' ',
    ' ',
    ' '
  ];

  for (let obj of widget.data) {
    totalValue = totalValue + (obj.value || null);
  }

  for (let obj of widget.data) {
    if (obj.value / totalValue >= 0.005) {
      dataChartObj.labels.push(
        ' ' +
          obj.label +
          (obj.label?.length < 15
            ? extraSpace.slice(obj.label?.length).join('')
            : '')
      );
      dataChartObj.values.push(obj.value);
    }
  }
  let dataChartPieStat = [dataChartObj];

  // console.log('dataChartPieStat: ', dataChartPieStat);
  // {
  //   name: 'Project Status',
  //   labels: ['Red', 'Yellow', 'Green', 'Complete', 'Cancelled', 'Unknown'],
  //   values: [25, 5, 5, 5, 5, 5]
  // }

  slide.addChart(
    (widget.innerChartRadius || 0) > 0 ? pres.charts.DOUGHNUT : pres.charts.PIE,
    [dataChartObj],
    {
      holeSize: widget.innerChartRadius || 0,
      // x: 0.5,
      // y: 0.6,
      // w: 4.0,
      // h: 3.2,
      // w: widget.width / 72 / 2,
      // h: widget.height / 72 / 2,
      // x: +widget?.left / 72 / 2,
      // y: widget.top / 72 / 2,

      w: (widget.width - borderWidth * 2) / 72 / 2,
      h: (widget.height - borderWidth * 2) / 72 / 2,
      x: (+widget?.left + borderWidth) / 72 / 2,
      y: (widget.top + borderWidth) / 72 / 2,

      chartArea: {
        fill: {
          // color: 'F1F1F1',
          transparency: (widget.chartFillOpacity - 1) * -100
        },
        // border: { pt: '1', color: 'f1f1f1', transparency: 100 },
        roundedCorners: true

        // shadow: {
        //   type: 'outer',
        //   blur: 10,
        //   offset: 5,
        //   angle: 45,
        //   color: '000000',
        //   opacity: 1
        // }
      },
      chartColors: chartFillColors, //COLORS_RYGU,
      dataBorder: {
        pt: widget.chartBorderWidth / 2, //1,
        color: widget?.chartBorderColor?.slice(1)
        //'F1F1F1'
      },

      //
      showLegend: widget.isShowingLegend, //true,
      legendPos: 'r',

      // dataTableFontSize: +widget?.fontSize?.split('px')[0] / 2 || 7,
      legendFontSize: +widget?.legendFontSize / 2 || 7, //8,
      titleFontSize: +widget?.titleFontSize / 2 || 7,
      // catAxisLabelFontSize: +widget?.fontSize?.split('px')[0] / 2 || 7,
      // catAxisTitleFontSize: +widget?.fontSize?.split('px')[0] / 2 || 7,
      // valAxisLabelFontSize: +widget?.fontSize?.split('px')[0] / 2 || 7,
      // valAxisTitleFontSize: +widget?.fontSize?.split('px')[0] / 2 || 7,
      dataLabelFontSize: +widget?.dataLabelFontSize / 2 || 7,
      // serAxisLabelFontSize: +widget?.fontSize?.split('px')[0] / 2 || 7,
      // serAxisTitleFontSize: +widget?.fontSize?.split('px')[0] / 2 || 7,

      legendFontFace: widget?.legendFontFamily || 'Roboto', //'Arial', //'Courier New',
      titleFontFace: widget?.titleFontFamily || 'Roboto',
      // catAxisLabelFontFace: widget?.fontFamily || 'Roboto',
      // catAxisTitleFontFace: widget?.fontFamily || 'Roboto',
      // valAxisLabelFontFace: widget?.fontFamily || 'Roboto',
      // valAxisTitleFontFace: widget?.fontFamily || 'Roboto',
      dataLabelFontFace: widget?.dataLabelFontFamily || 'Roboto',
      // serAxisLabelFontFace: widget?.fontFamily || 'Roboto',
      // serAxisTitleFontFace: widget?.fontFamily || 'Roboto',

      // fontSize: +widget?.fontSize?.split('px')[0] / 2 || 7,
      // color: widget?.fontColor || '#000000',
      // bold: widget?.isFontBold || false,
      // italic: widget?.isFontItalic || false,

      // catAxisLabelFontBold: widget?.isFontBold,
      // valAxisLabelFontBold: widget?.isFontBold,
      dataLabelFontBold: widget?.isDataLabelFontBold,
      // serAxisLabelFontBold: widget?.isFontBold,
      // catAxisLabelFontBold: widget?.isFontBold,

      // catAxisLabelColor: widget?.fontColor,
      // catAxisLineColor: widget?.fontColor,
      // catAxisTitleColor: widget?.fontColor,
      // valAxisLabelColor: widget?.fontColor,
      // valAxisLineColor: widget?.fontColor,
      // valAxisTitleColor: widget?.fontColor,
      dataLabelColor: widget?.dataLabelFontColor,
      // lineDataSymbolLineColor: widget?.fontColor,
      // serAxisLabelColor: widget?.fontColor,
      // serAxisTitleColor: widget?.fontColor,
      legendColor: widget?.legendFontColor,

      //

      showLeaderLines: true,
      showPercent: widget.isShowingDataLabelPercent, //true,
      showValue: widget.isShowingDataLabelValue, //true,
      // dataLabelColor: 'FFFFFF',
      // dataLabelFontSize: 7,
      // dataLabelPosition: 'bestFit', // 'bestFit' | 'outEnd' | 'inEnd' | 'ctr'
      dataLabelPosition: 'inEnd',
      //title
      showTitle: false,
      title: widget.title,
      // titleAlign: 'right',
      titlePos: { x: 55, y: 5 },
      firstSliceAng: 0,
      titleColor: widget.titleColor || '000000',
      // titleFontSize: 8,
      layout: { x: 0.05, y: 0.03, w: 0.56, h: 0.94 },
      // layout: { x: 0.03, y: 0.03, w: 0.56, h: 0.94 },
      // layout: { x: 0.04, y: 0.04, w: 0.56, h: 0.94 }

      // layout: { x: 200, y: 0, w: 0, h: 0 }

      // shadow: {
      //   type: 'outer',
      //   blur: 4,
      //   offset: 3,
      //   angle: 45,
      //   color: '000000',
      //   opacity: 0.4
      // }

      shadow: {
        type: 'outer',
        blur: 6,
        offset: 2,
        angle: 45,
        color: '000000',
        opacity: 0.2
      }

      // shadow: {
      //   type: 'outer',
      //   blur: 6,
      //   offset: 2,
      //   angle: 45,
      //   color: '000000',
      //   opacity: 0.3
      // }
    }
  );

  if (widget.isShowingTitle) {
    // let titleWidth = Math.round(widget?.title?.length * 9 + 10);
    let titleWidth = Math.round(
      widget.title.length * (+widget?.titleFontSize - 4)
    );
    // if (titleWidth < 120) {
    //   titleWidth = 120;
    // }

    // slide.addShape(pres.shapes.ROUNDED_RECTANGLE, {
    //   fill: {
    //     // color: widget.titleFilLColor, //'ababab',
    //     // transparency: 90
    //     color: widget.titleFillColor, // 'ababab',
    //     transparency: (widget.titleFillOpacity - 1) * -100 //90
    //   },
    //   border: { pt: '1', color: 'f1f1f1', transparency: 10 },
    //   w: (titleWidth + 20) / 72 / 2,
    //   h: 25 / 72 / 2,
    //   x:
    //     +widget?.left / 72 / 2 +
    //     widget?.width / 72 / 2 -
    //     (titleWidth + 20) / 72 / 2,
    //   y: widget.top / 72 / 2 + 0 / 72 / 2,

    //   rectRadius: 0.01
    //   // rectRadius: widget.outerBorderRadius / 72 / 2,
    //   // shadow: {
    //   //   type: 'outer',
    //   //   blur: 6,
    //   //   offset: 2,
    //   //   angle: 45,
    //   //   color: 'bcbcbc',
    //   //   opacity: 0.2
    //   // }
    // });

    let borderRadiusLimit =
      widget.width > widget.height ? widget.height : widget.width;

    // console.log('debug46>borderRadiusLimit: ', borderRadiusLimit);

    let borderRadiusPercent = +widget.outerBorderRadius / borderRadiusLimit;
    let outerBorderRadius =
      +widget.outerBorderRadius > borderRadiusLimit / 2
        ? borderRadiusLimit / 2
        : +widget.outerBorderRadius;

    slide.addText(widget.title, {
      w: titleWidth / 72 / 2,
      // h: 20 / 72 / 2,
      h: (+widget?.titleFontSize + 5) / 72 / 2,
      x:
        (+widget?.left +
          widget.width -
          (titleWidth + 20 + outerBorderRadius) -
          borderWidth) /
        72 /
        2,
      y: (widget.top + 13 + borderWidth) / 72 / 2 + 2 / 72 / 2,

      margin: 0,

      fontFace: widget?.titleFontFamily || 'Roboto', // 'Arial',
      // fontSize: ((widget?.titleFontSize || 14) / 96) * 72,
      // fontSize: (widget?.fontSize?.split('px')?.[0] || 14) / 2,
      // color: widget.fontColor || '000000',
      // bold: true,

      fontSize: +widget?.titleFontSize / 2 || 7,
      color: widget?.titleFontColor || '#000000',
      bold: widget?.isTitleFontBold, //true, //widget?.isFontBold || false,
      italic: widget?.isTitleFontItalic || false,
      // underline: true,
      isTextBox: true,
      align: 'right'
      // fill: { color: 'F1F1F1' }
      // highlight: '#bcbcbc'
    });
  }
};

export default pieChartWidget;
