import React, { useContext } from 'react';

import { FilterContext } from '../../../../context/FilterContext';

import SimpleToolTipWithChildren from './SimpleTootipWithChildren';

import { useInView } from 'react-intersection-observer';

import { InterfaceContext } from '../../../../context/InterfaceContext';

import { useNavigate, useSearchParams } from 'react-router-dom';

function PathItem({
  itemKey,
  itemValue,
  rowNumber,
  keyForMap,
  itemStyle,
  checked,
  // setFilterBySelection,
  item
  // filters,
  // setFilters
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { exportMode } = useContext(InterfaceContext);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });

  const { filters, setFilters, setFilterBySelection } =
    useContext(FilterContext);

  const onClickHandler = (obj) => {
    setFilters([
      // ...filters,
      {
        type: 'filter_by_selection',
        category: 'array',
        field: '_id',
        operator: 'EQUALS',
        conjunction: 'OR',
        // array: [String],
        // string: { type: String },
        values: [obj._id],
        isCaseSensitive: true,
        isIncludingAccounts: true,
        isIncludingFolders: true
      }
    ]);
    // setFilterBySelection([obj._id]);
    // setSearchParams({
    //   ...(searchParams.entries() || []),
    //   account_ids: searchParams.get('account_ids'),
    //   manager_account_id: searchParams.get('manager_account_id'),
    //   start_date: searchParams.get('start_date'),
    //   end_date: searchParams.get('end_date'),
    //   document_ids: JSON.stringify([obj._id])
    // });

    // let newSearchParams = {
    //   ...(searchParams.entries() || [])
    // };

    // let searchParamKeys = [
    //   'manager_account_id',
    //   'account_ids',
    //   'start_date',
    //   'end_date'
    // ];
    // for (let key of searchParamKeys) {
    //   if (searchParams.get(key)) {
    //     newSearchParams[key] = searchParams.get(key);
    //   }
    // }
    // if (obj._id) {
    //   newSearchParams.document_ids = JSON.stringify([obj._id]);
    // }
    // setSearchParams(newSearchParams);
    // if (!filters.includes('filter_by_selection')) {
    //   setFilters([...filters, 'filter_by_selection']);
    // }
  };

  return (
    <div
      ref={ref}
      key={keyForMap}
      style={{
        maxHeight: '46px',
        backgroundColor: checked
          ? itemStyle['checkedBackgroundColor']
          : itemStyle['backgroundColor'],
        color: itemStyle['color'],
        // backgroundColor: 'rgba(255, 255, 255, 1)',
        borderRight: itemStyle['border'],
        borderBottom: itemStyle['border'],
        // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
        fontSize: '12px',
        gridRowStart: rowNumber + 2,
        gridRowEnd: rowNumber + 2,
        display: 'flex',
        height: '100%',
        width: '100%',
        minWidth: '200px',
        overflow: 'hidden',
        padding: '12px 5px 12px 15px',
        whiteSpace: 'nowrap'
      }}
    >
      {(inView || exportMode) && (
        <span
          style={{
            width: '100%'
            //   margin: 'auto'
          }}
        >
          <p
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            <SimpleToolTipWithChildren>
              <span>
                <span> / </span>
                {(item?.interactivePath || []).map((obj, index) => {
                  return (
                    <span key={index + '_0'}>
                      <span
                        key={index + '_1'}
                        className="hover-grey"
                        style={{
                          cursor: 'pointer'
                          // textDecoration: 'underline'
                        }}
                        onClick={() => onClickHandler(obj)}
                      >
                        {obj.name}
                      </span>
                      <span key={index + '_2'}> / </span>
                    </span>
                  );
                })}
              </span>
            </SimpleToolTipWithChildren>
          </p>
          <p
            style={{
              fontSize: '8px',
              color: '#bcbcbc',
              visibility: 'hidden'
            }}
          >
            {' '}
            test
          </p>
        </span>
      )}
    </div>
  );
}

export default React.memo(PathItem);
