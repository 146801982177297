import React, { useEffect, useContext } from 'react';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { alpha, styled, useTheme } from '@mui/material/styles';

import { useSnackbar } from 'notistack';
import { InterfaceContext } from '../../context/InterfaceContext';
import { MenuItem } from '@mui/material';

export default function SwitchesSize({ colorMode }) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const PurpleSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#a72683',
      '&:hover': {
        backgroundColor: alpha('#a72683', theme.palette.action.hoverOpacity)
      }
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#a72683'
    }
  }));

  const { isDashboardUserMode, setIsDashboardUserMode } =
    useContext(InterfaceContext);

  const [checked, setChecked] = React.useState(false);

  const toggleChecked = () => {
    setIsDashboardUserMode(!isDashboardUserMode);
  };

  const label = {
    inputProps: {
      'aria-label': 'Switch demo',
      fontSize: '12px',
      color: 'black'
    }
  };

  return (
    <MenuItem onClick={toggleChecked}>
      <PurpleSwitch
        size="small"
        checked={isDashboardUserMode}
        // color="blue"
        {...label}
      />
      <span
        style={{
          fontSize: '12px',
          color: theme.palette.mode === 'dark' ? 'white' : 'black'
        }}
      >
        Dashboard User Mode mode
      </span>
      {/* <FormGroup>
        <FormControlLabel
          control={
            <PurpleSwitch
              size="small"
              checked={isAdvancedModeOn}
              onChange={toggleChecked}
              // color="blue"
              {...label}
            />
          }
          label="Advanced Mode"
          labelPlacement="start"
        />
      </FormGroup> */}
    </MenuItem>
  );
}
