import React, { useEffect, useState, useContext } from 'react';
import Button from '@mui/material/Button';
import { ClickAwayListener } from '@mui/base';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { makeStyles } from '@mui/styles';

import { addDocumentMutation } from '../../../graphql/queries';

import { useMutation } from '@apollo/client';

import { InterfaceContext } from '../../../context/InterfaceContext';
import RightClickColumnMenuPercent from './content-component-types/RightClickColumnMenuPercent';
import RightClickColumnMenuSparkline from './content-component-types/RightClickColumnMenuSparkline';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex'
//   },
//   paper: {
//     marginRight: theme.spacing(2)
//   }
// }));

export default function RightClickMenuPopper({
  children,
  // rightClickMenuAnchorRef,
  // setRightClickMenuAnchorRef,
  // typeItemOpen,
  // setTypeItemOpen,
  // // open,
  // // setOpen,
  // rightClickMenuToggledItem,
  // setRightClickMenuToggledItem,
  currentManagerAccountId
  // typeItemTemplateMenuOpen,
  // setTypeItemTemplateMenuOpen
}) {
  const {
    // rightClickMenuAnchorRef,
    // setRightClickMenuAnchorRef,
    // typeItemOpen: open,
    // setTypeItemOpen: setOpen,
    // rightClickMenuToggledItem,
    // setRightClickMenuToggledItem,
    // typeItemTemplateMenuOpen,
    // setTypeItemTemplateMenuOpen

    rightClickMenuAnchorRef,
    setRightClickMenuAnchorRef,
    rightClickMenuOpen: open,
    setRightClickMenuOpen: setOpen,
    rightClickMenuToggledItem,
    setRightClickMenuToggledItem,
    rightClickMenuContentComponentType,
    rightClickMenuToggledItemKey
  } = useContext(InterfaceContext);

  // useEffect(() => {
  //   console.log('rightClickMenuAnchorRef2: ', rightClickMenuAnchorRef);
  // }, [rightClickMenuAnchorRef]);

  // console.log(
  //   '  rightClickMenuContentComponentType: ',
  //   rightClickMenuContentComponentType
  // );
  // console.log(
  //   'RightClickMenuPopper>rightClickMenuAnchorRef: ',
  //   rightClickMenuAnchorRef
  // );
  // const classes = useStyles();
  //   const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  // const [addDocument] = useMutation(addDocumentMutation);

  // //   const [rightClickMenuAnchorRef, setRightClickMenuAnchorRef] = useState(null);

  // //   useEffect(() => {
  // //     setRightClickMenuAnchorRef(anchorRef);
  // //   }, [anchorRef]);

  // // console.log('rightClickMenuAnchorRef: ', rightClickMenuAnchorRef);

  const handleToggle = () => {
    console.log('toggle');
    // setOpen((prevOpen) => !prevOpen);
    setOpen(true);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setRightClickMenuToggledItem(null);
    setRightClickMenuAnchorRef(null);

    setOpen(false);
  };

  // let rightClickMenuContentComponentType = 'column_menu';

  let contentComponent = null;

  switch (true) {
    case rightClickMenuContentComponentType === 'column_menu' &&
      [
        'action01Roas',
        'action02Roas',
        'action03Roas',
        'action01Crr',
        'action02Crr',
        'action03Crr'
      ].includes(rightClickMenuToggledItemKey):
      // contentComponent = (
      //   <div
      //     style={{ background: 'green', width: '200px', height: '400px' }}
      //   ></div>
      // );
      contentComponent = <RightClickColumnMenuPercent />;
      break;
    case rightClickMenuContentComponentType === 'column_menu' &&
      rightClickMenuToggledItemKey.includes('Sparkline'):
      // contentComponent = (
      //   <div
      //     style={{ background: 'green', width: '200px', height: '400px' }}
      //   ></div>
      // );
      contentComponent = <RightClickColumnMenuSparkline />;
      break;

    default:
      setOpen(false);
  }

  // function handleListKeyDown(event) {
  //   if (event.key === 'Tab') {
  //     event.preventDefault();
  //     setOpen(false);
  //   }
  // }

  // // return focus to the button when we transitioned from !open -> open
  // const prevOpen = React.useRef(open);
  // React.useEffect(() => {
  //   if (prevOpen.current === true && open === false) {
  //     anchorRef.current.focus();
  //   }

  //   prevOpen.current = open;
  // }, [open]);

  // //   return children;

  // const handleClickMenuItem = (e, value, documentType) => {
  //   switch (value) {
  //     case 'new_folder':
  //       // let addDocumentInput = {
  //       //   action: 'CREATE_DOCUMENT',
  //       //   managerAccountId: currentManagerAccountId,
  //       //   // documentType: 'container',
  //       //   documentType: type,
  //       //   newDocumentAccountAccessLevel: 1,
  //       //   checkedDocumentId: rightClickMenuToggledItem._id
  //       //   // document: {}
  //       // };

  //       let name = 'new document';
  //       switch (documentType) {
  //         case 'folder':
  //           name = 'New Folder';
  //           break;
  //         case 'manualDataEntry':
  //           name = 'New Manual Input';
  //           break;
  //         case 'customForm':
  //           name = 'Custom Form';
  //           break;
  //         case 'task':
  //           name = 'New task';
  //           break;
  //         case 'subTask':
  //           name = 'New subtask';
  //           break;
  //         case 'taxonomy':
  //           name = 'New taxonomy';
  //           break;
  //       }

  //       // addDocumentInput = {
  //       //   ...addDocumentInput,
  //       //   level: rightClickMenuToggledItem.level,
  //       //   accountId: rightClickMenuToggledItem.accountId,
  //       //   document: {
  //       //     name,
  //       //     type
  //       //   }
  //       // };

  //       let addDocumentInput = {
  //         managerAccountId: currentManagerAccountId,
  //         accountId: rightClickMenuToggledItem.accountId,
  //         checkedDocumentId: rightClickMenuToggledItem._id,
  //         level: rightClickMenuToggledItem.level,
  //         newDocuments: [{ documentType, name }]
  //       };

  //       addDocument({ variables: { input: { ...addDocumentInput } } });

  //       break;
  //     case 'new_template':
  //       setTypeItemTemplateMenuOpen(true);
  //       break;
  //   }
  // };
  // console.log('RightClickMenuPopper>open: ', open);

  return (
    <div
    // className={classes.root}
    >
      <div ref={anchorRef} onClick={handleToggle}>
        <Popper
          style={{ zIndex: '5000' }}
          open={open}
          anchorEl={rightClickMenuAnchorRef?.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper style={{ background: 'none' }}>
                <ClickAwayListener onClickAway={handleClose}>
                  {/* {contentComponent} */}
                  <div>{contentComponent}</div>
                  {/* <div
                    style={{
                      width: '200px',
                      height: '200px',
                      background: 'red'
                    }}
                  ></div> */}
                  {/* <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      onClick={(e) =>
                        handleClickMenuItem(e, 'new_folder', 'folder')
                      }
                    >
                      New Folder
                    </MenuItem>
                    {rightClickMenuToggledItem?.type === 'folder' && (
                      <MenuItem
                        onClick={(e) =>
                          handleClickMenuItem(e, 'new_template', 'template')
                        }
                      >
                        New Template
                      </MenuItem>
                    )}
          
                  </MenuList> */}
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
