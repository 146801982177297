import React, { useState, useContext, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import UploadFileIcon from '@mui/icons-material/UploadFile';

import DragDropFile from './DragAndDropInput';

import { DialogContext } from '../../../../../context/DialogContext';

import { AccountContext } from '../../../../../context/AccountContext';

import { SnackbarProvider, useSnackbar } from 'notistack';

import SpinnerSmall from '../../../../layout/SpinnerSmall';

import ReportAsset from './report-asset/ReportAsset';

import SelectAccountMenu from '../../utils/SelectAccountMenu';

import Checkbox from '@mui/material/Checkbox';

import { useMutation } from '@apollo/client';

import axios from 'axios';

import {
  uploadReportAssetsMutation,
  generatePresignedUrlsForUploadMutation
} from './../../../../../graphql/queries';

import { createThumbnail } from './../../report-builder-list/createThumbnail';

import { ReportBuilderContext } from '../../../../../context/ReportBuilderContext';
import reportAsset from '../../../../../../../resolver-functions/query/reportAsset';

import { v4 as uuidv4 } from 'uuid';

import Resizer from 'react-image-file-resizer';

import ProgressSpinner from './ProgressSpinner';

const resizeFile = (
  file, // Is the file of the image which will resized.
  maxWidth, // Is the maxWidth of the resized new image.
  maxHeight, // Is the maxHeight of the resized new image.
  compressFormat, // Is the compressFormat of the resized new image.
  quality, // Is the quality of the resized new image.
  rotation, // Is the degree of clockwise rotation to apply to uploaded image.
  responseUriFunc, // Is the callBack function of the resized new image URI.
  outputType, // Is the output type of the resized new image.
  minWidth, // Is the minWidth of the resized new image.
  minHeight // Is the minHeight of the resized new image.
) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file, // Is the file of the image which will resized.
      maxWidth, // Is the maxWidth of the resized new image.
      maxHeight, // Is the maxHeight of the resized new image.
      compressFormat, // Is the compressFormat of the resized new image.
      quality, // Is the quality of the resized new image.
      rotation, // Is the degree of clockwise rotation to apply to uploaded image.
      (uri) => {
        resolve(uri);
      }, // Is the callBack function of the resized new image URI.
      outputType, // Is the output type of the resized new image.
      minWidth, // Is the minWidth of the resized new image.
      minHeight // Is the minHeight of the resized new image.
    );
  });

// import { MutationContext } from '../../../context/MutationContext';

// import {
//   useQuery,
//   useLazyQuery,
//   useMutation,
//   useSubscription
// } from '@apollo/client';
// import { addDocumentMutation } from './../../../graphql/queries';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableDialog() {
  const { enqueueSnackbar } = useSnackbar();

  const { refetchAssets, setRefetchAssets } = useContext(ReportBuilderContext);
  const { currentManagerAccountId, currentManagerAccount } =
    useContext(AccountContext);
  const {
    uploadReportAssetsDialogOpen: open,
    setUploadReportAssetsDialogOpen: setOpen
  } = useContext(DialogContext);

  const [isAllowingManagerAccountAccess, setIsAllowingManagerAccountAccess] =
    useState(false);

  const [isAllowingAccountAccess, setIsAllowingAccountAccess] = useState(false);
  const [selectedAccountIds, setSelectedAccountIds] = useState([]);

  const [isExpanded, setIsExpanded] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [fileUploaded, toggleFileUploaded] = useState(false);

  const [reportAssets, setReportAssets] = useState([]);

  const [generatePresignedUrlsForUpload, { called }] = useMutation(
    generatePresignedUrlsForUploadMutation
  );
  // console.log('excelData1: ', excelData);

  const handleClickOpen = () => {
    setOpen(true);
  };

  console.log('selectedAccountIds: ', selectedAccountIds);

  const [uploadReportAssets, { called_ }] = useMutation(
    uploadReportAssetsMutation
  );

  const handleClose = () => {
    // setNewNexusDocuments([]);
    // setExcelData({});
    setReportAssets([]);
    setIsLoading(false);
    toggleFileUploaded(false);
    setOpen(false);
  };

  const handleUpload = async () => {
    // setPreviousZoom(zoom);

    // await setZoom(1);

    if (reportAssets.length > 5) {
      enqueueSnackbar('Max 5 uploads at the time', {
        variant: 'error'
      });

      return;
    }
    if (
      !isAllowingManagerAccountAccess &&
      (!isAllowingAccountAccess || !selectedAccountIds?.length)
    ) {
      enqueueSnackbar('Please choose access', {
        variant: 'error'
      });

      return;
    }

    setIsLoading(true);

    const newReportAssets = [];
    for (let reportAsset of reportAssets) {
      // 1. get signed uploadurl

      // if (reportAsset.name) {
      //   enqueueSnackbar('Please fill in a name', {
      //     variant: 'error'
      //   });
      //   return;
      // }

      // delete copyOfReportPage.thumbnailImageDataUri;
      // delete copyOfReportPage.previewImageDataUri;

      const file = reportAsset.file;
      let compressFormat = 'PNG';
      console.log('file.type: ', file.type);

      switch (true) {
        case file.type === 'image/png':
          compressFormat = 'PNG';
          break;
        case file.type === 'image/jpeg':
          compressFormat = 'JPEG';
          break;
        default:
          enqueueSnackbar('Unsupported file format', {
            variant: 'error'
          });
      }

      const previewImageOptions = {
        previewImageXSmallFileId: {
          scale: 0.15
        },
        previewImageSmallFileId: {
          scale: 0.25
        },
        previewImageMediumFileId: {
          scale: 0.5
        },
        previewImageLargeFileId: { scale: 0.75 },
        imageFileId: {
          scale: 1
        }
      };
      const copyOfReportAsset = {
        ...reportAsset,
        id: uuidv4(),
        status: 'ACTIVE'
      };

      let fileToUpload = undefined;

      fileToUpload = reportAsset.file;

      let input1 = {
        managerAccountId: currentManagerAccount._id,
        isAllowingManagerAccountAccess,
        accountIds: isAllowingAccountAccess ? selectedAccountIds : [],
        type: 'report-assets',
        category: 'report-builder',
        subCategory: 'report-assets',
        assetUUID: copyOfReportAsset.id,
        files: [
          {
            id: uuidv4(),
            name: copyOfReportAsset.name,
            fileName: copyOfReportAsset.name,

            imageNaturalHeight: copyOfReportAsset.imageNaturalHeight,
            imageNaturalWidth: copyOfReportAsset.imageNaturalWidth,

            // imageNaturalAspectRatio:
            //   copyOfReportAsset.width / copyOfReportAsset.height,
            type: file.type //'image/png'
          }
        ]
      };

      let res1 = undefined;
      try {
        res1 = await generatePresignedUrlsForUpload({
          variables: { input: input1 }
        });
      } catch (error) {
        enqueueSnackbar('Failed', {
          variant: 'error'
        });
        return;
      }

      const files = res1?.data?.generatePresignedUrlsForUpload?.files;
      const uploadUrl = files[0].url;
      const fileId = files[0]._id;
      const fileUrl = files[0].url;
      // console.log('files: ', files);
      // 2. get thumbnail preview

      // console.log('previewImageFile: ', previewImageFile);
      // 4. upload file

      console.log('fileUrl: ', fileUrl);

      let data = fileToUpload; //'<file contents here>';

      let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: fileUrl, // 'https://nexustable-s3-bucket-0cc8f0ed-c179-43f3-8ac3-3e4a24aafc14.s3.eu-north-1.amazonaws.com/d38f28b0-2f6a-43ec-9dc2-cfed21d6ba56?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA2O4WVCUGWSHXD74S%2F20241116%2Feu-north-1%2Fs3%2Faws4_request&X-Amz-Date=20241116T201748Z&X-Amz-Expires=3600&X-Amz-Signature=8b933efc14f61767659955d75f259ceef3fd3e0b96009168873bc0ca860f55ff&X-Amz-SignedHeaders=host&x-id=PutObject',
        headers: {
          'Content-Type': 'image/png'
        },
        data: data
      };

      console.log('config: ', config);
      try {
        const result = await axios.request(config);
        console.log('result:', result);
      } catch (error) {
        console.log('error: ', error);
        enqueueSnackbar('Failed', {
          variant: 'error'
        });

        return;
      }

      // console.log('fileId: ', fileId);
      // 5. add preview asset id to report page

      copyOfReportAsset['imageFileId'] = fileId;

      delete copyOfReportAsset.imageDataUri;
      delete copyOfReportAsset.lastModified;
      delete copyOfReportAsset.thumbnailImageDataUri;
      delete copyOfReportAsset.previewImageDataUri;
      delete copyOfReportAsset.lastModifiedDate;
      delete copyOfReportAsset.fileName;
      delete copyOfReportAsset.size;
      delete copyOfReportAsset.file;

      newReportAssets.push(copyOfReportAsset);
    }
    console.log('newReportAssets: ', newReportAssets);

    // reportAssets.map((obj) => {
    //   const copyOfObj = { ...obj };
    //   delete copyOfObj.imageDataUri;
    //   delete copyOfObj.thumbnailImageDataUri;
    //   delete copyOfObj.file;

    //   return copyOfObj;
    // })

    // copyOfReportPage.name = name;
    // delete copyOfReportPage.isThumbnailOutOfDate;
    // delete copyOfReportPage._id;
    // delete copyOfReportPage.previewImageUrl;

    const input = {
      // managerAccountId: currentManagerAccount._id,
      // name,
      managerAccountId: currentManagerAccount._id,
      isAllowingManagerAccountAccess,
      accountIds: isAllowingAccountAccess ? selectedAccountIds : [],
      reportAssets: newReportAssets
      // action: String,
      // updateKeys: [String],
    };

    // console.log('SaveTemplatePopper>input: ', input);

    const res = await uploadReportAssets({
      variables: { input }
    });
    console.log('uploadReportAssets>res:', res);
    if (res?.data?.uploadReportAssets?.response === 'success') {
      enqueueSnackbar('Report Asset Uploaded', {
        variant: 'success'
      });
      setReportAssets([]);
      setIsAllowingManagerAccountAccess(false);
      setIsAllowingAccountAccess(false);
      setRefetchAssets(refetchAssets + 1);
      handleClose();
      // setName('');
      // setAnchorEl(null);
    }
    // setZoom(previousZoom || zoom);

    setIsLoading(false);
  };

  // const handleUpload = async () => {
  //   if (
  //     !isAllowingManagerAccountAccess &&
  //     (!isAllowingAccountAccess || !selectedAccountIds?.length)
  //   ) {
  //     enqueueSnackbar('Please choose access', {
  //       variant: 'error'
  //     });
  //     return;
  //   }

  //   setIsLoading(true);

  //   let input = {
  //     managerAccountId: currentManagerAccountId,
  //     isAllowingManagerAccountAccess,
  //     accountIds: isAllowingAccountAccess ? selectedAccountIds : [],
  //     category: 'reportAssets',
  //     files: reportAssets.map((obj) => {
  //       const copyOfObj = { ...obj };
  //       delete copyOfObj.imageDataUri;
  //       delete copyOfObj.thumbnailImageDataUri;
  //       delete copyOfObj.file;

  //       return copyOfObj;
  //     })
  //   };

  //   const res = await generatePresignedUrlsForUpload({
  //     variables: { input }
  //   });

  //   if (res?.data?.generatePresignedUrlsForUpload?.response === 'success') {
  //     const files = res?.data?.generatePresignedUrlsForUpload.files;

  //     for (let file of files) {
  //       const assetFile = reportAssets.filter(
  //         (reportAsset) => reportAsset.id === file.id
  //       )[0];

  //       let data = assetFile.file; //'<file contents here>';
  //       let config = {
  //         method: 'put',
  //         maxBodyLength: Infinity,
  //         url: file.url, // 'https://nexustable-s3-bucket-0cc8f0ed-c179-43f3-8ac3-3e4a24aafc14.s3.eu-north-1.amazonaws.com/d38f28b0-2f6a-43ec-9dc2-cfed21d6ba56?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIA2O4WVCUGWSHXD74S%2F20241116%2Feu-north-1%2Fs3%2Faws4_request&X-Amz-Date=20241116T201748Z&X-Amz-Expires=3600&X-Amz-Signature=8b933efc14f61767659955d75f259ceef3fd3e0b96009168873bc0ca860f55ff&X-Amz-SignedHeaders=host&x-id=PutObject',
  //         headers: {
  //           'Content-Type': 'image/png'
  //         },
  //         data: data
  //       };

  //       try {
  //         const result = await axios.request(config);
  //         if (result?.status === 200) {
  //           enqueueSnackbar('Assets uploaded', {
  //             variant: 'success'
  //           });
  //           setRefetchAssets(refetchAssets + 1);
  //         }
  //       } catch (error) {
  //         enqueueSnackbar('Failed', {
  //           variant: 'error'
  //         });
  //       }

  //       // const result = await fetch(file.url, {
  //       //   method: 'PUT',
  //       //   body: reportAsset.file,
  //       //   headers: {
  //       //     'Content-Type': 'image/jpeg'
  //       //   }
  //       // });
  //       // console.log('result: ', result);
  //     }
  //     // enqueueSnackbar('Assets uploaded', {
  //     //   variant: 'success'
  //     // });

  //     // setRefetchAssets(refetchAssets + 1);
  //     // setAnchorEl(null);
  //   }
  //   setIsLoading(false);
  //   handleClose();
  // };

  const headerText = 'Upload image assets';
  // console.log('reportAssets1: ', reportAssets);
  const [rerender, setRerender] = useState(0);

  let reportAssets_ = [];
  reportAssets_ = (reportAssets || [])?.map((reportAsset, index) => (
    <ReportAsset
      reportAsset={reportAsset}
      reportAssets={reportAssets}
      setReportAssets={setReportAssets}
      reportAssetIndex={index}
    />
  ));

  // useEffect(() => {
  //   reportAssets_ = (reportAssets || [])?.map((reportAsset) => (
  //     <ReportAsset reportAsset={reportAsset} />
  //   ));
  //   // const runScript = async () => {
  //   //   if (reportAssets?.length) {
  //   //     reportAssets_ = (reportAssets || [])?.map((reportAsset) => (
  //   //       <ReportAsset reportAsset={reportAsset} />
  //   //     ));
  //   //     console.log('reportAssets111: ', reportAssets);
  //   //   } else {
  //   //     await setTimeout(() => {
  //   //       console.log('Delayed for 1 second.');
  //   //     }, '2000');
  //   //     setRerender(rerender + 1);
  //   //   }
  //   // };

  //   const runScript = async () => {
  //     if (!reportAssets[0]?.type) {
  //       await setTimeout(() => {
  //         console.log('Delayed for 1 second.');
  //       }, '2000');
  //       setRerender(rerender + 1);
  //     }
  //   };

  //   runScript();
  // }, [reportAssets, reportAssets?.[0]?.type, rerender]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullScreen={isExpanded}
        // paperFullWidth={true}
        // paper={{ height: '90hw', width: '1000px' }}
        // maxWidth="lg"
        maxWidth="80%"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div
            style={{
              // display: 'grid',
              // gridTemplateColumns: 'auto 40px 40px 30px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <div
              style={{
                display: 'grid',
                gridColumnStart: 1,
                gridColumnEnd: 1
              }}
            >
              <span style={{ paddingLeft: '7px' }}>
                <UploadFileIcon
                  style={{
                    verticalAlign: 'text-bottom',
                    paddingRight: '5px'
                  }}
                />{' '}
                {headerText}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '65px'
              }}
            >
              <div
                style={{
                  display: 'grid',
                  gridColumnStart: 2,
                  gridColumnEnd: 2,
                  alignItems: 'end'
                }}
              >
                <div
                  style={{
                    cursor: 'pointer',
                    right: '0px',
                    textAlign: 'right'
                    // paddingRight: '25px'
                  }}
                >
                  <span
                    style={{ width: '50px' }}
                    onClick={() => setIsExpanded(!isExpanded)}
                  >
                    {isExpanded ? (
                      <FullscreenExitIcon className="hover-light-grey" />
                    ) : (
                      <FullscreenIcon className="hover-light-grey" />
                    )}
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: 'grid',
                  gridColumnStart: 3,
                  gridColumnEnd: 3,
                  alignItems: 'end'
                }}
              >
                <div
                  style={{
                    cursor: 'pointer',
                    right: '0px',
                    textAlign: 'right'
                    // paddingRight: '25px'
                  }}
                >
                  <span style={{ width: '50px' }} onClick={handleClose}>
                    <CloseIcon className="hover-light-grey" />
                  </span>
                </div>
              </div>
            </div>
            {/* <div
              style={{
                display: 'grid',
                gridColumnStart: 1,
                gridColumnEnd: 1,
                gridRowStart: 2,
                gridRowEnd: 2
              }}
            >
              <span style={{ paddingLeft: '50px', fontSize: '12px' }}>
                {item?.path}
              </span>
            </div> */}
          </div>
        </DialogTitle>
        <DialogContent>
          {fileUploaded ? (
            isLoading ? (
              <div
                style={{
                  width: '400px',
                  height: '400px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <ProgressSpinner seconds={(reportAssets?.length || 0) * 5} />
                {/* <SpinnerSmall /> */}
              </div>
            ) : (
              // <DataEditor
              //   excelData={excelData}
              //   setExcelData={setExcelData}
              //   newNexusDocuments={newNexusDocuments}
              //   setNewNexusDocuments={setNewNexusDocuments}
              // />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start'
                }}
              >
                <div style={{ display: 'relative', width: '500px' }}>
                  {reportAssets_}
                </div>
                <div style={{ width: '400px' }}>
                  <div>Access:</div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'start',
                      alignItems: 'center'
                    }}
                  >
                    <div>
                      <Checkbox
                        size="small"
                        checked={isAllowingManagerAccountAccess}
                        onChange={() =>
                          setIsAllowingManagerAccountAccess(
                            !isAllowingManagerAccountAccess
                          )
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </div>
                    <div
                      onClick={() =>
                        setIsAllowingManagerAccountAccess(
                          !isAllowingManagerAccountAccess
                        )
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      Current Manager Account
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'start',
                      alignItems: 'center'
                    }}
                  >
                    <div>
                      <Checkbox
                        size="small"
                        checked={isAllowingAccountAccess}
                        onChange={() =>
                          setIsAllowingAccountAccess(!isAllowingAccountAccess)
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </div>
                    <div>
                      <SelectAccountMenu
                        isDisabled={!isAllowingAccountAccess}
                        selectedAccountIds={selectedAccountIds}
                        setSelectedAccountIds={setSelectedAccountIds}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          ) : (
            <DragDropFile
              reportAssets={reportAssets}
              setReportAssets={setReportAssets}
              toggleFileUploaded={toggleFileUploaded}
            />
          )}

          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          {!isLoading && (
            <>
              <Button autoFocus onClick={handleClose}>
                Cancel
              </Button>
              <Button onClick={handleUpload}>Upload</Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
