import React, { useEffect, useState } from 'react';

export const FilterContext = React.createContext();

export const FilterProvider = (props) => {
  const [statusFilter, setStatusFilter] = useState(
    JSON.parse(localStorage.getItem('statusFilter')) || 'All but removed'
  );

  let params = new URL(document.location).searchParams;
  let ndids = params.get('document_ids'); // is the string "Jonathan Smith".

  const [filterBySelection, setFilterBySelection] = useState(
    // []
    ndids && decodeURIComponent(ndids).split(',')?.length
      ? decodeURIComponent(ndids).split(',')
      : JSON.parse(localStorage.getItem('filterBySelection')) || []
  );

  const [filters, setFilters] = useState(
    JSON.parse(localStorage.getItem('tempDataFilters3')) || []
  );

  useEffect(() => {
    // console.log(
    //   "decodeURIComponent(ndids).split(','): ",
    //   decodeURIComponent(ndids).split(',')
    // );
    if (ndids && decodeURIComponent(ndids).split(',')?.length) {
      setFilters([
        // ...filters,
        {
          type: 'filter_by_selection',
          category: 'array',
          field: '_id',
          operator: 'EQUALS',
          conjunction: 'OR',
          // array: [String],
          // string: { type: String },
          values: decodeURIComponent(ndids).split(','),
          isCaseSensitive: true,
          isIncludingAccounts: true,
          isIncludingFolders: true
        }
      ]);
    }
  }, []);

  const [filterByMemberIds, setFilterByMemberIds] = useState(
    JSON.parse(localStorage.getItem('filterByMemberIds')) || []
  );

  const [filterByLabelId, setFilterByLabelId] = useState(
    JSON.parse(localStorage.getItem('filterByLabelId')) || ''
  );

  const [filterByLabelName, setFilterByLabelName] = useState(
    JSON.parse(localStorage.getItem('filterByLabelName')) || ''
  );

  const [filterByString, setFilterByString] = useState('');

  const [filterByName, setFilterByName] = useState(
    JSON.parse(localStorage.getItem('filterByName')) || ''
  );
  const [filterByDataSourceName, setFilterByDataSourceName] = useState(
    JSON.parse(localStorage.getItem('filterByDataSourceName')) || ''
  );

  const [filterByDataSource, setFilterByDataSource] = useState(
    JSON.parse(localStorage.getItem('filterByDataSource')) || []
  );

  const [filterByStatus, setFilterByStatus] = useState(
    JSON.parse(localStorage.getItem('filterByStatus')) || []
  );

  const [filterByStatusExclusionArray, setFilterByStatusExclusionArray] =
    useState(
      JSON.parse(localStorage.getItem('filterByStatusExclusionArray')) || []
    );

  const [filterByInvoiceStatus, setFilterByInvoiceStatus] = useState(
    JSON.parse(localStorage.getItem('filterByInvoiceStatus')) || []
  );

  const [filterByInvoiceOrderId, setFilterByInvoiceOrderId] = useState(
    JSON.parse(localStorage.getItem('filterByInvoiceOrderId')) || ''
  );
  // const [viewDialogOpen, setViewDialogOpen] = useState(false);

  const [checkedBoxes, setCheckedBoxes] = useState([]);

  // const [checkedBoxesIds, setCheckedBoxesIds] = useState([]);

  const updateStatusFilter = (value) => {
    setStatusFilter(value);
    localStorage.setItem('statusFilter', JSON.stringify(value));
  };

  const updateFilters = (value) => {
    setFilters(value);
    localStorage.setItem('tempDataFilters3', JSON.stringify(value));
  };

  const updateFilterBySelection = (value) => {
    setFilterBySelection(value);
    // const ids = value.map((obj) => obj._id);
    localStorage.setItem('filterBySelection', JSON.stringify(value));
  };

  const updateFilterByMemberIds = (value) => {
    setFilterByMemberIds(value);
    localStorage.setItem('filterByMemberIds', JSON.stringify(value));
  };

  const updateFilterByLabelId = (value) => {
    setFilterByLabelId(value);
    localStorage.setItem('filterByLabelId', JSON.stringify(value));
  };

  const updateFilterByLabelName = (value) => {
    setFilterByLabelName(value);
    localStorage.setItem('filterByLabelName', JSON.stringify(value));
  };

  const updateFilterByName = (value) => {
    setFilterByName(value);
    localStorage.setItem('filterByName', JSON.stringify(value));
  };

  const updateFilterByDataSourceName = (value) => {
    setFilterByDataSourceName(value);
    localStorage.setItem('filterByDataSourceName', JSON.stringify(value));
  };

  const updateFilterByDataSource = (value) => {
    setFilterByDataSource(value);
    localStorage.setItem('filterByDataSource', JSON.stringify(value));
  };

  const updateFilterByStatus = (value) => {
    setFilterByStatus(value);
    localStorage.setItem('filterByStatus', JSON.stringify(value));
  };

  const updateFilterByStatusExclusionArray = (value) => {
    setFilterByStatusExclusionArray(value);
    localStorage.setItem('filterByStatusExclusionArray', JSON.stringify(value));
  };

  const updateFilterByInvoiceStatus = (value) => {
    setFilterByInvoiceStatus(value);
    localStorage.setItem('filterByInvoiceStatus', JSON.stringify(value));
  };

  const updateFilterByInvoiceOrderId = (value) => {
    setFilterByInvoiceOrderId(value);
    localStorage.setItem('filterByInvoiceOrderId', JSON.stringify(value));
  };

  // useEffect(() => {
  //   // if (JSON.parse(ndids)?.length && !filters.includes('filter_by_selection')) {
  //   //   setFilters([...filters, 'filter_by_selection']);
  //   // }
  //   if (filterBySelection?.length && !filters.includes('filter_by_selection')) {
  //     setFilters([...filters, 'filter_by_selection']);
  //   }
  // }, [filterBySelection]);

  // const updateFilterByString = (value) => {
  //   setFilterByString(value);
  //   localStorage.setItem('filterByString', JSON.stringify(value));
  // };

  // useEffect(() => {
  //   setFilterBySelection(JSON.parse(localStorage.getItem('filterBySelection')));
  //   setFilters(JSON.parse(localStorage.getItem('filters')));
  // }, []);

  // ############################# test ###################################

  // ######################## end of test #################################

  return (
    <FilterContext.Provider
      value={{
        filters,
        setFilters: updateFilters,
        statusFilter,
        setStatusFilter: updateStatusFilter,
        filterBySelection,
        setFilterBySelection: updateFilterBySelection,
        filterByMemberIds,
        setFilterByMemberIds: updateFilterByMemberIds,
        filterByLabelId,
        setFilterByLabelId: updateFilterByLabelId,
        filterByLabelName,
        setFilterByLabelName: updateFilterByLabelName,
        filterByString,
        setFilterByString, //: updateFilterByString,
        filterByName,
        setFilterByName: updateFilterByName,
        filterByDataSourceName,
        setFilterByDataSourceName: updateFilterByDataSourceName,
        filterByDataSource,
        setFilterByDataSource: updateFilterByDataSource,
        filterByStatus,
        setFilterByStatus: updateFilterByStatus,
        filterByInvoiceStatus,
        setFilterByInvoiceStatus: updateFilterByInvoiceStatus,
        filterByInvoiceOrderId,
        setFilterByInvoiceOrderId: updateFilterByInvoiceOrderId,
        filterByStatusExclusionArray,
        setFilterByStatusExclusionArray: updateFilterByStatusExclusionArray,
        // viewDialogOpen,
        // setViewDialogOpen,
        // setCheckedBoxesIds,
        // checkedBoxesIds,
        checkedBoxes,
        setCheckedBoxes
      }}
    >
      {props.children}
    </FilterContext.Provider>
  );
};
