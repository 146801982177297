import html2canvas from 'html2canvas';

import formatImage from '../formatImage';

// import { jsPDF } from 'jspdf';

function gray(imgObj) {
  var canvas = document.createElement('canvas');
  var canvasContext = canvas.getContext('2d');

  var imgW = imgObj.width;
  var imgH = imgObj.height;
  canvas.width = imgW;
  canvas.height = imgH;

  canvasContext.drawImage(imgObj, 0, 0);
  var imgPixels = canvasContext.getImageData(0, 0, imgW, imgH);

  for (var y = 0; y < imgPixels.height; y++) {
    for (var x = 0; x < imgPixels.width; x++) {
      var i = y * 4 * imgPixels.width + x * 4;
      var avg =
        (imgPixels.data[i] + imgPixels.data[i + 1] + imgPixels.data[i + 2]) / 3;
      imgPixels.data[i] = avg;
      imgPixels.data[i + 1] = avg;
      imgPixels.data[i + 2] = avg;
    }
  }

  canvasContext.putImageData(
    imgPixels,
    0,
    0,
    0,
    0,
    imgPixels.width,
    imgPixels.height
  );
  return canvas.toDataURL();
}

const imageWidget = async (widget, pres, slide, chartFillColors) => {
  try {
    const borderWidth = +widget?.outerBorderWidth || 0;
    // add shape

    // if (borderWidth > 0) {
    //   slide.addShape(
    //     +widget?.outerBorderRadius === 0
    //       ? pres.shapes.RECTANGLE
    //       : pres.shapes.ROUNDED_RECTANGLE,
    //     {
    //       fill: {
    //         // color: 'FFFFFF',
    //         // transparency: (widget.opacity - 1) * -100 //5
    //         color: widget.cardFillColor || 'FFFFFF',
    //         transparency: (widget.cardFillOpacity - 1) * -100 //5
    //       },
    //       line: +widget?.outerBorderWidth !== 0 && {
    //         width: +widget?.outerBorderWidth / 2,
    //         color: widget.outerBorderColor.split('#')[1], //'f1f1f1',
    //         transparency: (widget.outerBorderOpacity - 1) * -100 //5
    //         // transparency: 10
    //       },
    //       // border: { pt: '1', color: 'f1f1f1', transparency: 10 },
    //       w: (widget.width - borderWidth) / 72 / 2,
    //       h: (widget.height - borderWidth) / 72 / 2,
    //       x: (+widget?.left + borderWidth / 2) / 72 / 2,
    //       y: (widget.top + borderWidth / 2) / 72 / 2,

    //       // rectRadius: 0.05,
    //       rectRadius:
    //         +widget.outerBorderRadius / 72 / 2 +
    //         (+widget.outerBorderRadius === 0 ? 0.05 : 0),
    //       shadow: {
    //         type: 'outer',
    //         blur: 6,
    //         offset: 2,
    //         angle: 45,
    //         color: '000000',
    //         opacity: 0.2
    //       }
    //     }
    //   );
    // }

    // case widget.type === 'TABLE_CHART_WIDGET':
    // case widget.type === 'DATE_PICKER_WIDGET':
    // case widget.type === 'TEXT_WIDGET':
    // case widget.type === 'NUMBER_WIDGET':
    // case widget.type === 'LINE_CHART_WIDGET':
    // case widget.type === 'PIE_CHART_WIDGET':

    // const domElement = document.getElementById(widget.id);
    // console.log('imageWidget>domElement: ', domElement);

    // // const screenDomElement = document.getElementById('screen_' + widget.id);

    const oldOpacity = widget.opacity;
    // domElement.style.opacity = 1;

    var wrapperElement = document.createElement('div');

    const styleObject = {};
    for (let styleKey of [
      // 'height',
      // 'width',
      // 'top',
      // 'left',
      'boxShadow',
      'opacity'
    ]) {
      if (widget[styleKey]) {
        styleObject[styleKey] = widget[styleKey];
      }
    }

    for (let styleKey of [
      'height',
      'width',
      'top',
      'left'
      // 'boxShadow',
      // 'opacity'
    ]) {
      if (widget[styleKey]) {
        styleObject[styleKey] = widget[styleKey] + 'px';
      }
    }
    const style = {
      position: 'absolute',

      // left: widget.offsetX + 'px',
      // top: widget.offsetY + 'px',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '5px',
      // margin: '0px 0.5px',
      transform: 'translate(0, 0)',
      // overflow: 'hidden',
      lineBreak: 'anywhere',

      // boxShadow:
      //   !isDraggingThisWidget && 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
      ...styleObject,
      left: '-10000px'
    };

    delete style.opacity;

    // wrapperElement.style = style;

    for (let key of Object.keys(style)) {
      wrapperElement.style[key] = style[key];
    }

    var divElementParent = document.createElement('div');

    divElementParent.style.width = '100%';
    divElementParent.style.height = '100%';
    divElementParent.style.display = 'flex';
    divElementParent.style.flexDirection = 'column';
    divElementParent.style.justifyContent = 'center';
    divElementParent.style.alignItems = 'center';

    var divElement = document.createElement('div');
    // console.log('divElement: ', divElement);

    if (
      // widget.isGrayscale
      true
    ) {
      var imgObj = document.createElement('img');

      imgObj.src = widget.imageDataUri;

      // console.log('gray(imgObj): ', gray(imgObj));
      // console.log('debug31>widget: ', widget);
      divElement.style.backgroundImage = `url(${
        await formatImage({
          imgObj,
          backgroundColor: widget.imageBackgroundColor,
          backgroundColorOpacity: widget.imageBackgroundColorOpacity,
          // isGrayscale: Boolean(page.isGrayscale),
          grayScale: widget.imageGrayscaleFilter,
          blur: widget.imageBlurFilter,
          brightness: widget.imageBrightnessFilter,
          contrast: widget.imageContrastFilter,
          hueRotate: widget.imageHueRotateFilter,
          invert: widget.imageInvertFilter,
          opacity: widget.imageOpacityFilter,
          saturate: widget.imageSaturateFilter,
          sepia: widget.imageSepiaFilter,
          width: widget?.width,
          height: widget?.height,
          isBackground: false
        })

        // widget.imageDataUri || widget.imageUrl
      })`;
    } else {
      divElement.style.backgroundImage = `url(${
        widget.imageDataUri || widget.imageUrl
      })`;
    }
    divElement.style.borderRadius = widget.outerBorderRadius + 'px';
    divElement.style.backgroundRepeat = 'no-repeat';

    divElement.style.height = '100%';
    divElement.style.width = '100%';
    divElement.style.backgroundSize = 'cover';

    divElement.style.margin = '0 auto';
    divElement.style.overflow = 'hidden';
    divElement.style.display = 'flex';
    divElement.style.alignItems = 'center';
    divElement.style.justifyContent = 'center';
    divElement.style.position = 'relative';

    divElementParent.appendChild(divElement);
    wrapperElement.appendChild(divElementParent);

    document.body.appendChild(wrapperElement);
    // img: {
    //   height: '100%',
    //   width: '100%'
    // }

    await html2canvas(wrapperElement, {
      backgroundColor: null,
      scale: 1.6,
      // scale: window.devicePixelRatio,
      logging: true,
      letterRendering: 2,
      allowTaint: false,
      useCORS: true
    }).then((canvas) => {
      // var image = canvas.toDataURL('image/png', 1.0);
      var wid;
      var hgt;
      var image = canvas.toDataURL(
        'image/png',
        (wid = canvas.width),
        (hgt = canvas.height)
      );

      // console.log('image: ', image);

      // var doc = new jsPDF('l', 'px', [wid, hgt], true);
      // var doc = new jsPDF('p', 'pt', [wid, hgt], true);
      // var doc = new jsPDF('p', 'pt','a4',true);

      // console.log('ExportPDF>wid: ', wid);
      // console.log('ExportPDF>hgt: ', hgt);

      // var fileName = currentExport.split('Export')[0] + '.png';
      const fileName = 'NexusTable_export.pdf';
      // saveAs(image, fileName);
      // console.log('image: ', image);

      // var doc = new jsPDF('l', 'px', 'a4');
      // const padding = 0;
      // var hratio = hgt / wid;
      // var width = doc.internal.pageSize.width - padding * 2;
      // var height = width * hratio;

      // console.log('debug53>borderWidth:', borderWidth);

      const addImageObj = {
        data: image.split('data:')[1], //'image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg=='
        w: (widget.width - borderWidth * 2) / 72 / 2,
        h: (widget.height - borderWidth * 2) / 72 / 2,
        x: (+widget?.left + borderWidth) / 72 / 2,
        y: (widget.top + borderWidth) / 72 / 2,
        // transparency: (widget.imageOpacity - 1) * -100,

        shadow: widget.isDropShadowOn && {
          type: 'outer',
          blur: 6,
          offset: 2,
          angle: 45,
          color: '000000',
          opacity: 0.2
        }
      };
      // addImageObj.data = widget?.imageDataUri?.split('data:')[1];

      slide.addImage(addImageObj);

      // doc.addImage(image, 'PNG', padding, padding, width, height);
      // doc.save('NexusTable_export.pdf');
    });

    // divElement.style.opacity = oldOpacity;
    divElement.remove();
    divElementParent.remove();
    wrapperElement.remove();
  } catch (error) {
    console.log('error: ', error);
    throw error;
  }
};

export default imageWidget;
