export const defaultWidgetObject = {
  titleFontColor: '#000000',
  dataLabelFontColor: '#FFFFFF',
  textFontColor: '#000000',
  valueFontColor: '#000000',
  legendFontColor: '#000000',
  leftAxisLabelFontColor: '#000000',
  bottomAxisLabelFontColor: '#000000',
  rightAxisLabelFontColor: '#000000',
  bottomAxisTitleFontColor: '#000000',

  outerBorderColor: '#000000',
  leftAxisLineColor: '#000000',
  bottomAxisLineColor: '#000000',
  rightAxisLineColor: '#000000',
  leftValueGridLineColor: '#f5f5f5',
  rightValueGridLineColor: '#f5f5f5',
  chartBorderColor: '#ffffff',

  horizontalBorderColor: '#eeeeee',
  verticalBorderColor: '#eeeeee',

  cardFillColor: '#ffffff',
  titleFillColor: '#bcbcbc',
  shapeFillColor: '#bcbcbc',

  titleFontSize: 16,
  textFontSize: 14,
  valueFontSize: 14,
  dataLabelFontSize: 12,
  legendFontSize: 12,
  leftAxisLabelFontSize: 12,
  bottomAxisLabelFontSize: 12,
  rightAxisLabelFontSize: 12,
  bottomAxisTitleFontSize: 14,

  isTitleFontBold: true,
  isTextFontBold: true,
  isValueFontBold: true,
  isDataLabelFontBold: true,
  isLegendFontBold: false,
  isLeftAxisLabelFontBold: false,
  isBottomAxisLabelFontBold: false,
  isRightAxisLabelFontBold: false,
  isBottomAxisTitleFontBold: false,

  isTitleFontItalic: false,
  isTextFontItalic: false,
  isValueFontItalic: false,
  isDataLabelFontItalic: false,
  isLegendFontItalic: false,
  isLeftAxisLabelFontItalic: false,
  isBottomAxisLabelFontItalic: false,
  isRightAxisLabelFontItalic: false,
  isBottomAxisTitleFontItalic: false,

  titleFontFamily: 'Roboto',
  textFontFamily: 'Roboto',
  valueFontFamily: 'Roboto',
  dataLabelFontFamily: 'Roboto',
  legendFontFamily: 'Roboto',
  leftAxisLabelFontFamily: 'Roboto',
  bottomAxisLabelFontFamily: 'Roboto',
  rightAxisLabelFontFamily: 'Roboto',
  bottomAxisTitleFontFamily: 'Roboto',

  horizontalTextAlign: 'center',
  verticalTextAlign: 'center',

  outerBorderStyle: 'solid', // ??

  outerBorderWidth: 0,
  chartBorderWidth: 1,

  leftAxisLineWidth: 1, //1,
  bottomAxisLineWidth: 1, //1,
  rightAxisLineWidth: 1, // 1,

  horizontalBorderWidth: 2,
  verticalBorderWidth: 2,

  dataLineWidth: 3,
  dataLineStyle: 'solid',
  isDataLineSmooth: true,

  leftValueGridLineWidth: 1,
  rightValueGridLineWidth: 0,

  outerBorderRadius: 5,

  innerChartRadius: 50,
  outerChartRadius: 100,

  textFontOpacity: 1,
  cardFillOpacity: 1,
  shapeFillOpacity: 1,
  titleFillOpacity: 0.7,
  chartFillOpacity: 1,
  outerBorderOpacity: 1,
  leftAxisLineOpacity: 1,
  bottomAxisLineOpacity: 1,
  rightAxisLineOpacity: 1,
  // imageOpacity: 1,

  // isGrayscale: false,
  isCardShadowOn: true,
  isDropShadowOn: true,
  isShowingCard: true,
  isShowingTitle: true,
  isShowingBottomAxisTitle: false,
  isShowingComparison: false,
  isShowingLegend: true,
  isShowingDataLabelPercent: true,
  isShowingDataLabelValue: true,
  isTextTruncated: true,

  sortByColumn: undefined,
  sortByOrder: 'default',

  imageBackgroundColor: '#ffffff',
  imageBackgroundColorOpacity: 0,
  // backgroundOpacity: 1,

  imageGrayscaleFilter: 0,
  imageBlurFilter: 0,
  imageBrightnessFilter: 1,
  imageContrastFilter: 1,
  imageHueRotateFilter: 0,
  imageInvertFilter: 0,
  imageOpacityFilter: 1,
  imageSaturateFilter: 1,
  imageSepiaFilter: 0,

  mockupImageGrayscaleFilter: 0,
  mockupImageBlurFilter: 0,
  mockupImageBrightnessFilter: 1,
  mockupImageContrastFilter: 1,
  mockupImageHueRotateFilter: 0,
  mockupImageInvertFilter: 0,
  mockupImageOpacityFilter: 1,
  mockupImageSaturateFilter: 1,
  mockupImageSepiaFilter: 0
};

export const widgetStyleKeys = [
  'titleFontColor',
  'dataLabelFontColor',
  'textFontColor',
  'valueFontColor',
  'legendFontColor',
  'leftAxisLabelFontColor',
  'bottomAxisLabelFontColor',
  'rightAxisLabelFontColor',
  'bottomAxisTitleFontColor',

  'outerBorderColor',
  'leftAxisLineColor',
  'bottomAxisLineColor',
  'rightAxisLineColor',
  'leftValueGridLineColor',
  'rightValueGridLineColor',
  'chartBorderColor',

  'horizontalBorderColor',
  'verticalBorderColor',

  'cardFillColor',
  'titleFillColor',
  'shapeFillColor',

  'titleFontSize',
  'textFontSize',
  'valueFontSize',
  'dataLabelFontSize',
  'legendFontSize',
  'leftAxisLabelFontSize',
  'bottomAxisLabelFontSize',
  'rightAxisLabelFontSize',
  'bottomAxisTitleFontSize',

  'isTitleFontBold',
  'isTextFontBold',
  'isValueFontBold',
  'isDataLabelFontBold',
  'isLegendFontBold',
  'isLeftAxisLabelFontBold',
  'isBottomAxisLabelFontBold',
  'isRightAxisLabelFontBold',
  'isBottomAxisTitleFontBold',

  'isTitleFontItalic',
  'isTextFontItalic',
  'isValueFontItalic',
  'isDataLabelFontItalic',
  'isLegendFontItalic',
  'isLeftAxisLabelFontItalic',
  'isBottomAxisLabelFontItalic',
  'isRightAxisLabelFontItalic',
  'isBottomAxisTitleFontItalic',

  'titleFontFamily',
  'textFontFamily',
  'valueFontFamily',
  'dataLabelFontFamily',
  'legendFontFamily',
  'leftAxisLabelFontFamily',
  'bottomAxisLabelFontFamily',
  'rightAxisLabelFontFamily',
  'bottomAxisTitleFontFamily',

  'outerBorderWidth',
  'chartBorderWidth',

  'leftAxisLineWidth',
  'bottomAxisLineWidth',
  'rightAxisLineWidth',

  'horizontalBorderWidth',
  'verticalBorderWidth',

  'dataLineWidth',
  'dataLineStyle',
  'isDataLineSmooth',

  'leftValueGridLineWidth',
  'rightValueGridLineWidth',

  'outerBorderRadius',
  'innerChartRadius',
  'outerChartRadius',

  'cardFillOpacity',
  'shapeFillOpacity',
  'titleFillOpacity',
  'chartFillOpacity',
  'outerBorderOpacity',
  'leftAxisLineOpacity',
  'bottomAxisLineOpacity',
  'rightAxisLineOpacity',

  'imageBackgroundColor',
  'imageBackgroundColorOpacity',
  'imageGrayscaleFilter',
  'imageBlurFilter',
  'imageBrightnessFilter',
  'imageContrastFilter',
  'imageHueRotateFilter',
  'imageInvertFilter',
  'imageOpacityFilter',
  'imageSaturateFilter',
  'imageSepiaFilter',

  'mockupImageBackgroundColor',
  'mockupImageBackgroundOpacity',
  'mockupImageGrayscaleFilter',
  'mockupImageBlurFilter',
  'mockupImageBrightnessFilter',
  'mockupImageContrastFilter',
  'mockupImageHueRotateFilter',
  'mockupImageInvertFilter',
  'mockupImageOpacityFilter',
  'mockupImageSaturateFilter',
  'mockupImageSepiaFilter'
];
