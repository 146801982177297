const sumEvalFields = [
  'plannedGross',
  'plannedNet',
  'plannedNetNet',
  'plannedCtc',

  'plannedTrp',
  'plannedCpp',
  'plannedTechFee',
  'plannedAdOpsFee',
  'plannedAdServingCost',
  'plannedFeePercent',

  'budget',
  'kpi',
  'plannedImpressions',
  'plannedClicks',
  'plannedCtr',
  'plannedCpc',
  'plannedReach',
  'plannedCpm',
  'plannedFrequency',
  'plannedActionCount',
  'plannedActionValue',
  'plannedActionRoas',
  'plannedCpa',
  //   'universe',

  'trp',
  'reach',
  'cost',
  'impressions',
  'viewableImpressions',
  'measurableImpressions',
  'eligibleImpressions',
  'videoCompletions',
  'cpcv',
  'clicks',
  'cpc',
  'cpm',
  'vCpm',
  'ctr',
  'viewableRate',
  'vcr',
  'searchImpressionShare',
  'action01Count',
  'action02Count',
  'action03Count',
  'action01Value',
  'action02Value',
  'action03Value',
  'action01Cpa',
  'action02Cpa',
  'action03Cpa',
  'action01Vpa',
  'action02Vpa',
  'action03Vpa',
  'action01Roas',
  'action02Roas',
  'action03Roas',
  'action01Crr',
  'action02Crr',
  'action03Crr',
  'action01Cvr',
  'action02Cvr',
  'action03Cvr'
];

const aggregatedEvalFields = ['costLifetime', 'impressionsLifetime'];

const totalEvalFields = [
  'totalAction01Count',
  'totalAction02Count',
  'totalAction03Count',
  'totalAction01Value',
  'totalAction02Value',
  'totalAction03Value'
];

const calcEvalFields = ['universe', 'reach'];

const allEvalFields = [
  ...sumEvalFields,
  ...calcEvalFields,
  ...totalEvalFields,
  ...aggregatedEvalFields
];

exports.sumEvalFields = sumEvalFields;
exports.totalEvalFields = totalEvalFields;
exports.calcEvalFields = calcEvalFields;
exports.allEvalFields = allEvalFields;

exports.aggregatedEvalFields = aggregatedEvalFields;
