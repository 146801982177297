import React, { useContext, useState } from 'react';
import { MenuItem } from '@mui/material';

import pptxgen from 'pptxgenjs';

import html2canvas from 'html2canvas';

import { jsPDF } from 'jspdf';

import { AccountContext } from '../../../../context/AccountContext';
import { InterfaceContext } from '../../../../context/InterfaceContext';

import { ReportBuilderContext } from '../../../../context/ReportBuilderContext';

import { useTheme } from '@mui/material';

import formatDateYYYYMMDD from './../../roitable_functions/formatDateYYYYMMDD';

import tableChartWidget from './export-ppt/table-chart-widget';
import pieChartWidget from './export-ppt/pie-chart-widget';
import lineChartWidget from './export-ppt/line-chart-widget';
import comboChartWidget from './export-ppt/combo-chart-widget';
import numberWidget from './export-ppt/number-widget';
import textWidget from './export-ppt/text-widget';
import rectangleShapeWidget from './export-ppt/rectangle-shape-widget';
import dateWidget from './export-ppt/date-widget';

import imageWidget from './export-ppt/image-widget';

import widgetCard from './export-ppt/widget-card';

import mockupWidget from './export-ppt/mockup-widget';

import managerAccountLogoWidget from './export-ppt/manager-account-logo-widget';

import grayScale from './grayScaleFunction';

import opacity from './opacityFunction';

import formatImage from './formatImage';

import SpinnerSmall from '../../../layout/SpinnerSmall';

import {
  documentTypeToNameDict,
  documentTypeToShortNameDict
  // chartFillColors
} from './../report-builder-content/dictionary';

// export const dataChartPieStat = [
//   {
//     name: 'Project Status',
//     labels: ['Red', 'Yellow', 'Green', 'Complete', 'Cancelled', 'Unknown'],
//     values: [25, 5, 5, 5, 5, 5]
//   }
// ];

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
export const dataChartPieLocs = [
  {
    name: 'Sales by Location',
    labels: ['CN', 'DE', 'GB', 'MX', 'JP', 'IN', 'US'],
    values: [69, 35, 40, 85, 38, 99, 101]
  }
];

function ExportPPT({ handleClose, zoom }) {
  const theme = useTheme();
  const { currentManagerAccount } = useContext(AccountContext);

  const {
    widgets,
    setWidgets,
    updateWidget,
    report,
    reportBuilderColorPalette: chartFillColors_,
    setReportBuilderColorPalette,
    selectedPageId,
    setSelectedPageId
  } = useContext(ReportBuilderContext);

  const { setExportMode, setIsBackdropOpen } = useContext(InterfaceContext);

  const [isExporting, setIsExporting] = useState(false);

  const saveAs = (blob, fileName) => {
    var elem = window.document.createElement('a');
    elem.href = blob;
    elem.download = fileName;
    elem.style = 'display:none;';
    (document.body || document.documentElement).appendChild(elem);
    if (typeof elem.click === 'function') {
      elem.click();
    } else {
      elem.target = '_blank';
      elem.dispatchEvent(
        new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true
        })
      );
    }
    URL.revokeObjectURL(elem.href);
    elem.remove();
  };
  const savePPT = async () => {
    setIsBackdropOpen(true);
    setIsExporting(true);
    const selectedPageIdWhenStartingExport = selectedPageId;
    setExportMode(true);
    try {
      // 1. Create a new Presentation
      let pres = new pptxgen();
      pres.title = 'NexusTable_ppt_export_' + new Date();
      // let slide = pres.addSlide();

      let dummyPres = new pptxgen();
      // dummyPres.layout('LAYOUT_WIDE');

      dummyPres.layout = 'LAYOUT_WIDE';

      let dummySlide = dummyPres.addSlide();
      await sleep(200);
      for (let page of report?.pages || []) {
        await setSelectedPageId(page.id);

        await sleep(200);

        let layoutName = 'LAYOUT_WIDE';

        if (page.height !== 1080 || page.width !== 1920) {
          pres.defineLayout({
            name: 'CUSTOM',
            width: page.width / 72 / 2,
            height: page.height / 72 / 2
          });
          layoutName = 'CUSTOM';
        }

        pres.layout = layoutName;
        // 2. Add a Slide
        let slide = pres.addSlide();

        var imgObj = document.createElement('img');

        imgObj.src = page.backgroundImageDataUri;
        // imgObj.style.backgroundColor = 'black';
        // imgObj.style.background = 'black';
        await sleep(200);

        slide.background = {
          color: page.backgroundColor.split('#')[1],
          data:
            page.backgroundImageDataUri &&
            (await formatImage({
              imgObj,
              backgroundColor: page.backgroundColor,
              backgroundColorOpacity: 1,
              // isGrayscale: Boolean(page.isGrayscale),
              grayScale: page.backgroundImageGrayscaleFilter,
              blur: page.backgroundImageBlurFilter,
              brightness: page.backgroundImageBrightnessFilter,
              contrast: page.backgroundImageContrastFilter,
              hueRotate: page.backgroundImageHueRotateFilter,
              invert: page.backgroundImageInvertFilter,
              opacity: page.backgroundImageOpacityFilter,
              saturate: page.backgroundImageSaturateFilter,
              sepia: page.backgroundImageSepiaFilter,
              width: page.width,
              height: page.height,
              isBackground: true
            }))

          // transparency: (page.backgroundOpacity - 1) * -100
        };

        // if (page.backgroundImageDataUri) {
        //   if (page.isGrayscale) {
        //     console.log('debug55>row132');
        //     var imgObj = document.createElement('img');

        //     imgObj.src = page.backgroundImageDataUri;

        //     console.log('gray(imgObj): ', grayScale(imgObj));

        //     slide.background.data = grayScale(imgObj);
        //     // widget.imageDataUri || widget.imageUrl
        //   } else {
        //     console.log('debug55>row141');
        //     slide.background.data = page.backgroundImageDataUri;
        //   }
        // }

        // 3. Add one or more objects (Tables, Shapes, Images, Text and Media) to the Slide
        // let textboxText = 'Hello!';
        // let textboxOpts = { x: 1, y: 1, color: '363636' };
        // slide.addText(textboxText, textboxOpts);
        try {
        } catch (error) {
          setIsExporting(false);
          const selectedPageIdWhenStartingExport = selectedPageId;
          setExportMode(false);
          setIsBackdropOpen(false);
          return;
        }
        let imageData = '';
        for (let widget of page?.widgets || []) {
          await sleep(200);
          if (['IMAGE_WIDGET'].includes(widget.type) && !widget.imageDataUri) {
            continue;
          }
          let chartFillColors = widget?.chartFillColors?.length
            ? widget?.chartFillColors
            : chartFillColors_;
          widget.headlineWidth = Math.round(widget?.title?.length * 0.6);

          // ################## card #######################
          const borderWidth = +widget?.outerBorderWidth || 0;
          switch (true) {
            case [
              // 'MOCKUP_WIDGET',
              // 'MANAGER_ACCOUNT_LOGO_WIDGET',
              // 'IMAGE_WIDGET',
              'TABLE_CHART_WIDGET',
              'PIE_CHART_WIDGET',
              'LINE_CHART_WIDGET',
              'COMBO_CHART_WIDGET',
              'TABLE_CHART_WIDGET',
              'NUMBER_WIDGET',
              'TEXT_WIDGET',
              'RECTANGLE_SHAPE_WIDGET',
              'DATE_PICKER_WIDGET'
            ].includes(widget.type) && widget.isShowingCard:
            case [
              // 'MOCKUP_WIDGET',
              // 'MANAGER_ACCOUNT_LOGO_WIDGET',
              'IMAGE_WIDGET'
              // 'TABLE_CHART_WIDGET',
              // 'PIE_CHART_WIDGET',
              // 'LINE_CHART_WIDGET',
              // 'COMBO_CHART_WIDGET',
              // 'NUMBER_WIDGET',
              // 'TEXT_WIDGET',
              // 'RECTANGLE_SHAPE_WIDGET',
              // 'DATE_PICKER_WIDGET'
            ].includes(widget.type) && borderWidth > 0:
              // widget.type === 'MOCKUP_WIDGET':
              // case widget.type === 'MANAGER_ACCOUNT_LOGO_WIDGET':
              // case widget.type === 'IMAGE_WIDGET':
              // case widget.type === 'TABLE_CHART_WIDGET':
              // case widget.type === 'PIE_CHART_WIDGET':
              // case widget.type === 'LINE_CHART_WIDGET':
              // case widget.type === 'COMBO_CHART_WIDGET':
              // case widget.type === 'NUMBER_WIDGET':
              // case widget.type === 'TEXT_WIDGET':
              // case widget.type === 'RECTANGLE_SHAPE_WIDGET':
              // case widget.type === 'DATE_PICKER_WIDGET':
              await widgetCard(widget, pres, slide, chartFillColors);
          }

          // ###############################################

          switch (true) {
            case widget.type === 'MOCKUP_WIDGET':
              await mockupWidget(widget, pres, slide, chartFillColors);
              break;

            case widget.type === 'MANAGER_ACCOUNT_LOGO_WIDGET':
              await managerAccountLogoWidget(
                widget,
                pres,
                slide,
                chartFillColors
              );
              break;
            case widget.type === 'IMAGE_WIDGET':
              await imageWidget(widget, pres, slide, chartFillColors);
              break;

            case widget.type === 'TABLE_CHART_WIDGET':
              await tableChartWidget(
                widget,
                pres,
                slide,
                chartFillColors,
                dummySlide
              );
              break;

            case widget.type === 'PIE_CHART_WIDGET':
              await pieChartWidget(widget, pres, slide, chartFillColors);
              break;

            case widget.type === 'LINE_CHART_WIDGET':
              await lineChartWidget(widget, pres, slide, chartFillColors);
              break;
            case widget.type === 'COMBO_CHART_WIDGET':
              await comboChartWidget(widget, pres, slide, chartFillColors);
              break;
            case widget.type === 'NUMBER_WIDGET':
              await numberWidget(widget, pres, slide, chartFillColors);
              break;
            case widget.type === 'TEXT_WIDGET':
              await textWidget(widget, pres, slide, chartFillColors);
              break;
            case widget.type === 'RECTANGLE_SHAPE_WIDGET':
              await rectangleShapeWidget(widget, pres, slide, chartFillColors);
              break;
            case widget.type === 'DATE_PICKER_WIDGET':
              await dateWidget(widget, pres, slide, chartFillColors);
              break;
          }

          delete widget.headlineWidth;
        }

        // console.log('addImageObj: ', {
        //   data: imageData
        //   // w: +widget.style.width.split('px')[0] / 96,
        //   // h: +widget.style.height.split('px')[0] / 96,
        //   // x: +widget.style.left.split('px')[0] / 96,
        //   // y: +widget.style.top.split('px')[0] / 96
        // });
        // slide.addImage({
        //   data: imageData
        //   // w: +widget.style.width.split('px')[0] / 96,
        //   // h: +widget.style.height.split('px')[0] / 96,
        //   // x: +widget.style.left.split('px')[0] / 96,
        //   // y: +widget.style.top.split('px')[0] / 96
        // });
      }
      await sleep(200);

      // 4. Save the Presentation
      pres.writeFile({
        fileName:
          'NexusTable_ppt_export_' + formatDateYYYYMMDD(new Date()) + '.pptx'
      });
      await sleep(200);
      setSelectedPageId(selectedPageIdWhenStartingExport);
      setExportMode(false);
      setIsExporting(false);
      handleClose();
      setIsBackdropOpen(false);
    } catch (error) {
      console.log('error');
      setSelectedPageId(selectedPageIdWhenStartingExport);
      setExportMode(false);
      setIsExporting(false);
      handleClose();
      setIsBackdropOpen(false);
      return;
    }
  };

  if (isExporting) {
    return (
      <MenuItem
        style={{ fontSize: '12px', fontWeight: 'bold' }}
        // onClick={savePPT}
      >
        <SpinnerSmall size={20} />
      </MenuItem>
    );
  }

  return (
    <MenuItem
      style={{ fontSize: '12px', fontWeight: 'bold' }}
      onClick={savePPT}
    >
      PPT
    </MenuItem>
  );
}

export default ExportPPT;
