// import { pieChart } from './pieChart';

import { calcWidget } from './calcWidget';

import { calcDataFilters } from './calcDataFilters';

export const calcWidgets = ({
  isReportBuilderOpen,
  reportPage,
  widgets,
  listOfData,
  listOfFilteredDataSourceRows: listOfFilteredDataSourceRows_,
  aggregatedDateRange,
  startDateForLastDaysData,
  selectedDateStart,
  selectedDateStop,
  isDashboardUserMode
}) => {
  let listOfFilteredDataSourceRows = listOfFilteredDataSourceRows_;

  // ################### filter ##############################

  let filters = [];
  // if (reportPage?.dataFilters?.length > 0) {
  //   filters = reportPage?.dataFilters;
  // }

  // if (widget?.dataFilters?.length > 0) {
  //   filters = widget?.dataFilters;
  // }

  if (reportPage?.dataFilters || isDashboardUserMode) {
    filters = reportPage?.dataFilters || [];
    let {
      arrayOfData,

      listOfFilteredDataSourceRows: listOfFilteredDataSourceRows2,

      openRows
    } = calcDataFilters({ listOfData, filters });

    listOfFilteredDataSourceRows = listOfFilteredDataSourceRows2;
  }

  // #########################################################

  const newWidgets = [];
  for (let widget of widgets) {
    const copyOfWidget = calcWidget({
      isReportBuilderOpen,
      reportPage,
      widget,
      listOfData,
      listOfFilteredDataSourceRows,
      aggregatedDateRange,
      startDateForLastDaysData,
      selectedDateStart,
      selectedDateStop,
      isDashboardUserMode
    });

    newWidgets.push(copyOfWidget);
  }

  return newWidgets;
};
