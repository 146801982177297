import React, { useContext, useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SpeedIcon from '@mui/icons-material/Speed';

import { ReportBuilderContext } from '../../../../../context/ReportBuilderContext';

import Slider from '@mui/material/Slider';

import Tooltip from '@mui/material/Tooltip';

import { TextField } from '@mui/material';

import FormatSizeIcon from '@mui/icons-material/FormatSize';

import CheckBoxIcon from '@mui/icons-material/CheckBox';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

import Icon from '../Icon';

export default function BasicPopover({
  name,
  widget,
  valueKey,
  handleClose: handleMenuClose
}) {
  // const {
  //   widgets,
  //   setWidgets,
  //   selectedWidgetIds: selectedWidgetIds_,
  //   presetColors
  // } = useContext(ReportBuilderContext);

  const {
    reportPage,
    reportPages,
    setReportPages,
    // selectedReportPageIds: selectedReportPageIds_,
    presetColors,
    selectedPageId: reportPageId
  } = useContext(ReportBuilderContext);

  // console.log('daysToGraph: ', daysToGraph);

  const [value, setValue] = React.useState(false);

  let selectedReportPageIds_ = [];
  console.log('debug85>reportPages: ', reportPages);
  console.log('reportPageId: ', reportPageId);

  let selectedReportPageIds =
    selectedReportPageIds_.length &&
    selectedReportPageIds_.includes(reportPageId)
      ? selectedReportPageIds_
      : [reportPageId];

  useEffect(() => {
    setValue(
      Boolean(
        reportPages.filter((page) =>
          selectedReportPageIds?.includes(page.id)
        )?.[0]?.[valueKey]
      )
    );
  }, [reportPage?.[valueKey]]);

  // let selectedWidgetIds =
  //   selectedWidgetIds_.length && selectedWidgetIds_.includes(widget.id)
  //     ? selectedWidgetIds_
  //     : [widget?.id];

  // useEffect(() => {
  //   const defaultFontSize =
  //     widgets.filter((wid) => selectedWidgetIds.includes(wid.id))?.[0]?.[
  //       valueKey
  //     ] || 14;
  //   setValue(defaultFontSize);
  // }, [anchorEl, selectedWidgetIds]);

  // const handleClick = (event) => {
  //   event.preventDefault();
  //   event.stopPropagation();

  //   const copyOfWidgets = [...widgets];
  //   for (let copyOfWidget of copyOfWidgets) {
  //     if (selectedWidgetIds.includes(copyOfWidget.id)) {
  //       copyOfWidget[valueKey] = !value;
  //     }
  //   }

  //   // setValue(!value);
  //   setWidgets(copyOfWidgets);
  // };

  const handleClick = (color) => {
    // console.log('color: ', color);
    // setValue(!value);

    const copyOfReportPages = [...reportPages];
    console.log('debug85>selectedReportPageIds: ', selectedReportPageIds);
    for (let copyOfReportPage of copyOfReportPages) {
      if (selectedReportPageIds.includes(copyOfReportPage.id)) {
        console.log('debug85>copyOfReportPage: ', copyOfReportPage);
        copyOfReportPage[valueKey] = !value;
      }
    }
    console.log('debug85>copyOfReportPages: ', copyOfReportPages);
    setReportPages(copyOfReportPages);
    // toggleUpdate(true);
  };

  // let name = '';
  // switch (valueKey) {
  //   case 'isTitleFontBold':
  //     name = 'Title Font Bild';
  //     break;

  //   case 'isTextFontBold':
  //     name = 'Text Font Bold';
  //     break;

  //   case 'isDataLabelFontBold':
  //     name = 'Data Label Font';
  //     break;

  //   case 'isLegendFontBold':
  //     name = 'Legend Font Bold';
  //     break;
  //   case 'isLeftAxisLabelFontBold':
  //     name = 'Left Axis Font Bold';
  //     break;
  //   case 'isBottomAxisLabelFontBold':
  //     name = 'Bottom Axis Label Font Bold';
  //     break;
  //   case 'isRightAxisLabelFontBold':
  //     name = 'Right Axis Font Bold';
  //     break;

  //   case 'isTitleFontItalic':
  //     name = 'Title Font Italic';
  //     break;

  //   case 'isTextFontItalic':
  //     name = 'Text Font Italic';
  //     break;

  //   case 'isDataLabelFontItalic':
  //     name = 'Chart Font Italic';
  //     break;

  //   case 'isLegendFontItalic':
  //     name = 'Legend Font Italic';
  //     break;
  //   case 'isLeftAxisLabelFontItalic':
  //     name = 'Left Axis Font Italic';
  //     break;
  //   case 'isBottomAxisLabelFontItalic':
  //     name = 'Bottom Axis Label Font Italic';
  //     break;
  //   case 'isRightAxisLabelFontItalic':
  //     name = 'Right Axis Font Italic';
  //     break;
  // }

  return (
    <div
      // size="small"
      // aria-describedby={id}
      style={{
        width: '100%',
        height: '100%',

        textAlign: 'start',
        textTransform: 'none',

        // fontSize: '12px',
        // padding: '10px 10px',
        // padding: '4px 16px',
        padding: '8px 16px',
        margin: '0px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
      onClick={handleClick}
    >
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <Icon
          valueKey={valueKey}
          style={{ fontSize: '18px', marginRight: '18px' }}
        />
        <span>{name}</span>
      </div>

      {value ? (
        <CheckBoxIcon style={{ fontSize: '16px' }} />
      ) : (
        <CheckBoxOutlineBlankIcon style={{ fontSize: '16px' }} />
      )}
    </div>
  );
}
