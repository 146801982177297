import React, { useContext, useEffect, useState } from 'react';
import {
  PieChart,
  pieArcLabelClasses,
  pieArcClasses
} from '@mui/x-charts/PieChart';

import { DataContext } from '../../../../../context/DataContext';

import { ReportBuilderContext } from '../../../../../context/ReportBuilderContext';

import {
  documentTypeToNameDict,
  documentTypeToShortNameDict
  // chartFillColors
} from './../dictionary';

import { prepDataObject } from './prepDataObject';

import {
  nonSumEvalFields,
  sumEvalFields,
  plannedEvalFields
} from './../../../fields';

const desktopOS = [
  {
    label: 'Windows',
    value: 72.72
  },
  {
    label: 'OS X',
    value: 16.38
  },
  {
    label: 'Linux',
    value: 3.83
  },
  {
    label: 'Chrome OS',
    value: 2.42
  },
  {
    label: 'Other',
    value: 4.65
  }
];

const mobileOS = [
  {
    label: 'Android',
    value: 70.48
  },
  {
    label: 'iOS',
    value: 28.8
  },
  {
    label: 'Other',
    value: 0.71
  }
];

const platforms = [
  {
    label: 'Mobile',
    value: 59.12
  },
  {
    label: 'Desktop',
    value: 40.88
  }
];

function PieChartWidget({
  reportPage,
  widget,
  // displayMode,
  updateWidget,
  widgetIndex,
  // setMouseDownWidgetIndex,
  // // editMode,
  // dropOnWidget,
  dragOver,
  // dragStart,
  // dragEnd,
  // isDraggingWidget,
  // setIsDraggingWidget,
  // onMouseDownHandler,
  onMouseUpHandler
  // widgetType
}) {
  const key = widget.metrics?.[0].metric;
  const {
    listOfData,
    arrayOfData,

    listOfFilteredDataSourceRows
  } = useContext(DataContext);

  const { reportBuilderColorPalette: chartFillColors } =
    useContext(ReportBuilderContext);

  // console.log('debug99>arrayOfData: ', arrayOfData);

  // console.log('PieChartWidget>listOfData: ', listOfData);

  // const [seriesData, setSeriesData] = useState([]);

  const valueFormatter = (item) =>
    `${key}: ${(
      Math.round((item.value + Number.EPSILON) * 100) / 100
    ).toLocaleString()} ( ${item.share}% )`;

  const size = {
    // width: 400,
    // height: 200
    width: widget?.width,
    height: widget?.height * 0.9
  };

  // console.log('PieChartWidget>widget: ', widget);

  const ratio = widget.width / widget.height;
  // console.log('ratio: ', ratio);
  const data = {
    highlightScope: { fade: 'global', highlight: 'item' },
    faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
    data: widget?.data || [], //seriesData,
    valueFormatter,

    innerRadius:
      widget.innerChartRadius > 0
        ? widget.height * ((widget.innerChartRadius || 0) / 100) * 0.45
        : 0,
    outerRadius: widget.height * 0.42,
    cx: widget.isShowingLegend
      ? -10 + widget.height * 0.2 + widget.width * 0.2
      : widget.width * 0.485,
    // paddingAngle: 5,
    arcLabelRadius: ratio < 1 ? 85 / ratio + '%' : 75 + '%',
    cornerRadius: 2
  };

  return (
    <div
      key={widget.id}
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        alignItems: 'center',
        fontFamily: widget.fontFamily
          ? widget.fontFamily + ', Roboto, Raleway, sans-serif'
          : 'Roboto, Raleway, sans-serif'
        // position: 'relative',
        // width: widget?.width * 0.9,
        // height: widget?.height * 0.8,
        // padding: '0px'
      }}
    >
      <PieChart
        style={{
          filter:
            widget.isDropShadowOn &&
            'drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2))'
        }}
        colors={
          widget?.chartFillColors?.length
            ? widget?.chartFillColors
            : [...(chartFillColors || [])]
        } // Use palette
        series={[
          {
            arcLabel: (item) => {
              switch (true) {
                case widget.isShowingDataLabelPercent &&
                  widget.isShowingDataLabelValue:
                  return (
                    (widget.isShowingDataLabelValue &&
                      `${item?.value?.toLocaleString()}`) +
                    ' ' +
                    (widget.isShowingDataLabelPercent && `(${item.share}%)`)
                  );
                case widget.isShowingDataLabelPercent:
                  return `${item.share}%`;
                case widget.isShowingDataLabelValue:
                  return `${item?.value?.toLocaleString()}`;
              }
            },
            arcLabelMinAngle: 35,
            arcLabelRadius: '60%',
            ...data
          }
        ]}
        sx={{
          ['& .css-g5kqsh']: {
            fill: 'black !important'
          },
          [`& .${pieArcLabelClasses.root}`]: {
            fontFamily: widget.dataLabelFontFamily + ' !important',
            fontSize: widget.dataLabelFontSize + 'px' + ' !important',
            fontStyle: widget.isDataLabelFontItalic && 'italic',
            fontWeight: widget.isDataLabelFontBold && 'bold',
            fill: widget.dataLabelFontColor
          },
          [`& .${pieArcClasses.root}`]: {
            stroke: widget.chartBorderColor,
            strokeWidth: widget.chartBorderWidth
          },
          '& .MuiChartsLegend-series text': {
            fontFamily: widget.legendFontFamily + ' !important'
            // fontSize: widget.fontSize + ' !important',
            // fontStyle: widget.isFontItalic && 'italic',
            // fontWeight: widget.isFontBold && 'bold',
            // color: widget.fontColor
          }
          // '& .MuiChartsAxis-tickLabel tspan': {
          //   fontFamily: widget.fontFamily + ' !important',
          //   fontSize: widget.fontSize + ' !important',
          //   fontStyle: widget.isFontItalic && 'italic',
          //   fontWeight: widget.isFontBold && 'bold',
          //   color: widget.fontColor
          // },
          // '& .MuiChartsAxis-label tspan': {
          //   fontFamily: widget.fontFamily + ' !important',
          //   fontSize: widget.fontSize + ' !important',
          //   fontStyle: widget.isFontItalic && 'italic',
          //   fontWeight: widget.isFontBold && 'bold',
          //   color: widget.fontColor
          // }
        }}
        slotProps={{
          legend: {
            hidden: !widget.isShowingLegend,
            onItemClick: (e) => {
              console.log('test44>e: ', e);
            },
            direction: 'column',
            position: { vertical: 'middle', horizontal: 'right' },
            itemMarkWidth: 10,
            itemMarkHeight: 10,
            padding: 0 + +widget.legendFontSize,
            labelStyle: {
              fontSize: widget?.legendFontSize, // 12
              fontFamily: widget.legendFontFamily + ' !important',
              fontStyle: widget.isLegendFontItalic && 'italic',
              fontWeight: widget.isLegendFontBold && 'bold',
              // color: widget.fontColor
              fill: widget.legendFontColor
              //fill: 'blue'
            }
          }
        }}
        {...size}
      />
    </div>
  );
}

export default PieChartWidget;
