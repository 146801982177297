import React, { useState, useContext, useEffect } from 'react';

import { useLazyQuery } from '@apollo/client';
import { reportPageQuery } from '../../../../graphql/queries';

import { AccountContext } from '../../../../context/AccountContext';

import { Button } from '@mui/material';

import { ReportBuilderContext } from '../../../../context/ReportBuilderContext';
import { InterfaceContext } from '../../../../context/InterfaceContext';

import { v4 as uuidv4 } from 'uuid';

import { useSnackbar } from 'notistack';

import StatusMenu from './StatusMenu';

import SpinnerSmall from '../../../layout/SpinnerSmall';

import ProgressiveImg from './ProgressiveImg';

import ReportPageNameField from './ReportPageNameField';

function ReportPageInfo({
  selectedReportPage,
  setSelectedReportPage,
  refetchReportPagesCounter,
  setRefetchReportPagesCounter
}) {
  const { enqueueSnackbar } = useSnackbar();

  const { currentAccountIds, currentManagerAccountId } =
    useContext(AccountContext);

  const { isDashboardUserMode } = useContext(InterfaceContext);

  const { reportPages, setReportPages, setSelectedPageId, calcPageWidgets } =
    useContext(ReportBuilderContext);

  // console.log('debug2465>reportPages: ', reportPages);

  const [reportPage, setReportPage] = useState();
  const [isIncludingWidgets, setIsIncudingWIdget] = useState(false);

  const [variables, setVariables] = useState();

  const [previewImageFile, setPreviewImageFile] = useState();

  const [importCounter, setImportCounter] = useState(0);

  // 'previewImageXSmallFileId',
  // 'previewImageSmallFileId',
  // 'previewImageMediumFileId',
  // 'previewImageLargeFileId'
  useEffect(() => {
    switch (true) {
      case window.innerWidth > 3000:
        setPreviewImageFile('previewImageFileId');
        break;
      case window.innerWidth > 2000:
        setPreviewImageFile('previewImageMediumFileId');
        break;
      case window.innerWidth > 1500:
        setPreviewImageFile('previewImageSmallFileId');
        break;

      default:
        setPreviewImageFile('previewImageXSmallFileId');
    }
  }, [window.innerWidth]);

  useEffect(() => {
    setVariables({
      input: {
        managerAccountId: currentManagerAccountId,
        reportPageIds: [selectedReportPage?._id],
        isIncludingWidgets: false,
        previewImageFile
      }
    });
  }, [selectedReportPage?._id]);

  // const variables = ;

  const [loadReportPage, { called, loading, data, refetch }] = useLazyQuery(
    reportPageQuery,
    {
      variables,
      fetchPolicy: 'no-cache'
      // skip: !currentManagerAccountId && skip
    }
  );

  useEffect(() => {
    if (
      currentManagerAccountId &&
      selectedReportPage &&
      selectedReportPage?._id &&
      previewImageFile &&
      !called &&
      variables?.reportPageIds?.[0] !== null
    ) {
      loadReportPage();
    }
  }, [currentManagerAccountId, selectedReportPage]);

  useEffect(() => {
    if (data?.reportPage && called) {
      if (variables.input.isIncludingWidgets) {
        const newReportPage = { ...(data?.reportPage?.[0] || {}) };
        newReportPage.widgets = [
          ...newReportPage?.widgets?.map((widget) => ({
            ...widget,
            id: uuidv4(),
            metrics: widget?.metrics.map((metricObj) => ({
              ...metricObj,
              id: uuidv4()
            }))
          }))
        ];

        delete newReportPage._id;
        newReportPage.id = uuidv4();

        const setDate = async () => {
          const updatedNewRportPage = await calcPageWidgets(newReportPage);
          // console.log(
          //   'ReportPageInfo>updatedNewRportPage: ',
          //   updatedNewRportPage
          // );

          // ################################

          setReportPages([...reportPages, updatedNewRportPage]);

          setSelectedPageId(newReportPage.id, [
            ...reportPages,
            updatedNewRportPage
          ]);

          enqueueSnackbar('Template imported', {
            variant: 'success'
          });
        };
        setDate();
        // ########### test ###############

        // setReportPage(null);
      } else {
        setReportPage(data?.reportPage?.[0]);
      }
    }
  }, [data, importCounter]);

  const aspectRatio = reportPage?.width / reportPage?.height;

  // console.log('selectedReportPage: ', selectedReportPage);
  // console.log('reportPage: ', reportPage);

  const onClickImportHandler = async () => {
    // setReportPages(true);
    // variables.input.isIncludingWidgets = true;
    setVariables({
      ...variables,
      input: { ...variables.input, isIncludingWidgets: true }
    });
    setImportCounter(importCounter + 1);

    // const res = await loadReportPage();
    // console.log('ReportPageInfo>res: ', res);
  };

  const onClickCloseHandler = () => {
    setSelectedReportPage();
    setReportPage();
  };

  // if (loading) {
  //   return (
  //     <>
  //       <SpinnerSmall />
  //     </>
  //   );
  // }

  // let src = isLoading
  //   ? selectedReportPage?.previewImageUrl
  //   : reportPage?.previewImageUrl;

  // const customClass = isLoading
  //   ? 'reportPageImageLoading'
  //   : 'reportPageImageLoaded';

  // const placeholderSrc = selectedReportPage?.previewImageUrl;
  // const src = reportPage?.previewImageUrl;
  // const [imgSrc, setImgSrc] = useState(placeholderSrc || src);

  // useEffect(() => {
  //   const img = new Image();
  //   img.src = src;
  //   img.onload = () => {
  //     setImgSrc(src);
  //   };
  // }, [src]);

  if (!selectedReportPage?.previewImageUrl) {
    return <></>;
  }
  return (
    <div
      style={{
        height: '100%',
        width: '180%',
        // background: 'red',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '0px 0px 0px 10px',
        padding: '10px 0px 10px 10px',
        borderRadius: '5px',
        backdropFilter: 'blur(10px)',
        border: '1px solid rgba(150,150,150,0.2)',
        overflowY: 'scroll'
      }}
    >
      <div
        style={{
          // margin: '5px',
          overflow: 'hidden',
          borderRadius: '5px',
          width: '100%',
          aspectRatio: aspectRatio,
          // width: '400px',
          backgroundSize: 'cover'
          // width: '170px',

          // backgroundImage: `url(${
          //   isLoading
          //     ? selectedReportPage?.previewImageUrl
          //     : reportPage?.previewImageUrl
          // })`,
          // backgroundRepeat: 'no-repeat'
          // border:
          //   reportPage?._id === selectedReportPage?._id
          //     ? theme?.palette?.mode === 'dark'
          //       ? '1px solid rgba(255,255,255,1)'
          //       : 'red'
          //     : '1px solid rgba(255,255,255,0)'
        }}
      >
        <ProgressiveImg
          src={reportPage?.previewImageUrl}
          placeholderSrc={selectedReportPage?.previewImageUrl}
        />
      </div>

      {/* <div
        className={customClass}
        style={{
          // margin: '5px',
          borderRadius: '5px',
          width: '100%',
          aspectRatio: aspectRatio,
          // width: '400px',
          backgroundSize: 'cover',
          // width: '170px',

          backgroundImage: `url(${
            isLoading
              ? selectedReportPage?.previewImageUrl
              : reportPage?.previewImageUrl
          })`,
          backgroundRepeat: 'no-repeat'
          // border:
          //   reportPage?._id === selectedReportPage?._id
          //     ? theme?.palette?.mode === 'dark'
          //       ? '1px solid rgba(255,255,255,1)'
          //       : 'red'
          //     : '1px solid rgba(255,255,255,0)'
        }}
      >

      </div> */}
      <div
        style={{
          width: '100%',
          // minHeight: '200px',
          // margin: '10px',
          marginTop: '10px',
          padding: '10px',
          paddingLeft: '30px',
          borderRadius: '5px',
          background: 'rgba(150,150,150,0.3)',
          fontSize: '14px',
          fontWeight: 'bold',
          rowGap: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <div>
          <span
            style={{
              display: 'inline-block',
              width: '50px',
              marginRight: '20px'
            }}
          >
            Name:
          </span>{' '}
          <span>{reportPage?.name}</span>
          {/* <ReportPageNameField
            reportPage={reportPage}
            refetchReportPagesCounter={refetchReportPagesCounter}
            setRefetchReportPagesCounter={setRefetchReportPagesCounter}
            onClickCloseHandler={onClickCloseHandler}
            refetch={refetch}
          /> */}
        </div>
      </div>
      <div
        style={{
          margin: '10px 0px',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <div>
          {!isDashboardUserMode && (
            <StatusMenu
              reportPage={reportPage}
              refetchReportPagesCounter={refetchReportPagesCounter}
              setRefetchReportPagesCounter={setRefetchReportPagesCounter}
              onClickCloseHandler={onClickCloseHandler}
              refetch={refetch}
            />
          )}
        </div>
        <div>
          <Button
            onClick={onClickCloseHandler}
            variant="contained"
            style={{
              padding: '7px 15px',
              background: 'grey',
              color: 'white',
              marginRight: '10px',
              fontSize: '12px'
            }}
          >
            Close
          </Button>
          <Button
            onClick={onClickImportHandler}
            variant="contained"
            style={{
              padding: '7px 15px',
              background: 'green',
              color: 'white',
              fontSize: '12px'
            }}
          >
            Import
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ReportPageInfo;
