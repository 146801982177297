import React, { useContext } from 'react';

import { AccountContext } from '../../context/AccountContext';
import { AuthContext } from '../../context/AuthContext';

import { FilterContext } from '../../context/FilterContext';

import FilterByDataSourceChip from './filter-components/FilterByDataSourceChip';
import FilterByStatusChip from './filter-components/FilterByStatusChip';
import FilterByInvoiceStatusChip from './filter-components/FilterByInvoiceStatusChip';
import FilterByMemberChip from './filter-components/FilterByMemberChip';
import FilterByLabelChip from './filter-components/FilterByLabelChip';
import FilterByLabelNameChip from './filter-components/FilterByLabelNameChip';

import FilterBySelectionChip from './filter-components/FilterBySelectionChip';
import FilterByStringChip from './filter-components/FilterByStringChip';

import FilterByWarningsChip from './filter-components/FilterByWarningsChip';
import FilterByPaceChip from './filter-components/FilterByPaceChip';

import ArrayFilterChip from './filter-components/ArrayFilterChip';

import BooleanFilterChip from './filter-components/BooleanFilterChip';

function FilterComponent({ names, invoiceOrderIds, dataSourceNames }) {
  const { user } = useContext(AuthContext);
  const { currentManagerAccount } = useContext(AccountContext);

  const {
    statusFilter,
    setStatusFilter,
    filterBySelection,
    setFilterBySelection,
    filters,
    setFilters,
    filterByMemberId,
    setFilterByMemberId,

    filterByLabelId,
    setFilterByLabelId,

    filterByLabelName,
    setFilterByLabelName,

    filterByString,
    setFilterByString,
    filterByName,
    setFilterByName,
    filterByDataSourceName,
    setFilterByDataSourceName,
    filterByDataSource,
    setFilterByDataSource,
    filterByStatus,
    setFilterByStatus,
    filterByInvoiceStatus,
    setFilterByInvoiceStatus,
    filterByInvoiceOrderId,
    setFilterByInvoiceOrderId,
    viewDialogOpen,
    setViewDialogOpen,
    checkedBoxes,
    setCheckedBoxes
  } = useContext(FilterContext);

  return (
    <>
      {filters?.map((filter, filterIndex) => {
        switch (true) {
          case filter.category === 'array':
            return (
              <ArrayFilterChip
                filterIndex={filterIndex}
                // setCheckedBoxes={setCheckedBoxes}
                setFilters={setFilters}
                filters={filters}
                // setFilterBySelection={setFilterBySelection}
                // currentManagerAccountId={currentManagerAccount._id}
                // user={user}
                // setFilterByDataSource={setFilterByDataSource}
                // filterByDataSource={filterByDataSource}
                filter={filter}
                arrayOfOptions={[]}
              />
            );

          case filter.category === 'special':
            return (
              <BooleanFilterChip
                filterIndex={filterIndex}
                // setCheckedBoxes={setCheckedBoxes}
                setFilters={setFilters}
                filters={filters}
                // setFilterBySelection={setFilterBySelection}
                // currentManagerAccountId={currentManagerAccount._id}
                // user={user}
                // setFilterByDataSource={setFilterByDataSource}
                // filterByDataSource={filterByDataSource}
                filter={filter}
                arrayOfOptions={[]}
              />
            );
        }
      })}
    </>
  );

  return (
    // <>
    {
      /* {filters.includes('filter_by_data_source') && (
        <FilterByDataSourceChip
          setCheckedBoxes={setCheckedBoxes}
          setFilters={setFilters}
          filters={filters}
          setFilterBySelection={setFilterBySelection}
          currentManagerAccountId={currentManagerAccount._id}
          user={user}
          setFilterByDataSource={setFilterByDataSource}
          filterByDataSource={filterByDataSource}
          filter="filter_by_data_source"
          arrayOfOptions={names}
        />
      )}

      {filters.includes('filter_by_status') && (
        <FilterByStatusChip
          setCheckedBoxes={setCheckedBoxes}
          setFilters={setFilters}
          filters={filters}
          setFilterBySelection={setFilterBySelection}
          currentManagerAccountId={currentManagerAccount._id}
          user={user}
          // setFilterByDataSource={setFilterByDataSource}
          // filterByDataSource={filterByDataSource}
          filterByStatus={filterByStatus}
          setFilterByStatus={setFilterByStatus}
          filter="filter_by_status"
          arrayOfOptions={names}
        />
      )}
      {filters.includes('filter_by_invoice_status') && (
        <FilterByInvoiceStatusChip
          setCheckedBoxes={setCheckedBoxes}
          setFilters={setFilters}
          filters={filters}
          setFilterBySelection={setFilterBySelection}
          currentManagerAccountId={currentManagerAccount._id}
          user={user}
          // setFilterByDataSource={setFilterByDataSource}
          // filterByDataSource={filterByDataSource}
          filterByInvoiceStatus={filterByInvoiceStatus}
          setFilterByInvoiceStatus={setFilterByInvoiceStatus}
          filter="filter_by_invoice_status"
          arrayOfOptions={names}
        />
      )}

      {filters.includes('filter_by_invoice_order_id') && (
        <FilterByStringChip
          setCheckedBoxes={setCheckedBoxes}
          setFilters={setFilters}
          filters={filters}
          setFilterBySelection={setFilterBySelection}
          currentManagerAccountId={currentManagerAccount._id}
          user={user}
          setFilterByString={setFilterByInvoiceOrderId}
          filterByString={filterByInvoiceOrderId}
          filter="filter_by_invoice_order_id"
          arrayOfOptions={invoiceOrderIds}
          name="Filter by Invoice Order Id"
        />
      )}

      {filters.includes('filter_by_name') && (
        <FilterByStringChip
          setCheckedBoxes={setCheckedBoxes}
          setFilters={setFilters}
          filters={filters}
          setFilterBySelection={setFilterBySelection}
          currentManagerAccountId={currentManagerAccount._id}
          user={user}
          setFilterByString={setFilterByName}
          filterByString={filterByName}
          filter="filter_by_name"
          arrayOfOptions={names}
          name="Filter by Name"
        />
      )}

      {filters.includes('filter_by_data_source_name') && (
        <FilterByStringChip
          setCheckedBoxes={setCheckedBoxes}
          setFilters={setFilters}
          filters={filters}
          setFilterBySelection={setFilterBySelection}
          currentManagerAccountId={currentManagerAccount._id}
          user={user}
          setFilterByString={setFilterByDataSourceName}
          filterByString={filterByDataSourceName}
          filter="filter_by_data_source_name"
          arrayOfOptions={dataSourceNames}
          name="Filter by Data Source Name"
        />
      )}

      {filters.includes('filter_by_team') && (
        <FilterByMemberChip
          setCheckedBoxes={setCheckedBoxes}
          setFilters={setFilters}
          filters={filters}
          setFilterBySelection={setFilterBySelection}
          currentManagerAccountId={currentManagerAccount._id}
          user={user}
          filterByMemberId={filterByMemberId}
          setFilterByMemberId={setFilterByMemberId}
          // filterByLabelId={filterByLabelId}
          // setFilterByLabelId={setFilterByLabelId}
        />
      )}

      {filters.includes('filter_by_label') && (
        <FilterByLabelChip
          setCheckedBoxes={setCheckedBoxes}
          setFilters={setFilters}
          filters={filters}
          setFilterBySelection={setFilterBySelection}
          currentManagerAccountId={currentManagerAccount._id}
          user={user}
          filterByLabelId={filterByLabelId}
          setFilterByLabelId={setFilterByLabelId}
        />
      )}

      {filters.includes('filter_by_label_name') && (
        <FilterByLabelNameChip
          setCheckedBoxes={setCheckedBoxes}
          setFilters={setFilters}
          filters={filters}
          setFilterBySelection={setFilterBySelection}
          currentManagerAccountId={currentManagerAccount._id}
          user={user}
          filterByLabelName={filterByLabelName}
          setFilterByLabelName={setFilterByLabelName}
        />
      )}

      {filters.includes('filter_by_selection') && (
        <FilterBySelectionChip
          setCheckedBoxes={setCheckedBoxes}
          setFilters={setFilters}
          filters={filters}
          setFilterBySelection={setFilterBySelection}
        />
      )}

      {filters.includes('filter_by_warnings') && (
        <FilterByWarningsChip
          setCheckedBoxes={setCheckedBoxes}
          setFilters={setFilters}
          filters={filters}
        />
      )}

      {filters.includes('filter_by_pace') && (
        <FilterByPaceChip
          setCheckedBoxes={setCheckedBoxes}
          setFilters={setFilters}
          filters={filters}
        />
      )}
    </> */
    }
  );
}

export default FilterComponent;
