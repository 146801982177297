const numberWidget = async (widget, pres, slide, chartFillColors) => {
  // if (widget.isShowingCard) {
  //   slide.addShape(
  //     +widget?.outerBorderRadius === 0
  //       ? pres.shapes.RECTANGLE
  //       : pres.shapes.ROUNDED_RECTANGLE,
  //     {
  //       fill: {
  //         // color: 'FFFFFF',
  //         // transparency: (widget.opacity - 1) * -100 //5
  //         color: widget.cardFillColor || 'FFFFFF',
  //         transparency: (widget.cardFillOpacity - 1) * -100 //5
  //       },
  //       line: +widget?.outerBorderWidth !== 0 && {
  //         width: +widget?.outerBorderWidth / 2,
  //         color: widget.outerBorderColor.split('#')[1], //'f1f1f1',
  //         transparency: (widget.outerBorderOpacity - 1) * -100 //5
  //         // transparency: 10
  //       },
  //       // border: { pt: '1', color: 'f1f1f1', transparency: 10 },
  //       w: widget.width / 72 / 2,
  //       h: widget.height / 72 / 2,
  //       x: +widget?.left / 72 / 2,
  //       y: widget.top / 72 / 2,

  //       // rectRadius: 0.05,
  //       rectRadius:
  //         +widget.outerBorderRadius / 72 / 2 +
  //         (+widget.outerBorderRadius === 0 ? 0.05 : 0),
  //       shadow: {
  //         type: 'outer',
  //         blur: 6,
  //         offset: 2,
  //         angle: 45,
  //         color: '000000',
  //         opacity: 0.2
  //       }
  //     }
  //   );
  // }
  let numberOfDecimals = 2;
  const borderWidth = +widget?.outerBorderWidth || 0;

  slide.addText(
    widget.value.toLocaleString(undefined, {
      minimumFractionDigits: numberOfDecimals,
      maximumFractionDigits: numberOfDecimals
    }),
    {
      w: widget.width / 72 / 2,
      h: widget.height / 72 / 2,
      x: +widget?.left / 72 / 2,
      y: widget.top / 72 / 2,

      margin: 0,
      // fontFace: 'Roboto', // 'Arial',
      // fontSize: 8,
      // color: '000000',
      // bold: true,

      fontFace: widget?.valueFontFamily || 'Roboto', // 'Arial',
      fontSize: +widget?.valueFontSize / 2 || 7,
      color: widget?.valueFontColor || '#000000',
      bold: widget?.isValueFontBold || false,
      italic: widget?.isValueFontItalic || false,
      // underline: true,
      isTextBox: true,
      align: 'center'
      // fill: { color: 'F1F1F1' }
      // highlight: '#bcbcbc'
    }
  );

  if (widget.isShowingComparison) {
    let comparison = Math.round(
      (widget?.value / widget?.comparisonValue - 1) * 100
    );

    let comparisonPrefix = '';
    switch (true) {
      case comparison > 0:
        comparisonPrefix = '+';
        break;
      case comparison < 0:
        comparisonPrefix = '-';
        break;
      // case comparison < 0:
      //   comparisonPrefix = (
      //     <span
      //       style={{ fontWeight: widget.isValueFontBold && 'bold', color: 'red' }}
      //     >
      //       '- '
      //     </span>
      //   );
      //   break;
    }

    slide.addText(`${comparisonPrefix}${comparison}%`, {
      w: widget.width / 72 / 2,
      h: (widget?.valueFontSize + 5) / 72 / 2,
      x: +widget?.left / 72 / 2,
      y: (widget.top + Math.round(widget.height * 0.7)) / 72 / 2,

      margin: 0,
      // fontFace: 'Roboto', // 'Arial',
      // fontSize: 8,
      // color: '000000',
      // bold: true,

      fontFace: widget?.valueFontFamily || 'Roboto', // 'Arial',
      fontSize: +widget?.valueFontSize / 2 || 7,
      color:
        comparison > 0 ? '#00ff00' : comparison < 0 ? '#ff0000' : '#aaaaaa',
      bold: widget?.isValueFontBold || false,
      italic: widget?.isValueFontItalic || false,
      // underline: true,
      isTextBox: true,
      align: 'center'
      // fill: { color: 'F1F1F1' }
      // highlight: '#bcbcbc'
    });
  }

  let titleWidth = Math.round(
    widget.title.length * (+widget?.titleFontSize - 4)
  );
  // if (titleWidth < 120) {
  //   titleWidth = 120;
  // }

  // slide.addShape(pres.shapes.ROUNDED_RECTANGLE, {
  //   fill: {
  //     // color: 'ababab',
  //     // transparency: 90

  //     color: widget.titleFillColor, // 'ababab',
  //     transparency: (widget.titleFillOpacity - 1) * -100 //90
  //   },
  //   border: { pt: '1', color: 'f1f1f1', transparency: 10 },
  //   w: (titleWidth + 20) / 72 / 2,
  //   h: 25 / 72 / 2,
  //   x:
  //     +widget?.left / 72 / 2 +
  //     widget?.width / 72 / 2 -
  //     (titleWidth + 20) / 72 / 2,
  //   y: widget.top / 72 / 2 + 0 / 72 / 2,

  //   rectRadius: 0.01
  //   // shadow: {
  //   //   type: 'outer',
  //   //   blur: 6,
  //   //   offset: 2,
  //   //   angle: 45,
  //   //   color: 'bcbcbc',
  //   //   opacity: 0.2
  //   // }
  // });

  let top = widget.height / 5; ////'29px',

  slide.addText(widget.title, {
    // fill: { color: '0088CC' },
    // w: titleWidth / 72 / 2,
    // w: widget?.width / 72 / 2,
    // h: 20 / 72 / 2,
    // h: (+widget?.titleFontSize + 5) / 72 / 2,
    // x:
    //   +widget?.left / 72 / 2 +
    //   (widget?.width / 72 / 2 - titleWidth / 72 / 2) / 2,
    // x: +widget?.left / 72 / 2,
    // y: (widget.top + top) / 72 / 2 + 2 / 72 / 2,

    w: (widget.width - borderWidth * 2) / 72 / 2,
    h: (widget?.titleFontSize + 5) / 72 / 2,
    x: (widget.left + borderWidth) / 72 / 2,
    y: (widget.top + top + 2 + borderWidth) / 72 / 2,

    margin: 0,
    rectRadius: 3,
    fontFace: widget?.titleFontFamily || 'Roboto', // 'Arial',

    // fontSize: (widget?.fontSize?.split('px')?.[0] || 14) / 2,
    // color: widget.fontColor || '#000000',
    // bold: true,

    fontSize: +widget?.titleFontSize / 2 || 7,
    color: widget?.titleFontColor || '#000000',
    bold: widget?.isTitleFontBold, //true, //widget?.isFontBold || false,
    italic: widget?.isTitleFontItalic || false,
    // underline: true,
    isTextBox: true,
    align: 'center'
    // fill: { color: 'F1F1F1' }
    // highlight: '#bcbcbc'
  });
};

export default numberWidget;
