import React, { useState, useEffect, useContext, useLayoutEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';

import Spinner from '../../../../layout/SpinnerSmall';

import WidgetDescriptionMenu from './WidgetDescriptionMenu';

import { useTheme } from '@mui/material';

import { BarChart } from '@mui/x-charts/BarChart';

import {
  ChartsXAxis,
  ChartsYAxis,
  LinePlot,
  BarPlot,
  MarkPlot,
  ResponsiveChartContainer,
  ChartsLegend,
  ChartsTooltip
} from '@mui/x-charts';

import { DataContext } from '../../../../../context/DataContext';

import { DialogContext } from '../../../../../context/DialogContext';

import { ReportBuilderContext } from '../../../../../context/ReportBuilderContext';

import AxisMenu from './axis-menu/AxisMenu';

import {
  nonSumEvalFields,
  sumEvalFields,
  plannedEvalFields
} from '../../../fields';

import { prepDataObject } from './prepDataObject';

import {
  documentTypeToNameDict,
  documentTypeToShortNameDict,
  chartFillColors,
  dayOfWeekAsString
} from '../dictionary';

import formatDateYYYYMMDD from '../../../roitable_functions/formatDateYYYYMMDD';
import { InterfaceContext } from '../../../../../context/InterfaceContext';

function ComboChartWidget({
  reportPage,
  widget,
  // displayMode,
  updateWidget,
  widgetIndex,
  // setMouseDownWidgetIndex,
  // // editMode,
  // dropOnWidget,
  dragOver,
  // dragStart,
  // dragEnd,
  // isDraggingWidget,
  // setIsDraggingWidget,
  // onMouseDownHandler,
  onMouseUpHandler
  // widgetType
}) {
  const { reportBuilderColorPalette: chartFillColors } =
    useContext(ReportBuilderContext);

  const [xAxis, setXAxis] = useState();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  // console.log('widget?.data: ', widget?.data);

  // console.log('ComboChartWidget>widget: ', widget);
  useLayoutEffect(() => {
    setIsLoading(true);
  }, [widget.interval]);
  useEffect(() => {
    let newXAxis = {};
    switch (widget.interval) {
      case 'month':
        // const monthNames = [
        //   'January',
        //   'February',
        //   'March',
        //   'April',
        //   'May',
        //   'June',
        //   'July',
        //   'August',
        //   'September',
        //   'October',
        //   'November',
        //   'December'
        // ];

        newXAxis = widget?.metrics?.map((metricObj) => ({
          yAxisId: metricObj.axis,
          label: widget?.isShowingBottomAxisTitle && 'Month',
          data: widget?.dates || [], // xAxisData,
          tickInterval: widget?.dates || [], // xAxisData,
          scaleType: 'band' // 'band'
          // valueFormatter: (month) => {
          //   return month;
          // }
          // valueFormatter: (date) => {
          //   const formattedDate = formatDateYYYYMMDD(date);
          //   // console.log('formattedDate: ', formattedDate);
          //   return formattedDate;
          // } //(date) => dayjs(date).format('MMM D')
        }));
        break;

      case 'dayOfMonth':
        newXAxis = widget?.metrics?.map((metricObj) => ({
          yAxisId: metricObj.axis,
          label: widget?.isShowingBottomAxisTitle && 'Day of Month',
          data: widget?.dates || [], // xAxisData,
          tickInterval: widget?.dates || [], // xAxisData,
          scaleType: 'band',
          valueFormatter: (date) => {
            // console.log('date: ', date);
            const formattedDate = date;
            // console.log('formattedDate: ', formattedDate);
            return formattedDate;
          } //(date) => dayjs(date).format('MMM D')
        }));
        break;
      case 'dayOfWeek':
        newXAxis = widget?.metrics?.map((metricObj) => ({
          yAxisId: metricObj.axis,
          label: widget?.isShowingBottomAxisTitle && 'Day of Week',
          data: widget?.dates || [], // xAxisData,
          tickInterval: widget?.dates || [], // xAxisData,
          scaleType: 'band'
          // valueFormatter: (date) => {
          //   // console.log('date: ', date);
          //   const formattedDate = date;
          //   // console.log('formattedDate: ', formattedDate);
          //   return formattedDate;
          // } //(date) => dayjs(date).format('MMM D')
        }));
        break;
      case 'date':
        let dates_ = widget?.dates?.map((date) => new Date(date));
        // console.log('widget.dates: ', widget.dates);
        newXAxis = [
          {
            // yAxisId: metricObj.axis,
            label: widget?.isShowingBottomAxisTitle && 'Date',
            data: dates_ || [], // xAxisData,
            tickInterval: dates_ || [], // xAxisData,
            scaleType: 'band',
            valueFormatter: (date) => {
              // console.log('date: ', date);
              try {
                return formatDateYYYYMMDD(date).slice(5);
              } catch (error) {
                return null;
              }

              // console.log('formattedDate: ', formattedDate);
              // return formattedDate;
            } //(date) => dayjs(date).format('MMM D')
          }
        ];
        break;
      default:
        newXAxis = widget?.metrics?.map((metricObj) => ({
          yAxisId: metricObj.axis,
          label: widget?.isShowingBottomAxisTitle && widget?.interval,
          data: widget?.dates || [], // xAxisData,
          tickInterval: widget?.dates || [], // xAxisData,
          scaleType: 'band'
          // valueFormatter: (date) => {
          //   // console.log('date: ', date);
          //   const formattedDate = date;
          //   // console.log('formattedDate: ', formattedDate);
          //   return formattedDate;
          // } //(date) => dayjs(date).format('MMM D')
        }));
    }
    setXAxis(newXAxis);
    // console.log('newXAxis: ', newXAxis);
    setIsLoading(false);

    setData(
      widget?.data?.map((obj) => {
        if (!obj?.grouping?.includes('stack')) {
          return {
            ...obj,
            stack: obj.stack + '_' + obj.dimension
          };
        } else {
          return obj;
        }
      })
    );
  }, [widget?.interval, widget?.dates, widget?.axis, widget.metrics]);
  // let xAxis = {};

  // console.log('widget?.data: ', widget?.data);

  // console.log('widget: ', widget);

  if (isLoading) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Spinner />
      </div>
    );
  }
  if (!xAxis || !xAxis.length) {
    return <></>;
  }
  if (widget?.data?.[0]?.data?.length !== widget?.dates?.length) {
    // console.log('fail: widget?.data?.length: ', widget?.data?.data?.length);
    // console.log('fail: widget?.dates?.length: ', widget?.dates?.length);
    return <></>;
  }

  if (!widget?.dates?.length) {
    return <></>;
  }

  // console.log('ComboChartWidget>data: ', data);

  // // if (isLoading) {
  // //   return <div></div>;
  // // }

  // console.log('xAxis: ', xAxis);
  // console.log('widget?.data: ', widget?.data);

  // const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
  // const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];

  // const xLabels = [
  //   'Page A',
  //   'Page B',
  //   'Page C',
  //   'Page D',
  //   'Page E',
  //   'Page F',
  //   'Page G'
  // ];

  // // if (['metrics'].includes(currentlyDraggingWidget?.category)) {
  // //   return (
  // //     <div
  // //       style={{
  // //         borderRadius: '5px',
  // //         width: '100%',
  // //         height: '100%',
  // //         background: 'red',
  // //         display: 'flex'
  // //       }}
  // //     ></div>
  // //   );
  // // }
  // console.log('widget.metrics: ', widget.metrics);
  // console.log('data123: ', data);
  return (
    // <div onMouseUpHandler={onMouseUpHandler}>

    // <div
    //   style={{
    //     height: '100%',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     // justifyContent: 'space-between',
    //     justifyContent: 'space-between',
    //     alignItems: 'center'
    //     // position: 'relative',
    //     // width: widget.style.width.split('px')[0] * 0.9,
    //     // height: widget.style.height.split('px')[0] * 0.8,
    //     // padding: '0px'
    //   }}
    // >
    //   <div
    //     style={{
    //       width: '100%',
    //       display: 'flex',
    //       flexDirection: 'row',
    //       justifyContent: 'center',
    //       alignContent: 'center',
    //       fontSize: '14px',
    //       fontWeight: 'bold'
    //     }}
    //   >
    <div
      draggable={false}
      key={widget.id}
      style={{
        // height: '100%',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        alignItems: 'center',
        fontFamily: widget.fontFamily
          ? widget.fontFamily + ', Roboto, Raleway, sans-serif'
          : 'Roboto, Raleway, sans-serif'
        // position: 'relative',
        // width: widget.style.width.split('px')[0] * 0.9,
        // height: widget.style.height.split('px')[0] * 0.8,
        // padding: '0px'
      }}
    >
      <div
        style={{
          display: 'flex'
          // padding: '0px 3px'
        }}
      >
        <ResponsiveChartContainer
          // sx={{
          //   fontFamily: widget.fontFamily
          //     ? widget.fontFamily + ', Roboto, Raleway, sans-serif'
          //     : 'Roboto, Raleway, sans-serif',

          // }}
          style={{
            height: widget.height - +widget.outerBorderWidth * 2 + 'px',
            width: widget.width - +widget.outerBorderWidth + 'px'
          }}
          sx={{
            filter:
              widget.isDropShadowOn &&
              'drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2))',
            '& .MuiChartsLegend-series text': {
              fontFamily: widget.legendFontFamily + ' !important'
              // fontSize: widget.legendFontSize + ' !important',
              // fontStyle: widget.isLegendFontItalic && 'italic',
              // fontWeight: widget.isLegendFontBold && 'bold',
              // fill: widget.legendFontColor
            },
            '& .MuiLineElement-root': {
              // strokeDasharray: '10 5',
              strokeWidth: widget.dataLineWidth
            },
            '& .MuiChartsAxis-left .MuiChartsAxis-tickLabel tspan': {
              fontFamily: widget.leftAxisLabelFontFamily + ' !important',
              fontSize: widget.leftAxisLabelFontSize + 'px' + ' !important',
              fontStyle: widget.isLeftAxisLabelFontItalic && 'italic',
              fontWeight: widget.isLeftAxisLabelFontBold && 'bold',
              fill: widget.leftAxisLabelFontColor
            },
            '& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel tspan': {
              fontFamily: widget.bottomAxisLabelFontFamily + ' !important',
              fontSize: widget.bottomAxisLabelFontSize + 'px' + ' !important',
              fontStyle: widget.isBottomAxisLabelFontItalic && 'italic',
              fontWeight: widget.isBottomAxisLabelFontBold && 'bold',
              fill: widget.bottomAxisLabelFontColor
            },
            '& .MuiChartsAxis-right .MuiChartsAxis-tickLabel tspan': {
              fontFamily: widget.rightAxisLabelFontFamily + ' !important',
              fontSize: widget.rightAxisLabelFontSize + 'px' + ' !important',
              fontStyle: widget.isRightAxisLabelFontItalic && 'italic',
              fontWeight: widget.isRightAxisLabelFontBold && 'bold',
              fill: widget.rightAxisLabelFontColor
            },

            // label under bottom series
            '& .MuiChartsAxis-label tspan': {
              fontFamily: widget.bottomAxisTitleFontFamily + ' !important',
              fontSize: widget.bottomAxisTitleFontSize + 'px' + ' !important',
              fontStyle: widget.isBottomAxisTitleFontItalic && 'italic',
              fontWeight: widget.isBottomAxisTitleFontBold && 'bold',
              fill: widget.bottomAxisTitleFontColor
            },
            // '& .MuiChartsAxis-left .MuiChartsAxis-tickLabel': {
            //   strokeWidth: '0.4',
            //   fill: '#000000'
            // },
            // '& .MuiChartsAxis-right .MuiChartsAxis-tickLabel': {
            //   strokeWidth: '0.4',
            //   fill: '#000000'
            // },
            '& .MuiChartsAxis-left .MuiChartsAxis-tick': {
              fill: widget.leftAxisLineColor, //'#000000',
              stroke: widget.leftAxisLineColor, // '#000000',
              strokeWidth: 0.5
            },
            '& .MuiChartsAxis-bottom .MuiChartsAxis-tick': {
              fill: widget.bottomAxisLineColor, //'#000000',
              stroke: widget.bottomAxisLineColor, // '#000000',
              strokeWidth: 0.5
            },
            '& .MuiChartsAxis-right .MuiChartsAxis-tick': {
              fill: widget.rightAxisLineColor, //'#000000',
              stroke: widget.rightAxisLineColor, // '#000000',
              strokeWidth: 0.5
            },
            // change all labels fontFamily shown on both xAxis and yAxis
            // '& .MuiChartsAxis-tickContainer .MuiChartsAxis-tickLabel': {
            //   fontFamily: 'Roboto'
            // },
            // change bottom label styles
            // '& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel': {
            //   strokeWidth: '0.5',
            //   fill: '#000000'
            // },
            // bottomAxis Line Styles
            '& .MuiChartsAxis-bottom .MuiChartsAxis-line': {
              stroke: widget.bottomAxisLineColor, // '#000000',
              strokeWidth: widget.bottomAxisLineWidth // 0.5
            },
            // leftAxis Line Styles
            '& .MuiChartsAxis-left .MuiChartsAxis-line': {
              stroke: widget.leftAxisLineColor, // '#000000',
              strokeWidth: widget.leftAxisLineWidth // 0.5
            },
            '& .MuiChartsAxis-right .MuiChartsAxis-line': {
              stroke: widget.rightAxisLineColor, // '#000000',
              strokeWidth: widget.rightAxisLineWidth // 0.5
            }
          }}
          // style={{
          //   fontFamily: widget.fontFamily
          //     ? widget.fontFamily + ', Roboto, Raleway, sans-serif'
          //     : 'Roboto, Raleway, sans-serif'
          //   // fontFamily: widget.fontFamily + ' !important'
          // }}
          draggable={false}
          onDrop={(e) => {
            const droppedItemString = e.dataTransfer.getData('droppedItem');
            // console.log('debug55>dropOnWidget 5');
            // console.log('debug55>droppedItemString: ', droppedItemString);
            const droppedItem = JSON.parse(droppedItemString);

            if (droppedItem?.value?.category !== 'widgets') {
              e.preventDefault();
              e.stopPropagation();
            }

            return;
          }}
          colors={
            widget?.chartFillColors?.length
              ? widget?.chartFillColors
              : [...(chartFillColors || [])]
          } // Use palette
          // xAxis={[
          //   new Date('2024-08-01'),
          //   new Date('2024-08-02'),
          //   new Date('2024-08-03'),
          //   new Date('2024-08-04'),
          //   new Date('2024-08-05'),
          //   new Date('2024-08-06'),
          //   new Date('2024-08-07')
          // ]}

          xAxis={xAxis}
          yAxis={[{ id: 'leftAxisId' }, { id: 'rightAxisId' }]}
          rightAxis="rightAxisId"
          series={
            // widget?.data
            (data || [])?.map((obj) => ({
              ...obj,
              type: obj.chartType,
              // dataLineWidth: 4,
              // dataLineStyle: 'solid',
              // isDataLineSmooth: true,
              // strokeWidth: widget.dataLineWidth,
              curve:
                obj.isDataLineSmooth || widget.isDataLineSmooth
                  ? 'catmullRom' // 'monotoneX' //'natural'
                  : 'linear'
            })) || []
          }
          margin={{
            left: 70,
            right: 70
            // right: 60
            // top: 80,
            // bottom: 80,
          }}
          slots={{
            leftAxis: {
              onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                console.log('test111');
              }
            }
          }}
          slotProps={{
            xAxis: {
              onItemClick: (e) => {
                // console.log('test44>e: ', e);
              }
            },
            leftAxis: {
              onClick: () => {
                console.log('test11');
              }
            },

            legend: {
              onItemClick: (e) => {
                // console.log('test44>e: ', e);
              },
              // hidden: !widget.isShowingLegend,
              // fontFamily: widget.legendFontFamily
              //   ? widget.legendFontFamily + ', Roboto, Raleway, sans-serif'
              //   : 'Roboto, Raleway, sans-serif',
              // hidden: (widget?.data || [])?.length > 10,
              // direction: 'column',
              // position: { vertical: 'middle', horizontal: 'right' },
              position: { vertical: 'top', horizontal: 'center' }
              // itemMarkWidth: 10,
              // itemMarkHeight: 10
              // padding: {
              //   top: 0 + +widget.legendFontSize / 4,
              //   left: 10,
              //   right: 10
              // }
              // labelStyle: {
              //   // fontFamily: 'Roboto !important',
              //   fontSize: widget?.legendFontSize, // 12
              //   fontFamily: widget.legendFontFamily + ' !important',
              //   fontStyle: widget.isLegendFontItalic && 'italic',
              //   fontWeight: widget.isLegendFontBold && 'bold',
              //   // color: widget.legendFontColor,
              //   fill: widget.legendFontColor
              //   //fill: 'blue'
              // }
            }
          }}
          width={widget?.width}
          // height={widget?.height - (widget?.dimensions?.length ? 20 : 0)}
          height={widget.height - widget?.bottomAxisTitleFontSize / 1.5}
        >
          <ChartsTooltip />
          <BarPlot />
          <LinePlot />

          <ChartsYAxis
            id={'leftAxisId'}
            axisId={'leftAxisId'}
            position="left"
          />
          <ChartsYAxis
            id={'rightAxisId'}
            axisId={'rightAxisId'}
            position="right"
            // rightAxis
            // leftAxis={{
            //   onClick: () => {
            //     console.log('hej111');
            //   }
            // }}
          />
          <ChartsXAxis />
          <MarkPlot />
          <ChartsLegend
            onItemClick={(e) => {
              // console.log('test44>e: ', e);
            }}
            hidden={!widget.isShowingLegend}
            itemMarkWidth={20}
            itemMarkHeight={7}
            labelStyle={{
              padding: '0px 10px',
              // fontFamily: 'Roboto !important',
              fontSize: widget?.legendFontSize, // 12
              fontFamily: widget.legendFontFamily + ' !important',
              fontStyle: widget.isLegendFontItalic && 'italic',
              fontWeight: widget.isLegendFontBold && 'bold',
              // color: widget.legendFontColor,
              fill: widget.legendFontColor
              //fill: 'blue'
            }}
          />
        </ResponsiveChartContainer>
      </div>

      {/* <AxisMenu widget={widget} /> */}
    </div>
  );
}

export default React.memo(ComboChartWidget);
