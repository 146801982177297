export const typeToNameDict = {
  facebook_ads_campaign: 'Meta',
  google_ads_campaign: 'Google Ads',
  cm360_campaign: 'CM360',
  dv360_insertion_order: 'DV360',
  snapchat_ads_campaign: 'Snapchat Ads',
  tiktok_ads_campaign: 'TikTok Ads',
  pinterest_ads_campaign: 'Pinterest Ads',
  readpeak_campaign: 'ReadPeak Ads',
  strossle_campaign: 'Strossle',
  amazon_dsp_order: 'Amazon DSP',
  taboola_ads_campaign: 'Taboola Ads',
  microsoft_advertising_campaign: 'Microsoft Ads',
  microsoft_invest_insertion_order: 'Microsoft Invest',
  apple_search_ads_campaign: 'Apple Search Ads',
  adform_order: 'Adform',
  linkedin_ads_campaign_group: 'LinkedIn Ads',
  manualDataEntry: 'Manual Data Entry'
};
export const documentTypeToNameDict = {
  adform: 'Adform',
  facebookAds: 'Facebook Ads',
  googleAds: 'Google Ads',
  linkedInAds: 'LinkedIn Ads',
  dv360: 'Display & Video 360',
  cm360: 'Campaign Manager 360',
  snapchatAds: 'Snapchat Ads',
  tikTokAds: 'TikTok Ads',
  pinterestAds: 'Pinterest Ads',
  readpeak: 'Readpeak',
  strossle: 'Strossle',
  amazonDsp: 'Amazon DSP',
  taboolaAds: 'Taboola Ads',
  microsoftAdvertising: 'Microsoft Advertising',
  microsoftInvest: 'Microsoft Invest',
  appleSearchAds: 'Apple Search Ads',
  manualDataEntry: 'Manual Data Entry'
};

export const documentTypeToShortNameDict = {
  adform: 'Adform',
  facebookAds: 'Meta',
  googleAds: 'Google Ads',
  linkedInAds: 'LinkedIn',
  dv360: 'DV360',
  cm360: 'CM360',
  snapchatAds: 'Snapchat',
  tikTokAds: 'TikTok',
  pinterestAds: 'Pinterest',
  readpeak: 'Readpeak',
  strossle: 'Strossle',
  amazonDsp: 'Amazon DSP',
  taboolaAds: 'Taboola Ads',
  microsoftAdvertising: 'Microsoft Ads',
  microsoftInvest: 'Microsoft Inv',
  appleSearchAds: 'Apple Search',
  manualDataEntry: 'Manual Data'
};

export const chartFillColors = [
  '#FF0099',
  '#DCA564',
  '#A0AEBE',
  '#497F93',
  '#2CB890',
  '#EBA9CC',
  '#FFE19E',
  '#A4DDEC',
  '#B0DDD1',
  '#1A2E33'
];

export const dimensionDict = {
  documentType: 'Data Source Type',
  name: 'Name'
};

// export const metricToNumberDecimals = {
//   cpm: 1,
//   cost: 1,
//   cpc: 1,
//   ctr: 1
// };

export function dayOfWeekAsString(dayIndex) {
  return (
    [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday'
    ][dayIndex] || ''
  );
}

export const widgetOptionDictionary = {
  titleFontColor: 'Title Font Color',
  dataLabelFontColor: 'Data Label Font Color',
  textFontColor: 'Text Font Color',
  valueFontColor: 'Value Font Color',
  legendFontColor: 'Legend Font Color',
  leftAxisLabelFontColor: 'Left Axis Font Color',
  bottomAxisLabelFontColor: 'Bottom Axis Label Font Color',
  rightAxisLabelFontColor: 'Right Axis Font Color',
  bottomAxisTitleFontColor: 'Bottom Axis Title Font Color',

  outerBorderColor: 'Outer Border Color',
  leftAxisLineColor: 'Left Axis Line Color',
  bottomAxisLineColor: 'Bottom Axis Line Color',
  rightAxisLineColor: 'Right Axis Line Color',
  leftValueGridLineColor: 'Left Value Grid Line Color',
  rightValueGridLineColor: 'Right Value Grid Line Color',
  chartBorderColor: 'Chart Border Color',

  horizontalBorderColor: 'Horizontal Border Color',
  verticalBorderColor: 'Vertical Border Color',

  cardFillColor: 'Card Fill Color',
  titleFillColor: 'Title Fill Color',
  shapeFillColor: 'Shape Fill Color',

  titleFontSize: 'Title Font Size',
  textFontSize: 'Text Font Size',
  valueFontSize: 'Value Font Size',
  dataLabelFontSize: 'Data Label Font Size',
  legendFontSize: 'Legend Font Size',
  leftAxisLabelFontSize: 'Left Axis Font Size',
  bottomAxisLabelFontSize: 'Bottom Axis Label Font Size',
  rightAxisLabelFontSize: ' Right Axis Font Size',
  bottomAxisTitleFontSize: 'Bottom Axis Title Font Size',

  isTitleFontBold: 'Title Font Bold',
  isTextFontBold: 'Text Font Bold',
  isValueFontBold: 'Value Font Bold',
  isDataLabelFontBold: 'Data Label Font Bold',
  isLegendFontBold: 'Legend Font Bold',
  isLeftAxisLabelFontBold: 'Left Axis Font Bold',
  isBottomAxisLabelFontBold: 'Bottom Axis Label Font Bold',
  isRightAxisLabelFontBold: 'Right Axis Font Bold',
  isBottomAxisTitleFontBold: 'Bottom Axis Title Bold',

  isTitleFontItalic: 'Title Font Italic',
  isTextFontItalic: 'Text Font Italic',
  isValueFontItalic: 'Value Font Italic',
  isDataLabelFontItalic: 'Data Label Font Italic',
  isLegendFontItalic: 'Legend Font Italic',
  isLeftAxisLabelFontItalic: 'Left Axis Font Italic',
  isBottomAxisLabelFontItalic: 'Bottom Axis Label Font Italic',
  isRightAxisLabelFontItalic: 'Right Axis Font Italic',
  isBottomAxisTitleFontItalic: 'Bottom Axis Title Font Italic',

  titleFontFamily: 'Title Font Family',
  textFontFamily: 'Text Font Family',
  valueFontFamily: 'Value Font Family',
  dataLabelFontFamily: 'Data Label Font Family',
  legendFontFamily: 'Legend Font Family',
  leftAxisLabelFontFamily: 'Left Axis Font Family',
  bottomAxisLabelFontFamily: 'Bottom Axis Label Font Family',
  rightAxisLabelFontFamily: 'Right Axis Font Family',
  bottomAxisTitleFontFamily: 'Bottom Axis Title Font Family',

  horizontalTextAlign: 'Horizontal Text Align',
  verticalTextAlign: 'Vertical Text Align',

  outerBorderStyle: 'Outer Border Style',

  outerBorderWidth: 'Outer Border Width',
  chartBorderWidth: 'Chart Border Width',

  leftAxisLineWidth: 'Left Axis Line Width',
  bottomAxisLineWidth: 'Bottom Axis Line Width',
  rightAxisLineWidth: 'Right Axis Line Width',

  horizontalBorderWidth: 'Horizontal Border Width',
  verticalBorderWidth: 'Vertical Border Width',

  dataLineWidth: 'Data Line Width',
  dataLineStyle: 'Data Line Style',
  isDataLineSmooth: 'Smooth Data Line',

  leftValueGridLineWidth: 'Left Value Grid Line Width',
  rightValueGridLineWidth: 'Right Calue Grid Line Width',

  outerBorderRadius: 'Outer Border Radius',
  innerChartRadius: 'Inner Chart Radius',
  outerChartRadius: 'Outer Chart Radius',

  textFontOpacity: 'Text Font Opacity',
  cardFillOpacity: 'Card Fill Opacity',
  shapeFillOpacity: 'Shape Fill Opacity',
  titleFillOpacity: 'Title Fill Opacity',
  chartFillOpacity: 'Chart Fill Opacity',
  outerBorderOpacity: 'Outer Border Opacity',
  leftAxisLineOpacity: 'Left Axis Line Opacity',
  bottomAxisLineOpacity: 'Bottom Axis Line Opacity',
  rightAxisLineOpacity: 'Right Axis Line Opacity',
  // imageOpacity: 'Image Opacity',

  isGrayscale: 'Gray Scale',
  isCardShadowOn: 'Card Shadow',
  isDropShadowOn: 'Drop Shadow',
  isShowingCard: 'Show Card',
  isShowingTitle: 'Show Title',
  isShowingBottomAxisTitle: 'Show Bottom Axis Title',
  isShowingComparison: 'Show Comparison',
  isShowingLegend: 'Show Legend',

  isShowingDataLabelPercent: 'Show Data Label Percent',
  isShowingDataLabelValue: 'Show Data Label Value',

  isTextTruncated: 'Truncated Text',

  backgroundImageGrayscaleFilter: 'Gray Scale',
  backgroundImageBlurFilter: 'Blur',
  backgroundImageBrightnessFilter: 'Brightness',
  backgroundImageContrastFilter: 'Contrast',
  backgroundImageHueRotateFilter: 'Hue-Rotation',
  backgroundImageInvertFilter: 'Invert',
  backgroundImageOpacityFilter: 'Opacity',
  backgroundImageSaturateFilter: 'Saturation',
  backgroundImageSepiaFilter: 'Sepia',

  imageBackgroundColor: 'Background Color',
  imageBackgroundColorOpacity: 'Background color opacity',
  imageGrayscaleFilter: 'Image Gray Scale',
  imageBlurFilter: 'Blur',
  imageBrightnessFilter: 'Brightness',
  imageContrastFilter: 'Contrast',
  imageHueRotateFilter: 'Hue Rotation',
  imageInvertFilter: 'Invert',
  imageOpacityFilter: 'Opacity',
  imageSaturateFilter: 'Saturation',
  imageSepiaFilter: 'Sepia',

  mockupImageGrayscaleFilter: 'Image Gray Scale',
  mockupImageBlurFilter: 'Blur',
  mockupImageBrightnessFilter: 'Brightness',
  mockupImageContrastFilter: 'Contrast',
  mockupImageHueRotateFilter: 'Hue Rotation',
  mockupImageInvertFilter: 'Invert',
  mockupImageOpacityFilter: 'Opacity',
  mockupImageSaturateFilter: 'Saturation',
  mockupImageSepiaFilter: 'Sepia'
};

export const fieldDictionary = {
  isPersonal: 'Is Personal',
  subTasks: 'Subtasks',
  name: 'Name',
  warnings: 'Warnings',
  status: 'Status',
  path: 'Path',
  comments: 'Comments',
  members: 'Members',
  labels: 'Labels',
  dates: 'Dates',
  startDate: 'Start Date',
  endDate: 'End Date',
  numberOfDays: 'Number of Days',
  generalDescription: 'General Description',
  buyingTypeDescription: 'Buying Type Description',
  creativeDescription: 'Creative Description',
  formatDescription: 'Format Description',
  channelDescription: 'Channel Description',
  audienceDataDescription: 'Audience Data Description',
  targetGroupDescription: 'Target Group Description',
  placementDescription: 'Placement Description',
  goalDescription: 'Goal Description',
  budget: 'Budget',
  budgetCurrency: 'Budget Currency',
  calcDailyBudget: 'Calculated Daily Budget',
  plannedGross: 'Planned Gross',
  plannedNet: 'Planned Net',
  plannedNetNet: 'Planned Net Net',
  plannedCtc: 'Planned Cost To Client',

  plannedTrp: 'Planned Target Rating Points',
  plannedCpp: 'Planned Cost Per Point',
  plannedTechFee: 'Planned Tech Fee',
  plannedAdOpsFee: 'Planned Ad Ops Fee',
  plannedAdServingCost: 'Planned Ad Serving Cost',
  plannedFeePercent: 'Planned Fee Percent',

  kpi: 'KPI',
  plannedImpressions: 'Planned Impressions',
  plannedClicks: 'Planned Clicks',
  plannedCtr: 'Planned CTR',
  plannedCpc: 'Planned CPC',
  plannedReach: 'Planned Reach',
  plannedCpm: 'Planned CPM',
  plannedFrequency: 'Planned Frequency',
  plannedActionCount: 'Planned Actions',
  plannedActionValue: 'Planned Action Value',
  plannedActionRoas: 'Planned Action ROAS',
  plannedCpa: 'Planned CPA',
  cost: 'Cost',
  pace: 'Pace',
  pace_bar: 'Pace Bar',
  universe: 'Universe',
  trp: 'Target Rating Points',
  spots: 'Spots',
  impressions: 'Impressions',
  viewableImpressions: 'Viewable Impressions',
  measurableImpressions: 'Measurable Impressions',
  eligibleImpressions: 'Eligible Impressions',
  cpm: 'CPM',
  vCpm: 'vCPM',
  reach: 'Reach',
  videoCompletions: 'Video Completions',
  clicks: 'Clicks',
  ctr: 'CTR',
  viewableRate: 'Viewable Rate',
  vcr: 'Video Compl. Rate',
  searchImpressionShare: 'Search Impression Share',
  cpcv: 'CPCV',
  cpc: 'CPC',
  action01: 'Action 01 Type',
  action01Count: 'Action 01 Count',
  action01Cvr: 'Action 01 Conv. Rate',
  action01Cpa: 'Action 01 CPA',
  action01Value: 'Action 01 Value',
  action01Roas: 'Action 01 ROAS',
  action01Crr: 'Action 01 CRR',
  action01Vpa: 'Action 01 VPA',
  action02: 'Action 02 Type',
  action02Count: 'Action 02 Count',
  action02Cvr: 'Action 02 Conv. Rate',
  action02Cpa: 'Action 02 CPA',
  action02Value: 'Action 02 Value',
  action02Roas: 'Action 02 ROAS',
  action02Crr: 'Action 02 CRR',
  action02Vpa: 'Action 02 VPA',
  action03: 'Action 03 Type',
  action03Count: 'Action 03 Count',
  action03Cvr: 'Action 03 Conv. Rate',
  action03Cpa: 'Action 03 CPA',
  action03Value: 'Action 03 Value',
  action03Roas: 'Action 03 ROAS',
  action03Crr: 'Action 03 CRR',
  action03Vpa: 'Action 03 VPA',

  totalCost: 'Total Cost',
  totalImpressions: 'Total Impressions',
  totalCpm: 'Total CPM',
  totalClicks: 'Total Clicks',
  totalCtr: 'Total CTR',
  totalCpc: 'Total CPC',

  totalAction01: 'Total Action 01',
  totalAction01Count: 'Total Action 01 Count',
  totalaction01Cvr: 'Total Action 01 Conv. Rate',
  totalAction01Cpa: 'Total Action 01 CPA',
  totalAction01Value: 'Total Action 01 Value',
  totalAction01Roas: 'Total Action 01 ROAS',
  totalAction01Crr: 'Total Action 01 CRR',
  totalAction01Vpa: 'Total Action 01 VPA',
  totalAction02: 'Total Action 02',
  totalAction02Count: 'Total Action 02 Count',
  totalaction02Cvr: 'Total Action 02 Conv. Rate',
  totalAction02Cpa: 'Total Action 02 CPA',
  totalAction02Value: 'Total Action 02 Value',
  totalAction02Roas: 'Total Action 02 ROAS',
  totalAction02Crr: 'Total Action 02 CRR',
  totalAction02Vpa: 'Total Action 02 VPA',
  totalAction03: 'Total Action 03',
  totalAction03Count: 'Total Action 03 Count',
  totalaction03Cvr: 'Total Action 03 Conv. Rate',
  totalAction03Cpa: 'Total Action 03 CPA',
  totalAction03Value: 'Total Action 03 Value',
  totalAction03Roas: 'Total Action 03 ROAS',
  totalAction03Crr: 'Total Action 03 CRR',
  totalAction03Vpa: 'Total Action 03 VPA',

  totalAction01CountLineChart: 'Total Action 01 Count Line Chart',
  totalaction01CvrLineChart: 'Total Action 01 Conv. Rate Line Chart',
  totalAction01CpaLineChart: 'Total Action 01 CPA Line Chart',
  totalAction01ValueLineChart: 'Total Action 01 Value Line Chart',
  totalAction01RoasLineChart: 'Total Action 01 ROAS Line Chart',
  totalAction01CrrLineChart: 'Total Action 01 CRR Line Chart',
  totalAction01VpaLineChart: 'Total Action 01 VPA Line Chart',

  totalAction02CountLineChart: 'Total Action 02 Count Line Chart',
  totalaction02CvrLineChart: 'Total Action 02 Conv. Rate Line Chart',
  totalAction02CpaLineChart: 'Total Action 02 CPA Line Chart',
  totalAction02ValueLineChart: 'Total Action 02 Value Line Chart',
  totalAction02RoasLineChart: 'Total Action 02 ROAS Line Chart',
  totalAction02CrrLineChart: 'Total Action 02 CRR Line Chart',
  totalAction02VpaLineChart: 'Total Action 02 VPA Line Chart',

  totalAction03CountLineChart: 'Total Action 03 Count Line Chart',
  totalaction03CvrLineChart: 'Total Action 03 Conv. Rate Line Chart',
  totalAction03CpaLineChart: 'Total Action 03 CPA Line Chart',
  totalAction03ValueLineChart: 'Total Action 03 Value Line Chart',
  totalAction03RoasLineChart: 'Total Action 03 ROAS Line Chart',
  totalAction03CrrLineChart: 'Total Action 03 CRR Line Chart',
  totalAction03VpaLineChart: 'Total Action 03 VPA Line Chart',

  ganttBarColor: 'Gantt Bar Color',
  gantt: 'Gantt',

  impressionsToday: 'Impressions Today',
  impressionsYesterday: 'Impressions Yesterday',
  impressionsLineChart: 'Impressions Line Chart',

  viewableImpressionsToday: 'Viewable Impressions Today',
  viewableImpressionsYesterday: 'Viewable Impressions Yesterday',
  viewableImpressionsLineChart: 'Viewable Impressions Line Chart',

  measurableImpressionsToday: 'Measurable Impressions Today',
  measurableImpressionsYesterday: 'Measurable Impressions Yesterday',
  measurableImpressionsLineChart: 'Measurable Impressions Line Chart',

  eligibleImpressionsToday: 'Eligible Impressions Today',
  eligibleImpressionsYesterday: 'Eligible Impressions Yesterday',
  eligibleImpressionsLineChart: 'Eligible Impressions Line Chart',

  videoCompletionsToday: 'Video Completions Today',
  videoCompletionsYesterday: 'Video Completions Yesterday',
  videoCompletionsLineChart: 'Video Completions Line Chart',

  cpmToday: 'CPM Today',
  cpmYesterday: 'CPM Yesterday',
  cpmLineChart: 'CPM Line Chart',

  vCpmToday: 'vCPM Today',
  vCpmYesterday: 'vCPM Yesterday',
  vCpmLineChart: 'vCPM Line Chart',

  costToday: 'Cost Today',
  costYesterday: 'Cost Yesterday',
  costLineChart: 'Cost Line Chart',

  trpToday: 'Target Rating Points Today',
  trpYesterday: 'Target Rating Points Yesterday',
  trpLineChart: 'Target Rating Points Line Chart',

  spotsToday: 'Spots Today',
  spotsYesterday: 'Spots Yesterday',
  spotsLineChart: 'Spots Line Chart',

  clicksToday: 'Clicks Today',
  clicksYesterday: 'Clicks Yesterday',
  clicksLineChart: 'Clicks Line Chart',

  ctrToday: 'CTR Today',
  ctrYesterday: 'CTR Yesterday',
  ctrLineChart: 'CTR Line Chart',

  viewableRateToday: 'Viewable Rate Today',
  viewableRateYesterday: 'Viewable Rate Yesterday',
  viewableRateLineChart: 'Viewable Rate Line Chart',

  videoCompletionsToday: 'Viewable Rate Today',
  videoCompletionsYesterday: 'Viewable Rate Yesterday',
  vcrLineChart: 'Viewable Rate Line Chart',

  searchImpressionShareToday: 'Search Impression Share Today',
  searchImpressionShareYesterday: 'Search Impression Share Yesterday',
  searchImpressionShareLineChart: 'Search Impression Share Line Chart',

  cpcvToday: 'CPCV Today',
  cpcvLineChart: 'CPCV Line Chart',
  cpcvYesterday: 'CPCV Yesterday',

  cpcToday: 'CPC Today',
  cpcLineChart: 'CPC Line Chart',
  cpcYesterday: 'CPC Yesterday',

  action01CountLineChart: 'Action 01 Count Line Chart',
  action01CvrLineChart: 'Action 01 Conv. Rate Line Chart',
  action01CpaLineChart: 'Action 01 CPA Line Chart',
  action01ValueLineChart: 'Action 01 Value Line Chart',
  action01RoasLineChart: 'Action 01 ROAS Line Chart',
  action01CrrLineChart: 'Action 01 CRR Line Chart',
  action01VpaLineChart: 'Action 01 VPA Line Chart',

  action02CountLineChart: 'Action 02 Count Line Chart',
  action02CvrLineChart: 'Action 02 Conv. Rate Line Chart',
  action02CpaLineChart: 'Action 02 CPA Line Chart',
  action02ValueLineChart: 'Action 02 Value Line Chart',
  action02RoasLineChart: 'Action 02 ROAS Line Chart',
  action02CrrLineChart: 'Action 02 CRR Line Chart',
  action02VpaLineChart: 'Action 02 VPA Line Chart',

  action03CountLineChart: 'Action 03 Count Line Chart',
  action03CvrLineChart: 'Action 03 Conv. Rate Line Chart',
  action03CpaLineChart: 'Action 03 CPA Line Chart',
  action03ValueLineChart: 'Action 03 Value Line Chart',
  action03RoasLineChart: 'Action 03 ROAS Line Chart',
  action03CrrLineChart: 'Action 03 CRR Line Chart',
  action03VpaLineChart: 'Action 03 VPA Line Chart',

  action01CountToday: 'Action 01 Count Today',
  action01CvrToday: 'Action 01 Conv. Rate Today',
  action01CpaToday: 'Action 01 CPA Today',
  action01ValueToday: 'Action 01 Value Today',
  action01RoasToday: 'Action 01 ROAS Today',
  action01CrrToday: 'Action 01 CRR Today',
  action01VpaToday: 'Action 01 VPA Today',

  action02CountToday: 'Action 02 Count Today',
  action02CvrToday: 'Action 02 Conv. Rate Today',
  action02CpaToday: 'Action 02 CPA Today',
  action02ValueToday: 'Action 02 Value Today',
  action02RoasToday: 'Action 02 ROAS Today',
  action02CrrToday: 'Action 02 CRR Today',
  action02VpaToday: 'Action 02 VPA Today',

  action03CountToday: 'Action 03 Count Today',
  action03CvrToday: 'Action 03 Conv. Rate Today',
  action03CpaToday: 'Action 03 CPA Today',
  action03ValueToday: 'Action 03 Value Today',
  action03RoasToday: 'Action 03 ROAS Today',
  action03CrrToday: 'Action 03 CRR Today',
  action03VpaToday: 'Action 03 VPA Today',

  action01CountYesterday: 'Action 01 Count Yesterday',
  action01CvrYesterday: 'Action 01 Conv. Rate Yesterday',
  action01CpaYesterday: 'Action 01 CPA Yesterday',
  action01ValueYesterday: 'Action 01 Value Yesterday',
  action01RoasYesterday: 'Action 01 ROAS Yesterday',
  action01CrrYesterday: 'Action 01 CRR Yesterday',
  action01VpaYesterday: 'Action 01 VPA Yesterday',

  action02CountYesterday: 'Action 02 Count Yesterday',
  action02CvrYesterday: 'Action 02 Conv. Rate Yesterday',
  action02CpaYesterday: 'Action 02 CPA Yesterday',
  action02ValueYesterday: 'Action 02 Value Yesterday',
  action02RoasYesterday: 'Action 02 ROAS Yesterday',
  action02CrrYesterday: 'Action 02 CRR Yesterday',
  action02VpaYesterday: 'Action 02 VPA Yesterday',

  action03CountYesterday: 'Action 03 Count Yesterday',
  action03CvrYesterday: 'Action 03 Conv. Rate Yesterday',
  action03CpaYesterday: 'Action 03 CPA Yesterday',
  action03ValueYesterday: 'Action 03 Value Yesterday',
  action03RoasYesterday: 'Action 03 ROAS Yesterday',
  action03CrrYesterday: 'Action 03 CRR Yesterday',
  action03VpaYesterday: 'Action 03 VPA Yesterday'
};
