import formatDate from './formatDate';

import evaluateFormula from './evaluateFormula';

const parse_fb_data = (
  facebookadscampaign,
  campaignBaseValues,
  selectedDateStart,
  selectedDateStop,
  adsetBaseValues,
  range2
) => {
  const action01DataSource = facebookadscampaign?.action01?.dataSource;
  const action02DataSource = facebookadscampaign?.action02?.dataSource;
  const action03DataSource = facebookadscampaign?.action03?.dataSource;

  const action01DataSourceIsFacebookAds = action01DataSource === 'facebookAds';
  const action02DataSourceIsFacebookAds = action02DataSource === 'facebookAds';
  const action03DataSourceIsFacebookAds = action03DataSource === 'facebookAds';

  const action01Type = facebookadscampaign?.action01?.facebookAds?.type;
  const action02Type = facebookadscampaign?.action02?.facebookAds?.type;

  const action03Type = facebookadscampaign?.action03?.facebookAds?.type;

  const action01Name = facebookadscampaign?.action01?.name;

  const action02Name = facebookadscampaign?.action02?.name;
  const action03Name = facebookadscampaign?.action03?.name;

  const action01AttributionWindow =
    facebookadscampaign?.action01?.facebookAds?.attributionWindow;

  const action02AttributionWindow =
    facebookadscampaign?.action02?.facebookAds?.attributionWindow;

  const action03AttributionWindow =
    facebookadscampaign?.action03?.facebookAds?.attributionWindow;

  const translateAttributionWindow = (attributionWindow) => {
    switch (attributionWindow) {
      case '1d_view':
        return '_1d_view';

      case '1d_click':
        return '_1d_click';

      case '7d_click':
        return '_7d_click';

      case '28d_click':
        return '_28d_click';

      default:
        return 'value';
    }
  };

  const action01ConversionWindow = translateAttributionWindow(
    action01AttributionWindow
  );
  const action02ConversionWindow = translateAttributionWindow(
    action02AttributionWindow
  );
  const action03ConversionWindow = translateAttributionWindow(
    action03AttributionWindow
  );

  const campaign = {
    ...facebookadscampaign,
    ...campaignBaseValues,
    // dataSourceData: true,
    type: 'facebook_ads_campaign'
  };

  const runCostFormula = campaign.costFormula !== '=cost';

  if (
    !campaign.name &&
    facebookadscampaign &&
    facebookadscampaign.facebookadsadsets[0] &&
    facebookadscampaign.facebookadsadsets[0].campaign_name
  ) {
    campaign.name = facebookadscampaign.facebookadsadsets[0].campaign_name;
  }

  const listOfAdsets = [];

  const listOfPropagationData = [];

  if (facebookadscampaign.facebookadsadsets.length > 0) {
    // campaign.name = facebookadscampaign.facebookadsadsets[0].campaign_name;

    // get campaign metrics

    const listOfAdSetIds = [];

    for (let adset_object of facebookadscampaign.facebookadsadsets) {
      // metrics total

      let action01Count = null;
      let action02Count = null;
      let action03Count = null;

      let action01Value = null;
      let action02Value = null;
      let action03Value = null;

      let outbound_clicks = null;

      for (let action of adset_object.actions) {
        if (
          action01DataSourceIsFacebookAds &&
          action.action_type === action01Type
        ) {
          action01Count = action[action01ConversionWindow];
        }
        if (
          action02DataSourceIsFacebookAds &&
          action.action_type === action02Type
        ) {
          action02Count = action[action02ConversionWindow];
        }
        if (
          action03DataSourceIsFacebookAds &&
          action.action_type === action03Type
        ) {
          action03Count = action[action03ConversionWindow];
        }
      }

      for (let action_value of adset_object.action_values) {
        if (
          action01DataSourceIsFacebookAds &&
          action_value.action_type === action01Type
        ) {
          action01Value = action_value[action01ConversionWindow];
        }
        if (
          action02DataSourceIsFacebookAds &&
          action_value.action_type === action02Type
        ) {
          action02Value = action_value[action02ConversionWindow];
        }
        if (
          action03DataSourceIsFacebookAds &&
          action_value.action_type === action03Type
        ) {
          action03Value = action_value[action03ConversionWindow];
        }
      }

      for (let outbound_click of adset_object.outbound_clicks) {
        switch (outbound_click.action_type) {
          case 'outbound_click':
            outbound_clicks = outbound_click['value'];
        }
      }

      // const costScope = {
      //   cost: adset_object.spend || 0
      // };

      // const cost = runCostFormula
      //   ? evaluateFormula(campaign.costFormula, costScope)
      //   : adset_object.spend || null;

      const formulaFields = [
        // 'plannedGross',
        // 'plannedNet',
        // 'plannedNetNet',
        // 'plannedCtc',

        // "plannedTrp",
        // "plannedTechFee",
        // "plannedAdOpsFee",
        // "plannedAdServingCost",
        // "plannedFeePercent",

        // 'budget',
        // 'kpi',
        // 'plannedImpressions',
        // 'plannedClicks',
        // 'plannedCtr',
        // 'plannedCpc',
        // 'plannedReach',
        // 'plannedCpm',
        // 'plannedFrequency',
        // 'plannedActionCount',
        // 'plannedActionValue',
        // 'plannedActionRoas',
        // 'plannedCpa',
        'reach',
        'cost',
        'impressions',
        'clicks',
        'action01Count',
        'action02Count',
        'action03Count',
        'action01Value',
        'action02Value',
        'action03Value'
      ];

      const generateScope = (formulaField, calcObject) => {
        switch (formulaField) {
          case 'cost':
            return {
              cost: calcObject.spend || 0
            };

          case 'impressions':
            return {
              impressions: calcObject.impressions || 0
            };
          case 'clicks':
            return {
              clicks: outbound_clicks || 0
            };
          case 'action01Count':
          case 'action02Count':
          case 'action03Count':
          case 'action01Value':
          case 'action02Value':
          case 'action03Value':
            return {
              [formulaField]: calcObject[formulaField] || 0
            };

          default:
            return {};
        }
      };

      const calculatedFields = {};

      // for (let formulaField of formulaFields) {
      //   calculatedFields[formulaField] = evaluateFormula(
      //     campaign[formulaField + 'Formula'],
      //     generateScope(formulaField, adset_object)
      //   );
      // }

      for (let formulaField of formulaFields) {
        calculatedFields[formulaField] = evaluateFormula(
          '=' + formulaField,
          generateScope(formulaField, adset_object)
        );
      }

      listOfPropagationData.push({
        ...adset_object,
        ...calculatedFields,
        date_start: adset_object.date_start,
        date_stop: adset_object.date_stop,
        // cost: adset_object.cost,
        // cost: cost,
        // impressions: adset_object.impressions,
        action01Type,
        action01Count,
        action01Value,

        action02Type,
        action02Count,
        action02Value,

        action03Type,
        action03Count,
        action03Value,
        // outbound_clicks,
        // clicks: outbound_clicks,
        accountId: campaignBaseValues.accountId
      });

      // push adset_id to then make unique later and loop
      listOfAdSetIds.push(adset_object.adset_id);
    }

    const uniqueListOfAdSetIds = [...new Set(listOfAdSetIds)];

    // parse adset data

    for (let adset_id of uniqueListOfAdSetIds) {
      const adset = {
        ...adsetBaseValues,
        dataSourceData: true,
        warnings: [],
        subWarnings: [],
        snackbarWarnings: [],
        documentType: 'facebookAds',
        type: 'facebook_ads_ad_set',
        source: 'facebook_ads'
      };

      adset._id = adset_id + '(' + facebookadscampaign._id + ')';
      adset['level_' + adset.level + '_container_id'] = adset._id;

      adset.cost = null;
      adset.impressions = null;
      adset.clicks = null;
      adset.action03ActionRoas = null;
      adset.cpmSparkline = [];
      adset.action01Count = null;
      adset.action01Value = null;
      adset.action01ActionRoas = null;
      adset.action02Count = null;
      adset.action02Value = null;
      adset.action02ActionRoas = null;
      adset.action03Count = null;
      adset.action03Value = null;

      adset.costToday = null;
      adset.impressionsToday = null;
      adset.clicksToday = null;
      adset.action03ActionRoasToday = null;
      adset.cpmSparklineToday = [];
      adset.action01CountToday = null;
      adset.action01ValueToday = null;
      adset.action01ActionRoasToday = null;
      adset.action02CountToday = null;
      adset.action02ValueToday = null;
      adset.action02ActionRoasToday = null;
      adset.action03CountToday = null;
      adset.action03ValueToday = null;

      adset.costYesterday = null;
      adset.impressionsYesterday = null;
      adset.clicksYesterday = null;
      adset.action03ActionRoasYesterday = null;
      adset.cpmSparklineYesterday = [];
      adset.action01CountYesterday = null;
      adset.action01ValueYesterday = null;
      adset.action01ActionRoasYesterday = null;
      adset.action02CountYesterday = null;
      adset.action02ValueYesterday = null;
      adset.action02ActionRoasYesterday = null;
      adset.action03CountYesterday = null;
      adset.action03ValueYesterday = null;

      adset.costSparkline = [];
      adset.impressionsSparkline = [];
      adset.clicksSparkline = [];
      adset.ctrSparkline = [];
      adset.action01CvrSparkline = [];
      adset.action02CvrSparkline = [];
      adset.action03CvrSparkline = [];
      adset.cpcSparkline = [];
      adset.action01CountSparkline = [];
      adset.action01CpaSparkline = [];
      adset.action01RoasSparkline = [];
      adset.action01CrrSparkline = [];
      adset.action02CountSparkline = [];
      adset.action02CpaSparkline = [];
      adset.action02RoasSparkline = [];
      adset.action02CrrSparkline = [];
      adset.action03CountSparkline = [];
      adset.action03CpaSparkline = [];
      adset.action03RoasSparkline = [];
      adset.action03CrrSparkline = [];
      adset.action01VpaSparkline = [];
      adset.action02VpaSparkline = [];
      adset.action03VpaSparkline = [];
      adset.action01ValueSparkline = [];
      adset.action02ValueSparkline = [];
      adset.action03ValueSparkline = [];

      adset.total_list_of_propagation_data = [];

      const current_adsets = [];

      for (let adset_object of listOfPropagationData) {
        if (adset_object.adset_id === adset_id) {
          // get values for each adset

          current_adsets.push(adset_object);
        }

        current_adsets.sort(function (a, b) {
          var x = parseInt(a.date_start);
          var y = parseInt(b.date_start);
          if (x < y) {
            return -1;
          }
          if (x > y) {
            return 1;
          }
          return 0;
        });
      }

      // get total metric value for adset

      // for (let adset_object of current_adsets) {
      //   adset.name = adset_object.adset_name;
      //   adset.path = adset.path + adset.name;
      //   // adset.total_cost = adset.total_cost + adset_object.cost;
      //   // adset.total_impressions = adset.total_impressions + adset_object.impressions;
      // }

      adset.name =
        current_adsets && current_adsets[0] && current_adsets[0].adset_name
          ? current_adsets[0].adset_name
          : '[Ad set]';
      adset.path = adset.path + adset.name;

      // create value array for sparkline that fills in the dates without value with 0.

      for (let date of range2) {
        let cost = null;
        let impressions = null;
        let clicks = null;
        let action01Count = null;
        let action01Value = null;

        let action02Count = null;
        let action02Value = null;

        let action03Count = null;
        let action03Value = null;

        for (let adset_object of current_adsets) {
          if (
            new Date(parseInt(adset_object.date_start))
              .toISOString()
              .split('T')[0] === date
          ) {
            // clicks = clicks + (adset_object && adset_object.outbound_clicks && adset_object.outbound_clicks[0] && adset_object.outbound_clicks[0].value ? adset_object.outbound_clicks[0].value : 0);
            clicks = clicks + adset_object.clicks;
            cost = cost + (adset_object.cost || null);
            impressions = impressions + adset_object.impressions;

            if (action01DataSourceIsFacebookAds) {
              action01Count =
                action01Count + (adset_object.action01Count || null);
              action01Value =
                action01Value + (adset_object.action01Value || null);
            }

            if (action02DataSourceIsFacebookAds) {
              action02Count =
                action02Count + (adset_object.action02Count || null);
              action02Value =
                action02Value + (adset_object.action02Value || null);
            }

            if (action02DataSourceIsFacebookAds) {
              action03Count =
                action03Count + (adset_object.action03Count || null);
              action03Value =
                action03Value + (adset_object.action03Value || null);
            }
          }
        }

        adset.total_list_of_propagation_data.push({
          // ...adset_object,
          date_start: new Date(date).getTime(),
          date_stop: new Date(date).getTime(),
          cost: cost,
          impressions: impressions,
          clicks,
          action01Type,
          action01Count: action01Count,
          action01Value: action01Value,

          action02Type,
          action02Count: action02Count,
          action02Value: action02Value,

          action03Type,
          action03Count: action03Count,
          action03Value: action03Value
        });

        adset.costSparkline.push(cost);
        adset.impressionsSparkline.push(impressions);
        adset.clicksSparkline.push(clicks);
        adset.ctrSparkline.push(
          clicks && impressions ? clicks / impressions : 0
        );
        adset.cpcSparkline.push(clicks && cost ? cost / clicks : 0);

        if (action01DataSourceIsFacebookAds) {
          adset.action01CvrSparkline.push(
            clicks && action01Count ? action01Count / clicks : 0
          );
          adset.action01Count = adset.action01Count + action01Count;
          adset.action01Value = adset.action01Value + action01Value;
          adset.action01CountSparkline.push(action01Count);
          adset.action01ValueSparkline.push(action01Value);
          adset.action01RoasSparkline.push(cost ? action01Value / cost : 0);
          adset.action01CrrSparkline.push(
            action01Value ? cost / action01Value : 0
          );
          adset.action01VpaSparkline.push(
            action01Count && action01Value && action01Value / action01Count
              ? action01Value / action01Count
              : 0
          );

          adset.action01CpaSparkline.push(
            action01Count ? cost / action01Count : 0
          );
        }

        if (action02DataSourceIsFacebookAds) {
          adset.action02CvrSparkline.push(
            clicks && action02Count ? action02Count / clicks : 0
          );
          adset.action02Count = adset.action02Count + action02Count;
          adset.action02Value = adset.action02Value + action02Value;
          adset.action02CountSparkline.push(action02Count);
          adset.action02ValueSparkline.push(action02Value);
          adset.action02RoasSparkline.push(cost ? action02Value / cost : 0);
          adset.action02CrrSparkline.push(
            action02Value ? cost / action02Value : 0
          );
          adset.action02VpaSparkline.push(
            action02Count && action02Value && action02Value / action02Count
              ? action02Value / action02Count
              : 0
          );

          adset.action02CpaSparkline.push(
            action02Count ? cost / action02Count : 0
          );
        }

        if (action03DataSourceIsFacebookAds) {
          adset.action03CvrSparkline.push(
            clicks && action03Count ? action03Count / clicks : 0
          );
          adset.action03Count = adset.action03Count + action03Count;
          adset.action03Value = adset.action03Value + action03Value;
          adset.action03CountSparkline.push(action03Count);
          adset.action03ValueSparkline.push(action03Value);
          adset.action03RoasSparkline.push(cost ? action03Value / cost : 0);
          adset.action03CrrSparkline.push(
            action03Value ? cost / action03Value : 0
          );
          adset.action03VpaSparkline.push(
            action03Count && action03Value && action03Value / action03Count
              ? action03Value / action03Count
              : 0
          );

          adset.action03CpaSparkline.push(
            action03Count ? cost / action03Count : 0
          );
        }

        adset.cost = adset.cost + cost;
        adset.impressions = adset.impressions + impressions;
        adset.clicks = adset.clicks + clicks;

        adset.cpmSparkline.push(impressions ? cost / (impressions / 1000) : 0);
      }

      // adset calc
      adset.ctr =
        adset.impressions && adset.clicks
          ? adset.clicks / adset.impressions
          : null;

      if (action01DataSourceIsFacebookAds) {
        adset.action01Cvr =
          adset.clicks && adset.action01Count
            ? adset.action01Count / adset.clicks
            : null;

        adset.action01Roas = adset.cost
          ? (adset.action01Value || 0) / adset.cost
          : null;

        adset.action01Crr = adset.action01Value
          ? (adset.cost || 0) / adset.action01Value
          : null;

        adset.action01Cpa =
          adset.cost && adset.action01Count
            ? adset.cost / adset.action01Count
            : null;
        adset.action01Vpa =
          adset.action01Value && adset.action01Count
            ? adset.action01Value / adset.action01Count
            : null;
      }

      if (action02DataSourceIsFacebookAds) {
        adset.action02Cvr =
          adset.clicks && adset.action02Count
            ? adset.action02Count / adset.clicks
            : null;

        adset.action02Roas = adset.cost
          ? (adset.action02Value || 0) / adset.cost
          : null;

        adset.action02Crr = adset.action02Value
          ? (adset.cost || 0) / adset.action02Value
          : null;

        adset.action02Cpa =
          adset.cost && adset.action02Count
            ? adset.cost / adset.action02Count
            : null;
        adset.action02Vpa =
          adset.action02Value && adset.action02Count
            ? adset.action02Value / adset.action02Count
            : null;
      }

      if (action03DataSourceIsFacebookAds) {
        adset.action03Cvr =
          adset.clicks && adset.action03Count
            ? adset.action03Count / adset.clicks
            : null;

        adset.action03Roas = adset.cost
          ? (adset.action03Value || 0) / adset.cost
          : null;

        adset.action03Crr = adset.action03Value
          ? (adset.cost || 0) / adset.action03Value
          : null;

        adset.action03Cpa =
          adset.cost && adset.action03Count
            ? adset.cost / adset.action03Count
            : null;
        adset.action03Vpa =
          adset.action03Value && adset.action03Count
            ? adset.action03Value / adset.action03Count
            : null;
      }

      adset.cpc = adset.clicks && adset.cost ? adset.cost / adset.clicks : null;

      adset.cpm = adset.impressions
        ? (adset.cost || 0) / (adset.impressions / 1000)
        : null;

      listOfAdsets.push(adset);
    }
  }

  campaign.total_list_of_propagation_data = listOfPropagationData;

  return {
    prepared_campaign: campaign,
    prepared_listOfAdsets: listOfAdsets
  };
};

export default parse_fb_data;
