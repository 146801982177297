import React, { useContext, useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CardIcon from './CardIcon';

import SimpleTooltip from '../../../../item-components/body/ganttItem/SimpleTooltip';

import { FilterContext } from '../../../../../../context/FilterContext';

import { DialogContext } from '../../../../../../context/DialogContext';

import CardAvatar from './CardAvatar';

import WarningTooltip from '../../WarningTooltip';

import InfoModal from '../../../info/InfoModal';
import SubTasks from './SubTasks';

import CommentIcon from '@mui/icons-material/Comment';

import Comments from './Comments';

import PersonIcon from '@mui/icons-material/Person';

import { useInView } from 'react-intersection-observer';

import CheckboxItem from '../../../../item-components/body/CheckboxItem';

import WarningTooltipContent from '../../../WarningTooltipContent';

import StatusMenu from '../../../../item-components/body/StatusMenu';

import { InterfaceContext } from '../../../../../../context/InterfaceContext';

import Hyperlink from '../../../../item-components/body/Hyperlink';

import { styled, useTheme } from '@mui/material/styles';

import rgba2hex from '../../../../roitable_functions/rgba2hex';

// import {
//   Sparklines,
//   SparklinesBars,
//   SparklinesLine,
//   SparklinesCurve,
//   SparklinesNormalBand,
//   SparklinesReferenceLine,
//   SparklinesSpots
// } from 'react-sparklines';

import { SparkLineChart } from '@mui/x-charts/SparkLineChart';

const differenceInDays = (start, stop) => {
  // var date1 = new Date(start);
  // var date2 = new Date(stop);
  // var Difference_In_Time = date2.getTime() - date1.getTime();
  // var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date(start);
  const secondDate = new Date(stop);

  const diffDays = Math.ceil((secondDate - firstDate) / oneDay);

  return diffDays;
};

// const differenceInDaysFloor = (start, stop) => {
//   // var date1 = new Date(start);
//   // var date2 = new Date(stop);
//   // var Difference_In_Time = date2.getTime() - date1.getTime();
//   // var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

//   const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
//   const firstDate = new Date(start);
//   const secondDate = new Date(stop);

//   const diffDays = Math.floor((secondDate - firstDate) / oneDay);

//   return diffDays;
// };
// const numberOfDaysLeft = (startDate, endDate) => {
//   const numDaysLeft = differenceInDays(startDate, endDate) + 1;
//   return numDaysLeft > 0 ? numDaysLeft : 0;
// };

function Card({ item, rawArrayOfData, stylesStoredInRedux, hideSelected }) {
  const theme = useTheme();

  const redWarningColor = theme.palette.mode === 'dark' ? '#ff6b6b' : 'red';
  const blueWarningColor = theme.palette.mode === 'dark' ? '#9898ff' : 'blue';
  const { selectedDateStart, selectedDateStop } = useContext(InterfaceContext);

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });
  const { filters, setFilters, filterBySelection, setFilterBySelection } =
    useContext(FilterContext);

  const {
    infoModalDocumentOpen,
    setInfoModalDocumentOpen,
    setItemId,
    setGraphDialogOpen,
    setItemKey
  } = useContext(DialogContext);

  // ####################### drag and drop ##########################

  const dragStart = (e) => {
    const target = e.target;

    e.dataTransfer.setData(
      'droppedItem',
      JSON.stringify({
        id: target.id, //item._id,
        // id: item._id,
        status: item.status,
        accountId: item.accountId,
        type: item.type,
        level: item.level,
        documentType: item.documentType
        // level_1_container_id: item.level_1_container_id,
        // level_2_container_id: item.level_2_container_id,
        // level_3_container_id: item.level_3_container_id,
        // level_4_container_id: item.level_4_container_id,
        // level_5_container_id: item.level_5_container_id,
        // level_6_container_id: item.level_6_container_id,
        // level_7_container_id: item.level_7_container_id,
        // level_8_container_id: item.level_8_container_id,
        // level_9_container_id: item.level_9_container_id,
        // level_10_container_id: item.level_10_container_id
      })
    );

    setTimeout(() => {
      target.style.display = 'none';
    }, 0);
  };

  const dragEnd = (e, value) => {
    console.log('dragEnd');
    const target = e.target;

    setTimeout(() => {
      target.style.display = 'flex';
    }, 0);
  };

  // ########## end of drag and drop #############################

  const onClickHandler = (obj) => {
    // console.log('Card>obj: ', obj);
    // console.log('filters: ', filters);
    // setFilterBySelection([obj._id]);
    // if (!filters.includes('filter_by_selection')) {
    //   setFilters([...filters, 'filter_by_selection']);
    // }
    setFilters([
      ...filters,
      {
        type: 'filter_by_selection',
        category: 'array',
        field: '_id',
        operator: 'EQUALS',
        conjunction: 'OR',
        // array: [String],
        // string: { type: String },
        values: [obj._id],
        isCaseSensitive: true,
        isIncludingAccounts: true,
        isIncludingFolders: true
      }
    ]);
  };

  const [showStartDateTips, setShowStartDateTips] = useState(false);
  const [startDateTipsString, setStartDateTipsString] = useState(false);
  const [startDateTipsColor, setStartDateTipsColor] =
    useState('rgb(255 165 0)');

  const [showEndDateTips, setShowEndDateTips] = useState(false);
  const [endDateTipsString, setEndDateTipsString] = useState(false);
  const [endDateTipsColor, setEndDateTipsColor] = useState('rgb(255 165 0)');

  useEffect(() => {
    // let showDateTips = false;
    // let dateTipsString = '';

    const today = new Date();
    const todayAsLocaleDateString = today.toLocaleDateString();

    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const tomorrowAsLocaleDateString = tomorrow.toLocaleDateString();

    const yesterdayAsLocaleDateString = yesterday.toLocaleDateString();

    const startDateAsLocaleDateString =
      item?.startDate && new Date(item.startDate).toLocaleDateString();

    const endDateAsLocaleDateString =
      item?.endDate && new Date(item.endDate).toLocaleDateString();

    // console.log('kanban>Card>today: ', today);
    // console.log('kanban>Card>startDate: ', startDate);

    const daysTillStart = differenceInDays(today, item.startDate);

    const daysTillEnd = differenceInDays(today, item.endDate);
    // console.log('daysTillStart: ', daysTillStart);

    if (['DRAFT', 'PLANNED', 'READY', 'ACTIVE'].includes(item.status)) {
      if (
        item.endDate &&
        endDateAsLocaleDateString === todayAsLocaleDateString
      ) {
        setShowEndDateTips(true);
        setEndDateTipsString('Ending today.');
      } else if (
        item.endDate &&
        endDateAsLocaleDateString === tomorrowAsLocaleDateString
      ) {
        setShowEndDateTips(true);
        setEndDateTipsString('Ending tomorrow.');
      } else if (item.endDate && daysTillEnd >= 2 && daysTillEnd <= 4) {
        setShowEndDateTips(true);
        setEndDateTipsString(`Ending in ${daysTillEnd} days.`);
      } else if (
        item.endDate &&
        endDateAsLocaleDateString === yesterdayAsLocaleDateString
      ) {
        setShowEndDateTips(true);
        setEndDateTipsString(`Should have ended yesterday.`);
        setEndDateTipsColor(redWarningColor);
      } else if (item.endDate && daysTillEnd < 1) {
        setShowEndDateTips(true);
        setEndDateTipsString(`Should have ended ${daysTillEnd * -1} days ago.`);
        setEndDateTipsColor(redWarningColor);
      } else {
        if (item.endDate && showEndDateTips && endDateTipsString) {
          setShowEndDateTips(false);
          setEndDateTipsString('');
        }
      }
    }

    if (['DRAFT', 'PLANNED', 'READY'].includes(item.status)) {
      if (startDateAsLocaleDateString === todayAsLocaleDateString) {
        setShowStartDateTips(true);
        setStartDateTipsString('Starting today.');
      } else if (
        item.startDate &&
        startDateAsLocaleDateString &&
        startDateAsLocaleDateString === tomorrowAsLocaleDateString
      ) {
        setShowStartDateTips(true);
        setStartDateTipsString('Starting tomorrow.');
      } else if (item.startDate && daysTillStart >= 2 && daysTillStart <= 4) {
        setShowStartDateTips(true);
        setStartDateTipsString(`Starting in ${daysTillStart} days.`);
      } else if (
        item.startDate &&
        startDateAsLocaleDateString === yesterdayAsLocaleDateString
      ) {
        setShowStartDateTips(true);
        setStartDateTipsString(`Should have started yesterday.`);
        setStartDateTipsColor(redWarningColor);
      } else if (item.startDate && daysTillStart < 1) {
        setShowStartDateTips(true);
        setStartDateTipsString(
          `Should have started ${daysTillStart * -1} days ago.`
        );
        setStartDateTipsColor(redWarningColor);
      } else {
        if (item.startDate && showStartDateTips && startDateTipsString) {
          setShowStartDateTips(false);
          setStartDateTipsString('');
        }
      }
    }

    // switch (item.status) {
    //   case 'ACTIVE':
    //   case 'PAUSED':
    //     if (endDateAsLocaleDateString === todayAsLocaleDateString) {
    //       setShowDateTips(true);
    //       setDateTipsString('Ending today');
    //     } else if (endDateAsLocaleDateString === tomorrowAsLocaleDateString) {
    //       setShowDateTips(true);
    //       setDateTipsString('Ending tomorrow');
    //     } else if (daysTillEnd >= 2 && daysTillEnd <= 4) {
    //       setShowDateTips(true);
    //       setDateTipsString(`Ending in ${daysTillEnd} days`);
    //     } else if (endDateAsLocaleDateString === yesterdayAsLocaleDateString) {
    //       setShowDateTips(true);
    //       setDateTipsString(`Should have ended yesterday`);
    //       setDateTipsColor(redWarningColor);
    //     } else if (daysTillEnd < 1) {
    //       setShowDateTips(true);
    //       setDateTipsString(`Should have ended ${daysTillEnd * -1} days ago`);
    //       setDateTipsColor(redWarningColor);
    //     } else {
    //       if (showDateTips && dateTipsString) {
    //         setShowDateTips(false);
    //         setDateTipsString('');
    //       }
    //     }

    //     break;

    //   case 'COMPLETED':
    //   case 'CANCELED':
    //     break;
    //   default:
    //     if (startDateAsLocaleDateString === todayAsLocaleDateString) {
    //       setShowDateTips(true);
    //       setDateTipsString('Starting today');
    //     } else if (
    //       startDateAsLocaleDateString &&
    //       startDateAsLocaleDateString === tomorrowAsLocaleDateString
    //     ) {
    //       setShowDateTips(true);
    //       setDateTipsString('Starting tomorrow');
    //     } else if (daysTillStart >= 2 && daysTillStart <= 4) {
    //       setShowDateTips(true);
    //       setDateTipsString(`Starting in ${daysTillStart} days`);
    //     } else if (
    //       startDateAsLocaleDateString === yesterdayAsLocaleDateString
    //     ) {
    //       setShowDateTips(true);
    //       setDateTipsString(`Should have started yesterday`);
    //       setDateTipsColor(redWarningColor);
    //     } else if (daysTillStart < 1) {
    //       setShowDateTips(true);
    //       setDateTipsString(
    //         `Should have started ${daysTillStart * -1} days ago`
    //       );
    //       setDateTipsColor(redWarningColor);
    //     } else {
    //       if (showDateTips && dateTipsString) {
    //         setShowDateTips(false);
    //         setDateTipsString('');
    //       }
    //     }
    // }
  }, [item.startDate, item.endDate, item.status]);

  // const [kanbanCardOpen, setKanbanCardOpen] = useState(false);

  const [costSparkline, setCostSparkline] = useState([]);
  useEffect(() => {
    let sparkline = [];
    if (
      item?.cost &&
      item?.costSparkline?.some((element) => Boolean(element))
    ) {
      sparkline = [...item?.costSparkline];
      if (!window?.location?.href?.includes('fast-table')) {
        var date1 = new Date(selectedDateStart);
        var date2 = new Date();

        const daysTillToday = differenceInDays(date1, date2);
        console.log('daysTillToday: ', daysTillToday);
        let start = daysTillToday >= 14 ? daysTillToday - 14 : 0;
        if (daysTillToday > 0 && sparkline.length > 14) {
          sparkline = sparkline.slice(start, daysTillToday);
        }
        // console.log('sparkline: ', sparkline);
      }

      setCostSparkline(sparkline);
    } else {
      setCostSparkline([]);
    }
  }, [item?.costSparkline]);

  const onClickSparklineHandler = () => {
    setItemKey('costSparkline');
    setItemId(item._id);
    setGraphDialogOpen(true);
  };

  // let daysSinceStatusUpdate = null;
  // if (item.statusUpdatedAt) {
  //   const statusUpdatedDate = new Date(+item.statusUpdatedAt);
  //   daysSinceStatusUpdate = differenceInDaysFloor(
  //     statusUpdatedDate,
  //     new Date()
  //   );
  //   console.log('statusUpdatedDate: ', statusUpdatedDate);
  // }

  // ################### colors #####################

  const accountColorAsRGBA = item.accountColor
    ? item.accountColor
    : theme?.palette?.mode === 'dark'
    ? 'rgba(255,255,255,0.3)'
    : 'rgba(200,200,255,1)';

  const accountColorAsHex = '#' + rgba2hex(accountColorAsRGBA);

  const transparentAccountColorAsHex =
    accountColorAsHex.slice(0, 7) +
    (theme?.palette?.mode === 'dark' ? '05' : '10');
  // const transparentAccountColorAsHexNexusDocumentIcon =
  //   accountColorAsHex.slice(0, 7) + '10';
  const transparentAccountColorAsHexNexusDocumentIconBackground =
    theme?.palette?.mode === 'dark'
      ? 'rgba(255,255,255,0.3)'
      : accountColorAsHex.slice(0, 7) + '20';

  // ###########################################################

  return (
    <div
      ref={ref}
      id={item._id}
      onDragStart={dragStart}
      onDragEnd={dragEnd}
      draggable
      className="kanbanCard cursorGrab"
      style={{
        // position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        // minHeight: '80px',
        // display: 'grid',
        // minWidth: '90.2vw',
        // minWidth: '1485px',
        minHeight: '60px',
        margin: '0px 0px 10px 10px',
        // padding: '10px',
        // width: '100%',
        // backgroundColor:
        //   stylesStoredInRedux.styleName === 'light'
        //     ? 'rgba(255,255,255,.9)'
        //     : 'rgba(255,255,255,0.12)',
        backgroundColor:
          theme?.palette?.mode !== 'dark' && 'rgba(255,255,255,0.5)',
        // backgroundImage:
        //   inView &&
        //   (theme?.palette?.mode === 'dark'
        //     ? 'linear-gradient(to top, rgb(70, 70, 81), rgb(85, 85, 100))'
        //     : `linear-gradient(to top left, ${transparentAccountColorAsHex}, ${'rgba(255, 255, 255, 0.9)'})`),
        // border: item.highestWarningLevel === 5 && '2px solid red',
        borderRadius: '5px',
        boxShadow:
          '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
        // backdropFilter: inView && 'blur(50px)'
      }}
    >
      {!inView ? (
        <div
          style={{
            minHeight: 45 + 12 * ((item?.warnings?.length || 1) - 1) + 'px'
          }}
        ></div>
      ) : (
        <div
          style={{
            border: item.highestWarningLevel === 5 && '2px solid red',
            borderRadius: '5px',
            minHeight: '60px',
            // position: 'relative',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            padding: '10px',
            backdropFilter: inView && 'blur(50px)',
            backgroundImage:
              inView &&
              (theme?.palette?.mode === 'dark'
                ? 'linear-gradient(to top, rgba(70, 70, 81,0.5), rgba(85, 85, 100,0.5))'
                : `linear-gradient(to top left, ${transparentAccountColorAsHex}, ${'rgba(255, 255, 255, 0.9)'})`)
            // minHeight: 45 + 12 * ((item?.warnings?.length || 1) - 1) + 'px'
          }}
        >
          <CheckboxItem item={item} maxHeight="26px" />
          {!hideSelected.includes('accountName') && (
            <div
              style={{
                position: 'absolute',
                left: '0px',
                top: '0px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                // width: '150px',
                minWidth: '150px'
                // maxWidth: '150px',
                // padding: '0px 10px'
              }}
              // style={{
              //   display: 'flex',
              //   flexDirection: 'row',
              //   justifyContent: 'start',
              //   width: '150px',
              //   minWidth: '150px',
              //   maxWidth: '150px',
              //   padding: '0px 10px'
              // }}
            >
              <div
                style={{
                  fontSize: '10px',
                  backgroundColor: item.accountColor,
                  color: 'white',
                  padding: '1px 6px',
                  // margin: '2px 0px 10px 0px',
                  borderRadius: '5px 0px',
                  width: 'fit-content',
                  // maxWidth: '150px',
                  textOverflow: 'ellipsis'
                  // fontWeight: 'bold'
                }}
              >
                <span>{item.accountName}</span>
              </div>
            </div>
          )}

          {/* {!hideSelected.includes('accountName') && (
            <div
              style={{
                fontSize: '11px',
                // backgroundColor: item.accountColor,
                color: 'white',
                padding: '3px 7px',
                margin: '2px 0px 10px 0px',
                borderRadius: '5px',
                width: 'fit-content',
                maxWidth: '200px',
                textOverflow: 'ellipsis'
                // fontWeight: 'bold'
              }}
            >
              <CheckboxItem item={item} />
            </div>
          )} */}
          <div
            style={{
              wordWrap: 'break-word',
              lineHeight: 'normal',
              width: '160px',
              display: 'flex',
              flexDirection: 'row',
              marginBottom: '2px',
              alignItems: 'center',
              justifyContent: 'start',
              padding: '0px 15px'

              // padding: '0px 0px 5px 0px'
            }}
          >
            <div style={{ padding: '0px 10px' }}>
              <StatusMenu
                // currentManagerAccountId={currentManagerAccount._id}
                item={item}
                text={false}
              />
            </div>
            {/* <div style={{ width: '20px' }}>
              {item?.warnings.length > 0 && (
                <div style={{ display: 'inline-block', paddingRight: '3px' }}>
                  <WarningTooltip
                    warnings={item.warnings}
                    subWarnings={item.subWarnings}
                    stylesStoredInRedux={stylesStoredInRedux}
                  />
                </div>
              )}
            </div> */}

            <div style={{ display: 'inline-block' }}>
              <SimpleTooltip item={item} renderChildren={true}>
                <CardIcon itemType={item.type} />
              </SimpleTooltip>
            </div>
            <div
              style={{
                width: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '1px'
              }}
            >
              <Hyperlink item={item} fontSize="13px" />
            </div>
            <div
              style={{
                width: '30px',
                padding: '0px 3px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              {item?.comments > 0 && (
                <Comments item={item} />
                // <div>
                //   <CommentIcon style={{ fontSize: '9px' }} />{' '}
                //   <span style={{ fontSize: '12px' }}>{item.comments}</span>
                // </div>
              )}
            </div>
            <div style={{ padding: '5px', width: '20px', fontSize: '12px' }}>
              {Boolean(item?.daysSinceStatusUpdate) &&
                ['BUDDYCHECK', 'DOUBLECHECK'].includes(item?.status) && (
                  <div
                    style={{
                      display: 'inline-block',
                      marginRight: '5px',
                      color: item?.daysSinceStatusUpdate > 1 && redWarningColor
                    }}
                  >
                    {item?.daysSinceStatusUpdate}
                    <span>d</span>
                  </div>
                )}
            </div>
            {/* <div style={{ width: '20px' }}>
              {item.personal && (
                <div style={{ display: 'inline-block', marginRight: '5px' }}>
                  <PersonIcon
                    style={{
                      position: 'relative',
                      top: '3px',
                      height: '15px',
                      width: '14px'
                    }}
                  />
                </div>
              )}
            </div> */}
          </div>
          <div
            style={{
              wordWrap: 'break-word',
              lineHeight: 'normal',
              // width: '150px',
              width: '20%',
              minWidth: '150px',
              // maxWidth: '150px',
              padding: '0px 15px',
              minWidth: '280px'
              // maxWidth: '150px'
              // padding: '0px 0px 5px 0px'
            }}
          >
            <span
              onClick={() => {
                setItemId(item._id);
                setInfoModalDocumentOpen(true);
              }}
              style={{ fontSize: '11px', fontWeight: 'bold' }}
            >
              {item.name}
            </span>
          </div>
          {!hideSelected.includes('path') && (
            <div
              style={{
                fontSize: '10px',
                padding: '5px 0px',
                wordWrap: 'break-word',
                width: '20%',
                // width: '200px',
                minWidth: '200px',
                // maxWidth: '200px',
                padding: '0px 10px'
              }}
            >
              {/* <span>{item.path}</span> */}
              <span>
                <span> / </span>
                {(item?.interactivePath || []).map((obj) => {
                  return (
                    <>
                      <span
                        className="hover-grey"
                        style={{
                          cursor: 'pointer'
                          // textDecoration: 'underline'
                        }}
                        onClick={() => onClickHandler(obj)}
                      >
                        {obj.name}
                      </span>
                      <span> / </span>
                    </>
                  );
                })}
              </span>
            </div>
          )}
          {/* <div style={{ width: '150px', maxWidth: '150px', minWidth: '150px' }}>
            {item.showSubTasksOnCard && (
              <SubTasks
                rawArrayOfData={rawArrayOfData}
                item={item}
                stylesStoredInRedux={stylesStoredInRedux}
              />
            )}
          </div> */}
          <div
            style={{
              width: '150px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            // onClick={onClickSparklineHandler}
            // style={{
            //   fontSize: '10px',
            //   // padding: '5px 0px 0px 0px',
            //   padding: '0px 10px',
            //   alignSelf: 'center',
            //   width: '180px'
            // }}
          >
            {Boolean(costSparkline?.length) && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  minWidth: '140px'
                }}
                onClick={onClickSparklineHandler}
              >
                <SparkLineChart
                  area={true}
                  // plotType="bar"
                  // colors={['white', 'grey']}
                  colors={[
                    theme?.palette?.mode === 'dark'
                      ? 'rgb(180,180,180)'
                      : 'rgb(74, 144, 226)' //'#515D99'
                  ]}
                  data={(costSparkline || []).map((value) => Math.round(value))}
                  height={45}
                  // width={100}
                  showTooltip
                  showHighlight
                  // xAxis={{
                  //   scaleType: 'band',
                  //   data: [
                  //     new Date(2016, 0, 1),
                  //     new Date(2017, 0, 1),
                  //     new Date(2018, 0, 1),
                  //     new Date(2019, 0, 1),
                  //     new Date(2020, 0, 1),
                  //     new Date(2021, 0, 1),
                  //     new Date(2022, 0, 1),
                  //     new Date(2023, 0, 1)
                  //   ],
                  //   valueFormatter: (value) => Math.round(value)
                  // }}
                />
                {/* <Sparklines
                  data={costSparkline}
                  style={{ height: '40px', width: '110px' }}
                >
                  <SparklinesLine
                    // color="#00adb5"
                    color={
                      theme?.palette?.mode === 'dark' ? 'white' : '#1784a6'
                    }
                    // color="#b24393"
                    // color="#4bacfd"
                  />
                  <SparklinesNormalBand
                    style={{ fill: '#2991c8', fillOpacity: 0.1 }}
                  />
                  <SparklinesReferenceLine
                    type="mean"
                    color="#ffddcc"
                    style={{
                      stroke: 'grey',
                      strokeOpacity: 0.75,
                      strokeDasharray: '2, 2'
                    }}
                  />
                </Sparklines> */}
              </div>
            )}
          </div>

          <div
            style={{
              fontSize: '10px',
              // padding: '5px 0px 0px 0px',
              padding: '0px 10px',
              alignSelf: 'center',
              width: '180px'
            }}
          >
            <div>
              {item.startDate} - {item.endDate}
            </div>
            {showStartDateTips && (
              <div style={{ color: startDateTipsColor }}>
                {startDateTipsString}
              </div>
            )}
            {showEndDateTips && (
              <div style={{ color: endDateTipsColor }}>{endDateTipsString}</div>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
              alignItems: 'center',
              // alignSelf: 'end',
              width: '120px',
              padding: '10px'
            }}
          >
            <CardAvatar item={item} />
          </div>

          <div
            style={{
              width: '290px',
              minWidth: '290px',
              maxWidth: '290px',
              padding: '0px 0px',
              marginLeft: '10px',
              background:
                item?.warnings?.length &&
                theme?.palette?.mode === 'dark' &&
                'rgba(0,0,20,0.2)',
              borderRadius: '5px'
            }}
          >
            <WarningTooltipContent
              warnings={item.warnings || []}
              stylesStoredInRedux={stylesStoredInRedux}
              item={item}
              positionRight={true}
            />
          </div>
        </div>
      )}

      {/* {infoModalDocumentIdOpen === item._id && (
        <InfoModal
          item={item}
          open={infoModalDocumentIdOpen === item._id}
          setOpen={setInfoModalDocumentIdOpen}
          rawArrayOfData={rawArrayOfData}
        />
      )} */}
    </div>
  );
}

// export default Card;

Card.propTypes = {
  // getPosts: PropTypes.func.isRequired,
  // post: PropTypes.object.isRequired
  // currentManagerAccount: PropTypes.object.isRequired
  // style: PropTypes.object.isRequired,
  // user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  // currentManagerAccount: state.managerAccount.currentManagerAccount,
  // currentAccountIds: state.account.currentAccountIds,
  stylesStoredInRedux: state.style
  // user: state.auth.user
});

export default connect(mapStateToProps, {})(React.memo(Card));
