import React, { useState, useEffect, useContext, useRef } from 'react';
import Tooltip from '@mui/material/Tooltip';

import WidgetDescriptionMenu from './WidgetDescriptionMenu';

import { ReportBuilderContext } from '../../../../../context/ReportBuilderContext';

import { useTheme } from '@mui/material';

import AcUnitIcon from '@mui/icons-material/AcUnit';
import FilterListIcon from '@mui/icons-material/FilterList';

import AxisMenu from './axis-menu/AxisMenu';

import { InterfaceContext } from '../../../../../context/InterfaceContext';

function WidgetWrapper({
  widget,
  // displayMode,
  updateWidget,
  setMouseDownWidgetId,
  widgetIndex,
  setMouseDownWidgetIndex,
  // editMode,
  dropOnWidget,
  dragOver,
  dragStart,
  dragEnd,
  isDraggingWidget,
  setIsDraggingWidget,
  onMouseDownHandler,
  onMouseUpHandler,
  children
  // widgetType
}) {
  const theme = useTheme();

  const {
    setContextMenuAnchorPosition,
    setWidgetIdThatTriggeredContextMenu,
    setWidgetElementThatTriggeredContextMenu,
    selectedWidgetIds,
    setSelectedWidgetIds,
    isExportMode,
    setIsExportMode,
    hasMouseMoved,
    setHasMouseMoved
  } = useContext(ReportBuilderContext);

  const { isDashboardUserMode } = useContext(InterfaceContext);

  const ref = useRef();

  const [isDraggingThisWidget, setIsDraggingThisWidget] = useState(false);
  const [edit, toggleEdit] = useState(false);
  const [isWidgetDescriptionMenuOpen, setIsWidgetDescriptionMenuOpen] =
    useState(false);

  const [textInput, setTextInput] = useState('');

  const [valueIsEdited, setValueIsEdited] = useState(false);

  const [originalWidgetCopy, setOriginalWidgetCopy] = useState({ ...widget });

  const [hasMouseMovedLocal, setHasMouseMovedLocal] = useState(false);
  useEffect(() => {
    setHasMouseMovedLocal(hasMouseMoved);
  }, [hasMouseMoved]);

  useEffect(() => {
    if (textInput) {
      setValueIsEdited(true);
    }
    setTextInput(widget?.value);
  }, [widget?.value]);
  const onChangeHandler = (e) => {
    setTextInput(e.target.value);
  };

  useEffect(() => {
    if (ref.current) {
      switch (true) {
        case widget?.type === 'COMBO_CHART_WIDGET':
          // console.log('WidgetWrapper>ref: ', ref);

          const domElement = document.querySelector(`#widget-${widget?.id}`);

          if (!domElement) {
            return;
          }

          const muiBarElements =
            domElement.querySelectorAll('MuiBarElement-root');

          // for (let muiBarElement of muiBarElements) {
          //   muiBarElement?.addEventListener('contextmenu', (e) => {
          //     e.preventDefault();
          //     e.stopPropagation();

          //     setWidgetIdThatTriggeredContextMenu(widget?.id);

          //     switch (true) {

          //       default:
          //         setWidgetElementThatTriggeredContextMenu('muiBar');
          //     }
          //     setContextMenuAnchorPosition({ left: e.clientX, top: e.clientY });
          //     console.log('debug71>right click test');
          //   });
          // }

          for (let axis of ['left', 'right']) {
            const axisElement = domElement.querySelector(
              `.MuiChartsAxis-root.MuiChartsAxis-directionY.MuiChartsAxis-${axis}`
            );
            // leftAxisElement.style.background = 'red !important';
            // console.log('debug71>axisElement: ', axisElement);
            axisElement?.addEventListener('contextmenu', (e) => {
              e.preventDefault();
              e.stopPropagation();

              setWidgetIdThatTriggeredContextMenu(widget?.id);

              switch (true) {
                // case e?.target?.className === 'widget-title':
                //   setWidgetElementThatTriggeredContextMenu(String(e?.target?.className));
                //   break;

                //   // case ['COMBO_CHART_WIDGET'].includes(widget?.type) &&
                //   //   String(e?.target?.nodeName) === 'tspan':
                //   //   setWidgetElementThatTriggeredContextMenu(String(e?.target?.nodeName));

                //   break;
                // case ['COMBO_CHART_WIDGET', 'PIE_CHART_WIDGET'].includes(widget?.type):
                //   setWidgetElementThatTriggeredContextMenu(
                //     String(e?.target?.className?.baseVal)
                //   );
                //   break;

                default:
                  setWidgetElementThatTriggeredContextMenu(axis + 'Axis');
              }
              setContextMenuAnchorPosition({ left: e.clientX, top: e.clientY });
              // console.log('debug71>right click test');
            });
          }

          for (let axis of ['bottom']) {
            const axisElement = domElement.querySelector(
              `.MuiChartsAxis-root.MuiChartsAxis-directionX.MuiChartsAxis-${axis}`
            );
            // leftAxisElement.style.background = 'red !important';
            // console.log('debug71>axisElement: ', axisElement);
            axisElement?.addEventListener('contextmenu', (e) => {
              e.preventDefault();
              e.stopPropagation();

              setWidgetIdThatTriggeredContextMenu(widget?.id);

              switch (true) {
                // case e?.target?.className === 'widget-title':
                //   setWidgetElementThatTriggeredContextMenu(String(e?.target?.className));
                //   break;

                //   // case ['COMBO_CHART_WIDGET'].includes(widget?.type) &&
                //   //   String(e?.target?.nodeName) === 'tspan':
                //   //   setWidgetElementThatTriggeredContextMenu(String(e?.target?.nodeName));

                //   break;
                // case ['COMBO_CHART_WIDGET', 'PIE_CHART_WIDGET'].includes(widget?.type):
                //   setWidgetElementThatTriggeredContextMenu(
                //     String(e?.target?.className?.baseVal)
                //   );
                //   break;

                default:
                  setWidgetElementThatTriggeredContextMenu(axis + 'Axis');
              }
              setContextMenuAnchorPosition({ left: e.clientX, top: e.clientY });
              // console.log('debug71>right click test');
            });
          }

        // const domElement = document.querySelector(`#${'domElement'}`);
        // console.log('debug71>domElement: ', domElement);
      }
    }

    // #\30 f405576-4bc4-4fa9-8996-f27709355b4a > div:nth-child(1) > div:nth-child(1) > div > div > svg > g.MuiChartsAxis-root.MuiChartsAxis-directionY.MuiChartsAxis-left.css-1fordo3-MuiChartsAxis-root-MuiChartsYAxis-root
  }, [ref.current, widget?.metrics]);

  const onContextHandler = (e, widget) => {
    // console.log('onContextHandler>e: ', e);
    e.stopPropagation();
    e.preventDefault();
    // console.log('widget?.id: ', widget?.id);
    setWidgetIdThatTriggeredContextMenu(widget?.id);

    switch (true) {
      case e?.target?.className === 'widget-title':
        setWidgetElementThatTriggeredContextMenu(String(e?.target?.className));
        break;

      case String(e?.target?.className)?.includes('widget-surface'):
        setWidgetElementThatTriggeredContextMenu(String(e?.target?.className));
        break;

      case String(e?.target?.className)?.includes('widget-value'):
        setWidgetElementThatTriggeredContextMenu(String(e?.target?.className));
        break;

        // case ['COMBO_CHART_WIDGET'].includes(widget?.type) &&
        //   String(e?.target?.nodeName) === 'tspan':
        //   setWidgetElementThatTriggeredContextMenu(String(e?.target?.nodeName));

        break;
      case ['COMBO_CHART_WIDGET', 'PIE_CHART_WIDGET'].includes(widget?.type):
        setWidgetElementThatTriggeredContextMenu(
          String(e?.target?.className?.baseVal)
        );
        break;

      default:
        setWidgetElementThatTriggeredContextMenu(widget?.type);
    }
    setContextMenuAnchorPosition({ left: e.clientX, top: e.clientY });
  };

  // const onClickHandler = (e) => {
  //   var isRightMB;
  //   var isLeftMB;
  //   e = e || window.event;

  //   if ('which' in e) {
  //     // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
  //     isRightMB = e.which == 3;
  //     isLeftMB = e.which == 1;
  //   } else if ('button' in e) {
  //     // IE, Opera
  //     isRightMB = e.button == 2;
  //     isLeftMB = e.button == 1;
  //   }
  //   console.log('isRightMB: ', isRightMB);
  // };

  const onSaveHandler = () => {
    updateWidget(
      { ...widget, value: textInput || originalWidgetCopy.value },
      widgetIndex
    );
    // setTextInput('');

    if (!textInput) {
      setValueIsEdited(false);
    }
    toggleEdit(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      event.stopPropagation();
      setTextInput(widget?.value);
      toggleEdit(false);
      return;
    }

    if (event.key === 'Enter') {
      console.log('do validate');
      updateWidget(
        { ...widget, value: textInput || originalWidgetCopy.value },
        widgetIndex
      );
      // setTextInput('');

      if (!textInput) {
        setValueIsEdited(false);
      }

      toggleEdit(false);
      return;
    }
  };

  // const numUpper = (textInput?.match(/[A-Z]/g) || []).length;
  // const numLarge = (textInput?.match(/[MmWw]/g) || []).length;
  // const theRest = textInput?.length - numUpper;
  // console.log('numUpper: ', numUpper);
  let cursor = 'pointer';

  // switch (true) {
  //   case isDraggingWidget:
  //     cursor = 'grabbing';
  //     break;
  //   // case !isDraggingWidget:
  //   //   cursor = 'grab';
  //   //   break;
  //   case !editMode && widget?.type === 'TEXT':
  //     cursor = 'text';
  //     break;
  //   case editMode && widget?.type === 'TEXT':
  //     cursor = 'grab';
  //     break;
  //   case editMode:
  //     cursor = 'grab';
  //     break;
  //   default:
  //     cursor = 'not-allowed';
  // }

  const styleObject = {};
  for (let styleKey of [
    'height',
    'width',
    'top',
    'left'
    // 'boxShadow'
    // 'opacity'
  ]) {
    if (widget[styleKey]) {
      styleObject[styleKey] = widget[styleKey];
    }
  }

  // if (
  //   ['MOCKUP_WIDGET', 'IMAGE_WIDGET', 'RECTANGLE_SHAPE_WIDGET'].includes(
  //     widget?.type
  //   )
  // ) {
  //   styleObject.opacity = widget?.opacity;
  // }

  if (isExportMode) {
    delete styleObject.boxShadow;
    if (
      widget?.isShowingCard &&
      widget?.isCardShadowOn &&
      !['MOCKUP_WIDGET', 'IMAGE_WIDGET', 'DATE_PICKER_WIDGET'].includes(
        widget?.type
      )
    ) {
      styleObject.border = '1px solid lightgrey';
      // styleObject.opacity = widget?.opacity;
    }
  }

  let outerBorderOpacity = Math.round(
    widget?.outerBorderOpacity * 255
  ).toString(16);
  if (outerBorderOpacity.length === 1) {
    outerBorderOpacity = '0' + outerBorderOpacity;
  }

  let outerBorderColor = widget?.outerBorderColor + outerBorderOpacity;
  let border = 'none';
  switch (true) {
    case widget?.isShowingCard &&
      ![
        // 'IMAGE_WIDGET',
        'MOCKUP_WIDGET',
        'DATE_PICKER_WIDGET',
        'RECTANGLE_SHAPE_WIDGET'
      ].includes(widget?.type):
    case ['RECTANGLE_SHAPE_WIDGET'].includes(widget?.type) &&
      widget?.outerBorderWidth > 0:
    case ['IMAGE_WIDGET'].includes(widget?.type):
      border = `${widget?.outerBorderWidth}px ${widget?.outerBorderStyle} ${outerBorderColor}`;
      break;
  }

  let borderRadiusLimit =
    widget?.width > widget?.height ? widget?.height : widget?.width;

  // console.log('debug46>borderRadiusLimit: ', borderRadiusLimit);

  let borderRadiusPercent = +widget?.outerBorderRadius / borderRadiusLimit;

  // console.log('borderRadiusPercent: ', borderRadiusPercent);

  let outerBorderRadius =
    +widget?.outerBorderRadius > borderRadiusLimit / 2
      ? borderRadiusLimit / 2
      : +widget?.outerBorderRadius;
  // outerBorderRadius = +outerBorderRadius / 72 / 2 + 0 / 72 / 2;

  // console.log('debug46>outerBorderRadius: ', outerBorderRadius);

  let widthCorrection =
    (outerBorderRadius / widget?.width) * widget?.width * 0.3;
  let heightCorrection =
    (outerBorderRadius / widget?.height) * widget?.height * 0.3;

  if (borderRadiusPercent < 0.15) {
    widthCorrection = 0;
    heightCorrection = 0;
  }

  let titleFillOpacity = Math.round(widget?.titleFillOpacity * 255).toString(
    16
  );
  if (titleFillOpacity.length === 1) {
    titleFillOpacity = '0' + titleFillOpacity;
  }
  let titleFillColor = widget?.titleFillColor + titleFillOpacity;

  let cardFillOpacity = Math.round(widget?.cardFillOpacity * 255).toString(16);
  if (cardFillOpacity.length === 1) {
    cardFillOpacity = '0' + cardFillOpacity;
  }
  let cardFillColor = widget?.cardFillColor + cardFillOpacity;

  let titleStyle = {};

  switch (true) {
    case ['NUMBER_WIDGET'].includes(widget?.type):
      titleStyle = {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        position: 'absolute',
        top:
          widget?.height / 5 -
          Math.round((+widget?.titleFontSize - 14) * 0.9) +
          'px', ////'29px',
        // right: '-1px',
        // right: outerBorderRadius - 5 + 'px',
        // fontWeight: 'bold',
        // fontSize: widget?.fontSize || '14px',
        // padding: '3px',
        // paddingLeft: '10px',
        // borderRadius: '0px 5px 0px 10px',
        borderRadius: '0px 0px 0px 5px',
        // background: titleFillColor, // 'rgba(150,150,150,0.1)',
        // padding: '0px 7px 2px 7px',
        // background: 'rgba(150,150,150,0.1)',
        // minWidth: '100px',
        color: widget?.titleFontColor,
        fontFamily: widget?.titleFontFamily,
        fontSize: widget?.titleFontSize,
        fontStyle: widget?.isTitleFontItalic && 'italic',
        fontWeight: widget?.isTitleFontBold && 'bold' // widget?.isFontBold && 'bold'
      };
      break;
    default:
      titleStyle = {
        position: 'absolute',
        top: '-1px',
        // right: '-1px',
        right: outerBorderRadius - 5 + 'px',
        // fontWeight: 'bold',
        // fontSize: widget?.fontSize || '14px',
        // padding: '3px',
        // paddingLeft: '10px',
        // borderRadius: '0px 5px 0px 10px',
        borderRadius: '0px 0px 0px 5px',
        // background: titleFillColor, // 'rgba(150,150,150,0.1)',
        padding: '10px', //'0px 7px 2px 7px',
        // background: 'rgba(150,150,150,0.1)',
        // minWidth: '100px',
        color: widget?.titleFontColor,
        fontFamily: widget?.titleFontFamily,
        fontSize: widget?.titleFontSize,
        fontStyle: widget?.isTitleFontItalic && 'italic',
        fontWeight: widget?.isTitleFontBold && 'bold' // widget?.isFontBold && 'bold'
      };
  }

  return (
    <div
      onMouseDown={(e) => {
        if (
          e.button === 0 &&
          // selectedWidgetIds.length > 1 &&
          selectedWidgetIds.includes(widget?.id)
        ) {
          setMouseDownWidgetIndex(widgetIndex);
          setMouseDownWidgetId(widget?.id);
          // if (!timebox.isMutable) {
          //   return;
          // }
          //   setOriginalTimebox({ ...timebox });
          e.stopPropagation();
          // e.preventDefault();
          // if (timebox.type === 'outlookCalendarEvent' || altHeld) {
          //   return;
          // }
          onMouseDownHandler(e, widgetIndex, 'move');
        } else {
          // e.preventDefault();
        }
      }}
      ref={ref}
      style={{
        background: 'none',
        position: 'absolute',
        // background: 'green',
        top: widget?.top,
        left: widget?.left,
        width: widget?.width,
        height: widget?.height,
        transform: 'translate(0, 0)'
      }}
      id={'widget-' + widget?.id}
      onContextMenu={(e) => onContextHandler(e, widget)}
      draggable={
        isDashboardUserMode
          ? false
          : Boolean(selectedWidgetIds.length < 1) ||
            !selectedWidgetIds.includes(widget?.id)
      }
      onDragOver={(e) => dragOver(e, widget, widgetIndex)}
      // draggable={editMode}
      onDragStart={(e) => {
        if (isDashboardUserMode) {
          return;
        }
        dragStart(e, widget, 'field_widgets', widgetIndex);
        setIsDraggingThisWidget(true);
      }}
      onDragEnd={(e) => {
        dragEnd(e, widget, widgetIndex);
        setIsDraggingThisWidget(false);
      }}
      onMouseUpHandler={onMouseUpHandler}
      onClick={(event) => {
        if (isDashboardUserMode) {
          return;
        }
        const eventClassName = String(event?.target?.className) || '';
        console.log('eventClassName: ', eventClassName);
        switch (true) {
          case eventClassName?.includes('column') && !event.shiftKey:
            // sort
            const column = eventClassName?.split('_')?.[1];
            let sortByOrder = widget?.sortByOrder;

            switch (sortByOrder) {
              case 'asc':
                sortByOrder = 'desc';
                break;
              case 'desc':
                sortByOrder = 'asc';
                break;
              case 'default':
                sortByOrder = 'asc';
                break;
            }
            updateWidget({
              ...widget,
              sortByColumn: column,
              sortByOrder: sortByOrder
            });
            // console.log('sort!!!');
            break;
          // case ['IMAGE_WIDGET', 'MOCKUP_WIDGET'].includes(widget?.type):
          //   return;
          case event.shiftKey && selectedWidgetIds?.includes(widget?.id):
            setSelectedWidgetIds(
              selectedWidgetIds.filter((widgetId) => widgetId !== widget?.id)
            );
            break;
          case event.shiftKey:
            setSelectedWidgetIds([...selectedWidgetIds, widget?.id]);
            break;
          case selectedWidgetIds?.includes(widget?.id) &&
            selectedWidgetIds.length > 1 &&
            !hasMouseMovedLocal:
          case !selectedWidgetIds?.includes(widget?.id) && !hasMouseMovedLocal:
            setSelectedWidgetIds([widget?.id]);
            break;
          case selectedWidgetIds?.includes(widget?.id) && !hasMouseMovedLocal:
            setSelectedWidgetIds([]);
            break;
        }
        // if (event.shiftKey) {
        //   console.debug('Ctrl+click has just happened!');

        //   if (selectedWidgetIds.includes(widget?.id)) {
        //     setSelectedWidgetIds(
        //       selectedWidgetIds.filter((widgetId) => widgetId !== widget?.id)
        //     );
        //   } else {
        //     setSelectedWidgetIds([...selectedWidgetIds, widget?.id]);
        //   }
        // } else {
        //   if (selectedWidgetIds.includes(widget?.id)) {
        //     setSelectedWidgetIds(
        //       selectedWidgetIds.filter((widgetId) => widgetId !== widget?.id)
        //     );
        //   } else {
        //     setSelectedWidgetIds([...selectedWidgetIds, widget?.id]);
        //   }
        // }
        // setSelectedWidgetIds([widget?.id]);
        toggleEdit(true);
        setHasMouseMoved(false);
        // if (editMode || widget?.type === 'TEXT') {
        //   toggleEdit(true);

        //   if (!editMode && !valueIsEdited) {
        //     setTextInput('');
        //   }
        // }
      }}
      onDrop={(e) => dropOnWidget(e, widget, widgetIndex)}
    >
      <div
        style={{
          // border: '1px solid rgba(255,255,255,0)',
          // border:
          //   widget?.isShowingCard &&
          //   ![
          //     // 'MANAGER_ACCOUNT_LOGO_WIDGET', 'DATE_PICKER_WIDGET'
          //   ].includes(widget?.type) &&
          //   (isDraggingThisWidget
          //     ? '1px solid grey'
          //     : '1px solid rgba(150,150,150,0.15)'),

          border,
          // borderWidth:
          //   widget?.isShowingCard &&
          //   ['IMAGE_WIDGET', 'MOCKUP_WIDGET', 'DATE_PICKER_WIDGET'].includes(
          //     widget?.type
          //   ) &&
          //   widget?.outerBorderWidth,
          // borderColor: widget?.outerBorderColor,
          // borderStyle: widget?.outerBorderStyle,
          // background:
          //   widget?.isShowingCard &&
          //   ![
          //     // 'MANAGER_ACCOUNT_LOGO_WIDGET', 'DATE_PICKER_WIDGET'
          //   ].includes(widget?.type) &&
          //   (theme.palette.mode === 'dark'
          //     ? 'linear-gradient(to top, rgb(70, 70, 81), rgb(85, 85, 100))'
          //     : `rgba(255,255,255,${widget?.opacity})`),

          // background:
          //   widget?.isShowingCard && `rgba(255,255,255,${widget?.opacity})`,
          background: widget?.isShowingCard && cardFillColor,

          outline: selectedWidgetIds.includes(widget?.id) && '2px dotted red',
          // : '1px solid rgba(255,255,255,0)',
          // outline: selectedWidgetIds.includes(widget?.id) && '1px solid blue',
          // height: '100%',
          // width: '100%',
          // padding: '4px',
          // color: 'black',
          // background: 'white',
          // border: '1px solid lightgrey',
          // position: 'absolute',

          // left: widget?.offsetX + 'px',
          // top: widget?.offsetY + 'px',
          display: 'flex',
          flexDirection: 'column',
          borderRadius: widget?.outerBorderRadius, // '5px',
          // margin: '0px 0.5px',
          transform: 'translate(0, 0)',
          overflow: 'hidden',
          lineBreak: 'anywhere',

          // boxShadow:
          //   !isDraggingThisWidget &&      !isExportMode &&
          //   widget?.isShowingCard &&
          //   widget?.isCardShadowOn &&  && 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
          // ...widget,
          ...styleObject,
          // opacity:
          //   ['IMAGE_WIDGET'].includes(widget?.type) && widget?.imageOpacity,
          filter:
            !isExportMode &&
            ((widget?.isShowingCard && widget?.isCardShadowOn) ||
              (widget?.isDropShadowOn &&
                [
                  'IMAGE_WIDGET',
                  'MOCKUP_WIDGET',
                  'RECTANGLE_SHAPE_WIDGET'
                ].includes(widget?.type))) &&
            'drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2))'
          // boxShadow:
          //   !isExportMode && widget?.isShowingCard && widget?.isCardShadowOn
          //     ? 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
          //     : 'unset'
        }}

        // onBlur={(e) => onSaveHandler(e)}
      >
        {/* {displayMode === 'EXAMPLE' ? widget?.value : widget?.name} */}
        {/* {edit ? (
        <textarea
          autoFocus
          style={{
            padding: '10px',
            fontFamily: 'Roboto, Raleway, sans-serif',
            lineHeight: '1.5',
            fontSize: '12px',
            letterSpacing: '0.2px',
            color: 'black',
            // width: textInput.length * 1 + 'ch',
            // width:
            //   numUpper * 0.35 + numLarge * 1 + textInput.length * 0.925 + 'ch',
            // minWidth: '1ch',
            width: widget?.width,
            height: widget?.height,
            outline: 'none',
            border: 'none'
            // fontSize: '11.5px'
          }}
          value={textInput}
          onChange={onChangeHandler}
          onKeyDown={handleKeyDown}
        />
      ) : (
        <Tooltip
          title={
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '5px',
                maxWidth: '200px'
                // padding: '2px'
              }}
            >
              <span style={{ width: '100%', textAlign: 'center' }}>
                {widget?.type === 'FREETEXT'
                  ? '* Free text *'
                  : '* Static text *'}
              </span>

              <span
                style={{
                  maxWidth: '190px',
                  whiteSpace: 'pre-line'
                  // paddingRight: '10px'
                  // padding: '5px'
                }}
              >
                {widget?.description}
              </span>
            </div>
          }
        >
          <span
            style={{
              padding: '10px',
              // width: '100%',
              // lineBreak: 'anywhere',
              width: widget?.width,
              height: widget?.height,
              maxWidth: widget?.width,
              maxHeight: widget?.height,
              //   background: 'red',
              // top: timebox?.duration <= 5 && '-5px',
              // position: timebox?.duration <= 5 && 'relative',
              // lineHeight: timebox?.duration <= 5 && '3px',
              // padding:
              //   timebox?.duration <= 10 ? '3px 9px 0px 9px' : '9px 9px 3px 9px',
              whiteSpace: 'pre-wrap',
              overflowY: 'scroll'
            }}
            onMouseDown={() => setIsDraggingWidget(true)}
            onMouseUp={() => setIsDraggingWidget(false)}
          >
          
            {widget?.value}
          </span>
        </Tooltip>
      )} */}
        {children}
        {/* {isWidgetDescriptionMenuOpen && (
        <WidgetDescriptionMenu
          isWidgetDescriptionMenuOpen={isWidgetDescriptionMenuOpen}
          setIsWidgetDescriptionMenuOpen={setIsWidgetDescriptionMenuOpen}
          // inputRef={inputRef}
          // textInput={textInput}
          // setTextInput={setTextInput}
          widgetIndex={widgetIndex}
          // options={options}
          // setOptions={setOptions}
          // editMode={editMode}
          widget={widget}
          updateWidget={updateWidget}
          toggleEdit={toggleEdit}
        />
      )} */}

        {(widget?.isDataFrozen ||
          widget?.isComparisonDataFrozen ||
          Boolean(widget?.dataFilters)) && (
          <div
            style={{
              width: '200px',
              // height: '50px',
              position: 'absolute',
              bottom: '0px',
              right: '20px',
              marginRight: '3px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
              alignItems: 'center'
            }}
          >
            {widget?.isDataFrozen && (
              <div
                style={
                  {
                    // position: 'absolute',
                    // bottom: '0px',
                    // right: '0px',
                    // marginRight: '3px'
                  }
                }
              >
                <AcUnitIcon
                  style={{
                    color: 'rgba(86, 182, 255, 0.3)',
                    // theme?.palette?.mode === 'dark'
                    //   ? 'rgba(230,230,255,0.5)'
                    //   : 'rgba(204,232,255,0.9)', //'rgba(150,150,150,0.5)',
                    fontSize: '16px'
                  }}
                />
              </div>
            )}

            {widget?.isComparisonDataFrozen && (
              <div
                style={
                  {
                    // position: 'absolute',
                    // bottom: '0px',
                    // right: '18px',
                    // marginRight: '3px'
                  }
                }
              >
                <AcUnitIcon
                  style={{
                    color: 'rgba(86, 182, 255, 0.3)',
                    // theme?.palette?.mode === 'dark'
                    //   ? 'rgba(230,230,255,0.5)'
                    //   : 'rgba(104,132,255,0.9)', //'rgba(150,150,150,0.5)',
                    fontSize: '12px'
                  }}
                />
              </div>
            )}

            {Boolean(widget?.dataFilters) && (
              <div
                style={
                  {
                    // position: 'absolute',
                    // bottom: '0px',
                    // right: '18px',
                    // marginRight: '3px'
                  }
                }
              >
                <FilterListIcon
                  style={{
                    color: 'rgba(86, 182, 255, 0.9)',
                    // theme?.palette?.mode === 'dark'
                    //   ? 'rgba(230,230,255,0.5)'
                    //   : 'rgba(204,232,255,0.9)', //'rgba(150,150,150,0.5)',
                    fontSize: '16px'
                  }}
                />
              </div>
            )}
          </div>
        )}
        {/* {widget?.isDataFrozen && (
          <div
            style={{
              position: 'absolute',
              bottom: '0px',
              right: '0px',
              marginRight: '3px'
            }}
          >
            <AcUnitIcon
              style={{
                color:
                  theme?.palette?.mode === 'dark'
                    ? 'rgba(230,230,255,0.5)'
                    : 'rgba(204,232,255,0.9)', //'rgba(150,150,150,0.5)',
                fontSize: '16px'
              }}
            />
          </div>
        )} */}

        {widget?.isShowingCard &&
          widget?.isCardShadowOn &&
          widget?.boxShadow && (
            <div
              style={{
                bottom: '-5px',
                // right: '0px',
                height: '5px',
                position: 'absolute',
                filter: 'opacity(50%)',
                // backgroundColor: stylesStoredInRedux.background.backgroundColor
                //   ? stylesStoredInRedux.background.backgroundColor
                //   : 'rgba(255,255,255,0)',
                // cursor: 'col-resize',
                // backgroundColor: 'red',
                userSelect: 'none',
                width: 'calc(100%)',
                zIndex: '1099',
                borderRadius: '0px 0px 5px 5px',
                overflow: 'hidden'
              }}
            >
              <div
                style={{
                  filter: 'opacity(50%)',
                  top: '45px',
                  // right: '0px',
                  height: '5px',
                  // position: 'absolute',
                  background:
                    '0 0 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAECAYAAABP2FU6AAAAF0lEQVR4AWPUkNeSBhHCjJoK2twgFisAFagCCp3pJlAAAAAASUVORK5CYII=) repeat-x',
                  // cursor: 'col-resize',
                  // backgroundColor: 'red',
                  userSelect: 'none',
                  width: 'calc(100%)',
                  zIndex: '1100'
                }}
              ></div>
            </div>
          )}
        {widget?.isShowingTitle && (
          <div className="widget-title" style={titleStyle}>
            {/* <div
        style={{
          borderRadius: '0px 0px 5px 5px',
          background: 'rgba(150,150,150,0.1)',
          padding: '0px 7px 2px 7px'
        }}
      > */}
            <span
              className="widget-title"
              style={{
                borderRadius: '3px',
                // background: titleFillColor, // 'rgba(150,150,150,0.1)',
                padding: '0px 7px 2px 7px'
              }}
            >
              {widget?.title}
            </span>
            {/* </div> */}
          </div>
        )}
        <AxisMenu widget={widget} />
      </div>
      {widget?.isResizable && (
        <div
          className={
            theme?.palette?.mode === 'dark'
              ? 'timebox-resize-border-dark'
              : 'timebox-resize-border-light'
          }
          style={{
            width: '15px',
            height: '15px',
            // background: 'green',
            zIndex: 50000,
            // cursor:
            //   widget?.isMutable &&
            //   widget?.type !== 'outlookCalendarEvent' &&
            //   'ns-resize',
            cursor: 'nwse-resize',
            position: 'absolute',
            bottom: '0px',
            right: '0px',
            // border: '1px solid red',
            outline: selectedWidgetIds.includes(widget?.id) && '2px dotted red'
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onMouseDown={(e) => {
            if (isDashboardUserMode) {
              return;
            }
            setMouseDownWidgetIndex(widgetIndex);
            setMouseDownWidgetId(widget?.id);
            // if (!timebox.isMutable) {
            //   return;
            // }
            //   setOriginalTimebox({ ...timebox });
            e.stopPropagation();
            e.preventDefault();
            // if (timebox.type === 'outlookCalendarEvent' || altHeld) {
            //   return;
            // }
            onMouseDownHandler(e, widgetIndex, 'resize');
          }}
          // onMouseUp={(e) => {
          //   e.stopPropagation();
          //   console.log('debug30>Timebox>timebox.id: ', timebox.id);
          //   onMouseUpHandler(e, 'timebox', timebox.id);
          // }}
          // onMouseMove={updateMousePosition}
        ></div>
      )}
    </div>
  );
}

export default WidgetWrapper;
