import React, { useState, useEffect, useContext } from 'react';
import Tooltip from '@mui/material/Tooltip';

import WidgetDescriptionMenu from './WidgetDescriptionMenu';

import { useTheme } from '@mui/material';

import { DataContext } from '../../../../../context/DataContext';

import { prepDataObject } from './prepDataObject';

import {
  nonSumEvalFields,
  sumEvalFields,
  plannedEvalFields
} from './../../../fields';

function NumberWidget({
  reportPage,
  widget,
  // displayMode,
  updateWidget,
  widgetIndex,
  // setMouseDownWidgetIndex,
  // // editMode,
  // dropOnWidget,
  dragOver,
  // dragStart,
  // dragEnd,
  // isDraggingWidget,
  // setIsDraggingWidget,
  // onMouseDownHandler,
  onMouseUpHandler
  // widgetType
}) {
  const theme = useTheme();

  const { listOfData, arrayOfData, listOfFilteredDataSourceRows } =
    useContext(DataContext);

  // let value = 1000;

  // const [value, setValue] = useState(null);

  let comparison = Math.round(
    (widget?.value / widget?.comparisonValue - 1) * 100
  );

  let comparisonPrefix = <span></span>;
  switch (true) {
    case comparison > 0:
      comparisonPrefix = (
        <span
          style={{
            fontWeight: widget.isValueFontBold && 'bold',
            color: 'green'
          }}
        >
          '+'
        </span>
      );
      break;
    // case comparison < 0:
    //   comparisonPrefix = (
    //     <span
    //       style={{ fontWeight: widget.isValueFontBold && 'bold', color: 'red' }}
    //     >
    //       '- '
    //     </span>
    //   );
    //   break;
  }

  // console.log('widget: ', widget);
  return (
    <div
      className="number-widget-surface"
      key={widget.id}
      onDrop={(e) => {
        e.preventDefault();
        // e.stopPropagation();
      }}
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <span style={{ visibility: 'hidden', padding: '17px' }}>ipsum</span>
      <span
        className="widget-value"
        style={{
          // width: '100%',
          // height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          // fontWeight: 'bold',
          // fontSize: '16px',
          color: widget.valueFontColor,
          fontWeight: widget.isValueFontBold && 'bold',
          fontStyle: widget.isValueFontItalic && 'italic',
          // fontSize:
          //   +widget.valueFontSize +
          //   0.2 *
          //     ((widget.width / widget.value.length) *
          //       (+widget.valueFontSize / 14)) +
          //   'px',
          fontSize: widget.valueFontSize,
          fontFamily: widget.valueFontFamily
            ? widget.valueFontFamily + ', Roboto, Raleway, sans-serif'
            : 'Roboto, Raleway, sans-serif'
        }}
      >
        {widget?.value?.toLocaleString() || ''}
      </span>
      <span
        style={{
          display: 'flex',
          flexDirection: 'row',
          fontWeight: widget.isValueFontBold && 'bold',
          color: comparison > 0 ? 'green' : comparison < 0 ? 'red' : 'grey',
          visibility: !widget.isShowingComparison && 'hidden',
          padding: '5px',
          margin: '12px'
        }}
      >
        ({comparisonPrefix}
        {comparison}%)
      </span>
      {/* <div
        onDrop={(e) => {
          e.preventDefault();
          // e.stopPropagation();
        }}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          // fontWeight: 'bold',
          // fontSize: '16px',
          color: widget.valueFontColor,
          fontWeight: widget.isValueFontBold && 'bold',
          fontStyle: widget.isValueFontItalic && 'italic',
          fontSize: widget.valueFontSize || '16px',
          fontFamily: widget.valueFontFamily
            ? widget.valueFontFamily + ', Roboto, Raleway, sans-serif'
            : 'Roboto, Raleway, sans-serif'
        }}
      >
        <span>{widget?.value || ''}</span>
      </div> */}
    </div>
  );
}

export default NumberWidget;
