export const sumEvalFields = [
  //   'plannedGross',
  //   'plannedNet',
  //   'plannedNetNet',
  //   'plannedCtc',

  //   'plannedTrp',
  //   'plannedTechFee',
  //   'plannedAdOpsFee',
  //   'plannedAdServingCost',
  //   'plannedFeePercent',

  //   'budget',
  'kpi',
  //   'plannedImpressions',
  //   'plannedClicks',
  //   'plannedCtr',
  //   'plannedCpc',
  //   'plannedReach',
  //   'plannedCpm',
  //   'plannedFrequency',
  //   'plannedActionCount',
  //   'plannedActionValue',
  //   'plannedActionRoas',
  //   'plannedCpa',
  //   'universe',
  //   'reach',
  'trp',
  'cost',
  'impressions',
  'viewableImpressions',
  'measurableImpressions',
  'eligibleImpressions',
  'videoCompletions',
  'clicks',
  'action01Count',
  'action02Count',
  'action03Count',
  'action01Value',
  'action02Value',
  'action03Value'
];

const totalEvalFields = [
  'totalAction01Count',
  'totalAction02Count',
  'totalAction03Count',
  'totalAction01Value',
  'totalAction02Value',
  'totalAction03Value'
];

export const nonSumEvalFields = [
  'universe',
  'reach',
  'cpm',
  'vCpm',
  'cpcv',
  'cpc',
  'ctr',
  'viewableRate',
  'vcr',
  'searchImpressionShare',
  //   'plannedCtr',
  //   'plannedCpc',
  //   'plannedCpm',
  //   'plannedActionRoas',
  //   'plannedCpa',
  'action01Cvr',
  'action01Cpa',
  'action01Roas',
  'action01Crr',
  'action01Vpa',
  'action02Cvr',
  'action02Cpa',
  'action02Roas',
  'action02Crr',
  'action02Vpa',
  'action03Cvr',
  'action03Cpa',
  'action03Roas',
  'action03Crr',
  'action03Vpa'
];

export const plannedEvalFields = [
  'plannedReach',
  'plannedFrequency',
  'plannedActionCount',
  'plannedActionValue',
  'plannedCtr',
  'plannedCpc',
  'plannedCpm',
  'plannedActionRoas',
  'plannedCpa',
  'plannedGross',
  'plannedNet',
  'plannedNetNet',
  'plannedCtc',

  'plannedTrp',
  'plannedCpp',
  'plannedTechFee',
  'plannedAdOpsFee',
  'plannedAdServingCost',
  'plannedFeePercent',

  'budget',
  //   'kpi',
  'plannedImpressions',
  'plannedClicks'
];

export const aggregatedFields = ['costLifetime', 'impressionsLifetime'];

export const allEvalFields = [
  ...plannedEvalFields,
  ...sumEvalFields,
  ...nonSumEvalFields,
  ...totalEvalFields,
  ...aggregatedFields
];

export const allEvalFieldsExceptTotal = [
  ...plannedEvalFields,
  ...sumEvalFields,
  ...nonSumEvalFields,
  ...aggregatedFields
  // ...totalEvalFields
];
