import { dimensionDict } from './../../report-builder-content/dictionary';
const tableChartWidget = async (
  widget,
  pres,
  slide,
  chartFillColors,
  dummySlide
) => {
  // let dataChartObj = {
  //   name: widget.title,
  //   labels: [], // widget.data.map((obj) => obj.label),
  //   values: [] // widget.data.map((obj) => obj.value)
  // };

  // let totalValue = 0;

  // for (let obj of widget.data) {
  //   totalValue = totalValue + (obj.value || null);
  // }

  // for (let obj of widget.data) {
  //   if (obj.value / totalValue >= 0.005) {
  //     dataChartObj.labels.push(obj.label);
  //     dataChartObj.values.push(obj.value);
  //   }
  // }
  // let dataChartPieStat = [dataChartObj];

  //   let rows = [
  //     [
  //         { text: "Top Lft", options: { align: "left", fontFace: "Arial" } },
  //         { text: "Top Ctr", options: { align: "center", fontFace: "Verdana" } },
  //         { text: "Top Rgt", options: { align: "right", fontFace: "Courier" } },
  //     ],
  // ];

  const rowChildren = document.querySelector(
    `#widget-${widget.id} > div:nth-child(1) > div:nth-child(1) > table > tr:nth-child(1)`
  ).children;

  const colW = [];
  for (let child of rowChildren) {
    colW.push(child.offsetWidth / 72 / 2);
  }

  // const colW = colWPx.map((val) => val / 72 / 2);

  const tableDocument = document.querySelector(
    `#widget-${widget.id} > div:nth-child(1) > div:nth-child(1) > table`
  );

  const tableChildren = tableDocument.children;

  // const rowH = tableChildren.map((child) => child.offsetHeight / 72 / 2);

  const rowH = [];
  for (let child of tableChildren) {
    rowH.push(child.offsetHeight / 72 / 2);
  }

  // console.log('debug24>children: ', children);
  // const colWPx = [
  //   2,
  //   ...widget.metrics.map(
  //     (metric) => widget.columnWidths[metric.metric] * 0.9 + 10
  //   )
  // ];

  // let sumColWPx = 0;
  // for (let val of colWPx) {
  //   sumColWPx += val;
  // }
  // colWPx[0] = widget.width - (sumColWPx - colWPx[0]);
  // let maxNumberOfLetters = Math.round((colWPx[0] + 40) / 6);

  // console.log('maxNumberOfLetters: ', maxNumberOfLetters);

  const rowWidthDictionary = {
    impressions: 2
  };

  // const borderColor = 'EEEEEE';

  const horizontalBorderColor = widget.horizontalBorderColor.slice(1);
  const verticalBorderColor = widget.verticalBorderColor.slice(1);

  let rows = [
    [
      ...widget.metrics.map((metricObj, index) => {
        const metric = metricObj.metric;
        // let width = 1;

        // switch (metric) {
        //   case 'clicks':
        //     width = 1;
        //     break;
        //   case 'impressions':
        //     width = 2;
        //     break;
        // }
        return {
          text: metric[0].toUpperCase() + metric.slice(1),
          options: {
            align: 'right',
            fontFace: widget?.textFontFamily || 'Roboto', // 'Arial',
            bold: true,
            w: rowWidthDictionary[metric] || 1,

            border: [
              {
                type: 'none',
                pt: widget.horizontalBorderWidth / 2, //'1',
                color: horizontalBorderColor // borderColor
              },
              {
                type: index === widget.metrics.length - 1 && 'none',
                pt: widget.verticalBorderWidth / 2, //'1',
                color: verticalBorderColor //'FF0000' //borderColor
              },
              {
                // type: 'solid',
                pt: widget.horizontalBorderWidth / 2,
                color: horizontalBorderColor //'FF0000' //borderColor
              },
              {
                // type: 'solid',
                pt: widget.verticalBorderWidth / 2, //'1',
                color: verticalBorderColor //borderColor
              }
            ]
          }
        };
      })
    ]
  ];

  if (widget?.dimensions?.length) {
    rows[0].unshift({
      text: dimensionDict[widget.dimensions[0]] || widget.dimensions[0],
      options: {
        align: 'left',
        fontFace: 'Arial',
        bold: true,

        border: [
          {
            type: 'none',
            pt: widget.horizontalBorderWidth / 2, //'1',
            color: horizontalBorderColor //borderColor //'BCBCBC'
          },
          {
            // type: 'solid',
            pt: widget.verticalBorderWidth / 2, //'1',
            color: verticalBorderColor //borderColor
          },
          {
            // type: 'solid',
            pt: widget.horizontalBorderWidth / 2, //'1',
            color: horizontalBorderColor //borderColor
          },
          {
            type: 'none',
            pt: widget.verticalBorderWidth / 2, //'1',
            color: verticalBorderColor //borderColor
          }
        ]
      }
    });
  }

  let rowIndex = 0;
  for (let row of widget.data) {
    let maxNumberOfLetters = 0;
    let textWidth = 0;
    for (let letter of row['label']) {
      if (textWidth / 72 / 2 > colW[0]) {
        break;
      }
      // console.log('letter: ', letter);
      switch (true) {
        case '[]()!|lj,.:1 '.includes(letter):
          textWidth = textWidth + 3;
          break;
        case 'ABCDEFGHIJKLMNOP'.includes(letter):
          textWidth = textWidth + 12;
          break;
        default:
          textWidth = textWidth + 8;
      }
      maxNumberOfLetters++;
    }

    let newRow = [];
    if (widget.dimensions.length) {
      newRow.unshift({
        text:
          row['label'].slice(0, maxNumberOfLetters) +
          (row['label'].length > maxNumberOfLetters ? '...' : ''),
        options: {
          align: 'left',
          fontFace: 'Arial',
          border: [
            {
              type: 'solid',
              pt: widget.horizontalBorderWidth / 2, //'1',
              color: horizontalBorderColor // borderColor
            },
            {
              type: 'solid',
              pt: widget.verticalBorderWidth / 2, //'1',
              color: verticalBorderColor //borderColor
            },
            {
              type: rowIndex === widget.data.length - 1 && 'none',
              pt: widget.horizontalBorderWidth / 2, //'1',
              color: horizontalBorderColor //borderColor
            },
            {
              type: 'none',
              pt: widget.verticalBorderWidth / 2, //'1',
              color: verticalBorderColor //borderColor
            }
          ]
        }
      });
    }
    let metricIndex = 0;
    for (let metricObj of widget.metrics) {
      const metric = metricObj.metric;
      let numberOfDecimals = 2;
      switch (true) {
        case [
          'impressions',
          'viewableImpressions',
          'measurableImpressions',
          'eligibleImpressions',
          'videoCompletions',
          'clicks'
        ].includes(metric):
          numberOfDecimals = 0;
          break;
      }

      newRow.push({
        text: row[metric]?.toLocaleString(undefined, {
          minimumFractionDigits: numberOfDecimals,
          maximumFractionDigits: numberOfDecimals
        }),
        options: {
          align: 'right',
          fontFace: 'Arial',
          // bold: true,
          w: rowWidthDictionary[metric] || 1,

          border: [
            {
              type: 'solid',
              pt: '0',
              color: horizontalBorderColor //borderColor
            },
            {
              type: metricIndex === widget.metrics.length - 1 && 'none',
              pt: '0',
              color: verticalBorderColor //borderColor
            },
            {
              type: rowIndex === widget.data.length - 1 && 'none',
              pt: widget.horizontalBorderWidth / 2, //'1',
              color: horizontalBorderColor //borderColor
            },
            {
              type: 'solid',
              pt: '0',
              color: verticalBorderColor // borderColor
            }
          ]
        }
      });

      metricIndex++;
    }
    rows.push(newRow);

    rowIndex++;
  }

  // let summaryArray = rows[rows.length - 1];
  rows[rows.length - 1] = rows[rows.length - 1].map((obj) => {
    const newObj = {
      ...obj,
      options: { ...obj.options, bold: true }
    };
    // console.log('newObj: ', newObj);
    return newObj;
  });

  // rows.pop();
  // rows.push(summaryArray);

  // console.log('summaryArray: ', summaryArray);
  // console.log('rows[rows.length - 1]: ', rows[rows.length - 1]);
  // console.log('rows: ', rows);
  // console.log('rows: ', rows);
  await dummySlide.addTable(rows, {
    // w: 9
    w: widget.width / 72 / 2,
    h: widget.height / 72 / 2,
    x: +widget?.left / 72 / 2,
    y: widget.top / 72 / 2,

    // rowH: 1,
    align: 'left',
    fontFace: widget?.textFontFamily || 'Roboto',
    fontSize: 6

    // border: {
    //   type: 'solid',
    //   pt: 1,
    //   color: borderColor
    // }
  });

  // console.log(
  //   'slide: ',
  //   slide._slideObjects[slide._slideObjects.length - 1].options.h
  // );

  const cardHeight =
    dummySlide._slideObjects[dummySlide._slideObjects.length - 1].options.h;
  const cardHWidth =
    dummySlide._slideObjects[dummySlide._slideObjects.length - 1].options.h;

  // console.log(
  //   'dummySlide._slideObjects[dummySlide._slideObjects.length - 1]: ',
  //   dummySlide._slideObjects[dummySlide._slideObjects.length - 1]
  // );
  // slide._slideObjects[slide._slideObjects.length - 2].options.h = cardHeight;

  // console.log('cardHeight: ', cardHeight);
  // if (widget.isShowingCard) {
  //   slide.addShape(
  //     +widget?.outerBorderRadius === 0
  //       ? pres.shapes.RECTANGLE
  //       : pres.shapes.ROUNDED_RECTANGLE,
  //     {
  //       // fill: {
  //       //   // color: 'FFFFFF',
  //       //   // transparency: (widget.opacity - 1) * -100 //5
  //       //   color: widget.cardFillColor || 'FFFFFF',
  //       //   transparency: (widget.cardFillOpacity - 1) * -100 //5
  //       // },
  //       fill: {
  //         color: widget.cardFillColor || 'FFFFFF',
  //         transparency: (widget.cardFillOpacity - 1) * -100 //5
  //       },
  //       line: +widget?.outerBorderWidth !== 0 && {
  //         width: +widget?.outerBorderWidth / 2,
  //         color: widget.outerBorderColor.split('#')[1], //'f1f1f1',
  //         transparency: (widget.outerBorderOpacity - 1) * -100 //5
  //       },
  //       border: { pt: '1', color: 'f1f1f1', transparency: 10 },
  //       w: widget.width / 72 / 2,
  //       // h: widget.height / 72 / 2,
  //       // h: Math.round(cardHeight * 1.0),
  //       h: tableDocument.offsetHeight / 72 / 2,
  //       // h: 1190625,
  //       x: +widget?.left / 72 / 2,
  //       y: widget.top / 72 / 2,

  //       rectRadius: 0.05,
  //       shadow: widget.isCardShadowOn && {
  //         // type: 'outer',
  //         // blur: 6,
  //         // offset: 2,
  //         // angle: 45,
  //         // color: '000000',
  //         // opacity: 0.2

  //         type: 'outer',
  //         blur: 6,
  //         offset: 2,
  //         angle: 45,
  //         color: '000000',
  //         opacity: 0.2
  //       }
  //     }
  //   );
  // }

  await slide.addTable(rows, {
    // w: 9
    w: widget.width / 72 / 2,
    h: widget.height / 72 / 2,
    x: +widget?.left / 72 / 2,
    y: widget.top / 72 / 2,

    // rowH: 1,
    fontFace: widget?.textFontFamily || 'Roboto', // 'Roboto', // 'Arial',

    fontSize: +widget?.textFontSize / 2 || 7,
    color: widget?.textFontColor || '#000000',
    bold: widget?.isTextFontBold || false,
    italic: widget?.isTextFontItalic || false,
    align: widget.horizontalTextAlign, //'left'
    valign: widget.verticalTextAlign,
    colW,
    rowH
    // border: {
    //   type: 'solid',
    //   pt: 1,
    //   color: borderColor
    // }
  });
};

export default tableChartWidget;
