import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';

import { useTheme } from '@emotion/react';

// const columns = [
//   { field: 'id', headerName: 'ID', width: 70 },
//   { field: 'firstName', headerName: 'First name', width: 130 },
//   { field: 'lastName', headerName: 'Last name', width: 130 },
//   {
//     field: 'age',
//     headerName: 'Age',
//     type: 'number',
//     width: 90,
//   },
//   {
//     field: 'fullName',
//     headerName: 'Full name',
//     description: 'This column has a value getter and is not sortable.',
//     sortable: false,
//     width: 160,
//     valueGetter: (params) =>
//       `${params.getValue('firstName') || ''} ${params.getValue('lastName') || ''}`,
//   },
// ];

// const columns = [
//   { field: 'startDate', headerName: 'Period', width: 120 },
//   { field: 'cost', headerName: 'Cost', type: 'number', width: 120 },
//   { field: 'period_on_period_cost', headerName: '%', width: 75, renderCell: ({ value }) => {

//       if(value !== undefined){
//         return <span style={{ color: value>=0 ? 'green' : 'red', fontSize: '12px' }}>{(value>0 ? '+' : '') + value.toString() + '%'}</span>
//       } else {
//         return <span></span>
//       }

//     }
//   },
//   { field: 'impressions', headerName: 'Impressions', type: 'number', width: 120 },
//   { field: 'period_on_period_impressions', headerName: '%', width: 85, description: 'period_on_period_impressions' },
//   { field: 'cpm', headerName: 'CPM', type: 'number', width: 130 },
//   { field: 'period_on_period_cpm', headerName: '%', width: 85 },
//   { field: 'action_01_actions', headerName: 'action_01_actions', type: 'number', width: 130 },
//   { field: 'action_01_cpa', headerName: 'action_01_cpa', type: 'number', width: 130 },
//   { field: 'action_01_value', headerName: 'action_01_value', type: 'number', width: 130 },
//   { field: 'action_01_roas', headerName: 'action_01_roas', type: 'number', width: 130 },
//   { field: 'period_on_period_roas', headerName: '%', width: 85 }

// ]

export default function DataTable({ rows, time, columns }) {
  const theme = useTheme();
  const redColor =
    theme.palette.mode === 'dark'
      ? 'rgb(255 84 84)' //  '#ff6b6b'
      : 'red';

  const greenColor =
    theme.palette.mode === 'dark'
      ? 'lightgreen' //  '#ff6b6b'
      : 'green';

  const PoP = {
    renderCell: ({ value }) => {
      if (value !== undefined) {
        return (
          <span
            style={{
              width: '100%',
              textAlign: 'right',
              color: value > 0 ? greenColor : value < 0 ? redColor : 'grey',
              fontSize: '12px'
            }}
          >
            {(value > 0 ? '+' : '') + value.toString() + '%'}
          </span>
        );
      } else {
        return <span></span>;
      }
    }
    // valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
  };

  const PoP2 = {
    renderCell: ({ value }) => {
      if (value !== undefined) {
        return (
          <span
            style={{
              width: '100%',
              textAlign: 'right',
              color: value > 0 ? redColor : value < 0 ? greenColor : 'grey',
              fontSize: '12px'
            }}
          >
            {(value > 0 ? '+' : '') + value.toString() + '%'}
          </span>
        );
      } else {
        return <span></span>;
      }
    }
    // valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
  };
  // const blueWarningColor = theme.palette.mode === 'dark' ? '#9898ff' : 'blue';

  let description = '';
  switch (time) {
    case 'day':
      description = 'Day on day: ';
      break;
    case 'week':
      description = 'Week on week: ';
      break;
    case 'month':
      description = 'Month on month: ';
      break;
  }

  const columnTypes = [
    { field: 'date', headerName: 'Period', width: 120 },
    { field: 'cost', headerName: 'Cost', type: 'number', width: 120 },
    {
      field: 'costPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'cost',
      ...PoP
    },

    {
      field: 'trp',
      headerName: 'TRPs',
      description: 'Target Rating Points',
      type: 'number',
      width: 120
    },
    {
      field: 'trpPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Target Rating Points',
      ...PoP
    },

    {
      field: 'spots',
      headerName: 'Spots',
      description: 'Spots',
      type: 'number',
      width: 120
    },
    {
      field: 'SpotsPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Spots',
      ...PoP
    },

    {
      field: 'impressions',
      headerName: 'Impressions',
      description: 'Impressions',
      type: 'number',
      width: 120
    },
    {
      field: 'impressionsPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'impressions',
      ...PoP
    },

    {
      field: 'viewableImpressions',
      headerName: 'Viewable Impressions',
      description: 'Viewable Impressions',
      type: 'number',
      width: 120
    },
    {
      field: 'viewableImpressionsPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'viewableImpressions',
      ...PoP
    },

    {
      field: 'measurableImpressions',
      headerName: 'Measurable Impressions',
      description: 'Measurable Impressions',
      type: 'number',
      width: 120
    },
    {
      field: 'measurableImpressionsPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'measurableImpressions',
      ...PoP
    },

    {
      field: 'eligibleImpressions',
      headerName: 'Eligible Impressions',
      description: 'Eligible Impressions',
      type: 'number',
      width: 120
    },
    {
      field: 'eligibleImpressionsPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'eligibleImpressions',
      ...PoP
    },

    {
      field: 'cpm',
      headerName: 'CPM',
      description: 'Cost per thousand impressions',
      type: 'number',
      width: 100
    },
    {
      field: 'cpmPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'CPM',
      ...PoP2
    },

    {
      field: 'vCpm',
      headerName: 'vCPM',
      description: 'Cost per thousand viewable impressions',
      type: 'number',
      width: 100
    },
    {
      field: 'vCpmPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'vCPM',
      ...PoP2
    },
    {
      field: 'videoCompletions',
      headerName: 'Video Completions',
      description: 'Video Completions',
      type: 'number',
      width: 120
    },
    {
      field: 'videoCompletionsPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'videoCompletions',
      ...PoP
    },
    {
      field: 'clicks',
      headerName: 'Clicks',
      description: 'Clicks',
      type: 'number',
      width: 100
    },
    {
      field: 'clicksPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Clicks',
      ...PoP
    },

    {
      field: 'cpcv',
      headerName: 'CPCV',
      description: 'Cost per Completed Video',
      type: 'number',
      width: 100
    },
    {
      field: 'cpcvPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'CPCV',
      ...PoP2
    },

    {
      field: 'cpc',
      headerName: 'CPC',
      description: 'Cost per Click',
      type: 'number',
      width: 100
    },
    {
      field: 'cpcPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'CPC',
      ...PoP2
    },

    {
      field: 'action01Count',
      headerName: 'Action 01 Count',
      description: 'Action 01 Count',
      type: 'number',
      width: 130
    },
    {
      field: 'action01CountPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Action 01 Count',
      ...PoP
    },
    {
      field: 'action01Cpa',
      headerName: 'Action 01 CPA',
      description: 'Action 01 CPA',
      type: 'number',
      width: 130
    },
    {
      field: 'action01CpaPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Action 01 CPA',
      ...PoP2
    },
    {
      field: 'action01Value',
      headerName: 'Action 01 Value',
      type: 'number',
      width: 130
    },
    {
      field: 'action01ValuePeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Action 01 Value',
      ...PoP
    },
    {
      field: 'action01Roas',
      headerName: 'Action 01 ROAS',
      description: 'Action 01 ROAS',
      type: 'number',
      width: 130
    },
    {
      field: 'action01RoasPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Action 01 ROAS',
      ...PoP
    },
    {
      field: 'action01Crr',
      headerName: 'Action 01 CRR',
      description: 'Action 01 CRR',
      type: 'number',
      width: 130
    },
    {
      field: 'action01CrrPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Action 01 CRR',
      ...PoP
    },
    {
      field: 'action02Count',
      headerName: 'Action 02 Count',
      description: 'Action 02 Count',
      type: 'number',
      width: 130
    },
    {
      field: 'action02CountPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Action 02 Count',
      ...PoP
    },
    {
      field: 'action02Cpa',
      headerName: 'Action 02 CPA',
      description: 'Action 02 CPA',
      type: 'number',
      width: 130
    },
    {
      field: 'action02CpaPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Action 02 CPA',
      ...PoP2
    },
    {
      field: 'action02Value',
      headerName: 'Action 02 Value',
      description: 'Action 02 Value',
      type: 'number',
      width: 130
    },
    {
      field: 'action02ValuePeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Action 02 Value',
      ...PoP
    },
    {
      field: 'action02Roas',
      headerName: 'Action 02 ROAS',
      description: 'Action 02 ROAS',
      type: 'number',
      width: 130
    },
    {
      field: 'action02RoasPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Action 02 ROAS',
      ...PoP
    },

    {
      field: 'action02Crr',
      headerName: 'Action 02 CRR',
      description: 'Action 02 CRR',
      type: 'number',
      width: 130
    },
    {
      field: 'action02CrrPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Action 02 CRR',
      ...PoP
    },

    {
      field: 'action03Cpa',
      headerName: 'Action 03 CPA',
      type: 'number',
      width: 130
    },
    {
      field: 'action03CpaPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Action 03 CPA',
      ...PoP2
    },
    {
      field: 'action03Value',
      headerName: 'Action 03 Value',
      type: 'number',
      width: 130
    },
    {
      field: 'action03ValuePeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'action03Value',
      ...PoP
    },
    {
      field: 'action03Roas',
      headerName: 'Action 03 ROAS',
      type: 'number',
      width: 130
    },
    {
      field: 'action03RoasPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Action 03 ROAS',
      ...PoP
    },

    {
      field: 'action03Crr',
      headerName: 'Action 03 CRR',
      type: 'number',
      width: 130
    },
    {
      field: 'action03CrrPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Action 03 CRR',
      ...PoP
    },

    // ########################################################################

    {
      field: 'totalCost',
      headerName: 'Total Cost',
      type: 'number',
      width: 120
    },
    {
      field: 'totalCostPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Total Cost',
      ...PoP
    },

    {
      field: 'totaltrp',
      headerName: 'Total TRPs',
      description: 'Total Target Rating Points',
      type: 'number',
      width: 120
    },
    {
      field: 'totaltrpPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Total Target Rating Points',
      ...PoP
    },

    {
      field: 'totalSpots',
      headerName: 'Total Spots',
      description: 'Total Spots',
      type: 'number',
      width: 120
    },
    {
      field: 'totalSpotsPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Total Spots',
      ...PoP
    },

    {
      field: 'totalImpressions',
      headerName: 'Total Impressions',
      description: 'Total Impressions',
      type: 'number',
      width: 120
    },
    {
      field: 'totalImpressionsPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Total Impressions',
      ...PoP
    },
    {
      field: 'totalCpm',
      headerName: 'Total CPM',
      description: 'Total Cost per thousand impressions',
      type: 'number',
      width: 100
    },
    {
      field: 'totalCpmPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Total CPM',
      ...PoP2
    },

    {
      field: 'totalClicks',
      headerName: 'Total Clicks',
      description: 'Total Clicks',
      type: 'number',
      width: 100
    },
    {
      field: 'totalClicksPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Total Clicks',
      ...PoP2
    },

    {
      field: 'totaCpc',
      headerName: 'CPC',
      description: 'Cost per Click',
      type: 'number',
      width: 100
    },
    {
      field: 'totalCpcPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'CPC',
      ...PoP2
    },

    {
      field: 'totalAction01Count',
      headerName: 'Total Action 01 Count',
      description: 'Total Action 01 Count',
      type: 'number',
      width: 130
    },
    {
      field: 'totalAction01CountPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Total Action 01 Count',
      ...PoP
    },
    {
      field: 'totalAction01Cpa',
      headerName: 'Total Action 01 CPA',
      description: 'Total Action 01 CPA',
      type: 'number',
      width: 130
    },
    {
      field: 'totalAction01CpaPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Total Action 01 CPA',
      ...PoP2
    },
    {
      field: 'totalAction01Value',
      headerName: 'Action 01 Value',
      type: 'number',
      width: 130
    },
    {
      field: 'totalAction01ValuePeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Total Action 01 Value',
      ...PoP
    },
    {
      field: 'totalAction01Roas',
      headerName: 'Total Action 01 ROAS',
      description: 'Total Action 01 ROAS',
      type: 'number',
      width: 130
    },
    {
      field: 'totalAction01RoasPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Total Action 01 ROAS',
      ...PoP
    },

    {
      field: 'totalAction01Crr',
      headerName: 'Total Action 01 CRR',
      description: 'Total Action 01 CRR',
      type: 'number',
      width: 130
    },
    {
      field: 'totalAction01CrrPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Total Action 01 CRR',
      ...PoP
    },

    {
      field: 'totalAction02Count',
      headerName: 'Total Action 02 Count',
      description: 'Total Action 02 Count',
      type: 'number',
      width: 130
    },
    {
      field: 'totalAction02CountPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Total Action 02 Count',
      ...PoP
    },
    {
      field: 'totalAction02Cpa',
      headerName: 'Action 02 CPA',
      description: 'Action 02 CPA',
      type: 'number',
      width: 130
    },
    {
      field: 'totalAction02CpaPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Total Action 02 CPA',
      ...PoP2
    },
    {
      field: 'totalAction02Value',
      headerName: 'Total Action 02 Value',
      description: 'Total Action 02 Value',
      type: 'number',
      width: 130
    },
    {
      field: 'totalAction02ValuePeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Total Action 02 Value',
      ...PoP
    },
    {
      field: 'totalAction02Roas',
      headerName: 'Total Action 02 ROAS',
      description: 'Total Action 02 ROAS',
      type: 'number',
      width: 130
    },
    {
      field: 'totalAction02RoasPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Total Action 02 ROAS',
      ...PoP
    },

    {
      field: 'totalAction02Crr',
      headerName: 'Total Action 02 CRR',
      description: 'Total Action 02 CRR',
      type: 'number',
      width: 130
    },
    {
      field: 'totalAction02CrrPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Total Action 02 CRR',
      ...PoP
    },

    {
      field: 'totalAction03Cpa',
      headerName: 'Total Action 03 CPA',
      type: 'number',
      width: 130
    },
    {
      field: 'totalAction03CpaPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Total Action 03 CPA',
      ...PoP2
    },
    {
      field: 'totalAction03Value',
      headerName: 'Total Action 03 Value',
      type: 'number',
      width: 130
    },
    {
      field: 'totalAction03ValuePeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Total Action 03 Value',
      ...PoP
    },
    {
      field: 'totalAction03Roas',
      headerName: 'Total Action 03 ROAS',
      type: 'number',
      width: 130
    },
    {
      field: 'totalAction03RoasPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Total Action 03 ROAS',
      ...PoP
    },
    {
      field: 'totalAction03Crr',
      headerName: 'Total Action 03 CRR',
      type: 'number',
      width: 130
    },
    {
      field: 'totalAction03CrrPeriodOnPeriod',
      headerName: '%',
      width: 75,
      description: description + 'Total Action 03 CRR',
      ...PoP
    }
  ];

  let displayColumns = [];

  if (
    // time !== 'day'
    true
  ) {
    for (let column of ['date', ...columns]) {
      for (let columnType of columnTypes) {
        if (column === columnType.field) {
          displayColumns.push(columnType);
        }
        if (column + 'PeriodOnPeriod' === columnType.field) {
          displayColumns.push(columnType);
        }
      }
    }
  } else {
    for (let column of ['date', ...columns]) {
      for (let columnType of columnTypes) {
        if (column === columnType.field) {
          displayColumns.push(columnType);
        }
      }
    }
  }

  return (
    <div
      style={{
        height: 800,
        width: '100%',
        padding: '30px 40px 30px 30px',
        overflowX: 'scroll'
      }}
    >
      <DataGrid
        rows={rows}
        columns={displayColumns}
        pageSize={10}
        checkboxSelection
      />
    </div>
  );
}
