import React from 'react';

import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';

import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';

import FormatSizeIcon from '@mui/icons-material/FormatSize';

import FontDownloadIcon from '@mui/icons-material/FontDownload';

import RoundedCornerIcon from '@mui/icons-material/RoundedCorner';

import BorderColorIcon from '@mui/icons-material/BorderColor';

import BorderStyleIcon from '@mui/icons-material/BorderStyle';

import LineWeightIcon from '@mui/icons-material/LineWeight';

import OpacityIcon from '@mui/icons-material/Opacity';

import Tooltip from '@mui/material/Tooltip';

import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';

import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';

import WbShadeIcon from '@mui/icons-material/WbShade';

import TitleIcon from '@mui/icons-material/Title';

import LegendToggleIcon from '@mui/icons-material/LegendToggle';

import PaymentsIcon from '@mui/icons-material/Payments';
import RectangleTwoToneIcon from '@mui/icons-material/RectangleTwoTone';

import FilterBAndWIcon from '@mui/icons-material/FilterBAndW';

import TuneIcon from '@mui/icons-material/Tune';

import Brightness6Icon from '@mui/icons-material/Brightness6';
import ContrastIcon from '@mui/icons-material/Contrast';

import LensBlurIcon from '@mui/icons-material/LensBlur';

import InvertColorsIcon from '@mui/icons-material/InvertColors';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import DonutLargeIcon from '@mui/icons-material/DonutLarge';

import PercentIcon from '@mui/icons-material/Percent';
import MoneyIcon from '@mui/icons-material/Money';

function Icon(props) {
  let icon = <></>;
  let tooltipText = '';

  let valueKey = props.valueKey;

  switch (true) {
    case valueKey.includes('innerChartRadius'):
      icon = <DonutLargeIcon {...props} />;
      tooltipText = 'Opacity';
      break;
    case valueKey.includes('Opacity'):
      icon = <OpacityIcon {...props} />;
      tooltipText = 'Opacity';
      break;
    case valueKey.includes('FillColor'):
    case valueKey.includes('BackgroundColor'):
      icon = <FormatColorFillIcon {...props} />;
      tooltipText = 'Fill Color';
      break;

    case valueKey.includes('FontColor'):
      icon = <FormatColorTextIcon {...props} />;
      tooltipText = 'Font Color';
      break;

    case valueKey.includes('LineColor'):
    case valueKey.includes('BorderColor'):
      icon = <BorderColorIcon {...props} />;
      tooltipText = 'Line Color';
      break;

    case valueKey.includes('FontSize'):
      icon = <FormatSizeIcon {...props} />;
      tooltipText = 'Font Size';
      break;
    case valueKey.includes('Bold'):
      icon = <FormatBoldIcon {...props} />;
      tooltipText = 'Bold';
      break;
    case valueKey.includes('Italic'):
      icon = <FormatItalicIcon {...props} />;
      tooltipText = 'Italic';
      break;
    case valueKey.includes('FontFamily'):
      icon = <FontDownloadIcon {...props} />;
      tooltipText = 'Font Family';
      break;
    case valueKey.includes('BorderRadius'):
      icon = <RoundedCornerIcon {...props} />;
      tooltipText = 'Border Radius';
      break;
    case valueKey.includes('BorderStyle'):
      icon = <BorderStyleIcon {...props} />;
      tooltipText = 'Border Style';
      break;
    case valueKey.includes('BorderWidth'):
    case valueKey.includes('LineWidth'):
      icon = <LineWeightIcon {...props} />;
      tooltipText = 'Border Width';
      break;

    case valueKey.includes('HorizontalTextAlign'):
      icon = <FormatAlignCenterIcon {...props} />;
      tooltipText = 'Horizontal Text Align';
      break;
    case valueKey.includes('VerticalTextAlign'):
      icon = <VerticalAlignCenterIcon {...props} />;
      tooltipText = 'Vertical Text Align';
      break;

    case valueKey.includes('Shadow'):
      icon = <WbShadeIcon {...props} />;
      tooltipText = 'Shadow';
      break;

    case valueKey === 'isShowingTitle':
      icon = <TitleIcon {...props} />;
      tooltipText = 'Show Title';
      break;

    case valueKey === 'isShowingBottomAxisTitle':
      icon = <TitleIcon {...props} />;
      tooltipText = 'Bottom Axis Title';
      break;

    case valueKey === 'isShowingComparison':
      icon = <TitleIcon {...props} />;
      tooltipText = 'Show Comparison';
      break;

    case valueKey === 'isShowingLegend':
      icon = <LegendToggleIcon {...props} />;
      tooltipText = 'Legend';
      break;

    case valueKey === 'isShowingDataLabelPercent':
      icon = <PercentIcon {...props} />;
      tooltipText = 'Show Data Label Percent';
      break;
    case valueKey === 'isShowingDataLabelValue':
      icon = <MoneyIcon {...props} />;
      tooltipText = 'Show Data Label Value';
      break;

    case valueKey === 'isTextTruncated':
      icon = <MoreHorizIcon {...props} />;
      tooltipText = 'Truncated';
      break;

    case valueKey === 'isShowingCard':
      icon = <RectangleTwoToneIcon {...props} />;
      tooltipText = 'Card';
      break;

    case valueKey === 'isGrayscale':
    case valueKey.includes('Grayscale'):
      icon = <FilterBAndWIcon {...props} />;
      tooltipText = 'Grayscale';
      break;

    case valueKey.includes('Contrast'):
      icon = <ContrastIcon {...props} />;
      tooltipText = 'Contrast';
      break;

    case valueKey.includes('Brightness'):
      icon = <Brightness6Icon {...props} />;
      tooltipText = valueKey;
      break;

    case valueKey.includes('Blur'):
      icon = <LensBlurIcon {...props} />;
      tooltipText = valueKey;
      break;

    case valueKey.includes('Hue'):
    case valueKey.includes('Invert'):
      icon = <InvertColorsIcon {...props} />;
      tooltipText = valueKey;
      break;

    case valueKey.includes('Filter'):
      icon = <TuneIcon {...props} />;
      tooltipText = valueKey;
      break;
  }
  return icon;
}

export default Icon;
