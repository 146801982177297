import React, { useEffect, useState, useContext } from 'react';

import { useLazyQuery } from '@apollo/client';

import { customColumnSetsQuery } from './../graphql/queries';

import { AccountContext } from './AccountContext';

export const ColumnContext = React.createContext();

export const ColumnProvider = (props) => {
  const { currentManagerAccount } = useContext(AccountContext);

  // const [columnsUpdated, setColumnsUpdated] = useState(false);

  const [columns, updateColumns] = useState(
    localStorage.getItem('columns')
      ? JSON.parse(localStorage.getItem('columns'))
      : [
          // 'name',
          // 'type',
          'warnings',
          'status',
          'checkedAt',
          // 'notifications',
          'path',
          // 'comments',
          'members',
          'labels',
          'allowImpressionsBeforeStartDate',
          'dateRange',
          // 'startDate',
          // 'endDate',
          'allowImpressionsAfterEndDate',
          'numberOfDays',
          // 'numberOfDaysLeft',
          'daysLeft',
          'generalDescription',
          // 'buyingTypeDescription',
          // 'creativeDescription',
          // 'formatDescription',
          // 'channelDescription',
          // "audienceDataDescription",
          // 'targetGroupDescription',
          // 'placementDescription',
          'goalDescription',
          'budget',
          'budgetLeft',
          'budgetSpent',
          'timeSpent',
          'budgetCurrency',
          'allowFrontloadedDelivery',
          'allowMissingBudget',
          'calcDailyBudget',
          // 'plannedGross',
          // 'plannedNet',
          // 'plannedNetNet',
          // 'plannedCtc',

          // 'plannedTrp',
          // 'plannedTechFee',
          // 'plannedAdOpsFee',
          // 'plannedAdServingCost',
          // 'plannedFeePercent',

          //         'kpi',
          // 'plannedImpressions',
          // 'plannedClicks',
          // 'plannedCtr',
          // 'plannedCpc',
          // 'plannedReach',
          // 'plannedCpm',
          // 'plannedFrequency',
          // 'plannedActionCount',
          // 'plannedActionValue',
          // 'plannedActionRoas',
          // 'plannedCpa',
          'cost',
          'pace',
          'calcPaceYesterday',
          'impressions',
          'cpm',
          'videoCompletions',
          'vcr',
          'cpcv',
          'clicks',
          'allowMissingClicks',
          'allowAbnormallyHighCtr',
          // 'allowMissingImpressionsYesterday',
          'ctr',
          // 'viewableRate',

          // 'searchImpressionShare',

          'cpc',
          'action01',
          'action01Count',
          'action01Cvr',
          'action01Cpa',
          'action01Value',
          'action01Roas',
          'action01Crr',
          'action01Vpa',
          'ganttBarColor',
          'gantt'
        ]
  );

  const setColumns = (cols) => {
    updateColumns(cols);
    localStorage.setItem('columns', JSON.stringify(cols));
  };

  const variables = {
    customColumnSetsInput: { managerAccountId: currentManagerAccount._id }
  };

  const [
    loadColumns,
    { called, loading, data: columnData, refetch: refetchColumns }
  ] = useLazyQuery(customColumnSetsQuery, {
    variables
  });

  useEffect(() => {
    if (currentManagerAccount._id) {
      loadColumns();
    }
  }, [currentManagerAccount]);

  // ########################## update spend to cost script #######################
  // useEffect(() => {
  //   if (!columnsUpdated) {
  //     let update = false;
  //     const copyOfColumns = [...columns];

  //     const columnsToReplace = [
  //       'spend',
  //       'spendSparkline',
  //       'totalSpend',
  //       'spendToday',
  //       'spendYesterday',
  //       'action01Actions',
  //       'action02Actions',
  //       'action03Actions',
  //       'totalAction01Actions',
  //       'totalAction02Actions',
  //       'totalAction03Actions'
  //     ];

  //     const replaceDictionary = {
  //       spend: 'cost',
  //       spendSparkline: 'costSparkline',
  //       totalSpend: 'totalCost',
  //       spendToday: 'spendToday',
  //       spendYesterday: 'spendYesterday',
  //       action01Actions: 'action01Count',
  //       action02Actions: 'action02Count',
  //       action03Actions: 'action03Count',
  //       totalAction01Actions: 'totalAction01Count',
  //       totalAction02Actions: 'totalAction02Count',
  //       totalAction03Actions: 'totalAction03Count'
  //     };

  //     for (let col of columnsToReplace) {
  //       var index = copyOfColumns.indexOf(col);

  //       if (index !== -1) {
  //         update = true;
  //         copyOfColumns[index] = replaceDictionary[col];
  //       }
  //     }

  //     if (update) {
  //       setColumns(copyOfColumns);
  //     }
  //     setColumnsUpdated(true);
  //   }
  // }, [columns]);

  // ########################## end of update spend to cost script #######################

  return (
    <ColumnContext.Provider
      value={{
        columns,
        setColumns,
        columnData,
        refetchColumns
      }}
    >
      {props.children}
    </ColumnContext.Provider>
  );
};
